import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import stall1 from "./img/VR-02/VR-2 6 GRIDS.jpg";
import image from "./img/rs1.png";
import image1 from "./img/rs2.png";
import Modal from "react-modal";
import AWSService from "../services/aws.service";
import EventService from "../services/event.service";
import PPTPathService from "../services/pptpath.service";
import UnauthorizedService from "../common/unauthorized";
import UnAuthorizedService from "../common/unauthorized";
import AuthService from "../services/auth.service";
import { Uploader } from "../utils/upload";
import Progressbar from "../common/progressbar";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./styles/maping.css";
import $ from "jquery";
import "../App.css";
var lobbyeventname = "";
let tenant = "";
const { aws_s3_url, aws_s3_bucket_name } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "25vh",
    marginRight: "-50%",
    width: "calc(18% - 0px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "rgba(0,0,0,.9)",
    background: "unset !important",
    border: "unset !important",

    zIndex: "99999",
    backgroundcolor: "unset !important",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(20% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding: "0 0 0 0",
  },
};
class ResorceEdit5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initially, no file is selected
      selectedFile: null,
      poster1: false,
      poster1za: false,
      poster1zb: false,
      poster1zc: false,
      poster1zd: false,
      poster1ze: false,
      poster1zf: false,
      poster2: false,
      poster3: false,
      poster3edit5: false,

      poster4: false,
      poster5: false,
      poster6: false,
      poster6g02: false,
      poster6g03: false,
      poster6g04: false,
      poster6g05: false,
      poster6g06: false,






      R4poster1path: "",
      R4poster1pathza: "",
      R4poster1pathzb: "",
      R4poster1pathzc: "",
      R4poster1pathzd: "",
      R4poster1pathze: "",
      R4poster1pathzf: "",
      R4poster2path: "",
      R4poster3path: "",
      R4poster3edit5path: "",

      R4poster4path: "",
      R4poster5path: "",
      R4poster6path: "",
      R4poster6g02path: "",
      R4poster6g03path: "",
      R4poster6g04path: "",
      R4poster6g05path: "",
      R4poster6g06path: "",



      modalIsOpen: false,
      modalIsOpen5a: false,
      modalIsOpen5b: false,
      modalIsOpen5c: false,
      modalIsOpen5d: false,
      modalIsOpen5e: false,






      tenantname: tenant,
      localuploader: undefined,
      progressbar: 0,
    };
  }
  P1openModal = () => {
    this.setState({ modalIsOpen: true });
  };
  P1openModal5a = () => {
    this.setState({ modalIsOpen5a: true });
  };
  P1openModal5b = () => {
    this.setState({ modalIsOpen5b: true });
  };
  P1openModal5c = () => {
    this.setState({ modalIsOpen5c: true });
  };
  P1openModal5d = () => {
    this.setState({ modalIsOpen5d: true });
  };
  P1openModal5e = () => {
    this.setState({ modalIsOpen5e: true });
  };


  P1closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  P1closeModal5a = () => {
    this.setState({ modalIsOpen5a: false });
  };
  P1closeModal5b = () => {
    this.setState({ modalIsOpen5b: false });
  };
  P1closeModal5c = () => {
    this.setState({ modalIsOpen5c: false });
  };
  P1closeModal5d = () => {
    this.setState({ modalIsOpen5d: false });
  };
  P1closeModal5e = () => {
    this.setState({ modalIsOpen5e: false });
  };
  onFileChange01za = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange1za()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster1za.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader1").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster2zabar").show();
             $(".poster2zabarbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader1za").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R4poster1zapath:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs4" +
                  "/poster1za.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs3",
                "poster1za",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader1za").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChange01zb = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChangezb()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster1zb.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader1zb").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster2zbbar").show();
             $(".poster2zbbarbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader1zb").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R4poster1zbpath:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs4" +
                  "/poster1zb.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs3",
                "poster1zb",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader1zb").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChange01zc = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChangezc()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster1zc.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader1zc").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster2zcbar").show();
             $(".poster2zcbarbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader1zc").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R4poster1zcpath:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs4" +
                  "/poster1zc.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs3",
                "poster1zc",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader1zc").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChange01zd = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChangezd()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster1zd.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader1zd").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster2zdbar").show();
             $(".poster2zdbarbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader1zd").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R4poster1zdpath:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs4" +
                  "/poster1zd.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs3",
                "poster1zd",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader1zd").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChange01ze = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChangeze()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster1ze.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader1ze").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster2zebar").show();
             $(".poster2zebarbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader1ze").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R4poster1zepath:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs4" +
                  "/poster1ze.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs3",
                "poster1ze",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader1ze").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChange01zf = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChangezf()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster1zf.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader1zf").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster2zfbar").show();
             $(".poster2zfbarbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader1zf").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R4poster1zfpath:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs4" +
                  "/poster1zf.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs3",
                "poster1zf",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader1zf").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChange02 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  690 || this.height != 77) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 690 + " Height= " + 77
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs4f" + "/poster2f.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader2").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster2bar").show();
             $(".poster2barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader2").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R4poster2path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs4f" +
                  "/poster2f.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs4",
                "poster2",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader2").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };

  onFileChange03 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster3", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange03edit5 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster3edit5", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange6g02 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster6g02", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };
  onFileChange6g03 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster6g03", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };
  onFileChange6g04 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster6g04", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };
  onFileChange6g05 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster6g05", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange6g06 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster6g06", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };
// On file select (from the pop up)
 onFileChange04 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs4e5" + "/poster4" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs4",
            "poster4",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange5a = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs4" + "/poster5apdf" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs4",
            "poster5apdf",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange5b = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs4" + "/poster5bpdf" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs4",
            "poster5bpdf",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange5c= async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs4" + "/poster5cpdf" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs4",
            "poster5cpdf",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange5d= async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs4" + "/poster5dpdf" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs4",
            "poster5dpdf",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange5e = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs4" + "/poster5epdf" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs4",
            "poster5epdf",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};

  componentDidMount() {
    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname = EventService.getCurrentEvent();
    const filepath = "capgemini/" + lobbyeventname + "/Rs4" + "/poster1";
    console.log(`filepath= ${filepath}`);
    PPTPathService.getFiletype(eventname, "Rs4")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster3") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4poster3path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs4" +
              "/poster3" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster3: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4poster3path:filedetails.path,
                poster3: true,

              });
            this.setState({ R4poster3type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventnameedit5 = EventService.getCurrentEvent();
    const filepathedit5 = "capgemini/" + lobbyeventname + "/Rs4" + "/poster3edit5";
    console.log(`filepath= ${filepathedit5}`);
    PPTPathService.getFiletype(eventnameedit5, "Rs4")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster3edit5") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4poster3edit5path:
              AWS_S3_URL +
              "capgemini/" +
              eventnameedit5 +
              "/Rs4" +
              "/poster3edit5" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster3edit5: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4poster3edit5path:filedetails.path,
                poster3edit5: true,

              });
            this.setState({ R4poster3edit5type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname6g02 = EventService.getCurrentEvent();
    const filepath6g02 = "capgemini/" + lobbyeventname + "/Rs4" + "/poster6g02";
    console.log(`filepath= ${filepath6g02}`);
    PPTPathService.getFiletype(eventname6g02, "Rs4")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster6g02") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4poster6g02path:
              AWS_S3_URL +
              "capgemini/" +
              eventname6g02 +
              "/Rs4" +
              "/poster6g02" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster6g02: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4poster6g02path:filedetails.path,
                poster6g02: true,

              });
            this.setState({ R4poster6g02type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname6g03 = EventService.getCurrentEvent();
    const filepath6g03 = "capgemini/" + lobbyeventname + "/Rs4" + "/poster6g03";
    console.log(`filepath= ${filepath6g03}`);
    PPTPathService.getFiletype(eventname6g03, "Rs4")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster6g03") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4poster6g03path:
              AWS_S3_URL +
              "capgemini/" +
              eventname6g03 +
              "/Rs4" +
              "/poster6g03" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster6g03: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4poster6g03path:filedetails.path,
                poster6g03: true,

              });
            this.setState({ R4poster6g03type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname6g04 = EventService.getCurrentEvent();
    const filepath6g04 = "capgemini/" + lobbyeventname + "/Rs4" + "/poster6g04";
    console.log(`filepath= ${filepath6g04}`);
    PPTPathService.getFiletype(eventname6g04, "Rs4")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster6g04") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4poster6g04path:
              AWS_S3_URL +
              "capgemini/" +
              eventname6g04 +
              "/Rs4" +
              "/poster6g04" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster6g04: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4poster6g04path:filedetails.path,
                poster6g04: true,

              });
            this.setState({ R4poster6g04type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname6g05 = EventService.getCurrentEvent();
    const filepath6g05 = "capgemini/" + lobbyeventname + "/Rs4" + "/poster6g05";
    console.log(`filepath= ${filepath6g05}`);
    PPTPathService.getFiletype(eventname6g05, "Rs4")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster6g05") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4poster6g05path:
              AWS_S3_URL +
              "capgemini/" +
              eventname6g05 +
              "/Rs4" +
              "/poster6g05" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster6g05: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4poster6g05path:filedetails.path,
                poster6g05: true,

              });
            this.setState({ R4poster6g05type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname6g06 = EventService.getCurrentEvent();
    const filepath6g06 = "capgemini/" + lobbyeventname + "/Rs4" + "/poster6g06";
    console.log(`filepath= ${filepath6g06}`);
    PPTPathService.getFiletype(eventname6g06, "Rs4")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster6g06") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4poster6g06path:
              AWS_S3_URL +
              "capgemini/" +
              eventname6g06 +
              "/Rs4" +
              "/poster6g06" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster6g06: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4poster6g06path:filedetails.path,
                poster6g06: true,

              });
            this.setState({ R4poster6g06type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    EventService.getSpecificEvent()
    .then((response) => {
      console.log("Eventname from getSpecificEvent");
      console.log(response.data);
      console.log(response.data.eventname);
      const eventpath = "/" + response.data.eventname;
      this.setState({ tenantname: eventpath, showLoginPage: true });
      localStorage.setItem("eventname", response.data.eventname);
      console.log(response.data.eventname);

      const user=AuthService.getCurrentUser();

      AuthService.getCurrentRole().then((roles)=>{
        if (roles) {
          console.log("Got current role from server");
          this.setState({
            currentUser: user,
            showModeratorBoard: roles.data.includes("ROLE_MODERATOR"),
            showAdminBoard: roles.data.includes("ROLE_ADMIN"),
            showAddTenant: roles.data.includes("ROLE_SUPERADMIN"),
            showAddUser: roles.data.includes("ROLE_SUPERADMIN"),
            showRegModerators:
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showRegAdmins:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN"),
            showRegSuperAdmins: roles.data.includes("ROLE_SUPERADMIN"),
            showRegUsers:
              roles.data.includes("ROLE_MODERATOR") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showCreateEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showDetailLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showFeedbackReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEdit:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showNormalUser: roles.data.includes("ROLE_USER"),
            showTenants:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            //tenantname: tenant,
          });
        }



    this.setState({
      R4poster1path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster1.png?v=" +
        num,
        R4poster1zapath:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster1za.png?v=" +
        num,
        R4poster1zbpath:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster1zb.png?v=" +
        num,
        R4poster1zcpath:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster1zc.png?v=" +
        num,
        R4poster1zdpath:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster1zd.png?v=" +
        num,
        R4poster1zepath:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster1ze.png?v=" +
        num,
        R4poster1zfpath:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster1zf.png?v=" +
        num,
      R4poster2path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4f" +
        "/poster2f.png?v=" +
        num,
        R4poster6path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster6.png?v=" +
        num,
      // Rposter3path:
      //   AWS_S3_URL +
      //   "capgemini/" +
      //   lobbyeventname +
      //   "/Rs4" +
      //   "/poster3.txt?v=" +
      //   num,
        R4poster4path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster4.pdf?v=" +
        num,

        Rposter6g02path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster6g02.txt?v=" +
        num,
        Rposter6g03path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster6g03.txt?v=" +
        num,
        Rposter6g04path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster6g04.txt?v=" +
        num,
        Rposter6g05path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster6g05.txt?v=" +
        num,
        Rposter6g06path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster6g06.txt?v=" +
        num,
        Rposter3edit5path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster3edit5.txt?v=" +
        num,


      poster1: true,
      poster1za: true,
      poster1zb: true,
      poster1zc: true,
      poster1zd: true,
      poster1ze: true,
      poster1zf: true,
      poster2: true,
      poster3: true,
      poster6: true,
      poster6g02: true,
      poster6g03: true,
      poster6g04: true,
      poster6g05: true,
      poster6g06: true,
      poster3edit5: true,





    });
  })
}
    )
  }

  render() {
    function hideImg() {
      document.getElementById("myImg").style.display = "none";
    }
    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }

    function hideImgza() {
      document.getElementById("myImgza").style.display = "none";
    }
    function hideImgzb() {
      document.getElementById("myImgzb").style.display = "none";
    }
    function hideImgzc() {
      document.getElementById("myImgzc").style.display = "none";
    }
    function hideImgzd() {
      document.getElementById("myImgzd").style.display = "none";
    }
    function hideImgze() {
      document.getElementById("myImgze").style.display = "none";
    }
    function hideImgzf() {
      document.getElementById("myImgzf").style.display = "none";
    }

    const {
      poster1,
      poster1za,
      poster1zb,
      poster1zc,
      poster1zd,
      poster1ze,
      poster1zf,
      poster2,
      poster3,
      poster6,
      poster6g02,
      poster6g03,
      poster6g04,
      poster6g05,
      poster6g06,
      poster3edit5,






      R4poster1path,
      R4poster1zapath,
      R4poster1zbpath,
      R4poster1zcpath,
      R4poster1zdpath,
      R4poster1zepath,
      R4poster1zfpath,
      R4poster2path,
      R4poster3path,
      R4poster6path,
      R4poster6g02path,
      R4poster6g03path,
      R4poster6g04path,
      R4poster6g05path,
      R4poster6g06path,
      R4poster3edit5path,




      tenantname,
      progressbar,
    } = this.state;

    return (
      <div className="container-fluide">


        <div className="page-content">
          <div id="content-agenda">
            <div id="bg">
              <img src="https://d2e8va4puks5pl.cloudfront.net/VR-02/VR-2%206%20GRIDS.jpg" id="bg-image" className="mb-3" />
              <div className="sidenavogation">
                  {/* <Link to={tenantname + "/ResourceEdit"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Resource Center1</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit1"} className="Rs2  text-white buttonRes">
                              <span className="hide-menu">Resource Center2</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit2"} className="Rs3  text-white buttonRes">
                              <span className="hide-menu">Resource Center3</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit3"} className="Rs4  text-white buttonRes">
                              <span className="hide-menu">Resource Center4</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit4"} className="Rs5  text-white buttonRes">
                              <span className="hide-menu">Resource Center5</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit5"} className="Rs5  text-white buttonRes">
                              <span className="hide-menu">Resource Center6</span>
                  </Link> */}

                  <Link to={tenantname + "/agendaEdit"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Back To Agenda</span>
                  </Link>
                  </div>
              <div id="Resource4_g01">
                {poster1za && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader1za"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInputza.click()}
                    >
                      <img
                        src={R4poster1zapath}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImgza()}
                        id="myImgza"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInputza = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange01za}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>
              <div id="Resource4_g02">
                {poster1zb && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader1zb"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInputzb.click()}
                    >
                      <img
                        src={R4poster1zbpath}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImgzb()}
                        id="myImgzb"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInputzb = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange01zb}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>
              <div id="Resource4_g03">
                 {poster1zc && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader1zc"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInputzc.click()}
                    >
                      <img
                        src={R4poster1zcpath}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImgzc()}
                        id="myImgzc"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInputzc = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange01zc}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>
              <div id="Resource4_g04">
                {poster1zd && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader1zd"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInputzd.click()}
                    >
                      <img
                        src={R4poster1zdpath}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImgzd()}
                        id="myImgzd"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInputzd = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange01zd}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>
              <div id="Resource4_g05">
                {poster1ze && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader1ze"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInputze.click()}
                    >
                      <img
                        src={R4poster1zepath}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImgze()}
                        id="myImgze"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInputze = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange01ze}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>
              <div id="Resource4_g06">
                {poster1zf && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader1zf"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInputzf.click()}
                    >
                      <img
                        src={R4poster1zfpath}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImgzf()}
                        id="myImgzf"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInputzf = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange01zf}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource5">
                {poster2 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader2"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap5"
                      onClick={(e) => this.myInput1.click()}
                    >
                      <img
                        src={R4poster2path}
                        // src={image1}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg1()}
                        id="myImg1"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput1 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange02}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource6_g01">
                {poster3edit5 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange03edit5}
                        id="ResText"
                        placeholder={R4poster3edit5path}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource6_g02">
                {poster6g02 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange6g02}
                        id="ResText"
                        placeholder={R4poster6g02path}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource6_g03">
                {poster6g03 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange6g03}
                        id="ResText"
                        placeholder={R4poster6g03path}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource6_g04">
                {poster6g04 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange6g04}
                        id="ResText"
                        placeholder={R4poster6g04path}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource6_g05">
                {poster6g05 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange6g05}
                        id="ResText"
                        placeholder={R4poster6g05path}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource6_g06">
                {poster6g06 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange6g06}
                        id="ResText"
                        placeholder={R4poster6g06path}
                      />
                    </div>
                  </form>
                )}
              </div>

          <div id="ResourceCenter_g01">
          <div className="hotspot1_g01">
            {/* <div className="out"></div> */}
            <div className="in1_g01" onClick={this.P1openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen}
                  style={customStyles}
                  onRequestClose={this.P1closeModal}
                >
                  <span className="close" onClick={this.P1closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput23.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput24.click()}>Upload Video  </button>
                  <div id="loader4" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput23 = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange04}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput24 = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange04}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>
        <div id="ResourceCenter_g02">
          <div className="hotspot1_g02">
            {/* <div className="out"></div> */}
            <div className="in1_g02" onClick={this.P1openModal5a}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen5a}
                  style={customStyles}
                  onRequestClose={this.P1closeModal5a}
                >
                  <span className="close" onClick={this.P1closeModal5a}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5a.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5av.click()}>Upload Video  </button>
                  <div id="loader5a" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput5a = ref)}
            type="file"
            name="file"
            id="vid5a"
            onChange={this.onFileChange5a}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput5av = ref)}
            type="file"
            name="file"
            id="vid5a"
            onChange={this.onFileChange5a}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>
        <div id="ResourceCenter_g03">
          <div className="hotspot1_g03">
            {/* <div className="out"></div> */}
            <div className="in1_g03" onClick={this.P1openModal5b}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen5b}
                  style={customStyles}
                  onRequestClose={this.P1closeModal5b}
                >
                  <span className="close" onClick={this.P1closeModal5b}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5b.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5bv.click()}>Upload Video  </button>
                  <div id="loader5b" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput5b = ref)}
            type="file"
            name="file"
            id="vid5b"
            onChange={this.onFileChange5b}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput5bv = ref)}
            type="file"
            name="file"
            id="vid5b"
            onChange={this.onFileChange5b}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>
        <div id="ResourceCenter_g04">
          <div className="hotspot1_g04">
            {/* <div className="out"></div> */}
            <div className="in1_g04" onClick={this.P1openModal5c}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen5c}
                  style={customStyles}
                  onRequestClose={this.P1closeModal5c}
                >
                  <span className="close" onClick={this.P1closeModal5c}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5c.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5cv.click()}>Upload Video  </button>
                  <div id="loader5c" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput5c = ref)}
            type="file"
            name="file"
            id="vid5c"
            onChange={this.onFileChange5c}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput5cv = ref)}
            type="file"
            name="file"
            id="vid5c"
            onChange={this.onFileChange5c}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>
        <div id="ResourceCenter_g05">
          <div className="hotspot1_g05">
            {/* <div className="out"></div> */}
            <div className="in1_g05" onClick={this.P1openModal5d}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen5d}
                  style={customStyles}
                  onRequestClose={this.P1closeModal5d}
                >
                  <span className="close" onClick={this.P1closeModal5d}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5d.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5dv.click()}>Upload Video  </button>
                  <div id="loader5d" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput5d = ref)}
            type="file"
            name="file"
            id="vid5d"
            onChange={this.onFileChange5d}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput5dv = ref)}
            type="file"
            name="file"
            id="vid5d"
            onChange={this.onFileChange5d}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>
        <div id="ResourceCenter_g06">
          <div className="hotspot1_g06">
            {/* <div className="out"></div> */}
            <div className="in1_g06" onClick={this.P1openModal5e}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen5e}
                  style={customStyles}
                  onRequestClose={this.P1closeModal5e}
                >
                  <span className="close" onClick={this.P1closeModal5e}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5e.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5ev.click()}>Upload Video  </button>
                  <div id="loader5e" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput5e = ref)}
            type="file"
            name="file"
            id="vid5e"
            onChange={this.onFileChange5e}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput5ev = ref)}
            type="file"
            name="file"
            id="vid5e"
            onChange={this.onFileChange5e}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        {/* <div id="Resource8">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap8"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R4poster6path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput6 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange06}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ResorceEdit5;
