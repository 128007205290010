import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EventService from "../services/event.service";
import Form from "react-validation/build/form";
import bg from "./img/3.png";
import bg1 from "./img/02.jpg";
import refresh from "./img/refresh.jpg";
import "./styles/login.css";
import CheckButton from "react-validation/build/button";

import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
let captcha_value = "";
let captcha_number = "";
let backgroundColor_value = "";
let fontColor_value = "";
let charMap_value = "";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};
const Login = ({ onRoleSet }) => {
  //const location = useHistory();
  // var ind1 = location.pathname.indexOf("/");
  // var ind2 = location.pathname.lastIndexOf("/");
  // var domain = location.pathname.slice(ind1, ind2);
  //const { tenantname } = location.state;
  // console.log(location);
  //console.log(tenantname);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [checkBtn, setCheckBtn] = useState();
  const [form, setForm] = useState([]);
  //  const [captchaload, setcaptchaload] = useState(false);
  const [captchavalue, setChaptcha] = useState();
  const [userCaptcha, setuserCaptcha] = useState("");
  const [captcha_id, setcaptchaid] = useState("");
  useEffect(() => {
    // loadCaptchaEnginge(6);
    refreshCaptcha();
  }, []);
  const refreshCaptcha = () => {
    AuthService.getCaptcha()
      .then((response) => {
        // console.log(response.data);
        setChaptcha(response.data.Captcha);
        setcaptchaid(response.data.captcha_id);
      })
      .catch((error) => {
        console.error("Error fetching Captcha values:", error);
      });
  };
  const navigate = useNavigate();
  const user = AuthService.getCurrentUser();

  if (user) {
    localStorage.setItem("intro", 0);
    const eventname = "/" + EventService.getCurrentEvent();
    navigate(eventname + "/lobby");
    window.location.reload();
  }

  const onChangeEmail = async (e) => {
    setEmail(e.target.value);
  };

  const onChangePassword = async (e) => {
    setPassword(e.target.value);
  };
  const onChangeuserCaptha = async (e) => {
    setuserCaptcha(e.target.value);
  };
  const recoverpassword = async (e) => {
    setMessage("");
    setLoading(true);

    console.log("Inside recover password call ...............................");
    // if (checkBtn.context._errors.length === 0) {
    if (email.length > 0) {
      UserService.forgotpassword(email).then(
        () => {
          setMessage("Reset Password sent to your mail id");
          setLoading(false);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
          setLoading(false);
        }
      );
    } else {
      console.log(
        "Error checkbtn.context Inside recover password call ..............................."
      );
      setLoading(false);
      setMessage("Enter the email for password reset");
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    // console.log(location.pathname);
    // var ind1 = location.pathname.indexOf("/");
    // var ind2 = location.pathname.lastIndexOf("/");
    // var domain = location.pathname.slice(ind1, ind2);
    // console.log(domain);
    // console.log(location);
    //const { tenantname } = location.state;
    setMessage("");
    setLoading(true);

    form.validateAll();

    // if (userCaptcha !== captchavalue ) {
    //   setMessage("Captcha Does Not Match");
    //   setLoading(false);
    //   refreshCaptcha();
    //   document.getElementById("user_captcha_input").value = "";
    // } else {

    // }
    document.getElementById("user_captcha_input").value = "";
    if (checkBtn.context._errors.length === 0) {
      setMessage("");
      setLoading(true);
      AuthService.login(email, password, userCaptcha, captcha_id).then(
        (data) => {
          //onRoleSet(data.roles);
          //location.push("/enter");
          //const usr = AuthService.getCurrentUser();
          //if (usr.roles.includes("ROLE_USER")) navigate("/Resource");
          //navigate("/enter");
          //localStorage.setItem("intro", 0);
          console.log(
            "entry video........................................................"
          );
          const eventname = "/" + EventService.getCurrentEvent();
          navigate(eventname + "/enter", data.roles);
          console.log(data);
          window.location.reload();
        },
        (error) => {
          if (error.response.data.Response == "Invalid Captcha") {
            refreshCaptcha();
            setMessage(error.response.data.Response);
            setLoading(false);
          } else {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            refreshCaptcha();
            setMessage(resMessage);
            setLoading(false);
          }
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <div class="container-fluid text-center" id="body">
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      <div class="row content">
        <div class="col-sm-8 text-left image">
          <img src={bg} class="capimg" alt="Capemini background" width="100%" />
        </div>

        <div class="col-sm-3 sidenav">
          <div class="top">
            <div class="well">
              <img src={bg1} alt="Girl in a jacket" width="100%" />
            </div>
            <Form
              onSubmit={handleLogin}
              ref={(c) => {
                setForm(c);
              }}
            >
              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
              <h2 className="text-primary loginh text-left pt-5">LOGIN</h2>
              <CheckButton
                style={{ display: "none" }}
                ref={(c) => {
                  setCheckBtn(c);
                }}
              />
              <div className="input-icons">
                <i className="fa fa-user fa-lg icon"></i>
                <input
                  type="text"
                  className="input-field input text-left"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required]}
                  placeholder="Enter your Email ID"
                />
              </div>
              <div className="input-icons">
                <i className="fa fa-lock fa-lg icon "></i>
                <input
                  type="password"
                  className="input-field input text-left "
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required]}
                  placeholder="Enter your Password"
                />
              </div>

              {/* <div class="input-icons">
                <div className="form-group ml-3  recovery">
                  <a id="reload_href" href="#" style={{ cursor: "pointer" }}>
                    Reload Captcha
                  </a>
                </div>
                <div className="form-group ml-4  recovery">
                  <canvas id="canv" align="left"></canvas>
                </div>
              </div> */}
              <div class="d-flex flex-nowrap">
                <div className="order-2">
                  {/* <h1 class="bg-white">{captchavalue}</h1> */}
                  <img src={captchavalue} className="captchavalue" />
                </div>

                <div className="order-3 ml-3" recovery>
                  <a id="reload_href" href="#" onClick={refreshCaptcha}>
                    {/* Reload Captcha */}
                    <img src={refresh} className="refresh" />
                  </a>
                </div>
              </div>
              <div className="input-icons mt-2">
                <i className="fa fa-key fa-lg icon "></i>
                <input
                  id="user_captcha_input"
                  type="text"
                  className="input-field input text-left"
                  name="captcha"
                  placeholder="Enter Capctha"
                  autoComplete="off"
                  onChange={onChangeuserCaptha}
                />
              </div>

              <div className="form-group ml-3  recovery">
                <a
                  //href="./login"
                  href="#"
                  className=" nav-link "
                  onClick={recoverpassword}
                >
                  Forgot Password
                </a>
              </div>
              <div className="form-group mb-5">
                <button
                  className="btn btn-primary btn-block input"
                  style={{ backgroundColor: "#0070AD" }}
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Submit</span>
                </button>
              </div>
            </Form>
          </div>
        </div>
        <div className="ml-4 pt-4 mail">
          <a href="mailto:cvebsv.in@capgemini.com">
            <i class="fa fa-envelope fa-2x text-white" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
