import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import stall1 from "./img/VR-01/VR-1 4 GRID.jpg";
import image from "./img/rs1.png";
import image1 from "./img/rs2.png";
import Modal from "react-modal";
import AWSService from "../services/aws.service";
import EventService from "../services/event.service";
import PPTPathService from "../services/pptpath.service";
import UnAuthorizedService from "../common/unauthorized";
import AuthService from "../services/auth.service";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./styles/maping.css";
import $ from "jquery";
import "../App.css";
var lobbyeventname = "";
let tenant = "";
const { aws_s3_url, aws_s3_bucket_name } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "70vh",
    marginRight: "-50%",
    width: "calc(60% - 0px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    border: "unset !important",
    margintop: "calc(85vh - 10vh - 10px)",
    zIndex: "99999",
    backgroundcolor: "unset !important",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(50% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding: "0 0 0 0",
  },
};
class Resource3G2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initially, no file is selected
      selectedFile: null,
      poster1: false,
      poster2: false,
      poster313g2: false,
      poster4: false,
      poster6: false,
      poster1r: false,
      poster1r1: false,
      poster1r2: false,
      poster313g2reso: false,
      poster313g2reso1: false,
      poster313g2reso2: false,
      poster3g2: false,
      poster3g2a: false,
      poster3g2b: false,
      // poster3g2c: false,





      R3poster1path : "",
      R3poster2path : "",
      R3poster313g2path : "",
      R3poster4path : "",
      R3poster6path : "",
      R3poster1rpath : "",
      R3poster1r1path : "",
      R3poster1r2path : "",
      R3poster313g2resopath : "",
      R3poster313g2reso1path : "",
      R3poster313g2reso2path : "",
      R3poster3g2path : "",
      R3poster3g2apath : "",
      R3poster3g2bpath : "",
      // R3poster3g2cpath : "",







      R3poster313g2type: "",
      R3poster313g2resotype: "",
      R3poster313g2reso1type: "",
      R3poster313g2reso2type: "",

      R3poster4type: "",
      R3poster3g2type: "",
      R3poster3g2atype: "",
      R3poster3g2btype: "",
      // R3poster3g2ctype: "",

      modalIsOpen: false,
      modal1IsOpen: false,
      modal2IsOpen: false,
      tenantname: tenant,
    };
  }

  P1openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  P1closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  P2openModal = () => {
    this.setState({ modal1IsOpen: true });
  };

  P2closeModal = () => {
    this.setState({ modal1IsOpen: false });
  };
  P3openModal = () => {
    this.setState({ modal2IsOpen: true });
  };

  P3closeModal = () => {
    this.setState({ modal2IsOpen: false });
  };




  P1openModal1 = () => {
    this.setState({ modalIsOpen1: true });
  };

  P1closeModal1 = () => {
    this.setState({ modalIsOpen1: false });
  };

  P2openModal1 = () => {
    this.setState({ modal1IsOpen1: true });
  };

  P2closeModal1 = () => {
    this.setState({ modal1IsOpen1: false });
  };
  P3openModal1 = () => {
    this.setState({ modal2IsOpen1: true });
  };

  P3closeModal1 = () => {
    this.setState({ modal2IsOpen1: false });
  };




  P1openModal2 = () => {
    this.setState({ modalIsOpen2: true });
  };

  P1closeModal2 = () => {
    this.setState({ modalIsOpen2: false });
  };

  P2openModal2 = () => {
    this.setState({ modal1IsOpen2: true });
  };

  P2closeModal2 = () => {
    this.setState({ modal1IsOpen2: false });
  };
  P3openModal2 = () => {
    this.setState({ modal2IsOpen2: true });
  };

  P3closeModal2 = () => {
    this.setState({ modal2IsOpen2: false });
  };


  P1openModal3 = () => {
    this.setState({ modalIsOpen3: true });
  };

  P1closeModal3 = () => {
    this.setState({ modalIsOpen3: false });
  };

  P2openModal3 = () => {
    this.setState({ modal1IsOpen3: true });
  };

  P2closeModal3 = () => {
    this.setState({ modal1IsOpen3: false });
  };
  P3openModal3 = () => {
    this.setState({ modal2IsOpen3: true });
  };

  P3closeModal3 = () => {
    this.setState({ modal2IsOpen3: false });
  };





  componentDidMount() {

    localStorage.setItem("intro", 0);
    const eventname = EventService.getCurrentEvent();
    console.log(eventname);
    var num = Math.random();
    PPTPathService.getFiletype(eventname, "Rs3")
      .then((response) => {
        response.data.forEach((filedetails) => {
          if (filedetails.filename === "poster313g2") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R3poster313g2path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3" +
              "/poster313g213g2" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
              poster313g213g2: true,
            });
            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R3poster313g2path:filedetails.path,
                  poster313g2: true,

                });
              this.setState({ R3poster313g2type: "txt" });
            }
          }

          if (filedetails.filename === "poster313g21") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R3poster313g2resopath:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3" +
              "/poster313g21" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster313g21: true,
            });
            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R3poster313g2resopath:filedetails.path,
                  poster313g2reso: true,

                });
              this.setState({ R3poster313g2resotype: "txt" });
            }
          }

          if (filedetails.filename === "poster313g22") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R3poster313g2reso1path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3" +
                "/poster313g22" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster313g22: true,
            });
            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R3poster313g2reso1path:filedetails.path,
                  poster313g2reso1: true,

                });
              this.setState({ R3poster313g2reso1type: "txt" });
            }
          }

          if (filedetails.filename === "poster313g23") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R3poster313g2reso2path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3" +
                "/poster313g23" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster313g23: true,
            });
            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R3poster313g2reso2path:filedetails.path,
                  poster313g2reso2: true,

                });
              this.setState({ R3poster313g2reso2type: "txt" });
            }
          }



        if (filedetails.filename == "poster4") {
            this.setState({
              R3poster4path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3g2" +
                "/poster4" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster4: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R3poster4type: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R3poster4path:filedetails.path,
                  poster4: true,

                });
                this.setState({ R3poster4type: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R3poster4type: "pdf" });
              }
            }
            console.log(this.state.R3poster4path);
          }

          if (filedetails.filename == "poster3g2") {
            this.setState({
              R3poster3g2path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3" +
                "/poster3g2" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
                poster3g2: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R3poster3g2type: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R3poster3g2path:filedetails.path,
                  poster3g2: true,

                });
                this.setState({ R3poster3g2type: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R3poster3g2type: "pdf" });
              }
            }
            console.log(this.state.R3poster3g2path);
          }



          if (filedetails.filename == "poster3g2a") {
            this.setState({
              R3poster3g2apath:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3" +
                "/poster3g2a" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
                poster3g2a: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R3poster3g2atype: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R3poster3g2apath:filedetails.path,
                  poster3g2a: true,

                });
                this.setState({ R3poster3g2atype: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R3poster3g2atype: "pdf" });
              }
            }
            console.log(this.state.R3poster3g2apath);
          }



          if (filedetails.filename == "poster3g2bpdf") {
            this.setState({
              R3poster3g2bpath:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3" + "/poster3g2bpdf" + "." +
                filedetails.filetype +
                "?v=" +
                num,
                poster3g2b: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R3poster3g2btype: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R3poster3g2bpath:filedetails.path,
                  poster3g2b: true,

                });
                this.setState({ R3poster3g2btype: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R3poster3g2btype: "pdf" });
              }
            }
            console.log(this.state.R3poster3g2bpath);
          }






        })
      })

      .catch((err) => {
        UnAuthorizedService.unauthorized(err);
      });

    EventService.getSpecificEvent()
    .then((response) => {
      console.log("Eventname from getSpecificEvent");
      console.log(response.data);
      console.log(response.data.eventname);
      const eventpath = "/" + response.data.eventname;
      this.setState({ tenantname: eventpath, showLoginPage: true });
      localStorage.setItem("eventname", response.data.eventname);
      console.log(response.data.eventname);

      const user=AuthService.getCurrentUser();

      AuthService.getCurrentRole().then((roles)=>{
        if (roles) {
          console.log("Got current role from server");
          this.setState({
            currentUser: user,
            showModeratorBoard: roles.data.includes("ROLE_MODERATOR"),
            showAdminBoard: roles.data.includes("ROLE_ADMIN"),
            showAddTenant: roles.data.includes("ROLE_SUPERADMIN"),
            showAddUser: roles.data.includes("ROLE_SUPERADMIN"),
            showRegModerators:
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showRegAdmins:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN"),
            showRegSuperAdmins: roles.data.includes("ROLE_SUPERADMIN"),
            showRegUsers:
              roles.data.includes("ROLE_MODERATOR") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showCreateEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showDetailLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showFeedbackReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEdit:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showNormalUser: roles.data.includes("ROLE_USER"),
            showTenants:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            //tenantname: tenant,
          });
        }
        lobbyeventname = EventService.getCurrentEvent();
        console.log(lobbyeventname);

        this.setState({
          R3poster1path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs3" + "/poster1.png?v=" + num,
          R3poster2path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs3i" +
          "/poster2i.png?v=" + num,
          R3poster6path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs3" + "/poster6.png?v=" + num,
          R3poster1rpath :
          AWS_S3_URL + "capgemini/" + eventname +  "/Rs3" +
          "/poster3g21.png?v=" + num,
          R3poster1r1path :
          AWS_S3_URL + "capgemini/" + eventname +  "/Rs3" +
          "/poster3g22.png?v=" + num,
          R3poster1r2path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs3" +
          "/poster3g23.png?v=" + num,


          poster1: true,
          poster2: true,
          poster6: true,
          poster1r: true,
          poster1r1: true,
          poster1r2: true,




    });
    })
  }
    )
  }

  render() {
    function hideImg() {
      document.getElementById("myImg").style.display = "none";
    }
    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }
    function hideImgr() {
      document.getElementById("myImgr").style.display = "none";
    }
    function hideImgr1() {
      document.getElementById("myImgr1").style.display = "none";
    }
    function hideImgr2() {
      document.getElementById("myImgr2").style.display = "none";
    }




    const {

      poster1,
      poster2,
      poster313g2,
      poster4,
      poster6,
      poster1r,
      poster1r1,
      poster1r2,
      poster313g2reso,
      poster313g2reso1,
      poster313g2reso2,
      poster3g2,
      poster3g2a,
      poster3g2b,



      R3poster1path,
      R3poster2path,
      R3poster313g2path,
      R3poster4path,
      R3poster6path,
      R3poster1rpath,
      R3poster1r1path,
      R3poster1r2path,
      R3poster313g2resopath,
      R3poster313g2reso1path,
      R3poster313g2reso2path,
      R3poster3g2path,
      R3poster3g2apath,
      R3poster3g2bpath,





      R3poster313g2type,
      R3poster313g2resotype,
      R3poster313g2reso1type,
      R3poster313g2reso2type,

      R3poster4type,
      R3poster3g2type,
      R3poster3g2atype,
      R3poster3g2btype,
      tenantname


    } = this.state;

    return (
      <div className="container-fluide">



        <div className="page-content">
          <div id="content-agenda">
            <div id="bg">
              <img src="https://d2e8va4puks5pl.cloudfront.net/VR-01/VR-1%204%20GRID.jpg" id="bg-image" className="mb-3" />
              <div className="sidenavogation">
                    {/* <Link to={tenantname + "/Resource"} className="Rs1  text-white buttonRes">
                                <span className="hide-menu">Resource Center1</span>
                    </Link>
                    <Link to={tenantname + "/Resource1"} className="Rs2  text-white buttonRes">
                                <span className="hide-menu">Resource Center2</span>
                    </Link>
                    <Link to={tenantname + "/Resource2"} className="Rs3  text-white buttonRes">
                                <span className="hide-menu">Resource Center3</span>
                    </Link>
                    <Link to={tenantname + "/Resource3"} className="Rs4  text-white buttonRes">
                                <span className="hide-menu">Resource Center4</span>
                    </Link>
                    <Link to={tenantname + "/Resource4"} className="Rs5  text-white buttonRes">
                                <span className="hide-menu">Resource Center5</span>
                    </Link> */}
                     <Link to={tenantname + "/agenda"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Back To Agenda</span>
                  </Link>
                    </div>

              <div id="Resource12res">
              {poster1 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap122res" >
                  <img
                  src={R3poster1path}
                  // src={image}
                   alt=""  onError={() => hideImg()}
                   id="myImg"/>
                </div>

              </form>
            )}
              </div>
              <div id="Resource12rres">
              {poster1r && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap122r" >
                  <img
                  src={R3poster1rpath}
                  // src={image}
                   alt=""  onError={() => hideImgr()}
                   id="myImgr"/>
                </div>

              </form>
            )}
              </div>

              <div id="Resource12r1res">
              {poster1r1 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap122r" >
                  <img
                  src={R3poster1r1path}
                  // src={image}
                   alt=""  onError={() => hideImgr1()}
                   id="myImgr1"/>
                </div>

              </form>
            )}
              </div>
              <div id="Resource12r2res">
              {poster1r2 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap122r" >
                  <img
                  src={R3poster1r2path}
                  // src={image}
                   alt=""  onError={() => hideImgr2()}
                   id="myImgr2"/>
                </div>

              </form>
            )}
              </div>

              <div id="Resource21">
              {poster2 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader2"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap211" >
                  <img
                  src={R3poster2path}
                 // src={image1}
                   onError={() => hideImg1()}
                   id="myImg1"/>
                </div>

              </form>
            )}
              </div>


              <div id="Resource31edit3">
              {poster313g2 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap311" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter313g2path}
                        value={R3poster313g2path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>
              <div id="Resource31edit3reso">
              {poster313g2reso && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap311" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter313g2path}
                        value={R3poster313g2resopath}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>
              <div id="Resource31edit3reso1">
              {poster313g2reso1 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap311" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter313g2path}
                        value={R3poster313g2reso1path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>
              <div id="Resource31edit3reso2">
              {poster313g2reso2 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap311" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter313g2path}
                        value={R3poster313g2reso2path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>

        <div id="ResourceCenteredit3">
          <div>
            <div className="hotspot">
              {/* <div className="out"></div> */}
              {poster4 && R3poster4type === "ppt" && (
                <div className="in11" onClick={this.P1openModal} >
                  {" "}
                </div>
              )}
            </div>
            {poster4 && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen}
                  style={customStyles}
                  onRequestClose={this.P1closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal}>
                    x
                  </span>
                  <iframe
                   src={R3poster4path}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenteredit3">
          <div>
            <div className="hotspot1">
              {poster4 && R3poster4type === "video" && (
                <div className="in11" onClick={this.P2openModal}></div>
              )}
            </div>
            {poster4 && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal}
                >
                  <span className="close1" onClick={this.P2closeModal}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R3poster4path} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenteredit3">
          <div>
            <div className="hotspot1">
              {poster4 && R3poster4type === "pdf" && (
                <div className="in11" onClick={this.P3openModal}></div>
              )}
            </div>
            {poster4 && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen}
                  style={customStyles}
                  onRequestClose={this.P3closeModal}
                >
                  <span className="close1" onClick={this.P3closeModal}>
                    x
                  </span>

                  <iframe
                    src={R3poster4path}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>
           {/* <div id="Resource92">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap922"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R3poster6path}
                        // src={image}

                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>

                  </form>
                )}
              </div> */}
           </div>

           <div id="ResourceCenteredit31">
          <div>
            <div className="hotspot">
              {/* <div className="out"></div> */}
              {poster3g2 && R3poster3g2type === "ppt" && (
                <div className="in11" onClick={this.P1openModal1} >
                  {" "}
                </div>
              )}
            </div>
            {poster3g2 && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen1}
                  style={customStyles}
                  onRequestClose={this.P1closeModal1}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal1}>
                    x
                  </span>
                  <iframe
                   src={R3poster3g2path}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenteredit31">
          <div>
            <div className="hotspot1">
              {poster3g2 && R3poster3g2type === "video" && (
                <div className="in11" onClick={this.P2openModal1}></div>
              )}
            </div>
            {poster3g2 && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen1}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal1}
                >
                  <span className="close1" onClick={this.P2closeModal1}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R3poster3g2path} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenteredit31">
          <div>
            <div className="hotspot1">
              {poster3g2 && R3poster3g2type === "pdf" && (
                <div className="in11" onClick={this.P3openModal1}></div>
              )}
            </div>
            {poster3g2 && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen1}
                  style={customStyles}
                  onRequestClose={this.P3closeModal1}
                >
                  <span className="close1" onClick={this.P3closeModal1}>
                    x
                  </span>

                  <iframe
                    src={R3poster3g2path}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>
           {/* <div id="Resource92">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap922"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R3poster6path}
                        // src={image}

                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>

                  </form>
                )}
              </div> */}
           </div>

           <div id="ResourceCenteredit32">
          <div>
            <div className="hotspot">
              {/* <div className="out"></div> */}
              {poster3g2a && R3poster3g2atype === "ppt" && (
                <div className="in11" onClick={this.P1openModal2} >
                  {" "}
                </div>
              )}
            </div>
            {poster3g2a && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen2}
                  style={customStyles}
                  onRequestClose={this.P1closeModal2}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal2}>
                    x
                  </span>
                  <iframe
                   src={R3poster3g2apath}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenteredit32">
          <div>
            <div className="hotspot1">
              {poster3g2a && R3poster3g2atype === "video" && (
                <div className="in11" onClick={this.P2openModal2}></div>
              )}
            </div>
            {poster3g2a && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen2}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal2}
                >
                  <span className="close1" onClick={this.P2closeModal2}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R3poster3g2apath} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenteredit32">
          <div>
            <div className="hotspot1">
              {poster3g2a && R3poster3g2atype === "pdf" && (
                <div className="in11" onClick={this.P3openModal2}></div>
              )}
            </div>
            {poster3g2a && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen2}
                  style={customStyles}
                  onRequestClose={this.P3closeModal2}
                >
                  <span className="close1" onClick={this.P3closeModal2}>
                    x
                  </span>

                  <iframe
                    src={R3poster3g2apath}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>
           {/* <div id="Resource92">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap922"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R3poster6path}
                        // src={image}

                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>

                  </form>
                )}
              </div> */}
           </div>

           <div id="ResourceCenteredit33">
          <div>
            <div className="hotspot">
              {/* <div className="out"></div> */}
              {poster3g2b && R3poster3g2btype === "ppt" && (
                <div className="in11" onClick={this.P1openModal3} >
                  {" "}
                </div>
              )}
            </div>
            {poster3g2b && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen3}
                  style={customStyles}
                  onRequestClose={this.P1closeModal3}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal3}>
                    x
                  </span>
                  <iframe
                   src={R3poster3g2bpath}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenteredit33">
          <div>
            <div className="hotspot1">
              {poster3g2b && R3poster3g2btype === "video" && (
                <div className="in11" onClick={this.P2openModal3}></div>
              )}
            </div>
            {poster3g2b && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen3}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal3}
                >
                  <span className="close1" onClick={this.P2closeModal3}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R3poster3g2bpath} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenteredit33">
          <div>
            <div className="hotspot1">
              {poster3g2b && R3poster3g2btype === "pdf" && (
                <div className="in11" onClick={this.P3openModal3}></div>
              )}
            </div>
            {poster3g2b && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen3}
                  style={customStyles}
                  onRequestClose={this.P3closeModal3}
                >
                  <span className="close1" onClick={this.P3closeModal3}>
                    x
                  </span>

                  <iframe
                    src={R3poster3g2bpath}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>
           {/* <div id="Resource92">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap922"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R3poster6path}
                        // src={image}

                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>

                  </form>
                )}
              </div> */}
           </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Resource3G2;
