import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import stall1 from "./img/VR-03/VR-03 6 GRIDS.jpg";
import image from "./img/RS6.png";
import image1 from "./img/rs2.png";
import Modal from "react-modal";
import AWSService from "../services/aws.service";
import EventService from "../services/event.service";
import PPTPathService from "../services/pptpath.service";
import UnauthorizedService from "../common/unauthorized";
import UnAuthorizedService from "../common/unauthorized";
import AuthService from "../services/auth.service";
import { Uploader } from "../utils/upload";
import Progressbar from "../common/progressbar";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./styles/maping.css";
import $ from "jquery";
import "../App.css";
var lobbyeventname = "";
let tenant = "";
const { aws_s3_url, aws_s3_bucket_name } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "25vh",
    marginRight: "-50%",
    width: "calc(18% - 0px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "rgba(0,0,0,.9)",
    background: "unset !important",
    border: "unset !important",

    zIndex: "99999",
    backgroundcolor: "unset !important",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(20% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding: "0 0 0 0",
  },
};
class ResorceEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initially, no file is selected
      selectedFile: null,
      poster1: false,
      poster2: false,
      poster3edit5g3: false,
      poster4: false,
      poster5: false,
      poster6: false,
      posterg362: false,
      posterg363: false,
      posterg364: false,
      posterg365: false,
      posterg366: false,
      posterg3aa: false,
      posterg3bb: false,
      posterg3cc: false,
      posterg3dd: false,
      posterg3ee: false,



      resource1: false,
      resource2: false,
      resource3: false,
      resource4: false,
      resource5: false,
      resource6: false,

      R4poster1path: "",
      R4poster2path: "",
      R4poster3edit5g3path: "",
      R4poster4path: "",
      R4poster5path: "",
      R4poster6path: "",
      R4posterg362path: "",
      R4posterg363path: "",
      R4posterg364path: "",
      R4posterg365path: "",
      R4posterg366path: "",
      R4posterg3aapath: "",
      R4posterg3bbpath: "",
      R4posterg3ccpath: "",
      R4posterg3ddpath: "",
      R4posterg3eepath: "",



      modalIsOpen: false,
      modalIsOpen56: false,
      modalIsOpen57: false,
      modalIsOpen58: false,
      modalIsOpen59: false,
      modalIsOpen55: false,
      tenantname: tenant,
       template1: "",

      localuploader: undefined,
      progressbar: 0,
    };
  }
  P1openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  P1closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  P1openModal55 = () => {
    this.setState({ modalIsOpen55: true });
  };

  P1closeModal55 = () => {
    this.setState({ modalIsOpen55: false });
  };

  P1openModal56 = () => {
    this.setState({ modalIsOpen56: true });
  };

  P1closeModal56 = () => {
    this.setState({ modalIsOpen56: false });
  };
  P1openModal57 = () => {
    this.setState({ modalIsOpen57: true });
  };

  P1closeModal57 = () => {
    this.setState({ modalIsOpen57: false });
  };
  P1openModal58 = () => {
    this.setState({ modalIsOpen58: true });
  };

  P1closeModal58 = () => {
    this.setState({ modalIsOpen58: false });
  };

  P1openModal59 = () => {
    this.setState({ modalIsOpen59: true });
  };

  P1closeModal59 = () => {
    this.setState({ modalIsOpen59: false });
  };
  onFileChange01 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs" + "/poster1.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader1").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster2bar").show();
             $(".poster2barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader1").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                Rposter1path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs" +
                  "/poster1.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs",
                "poster1",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader1").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChangeg362 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChangeg362()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs" + "/posterg362.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loaderg362").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#posterg362bar").show();
             $(".posterg362barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loaderg362").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                Rposterg362path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs" +
                  "/posterg362.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs",
                "posterg362",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loaderg362").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChangeg363 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs" + "/posterg363.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loaderg363").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#posterg363bar").show();
             $(".posterg363barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loaderg363").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                Rposterg363path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs" +
                  "/posterg363.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs",
                "posterg363",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loaderg363").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChangeg364 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs" + "/posterg364.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loaderg364").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#posterg364bar").show();
             $(".posterg364barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loaderg364").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                Rposterg364path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs" +
                  "/posterg364.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs",
                "posterg364",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loaderg364").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChangeg365 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs" + "/posterg365.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loaderg365").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#posterg365bar").show();
             $(".posterg365barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loaderg365").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                Rposterg365path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs" +
                  "/posterg365.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs",
                "posterg365",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loaderg365").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChangeg366 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs" + "/posterg366.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loaderg366").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#posterg366bar").show();
             $(".posterg366barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loaderg366").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                Rposterg366path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs" +
                  "/posterg366.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs",
                "posterg366",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loaderg366").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };

  onFileChange02 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  690 || this.height != 77) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 690 + " Height= " + 77
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rsq" + "/poster2q.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader2").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster2bar").show();
             $(".poster2barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader2").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                Rposter2path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rsq" +
                  "/poster2q.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs",
                "poster2",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader2").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };

  onFileChange03 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs", "poster3edit5g3", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };


 // On file select (from the pop up)
 onFileChange04 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs5g3" + "/poster4" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs",
            "poster4",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange055 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange55()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs" + "/poster55" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs",
            "poster55",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange056 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange56()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs" + "/poster56" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs",
            "poster56",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange057 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange57()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs" + "/poster57" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs",
            "poster57",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};

onFileChange058 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange58()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs" + "/poster58" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs",
            "poster58",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};

onFileChange059 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange59()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs" + "/poster59" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs",
            "poster59",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};


onFileChangeg3aa = async (event) => {
  console.log(`Text to be uploaded is ${event.target.value}`);
  var filepath = event.target.value;
  PPTPathService.setFiletype(lobbyeventname, "Rs", "posterg3aa", "txt", filepath)
    .then((result) => {
      console.log(result);
      console.log(
        "Text Uploaded successfully........................................"
      );
      // Swal.fire({
      //   // position: 'top-end',
      //   icon: "success",
      //   title: "Text Uploaded Successfully!.",
      //   showConfirmButton: false,
      //   timer: 2000,
      // });
    })
    .catch((err) => {
      UnauthorizedService.unauthorized(err);
    });
};

onFileChangeg3bb = async (event) => {
  console.log(`Text to be uploaded is ${event.target.value}`);
  var filepath = event.target.value;
  PPTPathService.setFiletype(lobbyeventname, "Rs", "posterg3bb", "txt", filepath)
    .then((result) => {
      console.log(result);
      console.log(
        "Text Uploaded successfully........................................"
      );
      // Swal.fire({
      //   // position: 'top-end',
      //   icon: "success",
      //   title: "Text Uploaded Successfully!.",
      //   showConfirmButton: false,
      //   timer: 2000,
      // });
    })
    .catch((err) => {
      UnauthorizedService.unauthorized(err);
    });
};
onFileChangeg3cc = async (event) => {
  console.log(`Text to be uploaded is ${event.target.value}`);
  var filepath = event.target.value;
  PPTPathService.setFiletype(lobbyeventname, "Rs", "posterg3cc", "txt", filepath)
    .then((result) => {
      console.log(result);
      console.log(
        "Text Uploaded successfully........................................"
      );
      // Swal.fire({
      //   // position: 'top-end',
      //   icon: "success",
      //   title: "Text Uploaded Successfully!.",
      //   showConfirmButton: false,
      //   timer: 2000,
      // });
    })
    .catch((err) => {
      UnauthorizedService.unauthorized(err);
    });
};

onFileChangeg3dd = async (event) => {
  console.log(`Text to be uploaded is ${event.target.value}`);
  var filepath = event.target.value;
  PPTPathService.setFiletype(lobbyeventname, "Rs", "posterg3dd", "txt", filepath)
    .then((result) => {
      console.log(result);
      console.log(
        "Text Uploaded successfully........................................"
      );
      // Swal.fire({
      //   // position: 'top-end',
      //   icon: "success",
      //   title: "Text Uploaded Successfully!.",
      //   showConfirmButton: false,
      //   timer: 2000,
      // });
    })
    .catch((err) => {
      UnauthorizedService.unauthorized(err);
    });
};

onFileChangeg3ee = async (event) => {
  console.log(`Text to be uploaded is ${event.target.value}`);
  var filepath = event.target.value;
  PPTPathService.setFiletype(lobbyeventname, "Rs", "posterg3ee", "txt", filepath)
    .then((result) => {
      console.log(result);
      console.log(
        "Text Uploaded successfully........................................"
      );
      // Swal.fire({
      //   // position: 'top-end',
      //   icon: "success",
      //   title: "Text Uploaded Successfully!.",
      //   showConfirmButton: false,
      //   timer: 2000,
      // });
    })
    .catch((err) => {
      UnauthorizedService.unauthorized(err);
    });
};
  componentDidMount() {
    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname = EventService.getCurrentEvent();
    const filepath = "capgemini/" + lobbyeventname + "/Rs" + "/poster3edit5g3";
    console.log(`filepath= ${filepath}`);
    PPTPathService.getFiletype(eventname, "Rs")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster3edit5g3") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4poster3path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs" +
              "/poster3edit5g3" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster3edit5g3: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4poster3edit5g3path:filedetails.path,
                poster3edit5g3: true,

              });
            this.setState({ Rposter3edit5g3type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventnameg3aa = EventService.getCurrentEvent();
    const filepathg3aa = "capgemini/" + lobbyeventname + "/Rs" + "/posterg3aa";
    console.log(`filepath= ${filepathg3aa}`);
    PPTPathService.getFiletype(eventnameg3aa, "Rs")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "posterg3aa") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4posterg3aapath:
              AWS_S3_URL +
              "capgemini/" +
              eventnameg3aa +
              "/Rs" +
              "/posterg3aa" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            posterg3aa: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4posterg3aapath:filedetails.path,
                posterg3aa: true,

              });
            this.setState({ Rposterg3aatype: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });
    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventnameg3bb = EventService.getCurrentEvent();
    const filepathg3bb = "capgemini/" + lobbyeventname + "/Rs" + "/posterg3bb";
    console.log(`filepath= ${filepathg3bb}`);
    PPTPathService.getFiletype(eventnameg3bb, "Rs")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "posterg3bb") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4posterg3bbpath:
              AWS_S3_URL +
              "capgemini/" +
              eventnameg3bb +
              "/Rs" +
              "/posterg3bb" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            posterg3bb: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4posterg3bbpath:filedetails.path,
                posterg3bb: true,

              });
            this.setState({ Rposterg3bbtype: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventnameg3cc = EventService.getCurrentEvent();
    const filepathg3cc = "capgemini/" + lobbyeventname + "/Rs" + "/posterg3cc";
    console.log(`filepath= ${filepathg3cc}`);
    PPTPathService.getFiletype(eventnameg3cc, "Rs")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "posterg3cc") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4posterg3ccpath:
              AWS_S3_URL +
              "capgemini/" +
              eventnameg3cc +
              "/Rs" +
              "/posterg3cc" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            posterg3cc: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4posterg3ccpath:filedetails.path,
                posterg3cc: true,

              });
            this.setState({ Rposterg3cctype: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });
    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventnameg3dd = EventService.getCurrentEvent();
    const filepathg3dd = "capgemini/" + lobbyeventname + "/Rs" + "/posterg3dd";
    console.log(`filepath= ${filepathg3dd}`);
    PPTPathService.getFiletype(eventnameg3dd, "Rs")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "posterg3dd") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4posterg3ddpath:
              AWS_S3_URL +
              "capgemini/" +
              eventnameg3dd +
              "/Rs" +
              "/posterg3dd" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            posterg3dd: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4posterg3ddpath:filedetails.path,
                posterg3dd: true,

              });
            this.setState({ Rposterg3ddtype: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventnameg3ee = EventService.getCurrentEvent();
    const filepathg3ee = "capgemini/" + lobbyeventname + "/Rs" + "/posterg3ee";
    console.log(`filepath= ${filepathg3ee}`);
    PPTPathService.getFiletype(eventnameg3ee, "Rs")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "posterg3ee") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4posterg3eepath:
              AWS_S3_URL +
              "capgemini/" +
              eventnameg3ee +
              "/Rs" +
              "/posterg3ee" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            posterg3ee: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4posterg3eepath:filedetails.path,
                posterg3ee: true,

              });
            this.setState({ Rposterg3eetype: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });
    EventService.getEvents()
     .then((res) =>{
    //  console.log(res.data)
    console.log("Eventname from events");

      console.log(res.data)
      console.log(res.data.boardRoomNum)
      // console.log(res.data.boardRoomNum);

      this.setState({
        template1: res.data.engagementRoomNum
    }
    );
              if(res.data.engagementRoomNum == '1'){
                console.log( 'neeraj');
            //  !resource1;
            const isVisible1 = this.state.resource1;
            const isVisible2 = this.state.resource2;
            const isVisible3 = this.state.resource3;
            const isVisible4 = this.state.resource4;
            const isVisible5 = this.state.resource5;
            // const isVisible = this.state.resource1;
            this.setState({
              resource1: isVisible1,
              resource2: !isVisible2,
              resource3: !isVisible3,
              resource4: !isVisible4,
              resource5: !isVisible5
              });
      }
      if(res.data.engagementRoomNum == '2'){
        console.log( 'neeraj');
    //  !resource1;
    const isVisible1 = this.state.resource1;
    const isVisible2 = this.state.resource2;
    const isVisible3 = this.state.resource3;
    const isVisible4 = this.state.resource4;
    const isVisible5 = this.state.resource5;
    // const isVisible = this.state.resource1;
    this.setState({
      resource1: isVisible1,
      resource2: isVisible2,
      resource3: !isVisible3,
      resource4: !isVisible4,
      resource5: !isVisible5
      });
}

    } )


    EventService.getSpecificEvent()
    .then((response) => {
      console.log("Eventname from getSpecificEvent");
      console.log(response.data);
      console.log(response.data.eventname);
      const eventpath = "/" + response.data.eventname;
      this.setState({ tenantname: eventpath, showLoginPage: true });
      localStorage.setItem("eventname", response.data.eventname);
      console.log(response.data.eventname);

      const user=AuthService.getCurrentUser();

      AuthService.getCurrentRole().then((roles)=>{
        if (roles) {
          console.log("Got current role from server");
          this.setState({
            currentUser: user,
            showModeratorBoard: roles.data.includes("ROLE_MODERATOR"),
            showAdminBoard: roles.data.includes("ROLE_ADMIN"),
            showAddTenant: roles.data.includes("ROLE_SUPERADMIN"),
            showAddUser: roles.data.includes("ROLE_SUPERADMIN"),
            showRegModerators:
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showRegAdmins:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN"),
            showRegSuperAdmins: roles.data.includes("ROLE_SUPERADMIN"),
            showRegUsers:
              roles.data.includes("ROLE_MODERATOR") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showCreateEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showDetailLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showFeedbackReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEdit:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showNormalUser: roles.data.includes("ROLE_USER"),
            showTenants:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            //tenantname: tenant,
          });
        }



    this.setState({
      Rposter1path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs" +
        "/poster1.png?v=" +
        num,
      Rposter2path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rsq" +
        "/poster2q.png?v=" +
        num,
        Rposter6path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs" +
        "/poster6.png?v=" +
        num,
      // Rposter3path:
      //   AWS_S3_URL +
      //   "capgemini/" +
      //   lobbyeventname +
      //   "/Rs" +
      //   "/poster3.txt?v=" +
      //   num,
        Rposter4path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs" +
        "/poster4.pdf?v=" +
        num,

        R4posterg362path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs" +
        "/posterg362.png?v=" +
        num,
        R4posterg363path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs" +
        "/posterg363.png?v=" +
        num,
        R4posterg364path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs" +
        "/posterg364.png?v=" +
        num,
        R4posterg365path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs" +
        "/posterg365.png?v=" +
        num,
        R4posterg366path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs" +
        "/posterg366.png?v=" +
        num,
        Rposterg3aapath:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs" +
        "/posterg3aa.txt?v=" +
        num,
        Rposterg3bbpath:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs" +
        "/posterg3bb.txt?v=" +
        num,
        Rposterg3ccpath:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs" +
        "/posterg3cc.txt?v=" +
        num,
        Rposterg3ddpath:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs" +
        "/posterg3dd.txt?v=" +
        num,
        Rposterg3eepath:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs" +
        "/posterg3ee.txt?v=" +
        num,



      poster1: true,
      poster2: true,
      poster3edit5g3: true,
      poster4: true,
      poster6: true,

      posterg362: true,
      posterg363: true,
      posterg364: true,
      posterg365: true,
      posterg366: true,
      posterg3aa: true,
      posterg3bb: true,
      posterg3cc: true,
      posterg3dd: true,
      posterg3ee: true,




    });
  })
}
    )
  }

  render() {
    function hideImg() {
      document.getElementById("myImg").style.display = "none";
    }
    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }

    function hideImgg362() {
      document.getElementById("myImgg362").style.display = "none";
    }
    function hideImgg363() {
      document.getElementById("myImgg363").style.display = "none";
    }
    function hideImgg364() {
      document.getElementById("myImgg364").style.display = "none";
    }
    function hideImgg365() {
      document.getElementById("myImgg365").style.display = "none";
    }
    function hideImgg366() {
      document.getElementById("myImgg366").style.display = "none";
    }

    const {
      poster1,
      poster2,
      poster3edit5g3,
      poster6,


      posterg362,
      posterg363,
      posterg364,
      posterg365,
      posterg366,
      posterg3aa,
      posterg3bb,
      posterg3cc,
      posterg3dd,
      posterg3ee,




      Rposter1path,
      Rposter2path,
      R4poster3edit5g3path,
      Rposter6path,


      R4posterg362path,
      R4posterg363path,
      R4posterg364path,
      R4posterg365path,
      R4posterg366path,

      R4posterg3aapath,
      R4posterg3bbpath,
      R4posterg3ccpath,
      R4posterg3ddpath,
      R4posterg3eepath,



      tenantname,
      progressbar,
      resource1,
      resource2,
      resource3,
      resource4,
      resource5,
      resource6,
      template1
    } = this.state;

    return (
      <div className="container-fluide">





        <div className="page-content">
          <div id="content-agenda">
            <div id="bg">
              <img src="https://d2e8va4puks5pl.cloudfront.net/VR-03/VR-03%206%20GRIDS.jpg" id="bg-image" className="mb-3" />
              <div className="sidenavogation">


              {/* {

             !resource1?(
                 <Link to={tenantname + "/"+template1} className="Rs1  text-white buttonRes">
                           <span className="hide-menu">Resource Center1</span>
               </Link>
           ):null

           }
           {

             !resource2?(
            <Link to={tenantname + "/ResourceEdit1"} className="Rs2  text-white buttonRes">
                            <span className="hide-menu">Resource Center2</span>

                </Link>
                ):null

      }
                {

             !resource3 ?(
                <Link to={tenantname + "/ResourceEdit2"} className="Rs3  text-white buttonRes">
                            <span className="hide-menu">Resource Center3</span>
                </Link>
                ):null

      }
                {

             !resource4 ?(
                <Link to={tenantname + "/ResourceEdit3"} className="Rs4  text-white buttonRes">
                            <span className="hide-menu">Resource Center4</span>
                </Link>
                ):null

      }
                {

             !resource5?(
                <Link to={tenantname + "/ResourceEdit4"} className="Rs5  text-white buttonRes">
                            <span className="hide-menu">Resource Center5</span>
                </Link>
                ):null

      } */}

            <div>
            {/* <Link to={tenantname + "/ResourceEdit1"} className="Rs2  text-white buttonRes">
                            <span className="hide-menu">Resource Center2</span>

                </Link>
                <Link to={tenantname + "/ResourceEdit2"} className="Rs3  text-white buttonRes">
                            <span className="hide-menu">Resource Center3</span>
                </Link>
                <Link to={tenantname + "/ResourceEdit3"} className="Rs4  text-white buttonRes">
                            <span className="hide-menu">Resource Center4</span>
                </Link>
                <Link to={tenantname + "/ResourceEdit4"} className="Rs5  text-white buttonRes">
                            <span className="hide-menu">Resource Center5</span>
                </Link> */}
                <Link to={tenantname + "/agendaEdit"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Back To Agenda</span>
                  </Link>
            </div>


                </div>
              <div id="Resource1g3editsix1">
                {poster1 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader1"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap1"
                      onClick={(e) => this.myInput.click()}
                    >
                      <img
                        src={Rposter1path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg()}
                        id="myImg"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange01}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource1g3editsix2">
                {posterg362 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loaderg362"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap1"
                      onClick={(e) => this.myInputg362.click()}
                    >
                      <img
                        src={R4posterg362path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImgg362()}
                        id="myImgg362"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInputg362 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChangeg362}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource1g3editsix3">
                {posterg363 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loaderg363"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap1"
                      onClick={(e) => this.myInputg363.click()}
                    >
                      <img
                        src={R4posterg363path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImgg363()}
                        id="myImgg363"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInputg363 = ref)}
                      type="file"
                      name="file"
                      id="posterimgg363"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChangeg363}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource1g3editsix4">
                {posterg364 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loaderg364"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap1"
                      onClick={(e) => this.myInputg364.click()}
                    >
                      <img
                        src={R4posterg364path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImgg364()}
                        id="myImgg364"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInputg364 = ref)}
                      type="file"
                      name="file"
                      id="posterimgg364"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChangeg364}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource1g3editsix5">
                {posterg365 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loaderg365"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap1"
                      onClick={(e) => this.myInputg365.click()}
                    >
                      <img
                        src={R4posterg365path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImgg365()}
                        id="myImgg365"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInputg365 = ref)}
                      type="file"
                      name="file"
                      id="posterimgg365"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChangeg365}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource1g3editsix6">
                {posterg366 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loaderg366"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap1"
                      onClick={(e) => this.myInputg366.click()}
                    >
                      <img
                        src={R4posterg366path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImgg366()}
                        id="myImgg366"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInputg366 = ref)}
                      type="file"
                      name="file"
                      id="posterimgg366"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChangeg366}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>
              <div id="Resource2g3editsix">
                {poster2 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader2"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap2"
                      onClick={(e) => this.myInput1.click()}
                    >
                      <img
                         src={Rposter2path}
                       // src={image1}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg1()}
                        id="myImg1"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput1 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange02}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource3g3editsix1">
                {poster3edit5g3 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap3">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange03}
                        id="ResText"
                        placeholder={R4poster3edit5g3path}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource3g3editsix2">
                {posterg3aa && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap3">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChangeg3aa}
                        id="ResText"
                        placeholder={R4posterg3aapath}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource3g3editsix3">
                {posterg3bb && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap3">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChangeg3bb}
                        id="ResText"
                        placeholder={R4posterg3bbpath}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource3g3editsix4">
                {posterg3cc && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap3">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChangeg3cc}
                        id="ResText"
                        placeholder={R4posterg3ccpath}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource3g3editsix5">
                {posterg3dd && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap3">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChangeg3dd}
                        id="ResText"
                        placeholder={R4posterg3ddpath}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource3g3editsix6">
                {posterg3ee && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap3">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChangeg3ee}
                        id="ResText"
                        placeholder={R4posterg3eepath}
                      />
                    </div>
                  </form>
                )}
              </div>

          <div id="ResourceCenterg3editsix1">
          <div className="hotspot1g3editsix1">
            {/* <div className="out"></div> */}
            <div className="in1g3editsix1" onClick={this.P1openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen}
                  style={customStyles}
                  onRequestClose={this.P1closeModal}
                >
                  <span className="close" onClick={this.P1closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput23.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput24.click()}>Upload Video  </button>
                  <div id="loader4" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput23 = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange04}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput24 = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange04}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        <div id="ResourceCenterg3editsix2">
          <div className="hotspot1g3editsix2">
            {/* <div className="out"></div> */}
            <div className="in1g3editsix2" onClick={this.P1openModal55}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen55}
                  style={customStyles}
                  onRequestClose={this.P1closeModal55}
                >
                  <span className="close" onClick={this.P1closeModal55}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal55}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal55}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput23k.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput24k.click()}>Upload Video  </button>
                  <div id="loader4" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput23k = ref)}
            type="file"
            name="file"
            id="vid55"
            onChange={this.onFileChange055}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput24k = ref)}
            type="file"
            name="file"
            id="vid55"
            onChange={this.onFileChange055}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        <div id="ResourceCenterg3editsix3">
          <div className="hotspot1g3editsix3">
            {/* <div className="out"></div> */}
            <div className="in1g3editsix3" onClick={this.P1openModal56}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen56}
                  style={customStyles}
                  onRequestClose={this.P1closeModal56}
                >
                  <span className="close" onClick={this.P1closeModal56}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal56}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal56}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput23l.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput24l.click()}>Upload Video  </button>
                  <div id="loader4" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput23l = ref)}
            type="file"
            name="file"
            id="vid56"
            onChange={this.onFileChange056}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput24l = ref)}
            type="file"
            name="file"
            id="vid56"
            onChange={this.onFileChange056}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        <div id="ResourceCenterg3editsix4">
          <div className="hotspot1g3editsix4">
            {/* <div className="out"></div> */}
            <div className="in1g3editsix4" onClick={this.P1openModal57}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen57}
                  style={customStyles}
                  onRequestClose={this.P1closeModal57}
                >
                  <span className="close" onClick={this.P1closeModal57}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal57}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal57}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput23c.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput24c.click()}>Upload Video  </button>
                  <div id="loader4" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput23c = ref)}
            type="file"
            name="file"
            id="vid57"
            onChange={this.onFileChange057}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput24c = ref)}
            type="file"
            name="file"
            id="vid57"
            onChange={this.onFileChange057}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        <div id="ResourceCenterg3editsix5">
          <div className="hotspot1g3editsix5">
            {/* <div className="out"></div> */}
            <div className="in1g3editsix5" onClick={this.P1openModal58}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen58}
                  style={customStyles}
                  onRequestClose={this.P1closeModal58}
                >
                  <span className="close" onClick={this.P1closeModal58}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal58}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal58}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput23x.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput24x.click()}>Upload Video  </button>
                  <div id="loader4" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput23x = ref)}
            type="file"
            name="file"
            id="vid58"
            onChange={this.onFileChange058}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput24x = ref)}
            type="file"
            name="file"
            id="vid58"
            onChange={this.onFileChange058}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        <div id="ResourceCenterg3editsix6">
          <div className="hotspot1g3editsix6">
            {/* <div className="out"></div> */}
            <div className="in1g3editsix6" onClick={this.P1openModal59}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen59}
                  style={customStyles}
                  onRequestClose={this.P1closeModal59}
                >
                  <span className="close" onClick={this.P1closeModal59}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal59}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal59}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput23z.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput24z.click()}>Upload Video  </button>
                  <div id="loader4" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput23z = ref)}
            type="file"
            name="file"
            id="vid59"
            onChange={this.onFileChange059}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput24z= ref)}
            type="file"
            name="file"
            id="vid59"
            onChange={this.onFileChange059}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        {/* <div id="Resource8">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap8"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={Rposter6path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput6 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange06}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ResorceEdit;
