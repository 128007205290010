import React, { PureComponent } from "react";
import Marzipano from "marzipano";
import $ from "jquery";
import "./styles/styles.css";
import AWSService from "../services/aws.service";
import EventService from "../services/event.service";
import PPTPathService from "../services/pptpath.service";
import UnauthorizedService from "../common/unauthorized";
import { Uploader } from "../utils/upload";
import Modal from "react-modal";
import Swal from "sweetalert2";
import Progressbar from "../common/progressbar";
const { aws_s3_url ,aws_s3_bucket_name} = require("../config/config");
const AWS_S3_URL = aws_s3_url;
var lobbyeventname = "";
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "25vh",
    marginRight: "-50%",
    width: "calc(18% - 0px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "rgba(0,0,0,.9)",
    background: "unset !important",
    border: "unset !important",

    zIndex: "99999",
    backgroundcolor: "unset !important",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(20% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding: "0 0 0 0",
  },
};
class InnovationEdit1 extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      poster1: false,
      poster2: false,
      poster3: false,
      poster4: false,
      poster5: false,
      poster6: false,
      poster7: false,
      poster8: false,
      poster9: false,
      poster10: false,


      Iposter1path : "",
      Iposter2path : "",
      Iposter3path : "",
      Iposter4path : "",
      Iposter5path : "",
      Iposter6path : "",
      Iposter7path : "",
      Iposter8path : "",
      Iposter9path : "",
      Iposter10path : "",
      modalIsOpen: false,
      modal2IsOpen: false,
      modal3IsOpen: false,
      modal4IsOpen: false,
      modal5IsOpen: false,
      modal6IsOpen: false,
      localuploader: undefined,
      progressbar: 0,




    }
  }
  P1openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  P1closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  P2openModal = () => {
    this.setState({ modal2IsOpen: true });
  };

  P2closeModal = () => {
    this.setState({ modal2IsOpen: false });
  };
  P3openModal = () => {
    this.setState({ modal3IsOpen: true });
  };

  P3closeModal = () => {
    this.setState({ modal3IsOpen: false });
  };
  P4openModal = () => {
    this.setState({ modal4IsOpen: true });
  };

  P4closeModal = () => {
    this.setState({ modal4IsOpen: false });
  };
  P5openModal = () => {
    this.setState({ modal5IsOpen: true });
  };

  P5closeModal = () => {
    this.setState({ modal5IsOpen: false });
  };
  P6openModal = () => {
    this.setState({ modal6IsOpen: true });
  };

  P6closeModal = () => {
    this.setState({ modal6IsOpen: false });
  };
  static displayName = "Pano";
  onFileChange01 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 1308 || this.height != 624) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 1308 + " Height= " + 624
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/IN" + "/poster1.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader1").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster2bar").show();
             $(".poster2barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader1").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                Iposter1path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/IN" +
                  "/poster1.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "IN",
                "poster1",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader1").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChange02 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 420 || this.height != 270) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 420 + " Height= " + 270
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/IN" + "/poster2.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader2").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster3bar").show();
             $(".poster3barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader2").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                Iposter2path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/IN" +
                  "/poster2.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "IN",
                "poster2",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader2").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChange03 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 420 || this.height != 270) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height
          "Required FIle Dimension: Width = " + 420 + " Height= " + 270
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/IN" + "/poster3.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader3").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster4bar").show();
             $(".poster4barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader3").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                Iposter3path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/IN" +
                  "/poster3.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "IN",
                "poster3",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader3").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChange04 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 1216 || this.height != 570) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 1216 + " Height= " + 570
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/IN" + "/poster4.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader4").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster5bar").show();
             $(".poster5barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader1").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                Iposter4path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/IN" +
                  "/poster4.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "IN",
                "poster4",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader4").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChange05 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 1288 || this.height != 800) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 1288 + " Height= " + 800
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/IN" + "/poster5.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader5").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster6bar").show();
             $(".poster6barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader5").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                Iposter5path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/IN" +
                  "/poster5.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "IN",
                "poster5",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader5").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };

  onFileChange06 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0]);
    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    // img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    // img.src = objectUrl;
    //var fileType = event.target.files[0].type;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    // const eventname = EventService.getCurrentEvent();
    // let uploadfile = () => {
    var fileName =
      "capgemini/" + lobbyeventname + "/IN" + "/poster6" + "." + fileType;

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(fileType);
    console.log(event.target.files);
    console.log(fileType);
    var ctype = fileType;
    if (fileType == "pdf") {
      ctype = "application/pdf";
      console.log("filetype=pdf");
    }
    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      //contentType: ctype,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);
    this.setState({ localuploader: uploader });

    this.setState({ poster: false });
    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            this.setState({ progressbar: newPercentage });
            console.log(`${percentage}%`);
            $("#poster1bar").show();
            $(".modal-container").hide();
          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");

            this.setState({ poster: true });
            var num = Math.random();

            PPTPathService.setFiletype(
              lobbyeventname,
              "IN",
              "poster6",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                // Swal.fire({
                //   // position: 'top-end',
                //   icon: "success",
                //   title: "File Uploaded Successfully!.",
                //   showConfirmButton: false,
                //   timer: 2000,
                // });
               //$(".poster1barbtn").hide();
                $("#poster1bar").hide();
               // $(".modal-container").hide();
                // $(".progress").hide();
                $("#complete").show();

                // window.location.reload();
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
          } else if (upstatus === "failed") {

            Swal.fire({
              // position: 'top-end',
              icon: "danger",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
  onFileChange07= async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0]);
    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    // img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    // img.src = objectUrl;
    //var fileType = event.target.files[0].type;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    // const eventname = EventService.getCurrentEvent();
    // let uploadfile = () => {
    var fileName =
      "capgemini/" + lobbyeventname + "/IN" + "/poster7" + "." + fileType;

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(fileType);
    console.log(event.target.files);
    console.log(fileType);
    var ctype = fileType;
    if (fileType == "pdf") {
      ctype = "application/pdf";
      console.log("filetype=pdf");
    }
    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      //contentType: ctype,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);
    this.setState({ localuploader: uploader });

    this.setState({ poster: false });
    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            this.setState({ progressbar: newPercentage });
            console.log(`${percentage}%`);
            $("#poster1bar").show();
            $(".modal-container").hide();
          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");

            this.setState({ poster: true });
            var num = Math.random();

            PPTPathService.setFiletype(
              lobbyeventname,
              "IN",
              "poster7",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                // Swal.fire({
                //   // position: 'top-end',
                //   icon: "success",
                //   title: "File Uploaded Successfully!.",
                //   showConfirmButton: false,
                //   timer: 2000,
                // });
               //$(".poster1barbtn").hide();
                $("#poster1bar").hide();
               // $(".modal-container").hide();
                // $(".progress").hide();
                $("#complete").show();

                // window.location.reload();
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
          } else if (upstatus === "failed") {

            Swal.fire({
              // position: 'top-end',
              icon: "danger",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
  onFileChange08 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0]);
    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    // img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    // img.src = objectUrl;
    //var fileType = event.target.files[0].type;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    // const eventname = EventService.getCurrentEvent();
    // let uploadfile = () => {
    var fileName =
      "capgemini/" + lobbyeventname + "/IN" + "/poster8" + "." + fileType;

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(fileType);
    console.log(event.target.files);
    console.log(fileType);
    var ctype = fileType;
    if (fileType == "pdf") {
      ctype = "application/pdf";
      console.log("filetype=pdf");
    }
    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      //contentType: ctype,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);
    this.setState({ localuploader: uploader });

    this.setState({ poster: false });
    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            this.setState({ progressbar: newPercentage });
            console.log(`${percentage}%`);
            $("#poster1bar").show();
            $(".modal-container").hide();
          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");

            this.setState({ poster: true });
            var num = Math.random();

            PPTPathService.setFiletype(
              lobbyeventname,
              "IN",
              "poster8",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                // Swal.fire({
                //   // position: 'top-end',
                //   icon: "success",
                //   title: "File Uploaded Successfully!.",
                //   showConfirmButton: false,
                //   timer: 2000,
                // });
               //$(".poster1barbtn").hide();
                $("#poster1bar").hide();
               // $(".modal-container").hide();
                // $(".progress").hide();
                $("#complete").show();

                // window.location.reload();
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
          } else if (upstatus === "failed") {

            Swal.fire({
              // position: 'top-end',
              icon: "danger",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
  onFileChange09 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0]);
    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    // img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    // img.src = objectUrl;
    //var fileType = event.target.files[0].type;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    // const eventname = EventService.getCurrentEvent();
    // let uploadfile = () => {
    var fileName =
      "capgemini/" + lobbyeventname + "/IN" + "/poster9" + "." + fileType;

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(fileType);
    console.log(event.target.files);
    console.log(fileType);
    var ctype = fileType;
    if (fileType == "pdf") {
      ctype = "application/pdf";
      console.log("filetype=pdf");
    }
    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      //contentType: ctype,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);
    this.setState({ localuploader: uploader });

    this.setState({ poster: false });
    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            this.setState({ progressbar: newPercentage });
            console.log(`${percentage}%`);
            $("#poster1bar").show();
            $(".modal-container").hide();
          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");

            this.setState({ poster: true });
            var num = Math.random();

            PPTPathService.setFiletype(
              lobbyeventname,
              "IN",
              "poster9",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                // Swal.fire({
                //   // position: 'top-end',
                //   icon: "success",
                //   title: "File Uploaded Successfully!.",
                //   showConfirmButton: false,
                //   timer: 2000,
                // });
               //$(".poster1barbtn").hide();
                $("#poster1bar").hide();
               // $(".modal-container").hide();
                // $(".progress").hide();
                $("#complete").show();

                // window.location.reload();
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
          } else if (upstatus === "failed") {

            Swal.fire({
              // position: 'top-end',
              icon: "danger",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
  onFileChange010 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0]);
    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    // img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    // img.src = objectUrl;
    //var fileType = event.target.files[0].type;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    // const eventname = EventService.getCurrentEvent();
    // let uploadfile = () => {
    var fileName =
      "capgemini/" + lobbyeventname + "/IN" + "/poster10" + "." + fileType;

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(fileType);
    console.log(event.target.files);
    console.log(fileType);
    var ctype = fileType;
    if (fileType == "pdf") {
      ctype = "application/pdf";
      console.log("filetype=pdf");
    }
    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      //contentType: ctype,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);
    this.setState({ localuploader: uploader });

    this.setState({ poster: false });
    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            this.setState({ progressbar: newPercentage });
            console.log(`${percentage}%`);
            $("#poster1bar").show();
            $(".modal-container").hide();
          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");

            this.setState({ poster: true });
            var num = Math.random();

            PPTPathService.setFiletype(
              lobbyeventname,
              "IN",
              "poster10",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                // Swal.fire({
                //   // position: 'top-end',
                //   icon: "success",
                //   title: "File Uploaded Successfully!.",
                //   showConfirmButton: false,
                //   timer: 2000,
                // });
               //$(".poster1barbtn").hide();
                $("#poster1bar").hide();
               // $(".modal-container").hide();
                // $(".progress").hide();
                $("#complete").show();

                // window.location.reload();
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
          } else if (upstatus === "failed") {

            Swal.fire({
              // position: 'top-end',
              icon: "danger",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };

  componentDidMount() {
    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const filepath = "capgemini/" + lobbyeventname + "/IN" + "/poster";
    console.log(`filepath= ${filepath}`);
    this.setState({
    Iposter1path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/IN" + "/poster1.png?v=" +
      num,
    Iposter2path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/IN" + "/poster2.png?v=" +
      num,
    Iposter3path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/IN" + "/poster3.png?v=" +
      num,
    Iposter4path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/IN" + "/poster4.png?v=" +
      num,
    Iposter5path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/IN" + "/poster5.png?v=" +
      num,
    Iposter6path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/IN" + "/poster6.pptx?v=" +
      num,
      Iposter7path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/IN" + "/poster7.pptx?v=" +
      num,
      Iposter8path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/IN" + "/poster8.pptx?v=" +
      num,
      Iposter9path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/IN" + "/poster9.pptx?v=" +
      num,
      Iposter10path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/IN" + "/poster10.pptx?v=" +
      num,
      poster1: true,
      poster2: true,
      poster3: true,
      poster4: true,
      poster5: true,
      poster6: true,
      poster7: true,
      poster8: true,
      poster9: true,
      poster10: true,

    })
    $(document).on("click", "#pptupload", function(){

      const { value: text }= Swal.fire({
        input: 'text',
        inputLabel: ' LINK',
        inputPlaceholder: 'copy the link',
      }).then((text) => {
        if (text.isConfirmed) {
          let data=Object.entries(text)[3][1];
          var parser = new DOMParser();
          var parsedIframe = parser.parseFromString(data, "text/html");
           let iFrame = parsedIframe.getElementsByTagName("iframe");
           let src = iFrame[0].src;
          console.log(src);
          Swal.fire(src);
        PPTPathService.setFiletype(
        lobbyeventname,
        "IN",
        "poster6",
        // fileType,
        "ppt",
      src
        //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
      ) .then((result) => {
        console.log(result);
        console.log(
          "Uploaded successfully........................................"
        );

      })
      .catch(text => {
        if (text.isDenied) {
          Swal.fire('required input');
        }
      })

        }
      // .catch((err) => {
      //   UnauthorizedService.unauthorized(err);
      // })
    }
      )


   });
   $(document).on("click", "#pptupload1", function(){

    const { value: text }= Swal.fire({
      input: 'text',
      inputLabel: ' LINK',
      inputPlaceholder: 'copy the link',
    }).then((text) => {
      if (text.isConfirmed) {
        let data=Object.entries(text)[3][1];
        var parser = new DOMParser();
        var parsedIframe = parser.parseFromString(data, "text/html");
         let iFrame = parsedIframe.getElementsByTagName("iframe");
         let src = iFrame[0].src;
        console.log(src);
        Swal.fire(src);
      PPTPathService.setFiletype(
      lobbyeventname,
      "IN",
      "poster7",
      // fileType,
      "ppt",
     src
      //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
    ) .then((result) => {
      console.log(result);
      console.log(
        "Uploaded successfully........................................"
      );

    })
    .catch(text => {
      if (text.isDenied) {
        Swal.fire('required input');
      }
    })

      }
    // .catch((err) => {
    //   UnauthorizedService.unauthorized(err);
    // })
  }
    )


 });
 $(document).on("click", "#pptupload2", function(){

  const { value: text }= Swal.fire({
    input: 'text',
    inputLabel: ' LINK',
    inputPlaceholder: 'copy the link',
  }).then((text) => {
    if (text.isConfirmed) {
      let data=Object.entries(text)[3][1];
      var parser = new DOMParser();
      var parsedIframe = parser.parseFromString(data, "text/html");
       let iFrame = parsedIframe.getElementsByTagName("iframe");
       let src = iFrame[0].src;
      console.log(src);
      Swal.fire(src);
    PPTPathService.setFiletype(
    lobbyeventname,
    "IN",
    "poster8",
    // fileType,
    "ppt",
   src
    //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
  ) .then((result) => {
    console.log(result);
    console.log(
      "Uploaded successfully........................................"
    );

  })
  .catch(text => {
    if (text.isDenied) {
      Swal.fire('required input');
    }
  })

    }
  // .catch((err) => {
  //   UnauthorizedService.unauthorized(err);
  // })
}
  )


});
$(document).on("click", "#pptupload3", function(){

  const { value: text }= Swal.fire({
    input: 'text',
    inputLabel: ' LINK',
    inputPlaceholder: 'copy the link',
  }).then((text) => {
    if (text.isConfirmed) {
      let data=Object.entries(text)[3][1];
      var parser = new DOMParser();
      var parsedIframe = parser.parseFromString(data, "text/html");
       let iFrame = parsedIframe.getElementsByTagName("iframe");
       let src = iFrame[0].src;
      console.log(src);
      Swal.fire(src);
    PPTPathService.setFiletype(
    lobbyeventname,
    "IN",
    "poster9",
    // fileType,
    "ppt",
   src
    //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
  ) .then((result) => {
    console.log(result);
    console.log(
      "Uploaded successfully........................................"
    );

  })
  .catch(text => {
    if (text.isDenied) {
      Swal.fire('required input');
    }
  })

    }
  // .catch((err) => {
  //   UnauthorizedService.unauthorized(err);
  // })
}
  )


});
$(document).on("click", "#pptupload4", function(){

  const { value: text }= Swal.fire({
    input: 'text',
    inputLabel: ' LINK',
    inputPlaceholder: 'copy the link',
  }).then((text) => {
    if (text.isConfirmed) {
      let data=Object.entries(text)[3][1];
      var parser = new DOMParser();
      var parsedIframe = parser.parseFromString(data, "text/html");
       let iFrame = parsedIframe.getElementsByTagName("iframe");
       let src = iFrame[0].src;
      console.log(src);
      Swal.fire(src);
    PPTPathService.setFiletype(
    lobbyeventname,
    "IN",
    "poster10",
    // fileType,
    "ppt",
    src
    //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
  ) .then((result) => {
    console.log(result);
    console.log(
      "Uploaded successfully........................................"
    );

  })
  .catch(text => {
    if (text.isDenied) {
      Swal.fire('required input');
    }
  })

    }
  // .catch((err) => {
  //   UnauthorizedService.unauthorized(err);
  // })
}
  )


});

    this.viewer = new Marzipano.Viewer(this.pano); //, viewerOpts);
    // Create source.
    const source = Marzipano.ImageUrlSource.fromString("https://d2e8va4puks5pl.cloudfront.net/innovationlab.jpg");

    // Create geometry.
    const geometry = new Marzipano.EquirectGeometry([{ width: 5000 }]);

    const limiter = Marzipano.RectilinearView.limit.traditional(
      4*1024,
      (100 * Math.PI) / 180
    );
    const view = new Marzipano.RectilinearView({ yaw: Math.PI }, limiter);

    // Get the zview values

    var yaw = view.yaw();
    var pitch = view.pitch();
    var fov = view.fov(); // fov is horizontal
    /*var vfov = view.vfov();
var hfov = view.hfov();    // same as view.fov()*/

    // Change the values

    view.setYaw((0 * Math.PI) / 180);
    view.setPitch((0 * Math.PI) / 180);
    view.setFov((120 * Math.PI) / 180);

    const scene = this.viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      pinFirstLevel: true,
    });

    scene.switchTo();

    // Get the hotspot container for scene.
    var container = scene.hotspotContainer();

    container.createHotspot(
      this.poster1,
      { yaw: 3.135, pitch: -0.159 },
      {
        perspective: {
          radius: 1200,
          extraTransforms: "rotateX(10deg) rotateY(0deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster2,
      { yaw: 3.997, pitch: -0.06 },
      {
        perspective: {
          radius: 1860,
          extraTransforms: "rotateX(5deg) rotateY(-30deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster3,
      { yaw: 5.455, pitch: -0.0593 },
      {
        perspective: {
          radius: 1990,
          extraTransforms: "rotateX(2deg) rotateY(30deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster4,
      { yaw: 6.28, pitch: -0.158},
      {
        perspective: {
          radius: 910,
          extraTransforms: "rotateX(5deg) rotateY(0deg) rotateZ(0deg)",
        },
      }
    );

    container.createHotspot(
      this.poster5,
      { yaw: 7.848, pitch: -0.16},
      {
        perspective: {
          radius: 980,
          extraTransforms: "rotateX(8deg) rotateY(0deg) rotateZ(0deg)",
        },
      }
    );

    scene.hotspotContainer().createHotspot(document.querySelector("#pptview"), {
      yaw: -0.039,
      pitch: 0.045,
    });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview1"), {
        yaw: -0.85,
        pitch: 0.042,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview2"), {
        yaw: 1.54,
        pitch: 0.1,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview3"), {
        yaw: 3.11,
        pitch: 0.039,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview4"), {
        yaw: 3.966,
        pitch: 0.036,
      });
  }

  render() {
    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }
    function hideImg3() {
      document.getElementById("myImg3").style.display = "none";
    }
    function hideImg4() {
      document.getElementById("myImg4").style.display = "none";
    }
    function hideImg5() {
      document.getElementById("myImg5").style.display = "none";
    }
    const{
      poster1,
      poster2,
      poster3,
      poster4,
      poster5,
      poster6,
      poster7,
      poster8,
      poster9,
      poster10,
      poster11,
      Iposter1path,
      Iposter2path,
      Iposter3path,
      Iposter4path,
      Iposter5path,
      Iposter6path,
      Iposter7path,
      Iposter8path,
      Iposter9path,
      Iposter10path,
      progressbar,

    }=this.state;
    return (
      <div className="pano-container" ref={(pano) => (this.pano = pano)}>
        <div id="Inoposter1" ref={(poster1) => (this.poster1 = poster1)}>
        <div id="poster2bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster2barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete2">
            <button
                className="btn btn-xl complete2"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster1 && (
            <form enctype="multipart/form-data">
              <div id="richList"></div>

              <div id="loader1" class="lds-dual-ring hidden overlay"></div>
              <div  id="Imap1">
              <img
                src={Iposter1path}
                style={{ cursor: "pointer" }}
                onError={() => hideImg1()}
                id="myImg1"

              /></div>
               <input
                  ref={this.imgRef}
                  type="file"
                  name="file"
                  id="posterimg6"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange01}
                  style={{ display: "none" }}
                />
            </form>
          )}
          </label>
        </div>
        <div id="Inoposter2" ref={(poster2) => (this.poster2 = poster2)}>
        <div id="poster3bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster3barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete3">
            <button
                className="btn btn-xl complete3"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster2 && (
            <form enctype="multipart/form-data">
              <div id="richList"></div>

              <div id="loader2" class="lds-dual-ring hidden overlay"></div>
              <div  id="Imap2">
              <img
                src={Iposter2path}
                onError={() => hideImg2()}
                id="myImg2"
                style={{ cursor: "pointer" }}
                alt=""
              /></div>
               <input
                  ref={this.imgRef}
                  type="file"
                  name="file"
                  id="posterimg6"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange02}
                  style={{ display: "none" }}
                />
            </form>
          )}
          </label>
        </div>
        <div id="Inoposter3" ref={(poster3) => (this.poster3 = poster3)}>
        <div id="poster4bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster4barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete4">
            <button
                className="btn btn-xl complete4"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster3 && (
            <form enctype="multipart/form-data">
              <div id="richList"></div>

              <div id="loader3" class="lds-dual-ring hidden overlay"></div>
              <div  id="Imap3">
              <img
                src={Iposter3path}
                onError={() => hideImg3()}
                id="myImg3"
                style={{ cursor: "pointer" }}
                alt=""
              />
              </div>
              <input
                  ref={this.imgRef}
                  type="file"
                  name="file"
                  id="posterimg6"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange03}
                  style={{ display: "none" }}
                />
            </form>
          )}
          </label>
        </div>
        <div id="Inoposter4" ref={(poster4) => (this.poster4 = poster4)}>
        <div id="poster5bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster5barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete5">
            <button
                className="btn btn-xl complete5"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster4 && (
            <form enctype="multipart/form-data">
              <div id="richList"></div>

              <div id="loader4" class="lds-dual-ring hidden overlay"></div>
              <div  id="Imap4">
              <img
                src={Iposter4path}
                style={{ cursor: "pointer" }}
                onError={() => hideImg4()}
                id="myImg4"
              /></div>
               <input
                  ref={this.imgRef}
                  type="file"
                  name="file"
                  id="posterimg6"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange04}
                  style={{ display: "none" }}
                />
            </form>
          )}
          </label>
        </div>

        <div id="Inoposter6" ref={(poster5) => (this.poster5 = poster5)}>
        <div id="poster6bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster6barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete6">
            <button
                className="btn btn-xl complete6"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster5 && (
            <form enctype="multipart/form-data">
              <div id="richList"></div>

              <div id="loader5" class="lds-dual-ring hidden overlay"></div>
              <div  id="Imap5">
              <img
               src={Iposter5path}
               onError={() => hideImg5()}
               id="myImg5"
                style={{ cursor: "pointer" }}
                alt=""
              /></div>
               <input
                  ref={this.imgRef}
                  type="file"
                  name="file"
                  id="posterimg6"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange05}
                  style={{ display: "none" }}
                />
            </form>
          )}
          </label>
        </div>

        <div id="pptview">
          <div className="hotspot">

            <div className="in"   onClick={this.P1openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen}
                  style={customStyles}
                  onRequestClose={this.P1closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput1.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput2.click()}>Upload Video  </button>
                  <div id="loader6" className="lds-dual-ring hidden overlay loader-modal"></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput1 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept=".pdf"
            onChange={this.onFileChange06}
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput2 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange06}
            style={{ display: "none" }}
          />
        </div>
        <div id="pptview1">
          <div className="hotspot1" >
            <div className="in1"  onClick={this.P2openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modal2IsOpen}
                  style={customStyles}
                  onRequestClose={this.P2closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P2closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P2closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P2closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload1" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput3.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput4.click()}>Upload Video  </button>
                  <div id="loader7" className="lds-dual-ring hidden overlay loader-modal"></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput3 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept=".pdf"
            onChange={this.onFileChange07}
            style={{ display: "none" }}
          />
           <input
            ref={(ref) => (this.myInput4 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange07}
            style={{ display: "none" }}
          />
        </div>
        <div id="pptview2">
          <div className="hotspot2">
            {/* <div className="out"></div> */}
            <div className="in2" onClick={this.P3openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modal3IsOpen}
                  style={customStyles}
                  onRequestClose={this.P3closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P3closeModal}>
                    x
                  </span>

                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P3closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P3closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload2" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput5.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput6.click()}>Upload Video  </button>
                  <div id="loader8" className="lds-dual-ring hidden overlay loader-modal"></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput5 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept=".pdf"
            onChange={this.onFileChange08}
            style={{ display: "none" }}
          />
           <input
            ref={(ref) => (this.myInput6 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange08}
            style={{ display: "none" }}
          />
        </div>
        <div id="pptview3">
          <div className="hotspot3">
            {/* <div className="out"></div> */}
            <div className="in3"  onClick={this.P4openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modal4IsOpen}
                  style={customStyles}
                  onRequestClose={this.P4closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P4closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P4closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P4closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload3" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput7.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput8.click()}>Upload Video  </button>
                  <div id="loader9" className="lds-dual-ring hidden overlay loader-modal"></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput7 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept=".pdf"
            onChange={this.onFileChange09}
            style={{ display: "none" }}
          />
           <input
            ref={(ref) => (this.myInput8 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange09}
            style={{ display: "none" }}
          />
        </div>
        <div id="pptview4">
          <div className="hotspot4">
            {/* <div className="out"></div> */}
            <div className="in4"  onClick={this.P5openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modal5IsOpen}
                  style={customStyles}
                  onRequestClose={this.P5closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P5closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P5closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P5closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload4" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput9.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput10.click()}>Upload Video  </button>
                  <div id="loader10" className="lds-dual-ring hidden overlay loader-modal"></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput9 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept=".pdf"
            onChange={this.onFileChange010}
            style={{ display: "none" }}
          />
           <input
            ref={(ref) => (this.myInput10 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange010}
            style={{ display: "none" }}
          />
        </div>
      </div>
    );
  }
}

export default InnovationEdit1;
