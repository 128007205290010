import React, { Component } from "react";
import image from "./img//logo-capgemini.png";
class preloader extends Component {
    render() {
        return(
            <>
<div id="root">
<div className="loader-wrapper d-flex justify-content-center align-items-center">
<div className="p-5">
<img src={image} className="img-fluide" alt=""/></div>
  </div>
   {/* <div className='preloader d-flex justify-content-center align-items-center'>
  <div classn="loader p-5">
      <img src="assets/img/logo-capgemini.png" class="img-fluid" alt=""/>
  </div>
</div> */}
</div>
            </>

        )
    }
}
export default preloader;