import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import VideoPlayer from "./components/VideoPlayer";

const element = <VideoPlayer />;

// ReactDOM.render(element, document.getElementById("root"));

ReactDOM.render(
  <BrowserRouter>
    <App />
    {/* <App1 tenant={"/capgemini/virtualtour2"} /> */}
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
