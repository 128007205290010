import Swal from "sweetalert2";
class UnAuthorizedService {
  unauthorized = (err) => {
    //unauthorized = (err) => {
    if (err.response) {
      // client received an error response (5xx, 4xx)
      console.log(err.response.status);
      if (err.response.status == 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("mode");
        localStorage.removeItem("intro");
        //localStorage.removeItem("eventname");
        Swal.fire({
          // position: 'top-end',
          icon: "Failure",
          title: "Session TimeOut. Login Again!.",
          showConfirmButton: false,
          timer: 2000,
        });
        window.location.reload();
      }
    } else if (err.request) {
      // client never received a response, or request never left
      console.log(err.request);
    } else {
      // anything else
      console.log(err);
    }
  };
}

export default new UnAuthorizedService();
