import React, { useState, useEffect } from "react";
import styled from "styled-components";
// const Main = styled("div")`
//   font-family: sans-serif;
//   background: #f0f0f0;
//   height: 100vh;
// `;

const DropDownContainer = styled("div")`
  width: 19em;
  margin: 0 auto;
`;

const DropDownHeader = styled("div")`
  margin-bottom: 0.3em;
  padding: 0.1em 1em 0.1em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
  color: #3faffa;
  background: #ffffff;
  border: 2px solid #e5e5e5;
`;

const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.2em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.2em;
`;

export default function Dropdown({ onChangeRole, opt, defaultrole }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultrole);
  const options = opt;
  const toggling = () => setIsOpen(!isOpen);

  useEffect(() => {}, []);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
    onChangeRole(value);
  };

  return (
    <div>
      <DropDownContainer>
        <DropDownHeader onClick={toggling}>{selectedOption}</DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {options.map((option) => (
                <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
                  {option}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </div>
  );
}
