
import React from "react";
import rotate from "./img/rotatedevice.gif";
const Rotate = () => {
    return (

<div id="mobile-portrait" className="">
    <img src={rotate} alt="" className="center-device" />
  </div>
    )}
export default Rotate;