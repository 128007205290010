import axios from "axios";
import authHeader from "./auth-header";
import UnauthorizedService from "../common/unauthorized";
const FileDownload = require("js-file-download");
const {
  server_port,
  domain,
  react_env,
  subdomain,
} = require("../config/config");
let API_URL = "";
let API_EXCEL_URL = "";
if (react_env === "local") {
  API_URL = domain + ":" + server_port + "/";
  API_EXCEL_URL = domain + ":" + server_port + "/api/excel/";
} else {
  API_URL = domain + "/";
  API_EXCEL_URL = domain + "/api/excel/";
}

class FeedbackService {
  setFeedback(rating, comments) {
    return axios
      .post(
        API_URL + "setfeedback",
        { rating, comments, subdomain },
        { headers: authHeader() }
      )
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  }

  // delFeedback(id) {
  //   return axios.post(
  //     API_URL + "deletefeedback",
  //     { id },
  //     { headers: authHeader() }
  //   );
  // }

  // getFeedbackReport(page, matchstring) {
  //   return axios.post(
  //     API_URL + "feedbackreport",
  //     {
  //       page,
  //       matchstring,
  //     },
  //     { headers: authHeader() }
  //   );
  // }

  // getFeedbackRepSearch(page, matchstring) {
  //   return axios.post(
  //     API_URL + "feedbackrepsearch",
  //     {
  //       page,
  //       matchstring,
  //     },
  //     { headers: authHeader() }
  //   );
  // }

  // getFeedbackRepDateRange(page, matchstring, startDate, endDate) {
  //   return axios.post(
  //     API_URL + "feedbackrepdaterange",
  //     {
  //       page,
  //       matchstring,
  //       startDate,
  //       endDate,
  //     },
  //     { headers: authHeader() }
  //   );
  // }

  // downloadExcel() {
  //   return axios
  //     .get(API_EXCEL_URL + "downloadfeedback", {
  //       responseType: "blob",
  //     })
  //     .then((response) => {
  //       FileDownload(response.data, "FeedbackReport.xlsx");
  //     });
  // }
}

export default new FeedbackService();
