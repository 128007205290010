import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import stall1 from "./img/VR-03/VR-03 6 GRIDS.jpg";
import image from "./img/rs1.png";
import image1 from "./img/rs2.png";
import Modal from "react-modal";
import AWSService from "../services/aws.service";
import EventService from "../services/event.service";
import PPTPathService from "../services/pptpath.service";
import UnAuthorizedService from "../common/unauthorized";
import AuthService from "../services/auth.service";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./styles/maping.css";
import $ from "jquery";
import "../App.css";
var lobbyeventname = "";
let tenant = "";
const { aws_s3_url, aws_s3_bucket_name } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "70vh",
    marginRight: "-50%",
    width: "calc(60% - 0px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    border: "unset !important",
    margintop: "calc(85vh - 10vh - 10px)",
    zIndex: "99999",
    backgroundcolor: "unset !important",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(50% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding: "0 0 0 0",
  },
};
class Resource5G3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initially, no file is selected
      selectedFile: null,
      poster1: false,
      poster2: false,
      poster3: false,
      poster4: false,
      poster6: false,
      poster4r: false,
      poster4r1: false,
      poster4r2: false,
      poster4r3: false,
      poster4r4: false,
      poster55: false,
      poster56: false,
      poster57: false,
      poster58: false,
      poster59: false,


      poster3r: false,
      poster65g21: false,
      poster65g22: false,
      poster65g23: false,
      poster65g24: false,
      poster65g25: false,






      R4poster1path : "",
      R4poster2path : "",
      R4poster3path : "",
      R4poster4path : "",
      R4poster6path : "",
      R4poster4rpath : "",
      R4poster4r1path : "",
      R4poster4r2path : "",
      R4poster4r3path : "",
      R4poster3rpath : "",
      R4poster4r4path : "",
      R4poster65g21path : "",
      R4poster65g22path : "",
      R4poster65g23path : "",
      R4poster65g24path : "",
      R4poster65g25path : "",
      R4poster55path : "",
      R4poster56path : "",
      R4poster57path : "",
      R4poster58path : "",
      R4poster59path : "",









      R4poster3type: "",
      R4poster3rtype: "",
      R4poster65g21type: "",
      R4poster65g22type: "",
      R4poster65g23type: "",
      R4poster65g24type: "",
      R4poster65g25type: "",



      R4poster55type: "",
      R4poster56type: "",
      R4poster57type: "",
      R4poster58type: "",
      R4poster59type: "",

      modalIsOpen: false,
      modal1IsOpen: false,
      modal2IsOpen: false,
      tenantname: tenant,
    };
  }

  P1openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  P1closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  P2openModal = () => {
    this.setState({ modal1IsOpen: true });
  };

  P2closeModal = () => {
    this.setState({ modal1IsOpen: false });
  };
  P3openModal = () => {
    this.setState({ modal2IsOpen: true });
  };

  P3closeModal = () => {
    this.setState({ modal2IsOpen: false });
  };




  P1openModal1 = () => {
    this.setState({ modalIsOpen1: true });
  };

  P1closeModal1 = () => {
    this.setState({ modalIsOpen1: false });
  };

  P2openModal1 = () => {
    this.setState({ modal1IsOpen1: true });
  };

  P2closeModal1 = () => {
    this.setState({ modal1IsOpen1: false });
  };
  P3openModal1 = () => {
    this.setState({ modal2IsOpen1: true });
  };

  P3closeModal1 = () => {
    this.setState({ modal2IsOpen1: false });
  };

  P1openModal2 = () => {
    this.setState({ modalIsOpen2: true });
  };

  P1closeModal2 = () => {
    this.setState({ modalIsOpen2: false });
  };

  P2openModal2 = () => {
    this.setState({ modal1IsOpen2: true });
  };

  P2closeModal2 = () => {
    this.setState({ modal1IsOpen2: false });
  };
  P3openModal2 = () => {
    this.setState({ modal2IsOpen2: true });
  };

  P3closeModal2 = () => {
    this.setState({ modal2IsOpen2: false });
  };




  P1openModal3 = () => {
    this.setState({ modalIsOpen3: true });
  };

  P1closeModal3 = () => {
    this.setState({ modalIsOpen3: false });
  };

  P2openModal3 = () => {
    this.setState({ modal1IsOpen3: true });
  };

  P2closeModal3 = () => {
    this.setState({ modal1IsOpen3: false });
  };
  P3openModal3 = () => {
    this.setState({ modal2IsOpen3: true });
  };

  P3closeModal3 = () => {
    this.setState({ modal2IsOpen3: false });
  };



  P1openModal4 = () => {
    this.setState({ modalIsOpen4: true });
  };

  P1closeModal4 = () => {
    this.setState({ modalIsOpen4: false });
  };

  P2openModal4 = () => {
    this.setState({ modal1IsOpen4: true });
  };

  P2closeModal4 = () => {
    this.setState({ modal1IsOpen4: false });
  };
  P3openModal4 = () => {
    this.setState({ modal2IsOpen4: true });
  };

  P3closeModal4 = () => {
    this.setState({ modal2IsOpen4: false });
  };



  P1openModal5 = () => {
    this.setState({ modalIsOpen5: true });
  };

  P1closeModal5 = () => {
    this.setState({ modalIsOpen5: false });
  };

  P2openModal5 = () => {
    this.setState({ modal1IsOpen5: true });
  };

  P2closeModal5 = () => {
    this.setState({ modal1IsOpen5: false });
  };
  P3openModal5 = () => {
    this.setState({ modal2IsOpen5: true });
  };

  P3closeModal5 = () => {
    this.setState({ modal2IsOpen5: false });
  };
  componentDidMount() {

    localStorage.setItem("intro", 0);
    const eventname = EventService.getCurrentEvent();
    console.log(eventname);
    var num = Math.random();
    PPTPathService.getFiletype(eventname, "Rs")
      .then((response) => {
        response.data.forEach((filedetails) => {
          if (filedetails.filename === "poster3edit5g3") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R4poster3path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs" +
                "/poster3edit5g3" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
                poster3edit5g3: true,
            });

            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R4poster3path:filedetails.path,
                  poster3: true,

                });
              this.setState({ R4poster3type: "txt" });
            }
          }

          if (filedetails.filename === "posterg3aa") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R4poster65g21path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs" +
                "/posterg3aa" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
                posterg3aa: true,
            });

            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R4poster65g21path:filedetails.path,
                  poster65g21: true,

                });
              this.setState({ R4poster65g21type: "txt" });
            }
          }
          if (filedetails.filename === "posterg3bb") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R4poster65g22path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs4" +
                "/posterg3bb" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
                posterg3bb: true,
            });

            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R4poster65g22path:filedetails.path,
                  poster65g22: true,

                });
              this.setState({ R4poster65g22type: "txt" });
            }
          }
          if (filedetails.filename === "posterg3cc") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R4poster65g23path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs4" +
                "/posterg3cc" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
                posterg3cc: true,
            });

            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R4poster65g23path:filedetails.path,
                  poster65g23: true,

                });
              this.setState({ R4poster65g23type: "txt" });
            }
          }

          if (filedetails.filename === "posterg3dd") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R4poster5g24path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs4" +
                "/posterg3dd" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
                posterg3dd: true,
            });

            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R4poster65g24path:filedetails.path,
                  poster65g24: true,

                });
              this.setState({ R4poster65g24type: "txt" });
            }
          }


          if (filedetails.filename === "posterg3ee") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R4poster5g25path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs4" +
                "/posterg3ee" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
                posterg3ee: true,
            });

            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R4poster65g25path:filedetails.path,
                  poster65g25: true,

                });
              this.setState({ R4poster65g25type: "txt" });
            }
          }


          if (filedetails.filename === "poster3") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R4poster3path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs" +
              "/poster3" +
              "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster3: true,
            });

            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R4poster3rpath:filedetails.path,
                  poster3r: true,

                });
              this.setState({ R4poster3rtype: "txt" });
            }
          }



        if (filedetails.filename == "poster4") {
            this.setState({
              R4poster4path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs5g3" +
                "/poster4" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster4: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R4poster4type: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R2poster4path:filedetails.path,
                  poster4: true,

                });
                this.setState({ R4poster4type: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R4poster4type: "pdf" });
              }
            }
            console.log(this.state.R4poster4path);
          }



          if (filedetails.filename == "poster55") {
            this.setState({
              R4poster55path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs" +
                "/poster55" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster55: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R4poster55type: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R2poster55path:filedetails.path,
                  poster55: true,

                });
                this.setState({ R4poster55type: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R4poster55type: "pdf" });
              }
            }
            console.log(this.state.R4poster55path);
          }




          if (filedetails.filename == "poster56") {
            this.setState({
              R4poster56path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs" +
                "/poster56" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster56: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R4poster56type: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R2poster56path:filedetails.path,
                  poster56: true,

                });
                this.setState({ R4poster56type: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R4poster56type: "pdf" });
              }
            }
            console.log(this.state.R4poster56path);
          }


          if (filedetails.filename == "poster57") {
            this.setState({
              R4poster57path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs" +
                "/poster57" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster57: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R4poster57type: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R2poster57path:filedetails.path,
                  poster57: true,

                });
                this.setState({ R4poster57type: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R4poster57type: "pdf" });
              }
            }
            console.log(this.state.R4poster57path);
          }


          if (filedetails.filename == "poster58") {
            this.setState({
              R4poster58path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs" +
                "/poster58" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster58: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R4poster58type: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R2poster58path:filedetails.path,
                  poster58: true,

                });
                this.setState({ R4poster58type: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R4poster58type: "pdf" });
              }
            }
            console.log(this.state.R4poster58path);
          }


          if (filedetails.filename == "poster59") {
            this.setState({
              R4poster59path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs" +
                "/poster59" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster59: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R4poster59type: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R2poster59path:filedetails.path,
                  poster59: true,

                });
                this.setState({ R4poster59type: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R4poster59type: "pdf" });
              }
            }
            console.log(this.state.R4poster59path);
          }



        })
      })

      .catch((err) => {
        UnAuthorizedService.unauthorized(err);
      });

    EventService.getSpecificEvent()
    .then((response) => {
      console.log("Eventname from getSpecificEvent");
      console.log(response.data);
      console.log(response.data.eventname);
      const eventpath = "/" + response.data.eventname;
      this.setState({ tenantname: eventpath, showLoginPage: true });
      localStorage.setItem("eventname", response.data.eventname);
      console.log(response.data.eventname);

      const user=AuthService.getCurrentUser();

      AuthService.getCurrentRole().then((roles)=>{
        if (roles) {
          console.log("Got current role from server");
          this.setState({
            currentUser: user,
            showModeratorBoard: roles.data.includes("ROLE_MODERATOR"),
            showAdminBoard: roles.data.includes("ROLE_ADMIN"),
            showAddTenant: roles.data.includes("ROLE_SUPERADMIN"),
            showAddUser: roles.data.includes("ROLE_SUPERADMIN"),
            showRegModerators:
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showRegAdmins:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN"),
            showRegSuperAdmins: roles.data.includes("ROLE_SUPERADMIN"),
            showRegUsers:
              roles.data.includes("ROLE_MODERATOR") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showCreateEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showDetailLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showFeedbackReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEdit:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showNormalUser: roles.data.includes("ROLE_USER"),
            showTenants:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            //tenantname: tenant,
          });
        }
        lobbyeventname = EventService.getCurrentEvent();
        console.log(lobbyeventname);

        this.setState({
          R4poster1path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs" +
          "/poster1.png?v=" + num,
          R4poster2path :
          AWS_S3_URL + "capgemini/" + eventname +  "/Rsq" +
          "/poster2q.png?v=" + num,
          R4poster6path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs4" + "/poster6.png?v=" + num,
          R4poster4rpath :
          AWS_S3_URL + "capgemini/" + eventname  + "/Rs" +
          "/posterg362.png?v=" + num,
          R4poster4r1path :
          AWS_S3_URL + "capgemini/" + eventname  +  "/Rs" +
          "/posterg363.png?v=" + num,
          R4poster4r2path :
          AWS_S3_URL + "capgemini/" + eventname  +"/Rs" +
          "/posterg364.png?v=" + num,
          R4poster4r3path :
          AWS_S3_URL + "capgemini/" + eventname  + "/Rs" +
          "/posterg365.png?v=" + num,
          R4poster4r4path :
          AWS_S3_URL + "capgemini/" + eventname  +  "/Rs" +
          "/posterg366.png?v=" + num,



          poster1: true,
          poster2: true,
          poster6: true,
          poster4r: true,
          poster4r1: true,
          poster4r2: true,
          poster4r3: true,
          poster4r4: true,






    });
    })
  }
    )
  }

  render() {
    function hideImg() {
      document.getElementById("myImg").style.display = "none";
    }
    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }
    function hideImg4r() {
      document.getElementById("myImg4r").style.display = "none";
    }
    function hideImg4r1() {
      document.getElementById("myImg4r1").style.display = "none";
    }
    function hideImg4r2() {
      document.getElementById("myImg4r2").style.display = "none";
    }
    function hideImg4r3() {
      document.getElementById("myImg4r3").style.display = "none";
    }
    function hideImg4r4() {
        document.getElementById("myImg4r4").style.display = "none";
      }



    const {

      poster1,
      poster2,
      poster3,
      poster4,
      poster6,
      poster4r,
      poster4r1,
      poster4r2,
      poster4r3,
      poster3r,
      poster4r4,
      poster65g21,
      poster65g22,
      poster65g23,
      poster65g24,
      poster65g25,
      poster55,
      poster56,
      poster57,
      poster58,
      poster59,








      R4poster1path,
      R4poster2path,
      R4poster3path,
      R4poster4path,
      R4poster6path,
      R4poster3type,
      R4poster4type,
      R4poster55type,
      R4poster56type,
      R4poster57type,
      R4poster58type,
      R4poster59type,

      R4poster65g21type,
      R4poster65g22type,
      R4poster65g23type,
      R4poster65g24type,
      R4poster65g25type,
      R4poster4rpath,
      R4poster4r1path,
      R4poster4r2path,
      R4poster4r3path,
      R4poster3rpath,
      R4poster4r4path,
      R4poster65g21path,
      R4poster65g22path,
      R4poster65g23path,
      R4poster65g24path,
      R4poster65g25path,
      R4poster55path,
      R4poster56path,
      R4poster57path,
      R4poster58path,
      R4poster59path,








      tenantname


    } = this.state;

    return (
      <div className="container-fluide">


        <div className="page-content">
          <div id="content-agenda">
            <div id="bg">
              <img src="https://d2e8va4puks5pl.cloudfront.net/VR-03/VR-03%206%20GRIDS.jpg" id="bg-image" className="mb-3" />

              <div className="sidenavogation">
                  {/* <Link to={tenantname + "/Resource"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Resource Center1</span>
                  </Link>
                  <Link to={tenantname + "/Resource1"} className="Rs2  text-white buttonRes">
                              <span className="hide-menu">Resource Center2</span>
                  </Link>
                  <Link to={tenantname + "/Resource2"} className="Rs3  text-white buttonRes">
                              <span className="hide-menu">Resource Center3</span>
                  </Link>
                  <Link to={tenantname + "/Resource3"} className="Rs4  text-white buttonRes">
                              <span className="hide-menu">Resource Center4</span>
                  </Link>
                  <Link to={tenantname + "/Resource4"} className="Rs5  text-white buttonRes">
                              <span className="hide-menu">Resource Center5</span>
                  </Link> */}
                  <Link to={tenantname + "/agenda"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Back To Agenda</span>
                  </Link>

                  </div>
              <div id="Resource1g3editsix1">
              {poster1 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap41" >
                  <img
                  src={R4poster1path}
                  // src={image}
                   alt=""  onError={() => hideImg()}
                   id="myImg"/>
                </div>

              </form>
            )}
              </div>
              <div id="Resource1g3editsix2">
              {poster4r && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap41r" >
                  <img
                  src={R4poster4rpath}
                  // src={image}
                   alt=""  onError={() => hideImg4r()}
                   id="myImg4r"/>
                </div>

              </form>
            )}
              </div>
              <div id="Resource1g3editsix3">
              {poster4r1 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap41r1" >
                  <img
                  src={R4poster4r1path}
                  // src={image}
                   alt=""  onError={() => hideImg4r1()}
                   id="myImg4r1"/>
                </div>

              </form>
            )}
              </div>
              <div id="Resource1g3editsix4">
              {poster4r2 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap41r2" >
                  <img
                  src={R4poster4r2path}
                  // src={image}
                   alt=""  onError={() => hideImg4r2()}
                   id="myImg4r2"/>
                </div>

              </form>
            )}
              </div>
              <div id="Resource1g3editsix5">
              {poster4r3 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap41r3" >
                  <img
                  src={R4poster4r3path}
                  // src={image}
                   alt=""  onError={() => hideImg4r3()}
                   id="myImg4r3"/>
                </div>

              </form>
            )}
              </div>
              <div id="Resource1g3editsix6">
              {poster4r4 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap41r4" >
                  <img
                  src={R4poster4r4path}
                  // src={image}
                   alt=""  onError={() => hideImg4r4()}
                   id="myImg4r4"/>
                </div>

              </form>
            )}
              </div>


              <div id="Resource2g3editsix">
              {poster2 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader2"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap51" >
                  <img
                  src={R4poster2path}
                 // src={image1}
                   onError={() => hideImg1()}
                   id="myImg1"/>
                </div>

              </form>
            )}
              </div>

              <div id="Resource3g3editsix1">
              {poster3 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap61" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R4poster3path}
                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>
              <div id="Resource3g3editsix2">
              {poster65g21 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap61" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R4poster65g21path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>
              <div id="Resource3g3editsix3">
              {poster65g22 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap61" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R4poster65g22path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>
              <div id="Resource3g3editsix4">
              {poster65g23 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap61" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R4poster65g23path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>
              <div id="Resource3g3editsix5">
              {poster65g24 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap61" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R4poster65g24path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>
              <div id="Resource3g3editsix6">
              {poster65g25 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap61" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R4poster65g25path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>
              {/* <div id="Resource6r">
              {poster3r && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap61r" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R4poster3rpath}
                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div> */}

        <div id="ResourceCenterg3editsix1">
          <div>
            <div className="hotspot1g3editsix1">
              {/* <div className="out"></div> */}
              {poster4 && R4poster4type === "ppt" && (
                <div className="in1g3editsix1 bg-dark q" onClick={this.P1openModal} >
                  {" "}
                </div>
              )}
            </div>
            {poster4 && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen}
                  style={customStyles}
                  onRequestClose={this.P1closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal}>
                    x
                  </span>
                  <iframe
                   src={R4poster4path}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterg3editsix1">
          <div>
            <div className="hotspot1g3editsix1">
              {poster4 && R4poster4type === "video" && (
                <div className="in1g3editsix1 bg-dark q" onClick={this.P2openModal}></div>
              )}
            </div>
            {poster4 && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal}
                >
                  <span className="close1" onClick={this.P2closeModal}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R4poster4path} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterg3editsix1">
          <div>
            <div className="hotspot1g3editsix1">
              {poster4 && R4poster4type === "pdf" && (
                <div className="in1g3editsix1 bg-dark q" onClick={this.P3openModal}></div>
              )}
            </div>
            {poster4 && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen}
                  style={customStyles}
                  onRequestClose={this.P3closeModal}
                >
                  <span className="close1" onClick={this.P3closeModal}>
                    x
                  </span>

                  <iframe
                    src={R4poster4path}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>
           {/* <div id="Resource8">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap88"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R4poster6path}
                        // src={image}

                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>

                  </form>
                )}
              </div> */}
           </div>


           <div id="ResourceCenterg3editsix2">
          <div>
            <div className="hotspot1g3editsix2">
              {/* <div className="out"></div> */}
              {poster55 && R4poster55type === "ppt" && (
                <div className="in1g3editsix2 bg-dark q" onClick={this.P1openModal1} >
                  {" "}
                </div>
              )}
            </div>
            {poster55 && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen1}
                  style={customStyles}
                  onRequestClose={this.P1closeModal1}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal1}>
                    x
                  </span>
                  <iframe
                   src={R4poster55path}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterg3editsix2">
          <div>
            <div className="hotspot1g3editsix2">
              {poster55 && R4poster55type === "video" && (
                <div className="in1g3editsix2 bg-dark q" onClick={this.P2openModal1}></div>
              )}
            </div>
            {poster55 && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen1}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal1}
                >
                  <span className="close1" onClick={this.P2closeModal1}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R4poster55path} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterg3editsix2">
          <div>
            <div className="hotspot1g3editsix2">
              {poster55 && R4poster55type === "pdf" && (
                <div className="in1g3editsix2 bg-dark q" onClick={this.P3openModal1}></div>
              )}
            </div>
            {poster55 && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen1}
                  style={customStyles}
                  onRequestClose={this.P3closeModal1}
                >
                  <span className="close1" onClick={this.P3closeModal1}>
                    x
                  </span>

                  <iframe
                    src={R4poster55path}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>
           {/* <div id="Resource8">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap88"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R4poster6path}
                        // src={image}

                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>

                  </form>
                )}
              </div> */}
           </div>


           <div id="ResourceCenterg3editsix3">
          <div>
            <div className="hotspot1g3editsix3">
              {/* <div className="out"></div> */}
              {poster56 && R4poster56type === "ppt" && (
                <div className="in1g3editsix3 bg-dark q" onClick={this.P1openModal2} >
                  {" "}
                </div>
              )}
            </div>
            {poster56 && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen2}
                  style={customStyles}
                  onRequestClose={this.P1closeModal2}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal2}>
                    x
                  </span>
                  <iframe
                   src={R4poster56path}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterg3editsix3">
          <div>
            <div className="hotspot1g3editsix3">
              {poster56 && R4poster56type === "video" && (
                <div className="in1g3editsix3 bg-dark q" onClick={this.P2openModal2}></div>
              )}
            </div>
            {poster56 && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen2}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal2}
                >
                  <span className="close1" onClick={this.P2closeModal2}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R4poster56path} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterg3editsix3">
          <div>
            <div className="hotspot1g3editsix3">
              {poster56 && R4poster56type === "pdf" && (
                <div className="in1g3editsix3 bg-dark q" onClick={this.P3openModal2}></div>
              )}
            </div>
            {poster56 && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen2}
                  style={customStyles}
                  onRequestClose={this.P3closeModal2}
                >
                  <span className="close1" onClick={this.P3closeModal2}>
                    x
                  </span>

                  <iframe
                    src={R4poster56path}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>
           {/* <div id="Resource8">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap88"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R4poster6path}
                        // src={image}

                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>

                  </form>
                )}
              </div> */}
           </div>

           <div id="ResourceCenterg3editsix4">
          <div>
            <div className="hotspot1g3editsix4">
              {/* <div className="out"></div> */}
              {poster57 && R4poster57type === "ppt" && (
                <div className="in1g3editsix4 bg-dark q" onClick={this.P1openModal3} >
                  {" "}
                </div>
              )}
            </div>
            {poster57 && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen3}
                  style={customStyles}
                  onRequestClose={this.P1closeModal3}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal3}>
                    x
                  </span>
                  <iframe
                   src={R4poster57path}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterg3editsix4">
          <div>
            <div className="hotspot1g3editsix4">
              {poster57 && R4poster57type === "video" && (
                <div className="in1g3editsix4 bg-dark q" onClick={this.P2openModal3}></div>
              )}
            </div>
            {poster57 && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen3}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal3}
                >
                  <span className="close1" onClick={this.P2closeModal3}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R4poster57path} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterg3editsix4">
          <div>
            <div className="hotspot1g3editsix4">
              {poster57 && R4poster57type === "pdf" && (
                <div className="in1g3editsix4 bg-dark q" onClick={this.P3openModal3}></div>
              )}
            </div>
            {poster57 && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen3}
                  style={customStyles}
                  onRequestClose={this.P3closeModal3}
                >
                  <span className="close1" onClick={this.P3closeModal3}>
                    x
                  </span>

                  <iframe
                    src={R4poster57path}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>
           {/* <div id="Resource8">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap88"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R4poster6path}
                        // src={image}

                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>

                  </form>
                )}
              </div> */}
           </div>

           <div id="ResourceCenterg3editsix5">
          <div>
            <div className="hotspot1g3editsix5">
              {/* <div className="out"></div> */}
              {poster58 && R4poster58type === "ppt" && (
                <div className="in1g3editsix5 bg-dark q" onClick={this.P1openModal4} >
                  {" "}
                </div>
              )}
            </div>
            {poster58 && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen4}
                  style={customStyles}
                  onRequestClose={this.P1closeModal4}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal4}>
                    x
                  </span>
                  <iframe
                   src={R4poster58path}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterg3editsix5">
          <div>
            <div className="hotspot1g3editsix5">
              {poster58 && R4poster58type === "video" && (
                <div className="in1g3editsix5 bg-dark q" onClick={this.P2openModal4}></div>
              )}
            </div>
            {poster58 && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal4}
                >
                  <span className="close1" onClick={this.P2closeModal4}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R4poster58path} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterg3editsix5">
          <div>
            <div className="hotspot1g3editsix5">
              {poster58 && R4poster58type === "pdf" && (
                <div className="in1g3editsix5 bg-dark q" onClick={this.P3openModal4}></div>
              )}
            </div>
            {poster58 && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen4}
                  style={customStyles}
                  onRequestClose={this.P3closeModal4}
                >
                  <span className="close1" onClick={this.P3closeModal4}>
                    x
                  </span>

                  <iframe
                    src={R4poster58path}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>
           {/* <div id="Resource8">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap88"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R4poster6path}
                        // src={image}

                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>

                  </form>
                )}
              </div> */}
           </div>


           <div id="ResourceCenterg3editsix6">
          <div>
            <div className="hotspot1g3editsix6">
              {/* <div className="out"></div> */}
              {poster59 && R4poster59type === "ppt" && (
                <div className="in1g3editsix6 bg-dark q" onClick={this.P1openModal5} >
                  {" "}
                </div>
              )}
            </div>
            {poster59 && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen5}
                  style={customStyles}
                  onRequestClose={this.P1closeModal5}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal5}>
                    x
                  </span>
                  <iframe
                   src={R4poster59path}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterg3editsix6">
          <div>
            <div className="hotspot1g3editsix6">
              {poster59 && R4poster59type === "video" && (
                <div className="in1g3editsix6 bg-dark q" onClick={this.P2openModal5}></div>
              )}
            </div>
            {poster59 && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen5}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal5}
                >
                  <span className="close1" onClick={this.P2closeModal5}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R4poster59path} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterg3editsix6">
          <div>
            <div className="hotspot1g3editsix6">
              {poster59 && R4poster59type === "pdf" && (
                <div className="in1g3editsix6 bg-dark q" onClick={this.P3openModal5}></div>
              )}
            </div>
            {poster59 && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen5}
                  style={customStyles}
                  onRequestClose={this.P3closeModal5}
                >
                  <span className="close1" onClick={this.P3closeModal5}>
                    x
                  </span>

                  <iframe
                    src={R4poster59path}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>
           {/* <div id="Resource8">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap88"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R4poster6path}
                        // src={image}

                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>

                  </form>
                )}
              </div> */}
           </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Resource5G3;
