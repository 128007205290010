import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
// import { isEmail } from "validator";
import TenantService from "../services/tenant.service";
// import "./styles/register.css"
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

// const email = (value) => {
//   if (!isEmail(value)) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This is not a valid email.
//       </div>
//     );
//   }
// };

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The tenantname must be between 3 and 20 characters.
      </div>
    );
  }
};

const vnumber = (value) => {
  if (value.length > 5) {
    return (
      <div className="alert alert-danger" role="alert">
        The number must be less than 6 digits.
      </div>
    );
  }
};

export default class RegisterTenant extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeTenantname = this.onChangeTenantname.bind(this);
    this.onChangeAdminnos = this.onChangeAdminnos.bind(this);
    this.onChangeModnos = this.onChangeModnos.bind(this);
    this.onChangeEventnos = this.onChangeEventnos.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeUsernos = this.onChangeUsernos.bind(this);

    this.state = {
      tenantname: "",
      adminnos: "",
      modnos: "",
      eventnos: false,
      message: "",
      status: "",
      usernos: "",
    };
  }

  onChange(data) {
    this.setState({
      roles: [data.toLowerCase()],
    });
    console.log(data);
  }

  onChangeTenantname(e) {
    this.setState({
      tenantname: e.target.value,
    });
  }

  onChangeAdminnos(e) {
    this.setState({
      adminnos: e.target.value,
    });
  }
  onChangeModnos(e) {
    this.setState({
      modnos: e.target.value,
    });
  }

  onChangeEventnos(e) {
    this.setState({
      eventnos: e.target.value,
    });
  }

  onChangeStatus(e) {
    this.setState({
      status: e.target.value,
    });
  }

  onChangeUsernos(e) {
    this.setState({
      usernos: e.target.value,
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      TenantService.setTenant(
        this.state.tenantname,
        this.state.adminnos,
        this.state.modnos,
        this.state.eventnos,
        this.state.status,
        this.state.usernos
      ).then(
        (response) => {
          this.setState({
            message: response.data.message,
            successful: true,
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage,
          });
        }
      );
    }
  }

  render() {
    return (
      <div className="col-md-12" >
        <div className="card card-container">
          {/* <img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card "
          /> */}
          <Form
            onSubmit={this.handleRegister}
            ref={(c) => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>
                <div className="form-group ">
                  <label htmlFor="tenantname ">Organization Name</label>
                  <Input
                    type="text"
                    className=" form-control form-control-sm mt-0"
                    name="tenantname"
                    value={this.state.tenantname}
                    onChange={this.onChangeTenantname}
                    validations={[required, vusername]}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="adminnos ">No of Master Admin Licenses</label>
                  <Input
                    type="number"
                    className="form-control form-control-sm mt-0"
                    name="adminnos"
                    value={this.state.adminnos}
                    onChange={this.onChangeAdminnos}
                    validations={[required, vnumber]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="modnos">No of Admin Licenses</label>
                  <Input
                    type="number"
                    className="form-control form-control-sm mt-0"
                    name="modnos"
                    value={this.state.modnos}
                    onChange={this.onChangeModnos}
                    validations={[required, vnumber]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="eventnos">No of Events</label>
                  <Input
                    type="number"
                    className="form-control form-control-sm mt-0"
                    name="eventnos"
                    value={this.state.eventnos}
                    onChange={this.onChangeEventnos}
                    validations={[required, vnumber]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="status">License Status</label>
                  <Input
                    type="text"
                    className="form-control form-control-sm mt-0"
                    name="status"
                    value={this.state.status}
                    onChange={this.onChangeStatus}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="usernos">Event Users Limit</label>
                  <Input
                    type="number"
                    className="form-control form-control-sm mt-0"
                    name="usernos"
                    value={this.state.usernos}
                    onChange={this.onChangeUsernos}
                    validations={[required, vnumber]}
                  />
                </div>

                <div className="form-group form-group-sm">
                  <button className="btn btn-primary btn-block  ">
                    Add Client
                  </button>
                </div>
              </div>
            )}

            {this.state.message && (
              <div className="form-group form-group-sm">
                <div
                  className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}
