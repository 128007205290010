import axios from "axios";
import authHeader from "./auth-header";
const {
    server_port,
    domain,
    react_env,
    subdomain,
} = require("../config/config");
let API_URL = "";
console.log(server_port);
console.log(react_env);
if (react_env === "local") API_URL = domain + ":" + server_port + "/";
else API_URL = domain + "/";
console.log(`API_URL is ........................${API_URL}`);

class EventService {
    getEvents() {
        // return axios.get(API_URL + "getevents", { headers: authHeader() });
        var eventid = localStorage.getItem('eventid');
        console.log(eventid)
        var eventidremovespace = eventid.replace(/ /g, '');
        var url = API_URL + "geteventzonedetails?eventid=" + eventidremovespace;
        // console.log(url)
        // return axios.get(url, { headers: authHeader() });
        return axios.get(url, {
            headers: authHeader()
        });
    }
    getSpecificEvent() {
        return axios.get(API_URL + "getspecificevent" + "?subdomain=" + subdomain, {
            headers: authHeader(),
        });
    }

    getCurrentEvent() {
            console.log("Inside getCurrentEvent()");
            return localStorage.getItem("eventname");
        }
        // geteventzonedetails(eventid) {

    //     return axios.get(API_URL + "geteventzonedetails", { headers: authHeader(), data: { eventid: eventid } });
    // }
    // geteventzonedetails() {
    //     var eventid = localStorage.getItem('eventid');

    //     var url = API_URL + "geteventzonedetails?eventid=" + eventid;
    //     console.log(url)
    //     return axios.get(url, { headers: authHeader() });
    // }
    geteventzonedetails() {
        var eventid = localStorage.getItem('eventid');
        var eventidremovespace = eventid.replace(/ /g, '');
        var url = API_URL + "geteventzonedetails?eventid=" + eventidremovespace;
        // console.log(url)
        // return axios.get(url, { headers: authHeader() });
        return axios.get(url, {
            headers: authHeader(),
        });
    }


    getEvents1() {
        var eventid = localStorage.getItem('eventid');
        console.log(eventid)
        var eventidremovespace = eventid.replace(/ /g, '');
        var url = API_URL + "geteventresourcecentredetails?eventid=" + eventidremovespace;
        // console.log(url)
        // return axios.get(url, { headers: authHeader() });
        return axios.get(url, {
            headers: authHeader()
        });
    }
    // setEvent(
    //   topic,
    //   date,
    //   time,
    //   crmid,
    //   location,
    //   eventid,
    //   tenantid,
    //   eventname,
    //   eventlink,
    //   subdomain
    // ) {
    //   return axios.post(
    //     API_URL + "setevent",
    //     {
    //       topic,
    //       date,
    //       time,
    //       crmid,
    //       location,
    //       eventid,
    //       tenantid,
    //       eventname,
    //       eventlink,
    //       subdomain,
    //     },
    //     { headers: authHeader() }
    //   );
    // }

    // delEvent(id) {
    //   return axios.post(
    //     API_URL + "deleteevent",
    //     { id },
    //     { headers: authHeader() }
    //   );
    // }
    // UpdateEvent(id, topic, date, time, location, eventname, eventlink) {
    //   return axios.post(
    //     API_URL + "updateevent",
    //     { id, topic, date, time, location, eventname, eventlink },
    //     { headers: authHeader() }
    //   );
    // }
}

export default new EventService();