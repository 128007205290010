import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import stall1 from "./img/VR-01/VR-1 6 GRID.jpg";
import image from "./img/rs1.png";
import image1 from "./img/rs2.png";
import Modal from "react-modal";
import AWSService from "../services/aws.service";
import EventService from "../services/event.service";
import PPTPathService from "../services/pptpath.service";
import UnauthorizedService from "../common/unauthorized";
import UnAuthorizedService from "../common/unauthorized";
import AuthService from "../services/auth.service";
import { Uploader } from "../utils/upload";
import Progressbar from "../common/progressbar";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./styles/maping.css";
import $ from "jquery";
import "../App.css";
var lobbyeventname = "";
let tenant = "";
const { aws_s3_url, aws_s3_bucket_name } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
const customStyles = {
    overlay: {
        position: "fixed",
        zIndex: 1020,
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        background: "rgba(0,0,0,.9)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    content: {
        top: "40%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        height: "25vh",
        marginRight: "-50%",
        width: "calc(18% - 0px)",
        transform: "translate(-50%, -50%)",
        overflow: "hide",
        background: "rgba(0,0,0,.9)",
        background: "unset !important",
        border: "unset !important",

        zIndex: "99999",
        backgroundcolor: "unset !important",
        padding: "0px 0px 0px 0px",
    },
};
const customStylesV1 = {
    overlay: {
        position: "fixed",
        zIndex: 1,
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        background: "rgba(0,0,0,.9)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    content: {
        top: "60%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        width: "calc(20% - 0px)",
        height: "100%",
        transform: "translate(-50%, -50%)",
        overflow: "hide",
        background: "unset !important",
        margintop: "calc(85vh - 20vh - 20px)",
        zIndex: "99999",
        border: "unset !important",
        padding: "0 0 0 0",
    },
};
class ResorceEdit4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Initially, no file is selected
            selectedFile: null,
            poster1: false,
            poster2: false,
            poster3: false,
            poster4: false,
            poster5: false,
            poster6: false,
            poster45g2:false,
            poster5g21: false,
            poster5g22: false,
            poster5g23: false,
            poster5g24: false,
            poster5g25: false,
            poster65g21: false,
            poster65g22: false,
            poster65g23: false,
            poster65g24: false,
            poster65g25: false,
            poster35g2: false,





            R4poster1path: "",
            R4poster2path: "",
            R4poster3path: "",
            R4poster4path: "",
            R4poster5path: "",
            R4poster6path: "",
            R4poster5g21path: "",
            R4poster5g22path: "",
            R4poster5g23path: "",
            R4poster5g24path: "",
            R4poster5g25path: "",
            R4poster65g21path: "",
            R4poster65g22path: "",
            R4poster65g23path: "",
            R4poster65g24path: "",
            R4poster65g25path: "",
R4poster45g2path: "",
R4poster35g2path: "",


            modalIsOpen: false,
            modalIsOpen5g2: false,
            modalIsOpen5g2a: false,
            modalIsOpen5g2b: false,

            modalIsOpen5g2c: false,
            modalIsOpen5g2d: false,



            tenantname: tenant,
            localuploader: undefined,
            progressbar: 0,
        };
    }
    P1openModal = () => {
        this.setState({ modalIsOpen: true });
    };
    P1openModal5g2 = () => {
      this.setState({ modalIsOpen5g2: true });
  };

  P1openModal5g2a = () => {
    this.setState({ modalIsOpen5g2a: true });
};

P1openModal5g2b = () => {
  this.setState({ modalIsOpen5g2b: true });
};
P1openModal5g2c = () => {
  this.setState({ modalIsOpen5g2c: true });
};
P1openModal5g2d = () => {
  this.setState({ modalIsOpen5g2d: true });
};

    P1closeModal = () => {
        this.setState({ modalIsOpen: false });
    };
    P1closeModal5g2 = () => {
      this.setState({ modalIsOpen5g2: false });
  };

  P1closeModal5g2b = () => {
    this.setState({ modalIsOpen5g2b: false });
};


P1closeModal5g2c = () => {
  this.setState({ modalIsOpen5g2c: false });
};

P1closeModal5g2a = () => {
  this.setState({ modalIsOpen5g2a: false });
};

P1closeModal5g2d = () => {
  this.setState({ modalIsOpen5g2d: false });
};
    onFileChange45g2 = async(event) => {
        // Update the state
        this.setState({ selectedFile: event.target.files[0] });
        console.log("Inside this.onFileChange()");
        console.log(event.target.files[0].name);

        var _URL = window.URL || window.webkitURL;
        var file, img;
        file = event.target.files[0];
        img = new Image();
        var objectUrl = _URL.createObjectURL(event.target.files[0]);
        img.src = objectUrl;
        var fileType = event.target.files[0].name.split(".").pop();
        var filepath = null;
        img.onload = function() {
            if (this.width != 492 || this.height != 326) {
                alert(
                    // "Uploaded File Dimension: Width= " +
                    //   this.width +
                    //   "  Height= " +
                    //   this.height +
                    "Required FIle Dimension: Width = " + 492 + " Height= " + 326
                );
            } else {
                console.log("Inside file dimension are proper loop");
                uploadfile();
                //alert("File Dimensions are Proper");
            }
            _URL.revokeObjectURL(objectUrl);
        };
        // const eventname = EventService.getCurrentEvent();
        let uploadfile = () => {
            var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster45g2.png";

            console.log(`filepath is ${fileName}`);
            console.log(fileName);
            console.log(file);

            let data = JSON.stringify({
                fileName: fileName,
                contentType: fileType,
                bucketname: aws_s3_bucket_name,
            });

            const videoUploaderOptions = {
                fileName: fileName,
                file: file,
            };

            let percentage = undefined;
            let upstatus = undefined;

            const uploader = new Uploader(videoUploaderOptions);

            this.setState({ localuploader: uploader }, () => {
                console.log(
                    `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
                );
            });
            $("#loader1").addClass("hidden");
            this.setState({ poster: false });

            uploader
                .onProgress(
                    ({
                        percentage: newPercentage,
                        sent: sent,
                        total: total,
                        upstatus: upstatus,
                    }) => {
                        // to avoid the same percentage to be logged twice
                        if (newPercentage !== percentage) {

                            percentage = newPercentage;
                            console.log(`${percentage}%`);
                            $("#poster45g2bar").show();
                            $(".poster45g2barbtn").hide();

                        }
                        if (upstatus === "complete") {
                            console.log("File uploaded successfully");
                            $("#loader1").addClass("hidden");
                            this.setState({ poster: true });
                            var num = Math.random();

                            this.setState({
                                R4poster1path: AWS_S3_URL +
                                    "capgemini/" +
                                    lobbyeventname +
                                    "/Rs4" +
                                    "/poster45g2.png?v=" +
                                    num,
                            });
                            PPTPathService.setFiletype(
                                    lobbyeventname,
                                    "Rs3",
                                    "poster45g2",
                                    fileType,
                                    filepath
                                )
                                .then((result) => {
                                    console.log(result);
                                    console.log(
                                        "Uploaded successfully........................................"
                                    );
                                    Swal.fire({
                                        // position: 'top-end',
                                        icon: "success",
                                        title: "File Uploaded Successfully!.",
                                        showConfirmButton: false,
                                        timer: 2000,
                                    });
                                })
                                .catch((err) => {
                                    UnauthorizedService.unauthorized(err);
                                });
                            //window.location.reload(false);
                        } else if (upstatus === "failed") {
                            $("#loader1").addClass("hidden");
                            Swal.fire({
                                // position: 'top-end',
                                icon: "failure",
                                title: "File Upload Failed!.",
                                showConfirmButton: false,
                                timer: 2000,
                            });
                        }
                    }
                )
                .onError((error) => {
                    //setFile(undefined);
                    console.error(error);
                });

            uploader.start();
        };
    };
    onFileChange02 = async(event) => {
        // Update the state
        this.setState({ selectedFile: event.target.files[0] });
        console.log("Inside this.onFileChange()");
        console.log(event.target.files[0].name);

        var _URL = window.URL || window.webkitURL;
        var file, img;
        file = event.target.files[0];
        img = new Image();
        var objectUrl = _URL.createObjectURL(event.target.files[0]);
        img.src = objectUrl;
        var fileType = event.target.files[0].name.split(".").pop();
        var filepath = null;
        img.onload = function() {
            if (this.width != 690 || this.height != 77) {
                alert(
                    // "Uploaded File Dimension: Width= " +
                    //   this.width +
                    //   "  Height= " +
                    //   this.height +
                    "Required FIle Dimension: Width = " + 690 + " Height= " + 77
                );
            } else {
                console.log("Inside file dimension are proper loop");
                uploadfile();
                //alert("File Dimensions are Proper");
            }
            _URL.revokeObjectURL(objectUrl);
        };
        // const eventname = EventService.getCurrentEvent();
        let uploadfile = () => {
            var fileName = "capgemini/" + lobbyeventname + "/Rs4k" + "/poster2k.png";

            console.log(`filepath is ${fileName}`);
            console.log(fileName);
            console.log(file);

            let data = JSON.stringify({
                fileName: fileName,
                contentType: fileType,
                bucketname: aws_s3_bucket_name,
            });

            const videoUploaderOptions = {
                fileName: fileName,
                file: file,
            };

            let percentage = undefined;
            let upstatus = undefined;

            const uploader = new Uploader(videoUploaderOptions);

            this.setState({ localuploader: uploader }, () => {
                console.log(
                    `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
                );
            });
            $("#loader2").addClass("hidden");
            this.setState({ poster: false });

            uploader
                .onProgress(
                    ({
                        percentage: newPercentage,
                        sent: sent,
                        total: total,
                        upstatus: upstatus,
                    }) => {
                        // to avoid the same percentage to be logged twice
                        if (newPercentage !== percentage) {

                            percentage = newPercentage;
                            console.log(`${percentage}%`);
                            $("#poster2bar").show();
                            $(".poster2barbtn").hide();

                        }
                        if (upstatus === "complete") {
                            console.log("File uploaded successfully");
                            $("#loader2").addClass("hidden");
                            this.setState({ poster: true });
                            var num = Math.random();

                            this.setState({
                                R4poster2path: AWS_S3_URL +
                                    "capgemini/" +
                                    lobbyeventname +
                                    "/Rs4k" +
                                    "/poster2k.png?v=" +
                                    num,
                            });
                            PPTPathService.setFiletype(
                                    lobbyeventname,
                                    "Rs4",
                                    "poster2",
                                    fileType,
                                    filepath
                                )
                                .then((result) => {
                                    console.log(result);
                                    console.log(
                                        "Uploaded successfully........................................"
                                    );
                                    Swal.fire({
                                        // position: 'top-end',
                                        icon: "success",
                                        title: "File Uploaded Successfully!.",
                                        showConfirmButton: false,
                                        timer: 2000,
                                    });
                                })
                                .catch((err) => {
                                    UnauthorizedService.unauthorized(err);
                                });
                            //window.location.reload(false);
                        } else if (upstatus === "failed") {
                            $("#loader2").addClass("hidden");
                            Swal.fire({
                                // position: 'top-end',
                                icon: "failure",
                                title: "File Upload Failed!.",
                                showConfirmButton: false,
                                timer: 2000,
                            });
                        }
                    }
                )
                .onError((error) => {
                    //setFile(undefined);
                    console.error(error);
                });

            uploader.start();
        };
    };

    onFileChange035g2 = async(event) => {
        console.log(`Text to be uploaded is ${event.target.value}`);
        var filepath = event.target.value;
        PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster35g2", "txt", filepath)
            .then((result) => {
                console.log(result);
                console.log(
                    "Text Uploaded successfully........................................"
                );
                // Swal.fire({
                //   // position: 'top-end',
                //   icon: "success",
                //   title: "Text Uploaded Successfully!.",
                //   showConfirmButton: false,
                //   timer: 2000,
                // });
            })
            .catch((err) => {
                UnauthorizedService.unauthorized(err);
            });
    };

    onFileChange65g21 = async(event) => {
      console.log(`Text to be uploaded is ${event.target.value}`);
      var filepath = event.target.value;
      PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster65g21", "txt", filepath)
          .then((result) => {
              console.log(result);
              console.log(
                  "Text Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "Text Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
          })
          .catch((err) => {
              UnauthorizedService.unauthorized(err);
          });
  };
  onFileChange65g22 = async(event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster65g22", "txt", filepath)
        .then((result) => {
            console.log(result);
            console.log(
                "Text Uploaded successfully........................................"
            );
            // Swal.fire({
            //   // position: 'top-end',
            //   icon: "success",
            //   title: "Text Uploaded Successfully!.",
            //   showConfirmButton: false,
            //   timer: 2000,
            // });
        })
        .catch((err) => {
            UnauthorizedService.unauthorized(err);
        });
};

onFileChange65g23 = async(event) => {
  console.log(`Text to be uploaded is ${event.target.value}`);
  var filepath = event.target.value;
  PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster65g23", "txt", filepath)
      .then((result) => {
          console.log(result);
          console.log(
              "Text Uploaded successfully........................................"
          );
          // Swal.fire({
          //   // position: 'top-end',
          //   icon: "success",
          //   title: "Text Uploaded Successfully!.",
          //   showConfirmButton: false,
          //   timer: 2000,
          // });
      })
      .catch((err) => {
          UnauthorizedService.unauthorized(err);
      });
};

onFileChange65g24 = async(event) => {
  console.log(`Text to be uploaded is ${event.target.value}`);
  var filepath = event.target.value;
  PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster65g24", "txt", filepath)
      .then((result) => {
          console.log(result);
          console.log(
              "Text Uploaded successfully........................................"
          );
          // Swal.fire({
          //   // position: 'top-end',
          //   icon: "success",
          //   title: "Text Uploaded Successfully!.",
          //   showConfirmButton: false,
          //   timer: 2000,
          // });
      })
      .catch((err) => {
          UnauthorizedService.unauthorized(err);
      });
};

onFileChange65g25 = async(event) => {
  console.log(`Text to be uploaded is ${event.target.value}`);
  var filepath = event.target.value;
  PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster65g25", "txt", filepath)
      .then((result) => {
          console.log(result);
          console.log(
              "Text Uploaded successfully........................................"
          );
          // Swal.fire({
          //   // position: 'top-end',
          //   icon: "success",
          //   title: "Text Uploaded Successfully!.",
          //   showConfirmButton: false,
          //   timer: 2000,
          // });
      })
      .catch((err) => {
          UnauthorizedService.unauthorized(err);
      });
};

    // On file select (from the pop up)
    onFileChange04 = async(event) => {
        // Update the state
        this.setState({ selectedFile: event.target.files[0] });
        console.log("Inside this.onFileChange()");
        console.log(event.target.files[0]);
        var _URL = window.URL || window.webkitURL;
        var file, img;
        file = event.target.files[0];
        // img = new Image();
        var objectUrl = _URL.createObjectURL(event.target.files[0]);
        // img.src = objectUrl;
        //var fileType = event.target.files[0].type;
        var fileType = event.target.files[0].name.split(".").pop();
        var filepath = null;
        // const eventname = EventService.getCurrentEvent();
        // let uploadfile = () => {
        var fileName =
            "capgemini/" + lobbyeventname + "/Rs45g2" + "/poster4" + "." + fileType;

        console.log(`filepath is ${fileName}`);
        console.log(fileName);
        console.log(fileType);
        console.log(event.target.files);
        console.log(fileType);
        var ctype = fileType;
        if (fileType == "pdf") {
            ctype = "application/pdf";
            console.log("filetype=pdf");
        }
        let data = JSON.stringify({
            fileName: fileName,
            contentType: fileType,
            //contentType: ctype,
            bucketname: aws_s3_bucket_name,
        });

        const videoUploaderOptions = {
            fileName: fileName,
            file: file,
        };

        let percentage = undefined;
        let upstatus = undefined;

        const uploader = new Uploader(videoUploaderOptions);
        this.setState({ localuploader: uploader });

        this.setState({ poster: false });
        uploader
            .onProgress(
                ({
                    percentage: newPercentage,
                    sent: sent,
                    total: total,
                    upstatus: upstatus,
                }) => {
                    // to avoid the same percentage to be logged twice
                    if (newPercentage !== percentage) {
                        percentage = newPercentage;
                        this.setState({ progressbar: newPercentage });
                        console.log(`${percentage}%`);
                        $("#poster1bar").show();
                        $(".modal-container").hide();
                    }
                    if (upstatus === "complete") {
                        console.log("File uploaded successfully");

                        this.setState({ poster: true });
                        var num = Math.random();

                        PPTPathService.setFiletype(
                                lobbyeventname,
                                "Rs45g2",
                                "poster4",
                                fileType,
                                filepath
                            )
                            .then((result) => {
                                console.log(result);
                                console.log(
                                    "Uploaded successfully........................................"
                                );
                                // Swal.fire({
                                //   // position: 'top-end',
                                //   icon: "success",
                                //   title: "File Uploaded Successfully!.",
                                //   showConfirmButton: false,
                                //   timer: 2000,
                                // });
                                //$(".poster1barbtn").hide();
                                $("#poster1bar").hide();
                                // $(".modal-container").hide();
                                // $(".progress").hide();
                                $("#complete").show();

                                // window.location.reload();
                            })
                            .catch((err) => {
                                UnauthorizedService.unauthorized(err);
                            });
                    } else if (upstatus === "failed") {

                        Swal.fire({
                            // position: 'top-end',
                            icon: "danger",
                            title: "File Upload Failed!.",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                    }
                }
            )
            .onError((error) => {
                //setFile(undefined);
                console.error(error);
            });

        uploader.start();
    };
    onFileChange5g2= async(event) => {
      // Update the state
      this.setState({ selectedFile: event.target.files[0] });
      console.log("Inside this.onFileChange()");
      console.log(event.target.files[0]);
      var _URL = window.URL || window.webkitURL;
      var file, img;
      file = event.target.files[0];
      // img = new Image();
      var objectUrl = _URL.createObjectURL(event.target.files[0]);
      // img.src = objectUrl;
      //var fileType = event.target.files[0].type;
      var fileType = event.target.files[0].name.split(".").pop();
      var filepath = null;
      // const eventname = EventService.getCurrentEvent();
      // let uploadfile = () => {
      var fileName =
          "capgemini/" + lobbyeventname + "/Rs4" + "/poster5g2pdf" + "." + fileType;

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(fileType);
      console.log(event.target.files);
      console.log(fileType);
      var ctype = fileType;
      if (fileType == "pdf") {
          ctype = "application/pdf";
          console.log("filetype=pdf");
      }
      let data = JSON.stringify({
          fileName: fileName,
          contentType: fileType,
          //contentType: ctype,
          bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
          fileName: fileName,
          file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);
      this.setState({ localuploader: uploader });

      this.setState({ poster: false });
      uploader
          .onProgress(
              ({
                  percentage: newPercentage,
                  sent: sent,
                  total: total,
                  upstatus: upstatus,
              }) => {
                  // to avoid the same percentage to be logged twice
                  if (newPercentage !== percentage) {
                      percentage = newPercentage;
                      this.setState({ progressbar: newPercentage });
                      console.log(`${percentage}%`);
                      $("#poster1bar").show();
                      $(".modal-container").hide();
                  }
                  if (upstatus === "complete") {
                      console.log("File uploaded successfully");

                      this.setState({ poster: true });
                      var num = Math.random();

                      PPTPathService.setFiletype(
                              lobbyeventname,
                              "Rs4",
                              "poster5g2pdf",
                              fileType,
                              filepath
                          )
                          .then((result) => {
                              console.log(result);
                              console.log(
                                  "Uploaded successfully........................................"
                              );
                              // Swal.fire({
                              //   // position: 'top-end',
                              //   icon: "success",
                              //   title: "File Uploaded Successfully!.",
                              //   showConfirmButton: false,
                              //   timer: 2000,
                              // });
                              //$(".poster1barbtn").hide();
                              $("#poster1bar").hide();
                              // $(".modal-container").hide();
                              // $(".progress").hide();
                              $("#complete").show();

                              // window.location.reload();
                          })
                          .catch((err) => {
                              UnauthorizedService.unauthorized(err);
                          });
                  } else if (upstatus === "failed") {

                      Swal.fire({
                          // position: 'top-end',
                          icon: "danger",
                          title: "File Upload Failed!.",
                          showConfirmButton: false,
                          timer: 2000,
                      });
                  }
              }
          )
          .onError((error) => {
              //setFile(undefined);
              console.error(error);
          });

      uploader.start();
  };
  onFileChange5g2a= async(event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0]);
    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    // img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    // img.src = objectUrl;
    //var fileType = event.target.files[0].type;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    // const eventname = EventService.getCurrentEvent();
    // let uploadfile = () => {
    var fileName =
        "capgemini/" + lobbyeventname + "/Rs4" + "/poster5g2apdf" + "." + fileType;

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(fileType);
    console.log(event.target.files);
    console.log(fileType);
    var ctype = fileType;
    if (fileType == "pdf") {
        ctype = "application/pdf";
        console.log("filetype=pdf");
    }
    let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        //contentType: ctype,
        bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
        fileName: fileName,
        file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);
    this.setState({ localuploader: uploader });

    this.setState({ poster: false });
    uploader
        .onProgress(
            ({
                percentage: newPercentage,
                sent: sent,
                total: total,
                upstatus: upstatus,
            }) => {
                // to avoid the same percentage to be logged twice
                if (newPercentage !== percentage) {
                    percentage = newPercentage;
                    this.setState({ progressbar: newPercentage });
                    console.log(`${percentage}%`);
                    $("#poster1bar").show();
                    $(".modal-container").hide();
                }
                if (upstatus === "complete") {
                    console.log("File uploaded successfully");

                    this.setState({ poster: true });
                    var num = Math.random();

                    PPTPathService.setFiletype(
                            lobbyeventname,
                            "Rs4",
                            "poster5g2apdf",
                            fileType,
                            filepath
                        )
                        .then((result) => {
                            console.log(result);
                            console.log(
                                "Uploaded successfully........................................"
                            );
                            // Swal.fire({
                            //   // position: 'top-end',
                            //   icon: "success",
                            //   title: "File Uploaded Successfully!.",
                            //   showConfirmButton: false,
                            //   timer: 2000,
                            // });
                            //$(".poster1barbtn").hide();
                            $("#poster1bar").hide();
                            // $(".modal-container").hide();
                            // $(".progress").hide();
                            $("#complete").show();

                            // window.location.reload();
                        })
                        .catch((err) => {
                            UnauthorizedService.unauthorized(err);
                        });
                } else if (upstatus === "failed") {

                    Swal.fire({
                        // position: 'top-end',
                        icon: "danger",
                        title: "File Upload Failed!.",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                }
            }
        )
        .onError((error) => {
            //setFile(undefined);
            console.error(error);
        });

    uploader.start();
};
onFileChange5g2b= async(event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
      "capgemini/" + lobbyeventname + "/Rs4" + "/poster5g2bpdf" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
      ctype = "application/pdf";
      console.log("filetype=pdf");
  }
  let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      //contentType: ctype,
      bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
      fileName: fileName,
      file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
      .onProgress(
          ({
              percentage: newPercentage,
              sent: sent,
              total: total,
              upstatus: upstatus,
          }) => {
              // to avoid the same percentage to be logged twice
              if (newPercentage !== percentage) {
                  percentage = newPercentage;
                  this.setState({ progressbar: newPercentage });
                  console.log(`${percentage}%`);
                  $("#poster1bar").show();
                  $(".modal-container").hide();
              }
              if (upstatus === "complete") {
                  console.log("File uploaded successfully");

                  this.setState({ poster: true });
                  var num = Math.random();

                  PPTPathService.setFiletype(
                          lobbyeventname,
                          "Rs4",
                          "poster5g2bpdf",
                          fileType,
                          filepath
                      )
                      .then((result) => {
                          console.log(result);
                          console.log(
                              "Uploaded successfully........................................"
                          );
                          // Swal.fire({
                          //   // position: 'top-end',
                          //   icon: "success",
                          //   title: "File Uploaded Successfully!.",
                          //   showConfirmButton: false,
                          //   timer: 2000,
                          // });
                          //$(".poster1barbtn").hide();
                          $("#poster1bar").hide();
                          // $(".modal-container").hide();
                          // $(".progress").hide();
                          $("#complete").show();

                          // window.location.reload();
                      })
                      .catch((err) => {
                          UnauthorizedService.unauthorized(err);
                      });
              } else if (upstatus === "failed") {

                  Swal.fire({
                      // position: 'top-end',
                      icon: "danger",
                      title: "File Upload Failed!.",
                      showConfirmButton: false,
                      timer: 2000,
                  });
              }
          }
      )
      .onError((error) => {
          //setFile(undefined);
          console.error(error);
      });

  uploader.start();
};
onFileChange5g2c= async(event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
      "capgemini/" + lobbyeventname + "/Rs4" + "/poster5g2cpdf" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
      ctype = "application/pdf";
      console.log("filetype=pdf");
  }
  let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      //contentType: ctype,
      bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
      fileName: fileName,
      file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
      .onProgress(
          ({
              percentage: newPercentage,
              sent: sent,
              total: total,
              upstatus: upstatus,
          }) => {
              // to avoid the same percentage to be logged twice
              if (newPercentage !== percentage) {
                  percentage = newPercentage;
                  this.setState({ progressbar: newPercentage });
                  console.log(`${percentage}%`);
                  $("#poster1bar").show();
                  $(".modal-container").hide();
              }
              if (upstatus === "complete") {
                  console.log("File uploaded successfully");

                  this.setState({ poster: true });
                  var num = Math.random();

                  PPTPathService.setFiletype(
                          lobbyeventname,
                          "Rs4",
                          "poster5g2cpdf",
                          fileType,
                          filepath
                      )
                      .then((result) => {
                          console.log(result);
                          console.log(
                              "Uploaded successfully........................................"
                          );
                          // Swal.fire({
                          //   // position: 'top-end',
                          //   icon: "success",
                          //   title: "File Uploaded Successfully!.",
                          //   showConfirmButton: false,
                          //   timer: 2000,
                          // });
                          //$(".poster1barbtn").hide();
                          $("#poster1bar").hide();
                          // $(".modal-container").hide();
                          // $(".progress").hide();
                          $("#complete").show();

                          // window.location.reload();
                      })
                      .catch((err) => {
                          UnauthorizedService.unauthorized(err);
                      });
              } else if (upstatus === "failed") {

                  Swal.fire({
                      // position: 'top-end',
                      icon: "danger",
                      title: "File Upload Failed!.",
                      showConfirmButton: false,
                      timer: 2000,
                  });
              }
          }
      )
      .onError((error) => {
          //setFile(undefined);
          console.error(error);
      });

  uploader.start();
};

onFileChange5g2d= async(event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
      "capgemini/" + lobbyeventname + "/Rs4" + "/poster5g2dpdf" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
      ctype = "application/pdf";
      console.log("filetype=pdf");
  }
  let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      //contentType: ctype,
      bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
      fileName: fileName,
      file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
      .onProgress(
          ({
              percentage: newPercentage,
              sent: sent,
              total: total,
              upstatus: upstatus,
          }) => {
              // to avoid the same percentage to be logged twice
              if (newPercentage !== percentage) {
                  percentage = newPercentage;
                  this.setState({ progressbar: newPercentage });
                  console.log(`${percentage}%`);
                  $("#poster1bar").show();
                  $(".modal-container").hide();
              }
              if (upstatus === "complete") {
                  console.log("File uploaded successfully");

                  this.setState({ poster: true });
                  var num = Math.random();

                  PPTPathService.setFiletype(
                          lobbyeventname,
                          "Rs4",
                          "poster5g2dpdf",
                          fileType,
                          filepath
                      )
                      .then((result) => {
                          console.log(result);
                          console.log(
                              "Uploaded successfully........................................"
                          );
                          // Swal.fire({
                          //   // position: 'top-end',
                          //   icon: "success",
                          //   title: "File Uploaded Successfully!.",
                          //   showConfirmButton: false,
                          //   timer: 2000,
                          // });
                          //$(".poster1barbtn").hide();
                          $("#poster1bar").hide();
                          // $(".modal-container").hide();
                          // $(".progress").hide();
                          $("#complete").show();

                          // window.location.reload();
                      })
                      .catch((err) => {
                          UnauthorizedService.unauthorized(err);
                      });
              } else if (upstatus === "failed") {

                  Swal.fire({
                      // position: 'top-end',
                      icon: "danger",
                      title: "File Upload Failed!.",
                      showConfirmButton: false,
                      timer: 2000,
                  });
              }
          }
      )
      .onError((error) => {
          //setFile(undefined);
          console.error(error);
      });

  uploader.start();
};
    onFileChange5g21 = async(event) => {
      // Update the state
      this.setState({ selectedFile: event.target.files[0] });
      console.log("Inside this.onFileChange()");
      console.log(event.target.files[0].name);

      var _URL = window.URL || window.webkitURL;
      var file, img;
      file = event.target.files[0];
      img = new Image();
      var objectUrl = _URL.createObjectURL(event.target.files[0]);
      img.src = objectUrl;
      var fileType = event.target.files[0].name.split(".").pop();
      var filepath = null;
      img.onload = function() {
          if (this.width != 492 || this.height != 326) {
              alert(
                  // "Uploaded File Dimension: Width= " +
                  //   this.width +
                  //   "  Height= " +
                  //   this.height +
                  "Required FIle Dimension: Width = " + 492 + " Height= " + 326
              );
          } else {
              console.log("Inside file dimension are proper loop");
              uploadfile();
              //alert("File Dimensions are Proper");
          }
          _URL.revokeObjectURL(objectUrl);
      };
      // const eventname = EventService.getCurrentEvent();
      let uploadfile = () => {
          var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster5g21.png";

          console.log(`filepath is ${fileName}`);
          console.log(fileName);
          console.log(file);

          let data = JSON.stringify({
              fileName: fileName,
              contentType: fileType,
              bucketname: aws_s3_bucket_name,
          });

          const videoUploaderOptions = {
              fileName: fileName,
              file: file,
          };

          let percentage = undefined;
          let upstatus = undefined;

          const uploader = new Uploader(videoUploaderOptions);

          this.setState({ localuploader: uploader }, () => {
              console.log(
                  `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
              );
          });
          $("#loader5g21").addClass("hidden");
          this.setState({ poster: false });

          uploader
              .onProgress(
                  ({
                      percentage: newPercentage,
                      sent: sent,
                      total: total,
                      upstatus: upstatus,
                  }) => {
                      // to avoid the same percentage to be logged twice
                      if (newPercentage !== percentage) {

                          percentage = newPercentage;
                          console.log(`${percentage}%`);
                          $("#poster5g21bar").show();
                          $(".poster5g21barbtn").hide();

                      }
                      if (upstatus === "complete") {
                          console.log("File uploaded successfully");
                          $("#loader5g21").addClass("hidden");
                          this.setState({ poster: true });
                          var num = Math.random();

                          this.setState({
                              R4poster1path: AWS_S3_URL +
                                  "capgemini/" +
                                  lobbyeventname +
                                  "/Rs4" +
                                  "/poster5g21.png?v=" +
                                  num,
                          });
                          PPTPathService.setFiletype(
                                  lobbyeventname,
                                  "Rs4",
                                  "poster5g21",
                                  fileType,
                                  filepath
                              )
                              .then((result) => {
                                  console.log(result);
                                  console.log(
                                      "Uploaded successfully........................................"
                                  );
                                  Swal.fire({
                                      // position: 'top-end',
                                      icon: "success",
                                      title: "File Uploaded Successfully!.",
                                      showConfirmButton: false,
                                      timer: 2000,
                                  });
                              })
                              .catch((err) => {
                                  UnauthorizedService.unauthorized(err);
                              });
                          //window.location.reload(false);
                      } else if (upstatus === "failed") {
                          $("#loader5g21").addClass("hidden");
                          Swal.fire({
                              // position: 'top-end',
                              icon: "failure",
                              title: "File Upload Failed!.",
                              showConfirmButton: false,
                              timer: 2000,
                          });
                      }
                  }
              )
              .onError((error) => {
                  //setFile(undefined);
                  console.error(error);
              });

          uploader.start();
      };
  };
  onFileChange5g22 = async(event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function() {
        if (this.width != 492 || this.height != 326) {
            alert(
                // "Uploaded File Dimension: Width= " +
                //   this.width +
                //   "  Height= " +
                //   this.height +
                "Required FIle Dimension: Width = " + 492 + " Height= " + 326
            );
        } else {
            console.log("Inside file dimension are proper loop");
            uploadfile();
            //alert("File Dimensions are Proper");
        }
        _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
        var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster5g22.png";

        console.log(`filepath is ${fileName}`);
        console.log(fileName);
        console.log(file);

        let data = JSON.stringify({
            fileName: fileName,
            contentType: fileType,
            bucketname: aws_s3_bucket_name,
        });

        const videoUploaderOptions = {
            fileName: fileName,
            file: file,
        };

        let percentage = undefined;
        let upstatus = undefined;

        const uploader = new Uploader(videoUploaderOptions);

        this.setState({ localuploader: uploader }, () => {
            console.log(
                `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
            );
        });
        $("#loader5g22").addClass("hidden");
        this.setState({ poster: false });

        uploader
            .onProgress(
                ({
                    percentage: newPercentage,
                    sent: sent,
                    total: total,
                    upstatus: upstatus,
                }) => {
                    // to avoid the same percentage to be logged twice
                    if (newPercentage !== percentage) {

                        percentage = newPercentage;
                        console.log(`${percentage}%`);
                        $("#poster5g22bar").show();
                        $(".poster5g22barbtn").hide();

                    }
                    if (upstatus === "complete") {
                        console.log("File uploaded successfully");
                        $("#loader5g22").addClass("hidden");
                        this.setState({ poster: true });
                        var num = Math.random();

                        this.setState({
                            R4poster1path: AWS_S3_URL +
                                "capgemini/" +
                                lobbyeventname +
                                "/Rs4" +
                                "/poster5g22.png?v=" +
                                num,
                        });
                        PPTPathService.setFiletype(
                                lobbyeventname,
                                "Rs4",
                                "poster5g22",
                                fileType,
                                filepath
                            )
                            .then((result) => {
                                console.log(result);
                                console.log(
                                    "Uploaded successfully........................................"
                                );
                                Swal.fire({
                                    // position: 'top-end',
                                    icon: "success",
                                    title: "File Uploaded Successfully!.",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            })
                            .catch((err) => {
                                UnauthorizedService.unauthorized(err);
                            });
                        //window.location.reload(false);
                    } else if (upstatus === "failed") {
                        $("#loader5g22").addClass("hidden");
                        Swal.fire({
                            // position: 'top-end',
                            icon: "failure",
                            title: "File Upload Failed!.",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                    }
                }
            )
            .onError((error) => {
                //setFile(undefined);
                console.error(error);
            });

        uploader.start();
    };
};
onFileChange5g23 = async(event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function() {
      if (this.width != 492 || this.height != 326) {
          alert(
              // "Uploaded File Dimension: Width= " +
              //   this.width +
              //   "  Height= " +
              //   this.height +
              "Required FIle Dimension: Width = " + 492 + " Height= " + 326
          );
      } else {
          console.log("Inside file dimension are proper loop");
          uploadfile();
          //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster5g23.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
          fileName: fileName,
          contentType: fileType,
          bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
          fileName: fileName,
          file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
          console.log(
              `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
          );
      });
      $("#loader5g23").addClass("hidden");
      this.setState({ poster: false });

      uploader
          .onProgress(
              ({
                  percentage: newPercentage,
                  sent: sent,
                  total: total,
                  upstatus: upstatus,
              }) => {
                  // to avoid the same percentage to be logged twice
                  if (newPercentage !== percentage) {

                      percentage = newPercentage;
                      console.log(`${percentage}%`);
                      $("#poster5g23bar").show();
                      $(".poster5g23barbtn").hide();

                  }
                  if (upstatus === "complete") {
                      console.log("File uploaded successfully");
                      $("#loader5g23").addClass("hidden");
                      this.setState({ poster: true });
                      var num = Math.random();

                      this.setState({
                          R4poster1path: AWS_S3_URL +
                              "capgemini/" +
                              lobbyeventname +
                              "/Rs4" +
                              "/poster5g23.png?v=" +
                              num,
                      });
                      PPTPathService.setFiletype(
                              lobbyeventname,
                              "Rs4",
                              "poster5g23",
                              fileType,
                              filepath
                          )
                          .then((result) => {
                              console.log(result);
                              console.log(
                                  "Uploaded successfully........................................"
                              );
                              Swal.fire({
                                  // position: 'top-end',
                                  icon: "success",
                                  title: "File Uploaded Successfully!.",
                                  showConfirmButton: false,
                                  timer: 2000,
                              });
                          })
                          .catch((err) => {
                              UnauthorizedService.unauthorized(err);
                          });
                      //window.location.reload(false);
                  } else if (upstatus === "failed") {
                      $("#loader5g23").addClass("hidden");
                      Swal.fire({
                          // position: 'top-end',
                          icon: "failure",
                          title: "File Upload Failed!.",
                          showConfirmButton: false,
                          timer: 2000,
                      });
                  }
              }
          )
          .onError((error) => {
              //setFile(undefined);
              console.error(error);
          });

      uploader.start();
  };
};

onFileChange5g24 = async(event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function() {
      if (this.width != 492 || this.height != 326) {
          alert(
              // "Uploaded File Dimension: Width= " +
              //   this.width +
              //   "  Height= " +
              //   this.height +
              "Required FIle Dimension: Width = " + 492 + " Height= " + 326
          );
      } else {
          console.log("Inside file dimension are proper loop");
          uploadfile();
          //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster5g24.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
          fileName: fileName,
          contentType: fileType,
          bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
          fileName: fileName,
          file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
          console.log(
              `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
          );
      });
      $("#loader5g24").addClass("hidden");
      this.setState({ poster: false });

      uploader
          .onProgress(
              ({
                  percentage: newPercentage,
                  sent: sent,
                  total: total,
                  upstatus: upstatus,
              }) => {
                  // to avoid the same percentage to be logged twice
                  if (newPercentage !== percentage) {

                      percentage = newPercentage;
                      console.log(`${percentage}%`);
                      $("#poster5g22bar").show();
                      $(".poster5g22barbtn").hide();

                  }
                  if (upstatus === "complete") {
                      console.log("File uploaded successfully");
                      $("#loader5g24").addClass("hidden");
                      this.setState({ poster: true });
                      var num = Math.random();

                      this.setState({
                          R4poster1path: AWS_S3_URL +
                              "capgemini/" +
                              lobbyeventname +
                              "/Rs4" +
                              "/poster5g24.png?v=" +
                              num,
                      });
                      PPTPathService.setFiletype(
                              lobbyeventname,
                              "Rs3",
                              "poster5g24",
                              fileType,
                              filepath
                          )
                          .then((result) => {
                              console.log(result);
                              console.log(
                                  "Uploaded successfully........................................"
                              );
                              Swal.fire({
                                  // position: 'top-end',
                                  icon: "success",
                                  title: "File Uploaded Successfully!.",
                                  showConfirmButton: false,
                                  timer: 2000,
                              });
                          })
                          .catch((err) => {
                              UnauthorizedService.unauthorized(err);
                          });
                      //window.location.reload(false);
                  } else if (upstatus === "failed") {
                      $("#loader5g24").addClass("hidden");
                      Swal.fire({
                          // position: 'top-end',
                          icon: "failure",
                          title: "File Upload Failed!.",
                          showConfirmButton: false,
                          timer: 2000,
                      });
                  }
              }
          )
          .onError((error) => {
              //setFile(undefined);
              console.error(error);
          });

      uploader.start();
  };
};

onFileChange5g25 = async(event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function() {
      if (this.width != 492 || this.height != 326) {
          alert(
              // "Uploaded File Dimension: Width= " +
              //   this.width +
              //   "  Height= " +
              //   this.height +
              "Required FIle Dimension: Width = " + 492 + " Height= " + 326
          );
      } else {
          console.log("Inside file dimension are proper loop");
          uploadfile();
          //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster5g25.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
          fileName: fileName,
          contentType: fileType,
          bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
          fileName: fileName,
          file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
          console.log(
              `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
          );
      });
      $("#loader5g25").addClass("hidden");
      this.setState({ poster: false });

      uploader
          .onProgress(
              ({
                  percentage: newPercentage,
                  sent: sent,
                  total: total,
                  upstatus: upstatus,
              }) => {
                  // to avoid the same percentage to be logged twice
                  if (newPercentage !== percentage) {

                      percentage = newPercentage;
                      console.log(`${percentage}%`);
                      $("#poster5g25bar").show();
                      $(".poster5g25barbtn").hide();

                  }
                  if (upstatus === "complete") {
                      console.log("File uploaded successfully");
                      $("#loader5g25").addClass("hidden");
                      this.setState({ poster: true });
                      var num = Math.random();

                      this.setState({
                          R4poster1path: AWS_S3_URL +
                              "capgemini/" +
                              lobbyeventname +
                              "/Rs4" +
                              "/poster5g25.png?v=" +
                              num,
                      });
                      PPTPathService.setFiletype(
                              lobbyeventname,
                              "Rs3",
                              "poster5g25",
                              fileType,
                              filepath
                          )
                          .then((result) => {
                              console.log(result);
                              console.log(
                                  "Uploaded successfully........................................"
                              );
                              Swal.fire({
                                  // position: 'top-end',
                                  icon: "success",
                                  title: "File Uploaded Successfully!.",
                                  showConfirmButton: false,
                                  timer: 2000,
                              });
                          })
                          .catch((err) => {
                              UnauthorizedService.unauthorized(err);
                          });
                      //window.location.reload(false);
                  } else if (upstatus === "failed") {
                      $("#loader5g25").addClass("hidden");
                      Swal.fire({
                          // position: 'top-end',
                          icon: "failure",
                          title: "File Upload Failed!.",
                          showConfirmButton: false,
                          timer: 2000,
                      });
                  }
              }
          )
          .onError((error) => {
              //setFile(undefined);
              console.error(error);
          });

      uploader.start();
  };
};


    componentDidMount() {
        lobbyeventname = EventService.getCurrentEvent();
        console.log(lobbyeventname);
        var num = Math.random();
        const eventname = EventService.getCurrentEvent();
        const filepath = "capgemini/" + lobbyeventname + "/Rs4" + "/poster35g2";
        console.log(`filepath= ${filepath}`);
        PPTPathService.getFiletype(eventname, "Rs4")
            .then((response) => {
                response.data.forEach((filedetails) => {
                    if (filedetails.filename === "poster35g2") {
                        console.log("filepath got from db is sssssssssssssssssssssssss");
                        console.log(filedetails);
                        console.log(filedetails.path);
                        this.setState({
                            R4poster3gg2path: AWS_S3_URL +
                                "capgemini/" +
                                eventname +
                                "/Rs4" +
                                "/poster35g2" +
                                "." +
                                filedetails.filetype +
                                "?v=" +
                                num,
                            poster35g2: true,
                        });
                        if (
                            filedetails.filetype === "txt"

                        ) {
                            this.setState({
                                R4poster35g2path: filedetails.path,
                                poster35g2: true,

                            });
                            this.setState({ R4poster35g2type: "txt" });
                        }
                    }



                })
            })

        .catch((err) => {
            UnAuthorizedService.unauthorized(err);
        });

        lobbyeventname = EventService.getCurrentEvent();
        console.log(lobbyeventname);
        var num = Math.random();
        const eventname65g21 = EventService.getCurrentEvent();
        const filepath65g21 = "capgemini/" + lobbyeventname + "/Rs4" + "/poster65g21";
        console.log(`filepath= ${filepath65g21}`);
        PPTPathService.getFiletype(eventname65g21, "Rs4")
            .then((response) => {
                response.data.forEach((filedetails) => {
                    if (filedetails.filename === "poster65g21") {
                        console.log("filepath got from db is sssssssssssssssssssssssss");
                        console.log(filedetails);
                        console.log(filedetails.path);
                        this.setState({
                            R4poster65g21path: AWS_S3_URL +
                                "capgemini/" +
                                eventname65g21 +
                                "/Rs4" +
                                "/poster65g21" +
                                "." +
                                filedetails.filetype +
                                "?v=" +
                                num,
                            poster65g21: true,
                        });
                        if (
                            filedetails.filetype === "txt"

                        ) {
                            this.setState({
                                R4poster65g21path: filedetails.path,
                                poster65g21: true,

                            });
                            this.setState({ R4poster65g21type: "txt" });
                        }
                    }



                })
            })

        .catch((err) => {
            UnAuthorizedService.unauthorized(err);
        });

        lobbyeventname = EventService.getCurrentEvent();
        console.log(lobbyeventname);
        var num = Math.random();
        const eventname65g22 = EventService.getCurrentEvent();
        const filepath65g22 = "capgemini/" + lobbyeventname + "/Rs4" + "/poster65g22";
        console.log(`filepath= ${filepath65g22}`);
        PPTPathService.getFiletype(eventname65g22, "Rs4")
            .then((response) => {
                response.data.forEach((filedetails) => {
                    if (filedetails.filename === "poster65g22") {
                        console.log("filepath got from db is sssssssssssssssssssssssss");
                        console.log(filedetails);
                        console.log(filedetails.path);
                        this.setState({
                          R4poster65g22path: AWS_S3_URL +
                                "capgemini/" +
                                eventname65g22 +
                                "/Rs4" +
                                "/poster65g22" +
                                "." +
                                filedetails.filetype +
                                "?v=" +
                                num,
                            poster65g22: true,
                        });
                        if (
                            filedetails.filetype === "txt"

                        ) {
                            this.setState({
                                R4poster65g22path: filedetails.path,
                                poster65g22: true,

                            });
                            this.setState({ R4poster65g22type: "txt" });
                        }
                    }



                })
            })

        .catch((err) => {
            UnAuthorizedService.unauthorized(err);
        });

        lobbyeventname = EventService.getCurrentEvent();
        console.log(lobbyeventname);
        var num = Math.random();
        const eventname65g23 = EventService.getCurrentEvent();
        const filepath65g23 = "capgemini/" + lobbyeventname + "/Rs4" + "/poster65g23";
        console.log(`filepath= ${filepath65g23}`);
        PPTPathService.getFiletype(eventname65g23, "Rs4")
            .then((response) => {
                response.data.forEach((filedetails) => {
                    if (filedetails.filename === "poster65g23") {
                        console.log("filepath got from db is sssssssssssssssssssssssss");
                        console.log(filedetails);
                        console.log(filedetails.path);
                        this.setState({
                          R4poster65g23path: AWS_S3_URL +
                                "capgemini/" +
                                eventname65g23 +
                                "/Rs4" +
                                "/poster65g23" +
                                "." +
                                filedetails.filetype +
                                "?v=" +
                                num,
                            poster65g23: true,
                        });
                        if (
                            filedetails.filetype === "txt"

                        ) {
                            this.setState({
                                R4poster65g23path: filedetails.path,
                                poster65g23: true,

                            });
                            this.setState({ R4poster65g23type: "txt" });
                        }
                    }



                })
            })

        .catch((err) => {
            UnAuthorizedService.unauthorized(err);
        });

        lobbyeventname = EventService.getCurrentEvent();
        console.log(lobbyeventname);
        var num = Math.random();
        const eventname65g24 = EventService.getCurrentEvent();
        const filepath65g24 = "capgemini/" + lobbyeventname + "/Rs4" + "/poster65g24";
        console.log(`filepath= ${filepath65g24}`);
        PPTPathService.getFiletype(eventname65g24, "Rs4")
            .then((response) => {
                response.data.forEach((filedetails) => {
                    if (filedetails.filename === "poster65g24") {
                        console.log("filepath got from db is sssssssssssssssssssssssss");
                        console.log(filedetails);
                        console.log(filedetails.path);
                        this.setState({
                          R4poster65g24path: AWS_S3_URL +
                                "capgemini/" +
                                eventname65g24 +
                                "/Rs4" +
                                "/poster65g24" +
                                "." +
                                filedetails.filetype +
                                "?v=" +
                                num,
                            poster65g24: true,
                        });
                        if (
                            filedetails.filetype === "txt"

                        ) {
                            this.setState({
                                R4poster65g24path: filedetails.path,
                                poster65g24: true,

                            });
                            this.setState({ R4poster65g24type: "txt" });
                        }
                    }



                })
            })

        .catch((err) => {
            UnAuthorizedService.unauthorized(err);
        });

        lobbyeventname = EventService.getCurrentEvent();
        console.log(lobbyeventname);
        var num = Math.random();
        const eventname65g25 = EventService.getCurrentEvent();
        const filepath65g25 = "capgemini/" + lobbyeventname + "/Rs4" + "/poster65g25";
        console.log(`filepath= ${filepath65g25}`);
        PPTPathService.getFiletype(eventname65g25, "Rs4")
            .then((response) => {
                response.data.forEach((filedetails) => {
                    if (filedetails.filename === "poster65g25") {
                        console.log("filepath got from db is sssssssssssssssssssssssss");
                        console.log(filedetails);
                        console.log(filedetails.path);
                        this.setState({
                          R4poster65g25path: AWS_S3_URL +
                                "capgemini/" +
                                eventname65g25 +
                                "/Rs4" +
                                "/poster65g25" +
                                "." +
                                filedetails.filetype +
                                "?v=" +
                                num,
                            poster65g25: true,
                        });
                        if (
                            filedetails.filetype === "txt"

                        ) {
                            this.setState({
                                R4poster65g25path: filedetails.path,
                                poster65g25: true,

                            });
                            this.setState({ R4poster65g25type: "txt" });
                        }
                    }



                })
            })

        .catch((err) => {
            UnAuthorizedService.unauthorized(err);
        });
        EventService.getSpecificEvent()
            .then((response) => {
                console.log("Eventname from getSpecificEvent");
                console.log(response.data);
                console.log(response.data.eventname);
                const eventpath = "/" + response.data.eventname;
                this.setState({ tenantname: eventpath, showLoginPage: true });
                localStorage.setItem("eventname", response.data.eventname);
                console.log(response.data.eventname);

                const user = AuthService.getCurrentUser();

                AuthService.getCurrentRole().then((roles) => {
                    if (roles) {
                        console.log("Got current role from server");
                        this.setState({
                            currentUser: user,
                            showModeratorBoard: roles.data.includes("ROLE_MODERATOR"),
                            showAdminBoard: roles.data.includes("ROLE_ADMIN"),
                            showAddTenant: roles.data.includes("ROLE_SUPERADMIN"),
                            showAddUser: roles.data.includes("ROLE_SUPERADMIN"),
                            showRegModerators: roles.data.includes("ROLE_ADMIN") ||
                                roles.data.includes("ROLE_SUPERADMIN"),
                            showRegAdmins: roles.data.includes("ROLE_SUPERADMIN") ||
                                roles.data.includes("ROLE_ADMIN"),
                            showRegSuperAdmins: roles.data.includes("ROLE_SUPERADMIN"),
                            showRegUsers: roles.data.includes("ROLE_MODERATOR") ||
                                roles.data.includes("ROLE_ADMIN") ||
                                roles.data.includes("ROLE_SUPERADMIN"),
                            showCreateEvents: roles.data.includes("ROLE_SUPERADMIN") ||
                                roles.data.includes("ROLE_ADMIN") ||
                                roles.data.includes("ROLE_MODERATOR"),
                            showEvents: roles.data.includes("ROLE_SUPERADMIN") ||
                                roles.data.includes("ROLE_ADMIN") ||
                                roles.data.includes("ROLE_MODERATOR"),
                            showLoginReport: roles.data.includes("ROLE_SUPERADMIN") ||
                                roles.data.includes("ROLE_ADMIN") ||
                                roles.data.includes("ROLE_MODERATOR"),
                            showDetailLoginReport: roles.data.includes("ROLE_SUPERADMIN") ||
                                roles.data.includes("ROLE_ADMIN") ||
                                roles.data.includes("ROLE_MODERATOR"),
                            showFeedbackReport: roles.data.includes("ROLE_SUPERADMIN") ||
                                roles.data.includes("ROLE_ADMIN") ||
                                roles.data.includes("ROLE_MODERATOR"),
                            showEdit: roles.data.includes("ROLE_SUPERADMIN") ||
                                roles.data.includes("ROLE_ADMIN") ||
                                roles.data.includes("ROLE_MODERATOR"),
                            showNormalUser: roles.data.includes("ROLE_USER"),
                            showTenants: roles.data.includes("ROLE_SUPERADMIN") ||
                                roles.data.includes("ROLE_ADMIN") ||
                                roles.data.includes("ROLE_MODERATOR"),
                            //tenantname: tenant,
                        });
                    }



                    this.setState({
                        R4poster1path: AWS_S3_URL +
                            "capgemini/" +
                            lobbyeventname +
                            "/Rs4" +
                            "/poster1.png?v=" +
                            num,
                        R4poster2path: AWS_S3_URL +
                            "capgemini/" +
                            lobbyeventname +
                            "/Rs4k" +
                            "/poster2k.png?v=" +
                            num,
                        R4poster6path: AWS_S3_URL +
                            "capgemini/" +
                            lobbyeventname +
                            "/Rs4" +
                            "/poster6.png?v=" +
                            num,

                        R4poster4path: AWS_S3_URL +
                            "capgemini/" +
                            lobbyeventname +
                            "/Rs4" +
                            "/poster4.pdf?v=" +
                            num,
                            R4poster5g21path: AWS_S3_URL +
                            "capgemini/" +
                            lobbyeventname +
                            "/Rs4" +
                            "/poster5g21.png?v=" +
                            num,
                            R4poster5g22path: AWS_S3_URL +
                            "capgemini/" +
                            lobbyeventname +
                            "/Rs4" +
                            "/poster5g22.png?v=" +
                            num,
                            R4poster5g23path: AWS_S3_URL +
                            "capgemini/" +
                            lobbyeventname +
                            "/Rs4" +
                            "/poster5g23.png?v=" +
                            num,
                            R4poster5g24path: AWS_S3_URL +
                            "capgemini/" +
                            lobbyeventname +
                            "/Rs4" +
                            "/poster5g24.png?v=" +
                            num,
                            R4poster5g25path: AWS_S3_URL +
                            "capgemini/" +
                            lobbyeventname +
                            "/Rs4" +
                            "/poster5g25.png?v=" +
                            num,
                            R4poster45g2path: AWS_S3_URL +
                            "capgemini/" +
                            lobbyeventname +
                            "/Rs4" +
                            "/poster45g2.png?v=" +
                            num,

                            Rposter65g21path:
                          AWS_S3_URL +
                          "capgemini/" +
                          lobbyeventname +
                          "/Rs4" +
                          "/poster65g21.txt?v=" +
                          num,

                          Rposter65g22path:
                          AWS_S3_URL +
                          "capgemini/" +
                          lobbyeventname +
                          "/Rs4" +
                          "/poster65g22.txt?v=" +
                          num,

                        Rposter65g23path:
                          AWS_S3_URL +
                          "capgemini/" +
                          lobbyeventname +
                          "/Rs4" +
                          "/poster65g23.txt?v=" +
                          num,

                        Rposter65g24path:
                          AWS_S3_URL +
                          "capgemini/" +
                          lobbyeventname +
                          "/Rs4" +
                          "/poster65g24.txt?v=" +
                          num,

                        Rposter65g25path:
                          AWS_S3_URL +
                          "capgemini/" +
                          lobbyeventname +
                          "/Rs4" +
                          "/poster65g25.txt?v=" +
                          num,


                        poster1: true,
                        poster2: true,
                        poster3: true,
                        poster6: true,
                        poster5g21: true,
                        poster5g22: true,
                        poster5g23: true,
                        poster5g24: true,
                        poster5g25: true,
            poster65g2:true,
            poster45g2:true,
            poster35g2: true,



                        poster65g21: true,
                        poster65g22: true,
                        poster65g23: true,
                        poster65g24: true,
                        poster65g25: true,






                    });
                })
            })
    }

    render() {
        function hideImg() {
            document.getElementById("myImg").style.display = "none";
        }

        function hideImg1() {
            document.getElementById("myImg1").style.display = "none";
        }

        function hideImg2() {
            document.getElementById("myImg2").style.display = "none";
        }

        function hideImg5g21() {
          document.getElementById("myImg5g21").style.display = "none";
      }

      function hideImg5g22() {
        document.getElementById("myImg5g22").style.display = "none";
    }
    function hideImg5g23() {
      document.getElementById("myImg5g23").style.display = "none";
  }
  function hideImg5g24() {
    document.getElementById("myImg5g24").style.display = "none";
}
function hideImg5g25() {
  document.getElementById("myImg5g25").style.display = "none";
}
function hideImg45g2() {
  document.getElementById("myImg45g2").style.display = "none";
}





        const {
            poster1,
            poster2,
            poster3,
            poster6,
            poster5g21,
            poster5g22,
            poster5g23,
            poster5g24,
            poster5g25,
            poster65g2,

            poster65g21,
            poster65g22,

            poster65g23,
            poster65g24,
            poster65g25,
            poster645g2,
            poster45g2,
            poster35g2,






            R4poster1path,
            R4poster2path,
            R4poster3path,
            R4poster6path,
            R4poster5g21path,
            R4poster5g22path,
            R4poster5g23path,
            R4poster5g24path,
            R4poster5g25path,
            R4poster65g2path,

            R4poster65g21path,
            R4poster65g22path,
            R4poster65g23path,
            R4poster65g24path,
            R4poster65g25path,
            R4poster45g2path,
            R4poster35g2path,





            tenantname,
            progressbar,
        } = this.state;

        return (
            <div className="container-fluide">
        <div className="page-content">
          <div id="content-agenda">
            <div id="bg">
              <img src="https://d2e8va4puks5pl.cloudfront.net/VR-01/VR-1%206%20GRID.jpg" id="bg-image" className="mb-3" />
              <div className="sidenavogation">
                  {/* <Link to={tenantname + "/ResourceEdit"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Resource Center1</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit1"} className="Rs2  text-white buttonRes">
                              <span className="hide-menu">Resource Center2</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit2"} className="Rs3  text-white buttonRes">
                              <span className="hide-menu">Resource Center3</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit3"} className="Rs4  text-white buttonRes">
                              <span className="hide-menu">Resource Center4</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit4"} className="Rs5  text-white buttonRes">
                              <span className="hide-menu">Resource Center5</span>
                  </Link> */}
                   <Link to={tenantname + "/agendaEdit"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Back To Agenda</span>
                  </Link>
                  </div>
              {/* <div id="Resource4">
                {poster1 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader1"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInput.click()}
                    >
                      <img
                        src={R4poster1path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg()}
                        id="myImg"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange01}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div> */}

              <div id="Resource45g2">
                {poster45g2 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader1"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInput.click()}
                    >
                      <img
                        src={R4poster45g2path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg45g2()}
                        id="myImg45g2"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange45g2}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>
              <div id="Resource45g2-1">
                {poster5g21 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader5g21"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInput5g21.click()}
                    >
                      <img
                        src={R4poster5g21path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg5g21()}
                        id="myImg5g21"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput5g21 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange5g21}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>
              <div id="Resource45g2-2">
                {poster5g22 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader5g22"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInput5g22.click()}
                    >
                      <img
                        src={R4poster5g22path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg5g22()}
                        id="myImg5g22"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput5g22 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange5g22}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>
              <div id="Resource45g2-3">
                {poster5g23 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader5g23"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInput5g23.click()}
                    >
                      <img
                        src={R4poster5g23path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg5g23()}
                        id="myImg5g23"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput5g23 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange5g23}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>
              <div id="Resource45g2-4">
                {poster5g24 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader5g24"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInput5g24.click()}
                    >
                      <img
                        src={R4poster5g24path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg5g24()}
                        id="myImg5g24"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput5g24 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange5g24}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>
              <div id="Resource45g2-5">
                {poster5g25 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader5g25"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInput5g25.click()}
                    >
                      <img
                        src={R4poster5g25path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg5g25()}
                        id="myImg5g25"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput5g25 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange5g25}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>
              <div id="Resource5">
                {poster2 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader2"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap5"
                      onClick={(e) => this.myInput1.click()}
                    >
                      <img
                        src={R4poster2path}
                        // src={image1}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg1()}
                        id="myImg1"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput1 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange02}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>
              <div id="Resource65g2">
                {poster35g2 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">
                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"
                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange035g2}
                        id="ResText"
                        placeholder={R4poster35g2path}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource65g2-1">
                {poster65g21 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">
                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"
                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange65g21}
                        id="ResText"
                        placeholder={R4poster65g21path}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource65g2-2">
                {poster65g22 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">
                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"
                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange65g22}
                        id="ResText"
                        placeholder={R4poster65g22path}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource65g2-3">
                {poster65g23 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">
                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"
                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange65g23}
                        id="ResText"
                        placeholder={R4poster65g23path}
                      />
                    </div>
                  </form>
                )}
              </div>
              <div id="Resource65g2-4">
                {poster65g24 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">
                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"
                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange65g24}
                        id="ResText"
                        placeholder={R4poster65g24path}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource65g2-5">
                {poster65g25 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">
                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"
                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange65g25}
                        id="ResText"
                        placeholder={R4poster65g25path}
                      />
                    </div>
                  </form>
                )}
              </div>
          <div id="ResourceCenter5g2">
          <div className="hotspot1">
            {/* <div className="out"></div> */}
            <div className="in1" onClick={this.P1openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen}
                  style={customStyles}
                  onRequestClose={this.P1closeModal}
                >
                  <span className="close" onClick={this.P1closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>
            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput23.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput24.click()}>Upload Video  </button>
                  <div id="loader4" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput23 = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange04}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput24 = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange04}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        <div id="ResourceCenter5g2-1">
          <div className="hotspot1">
            {/* <div className="out"></div> */}
            <div className="in1" onClick={this.P1openModal5g2}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen5g2}
                  style={customStyles}
                  onRequestClose={this.P1closeModal5g2}
                >
                  <span className="close" onClick={this.P1closeModal5g2}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>
            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5g2.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5g2v.click()}>Upload Video  </button>
                  <div id="loader5g2" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput5g2 = ref)}
            type="file"
            name="file"
            id="vid5g2"
            onChange={this.onFileChange5g2}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput5g2v = ref)}
            type="file"
            name="file"
            id="vid5g2"
            onChange={this.onFileChange5g2}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        <div id="ResourceCenter5g2-2">
          <div className="hotspot1">
            {/* <div className="out"></div> */}
            <div className="in1" onClick={this.P1openModal5g2a}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen5g2a}
                  style={customStyles}
                  onRequestClose={this.P1closeModal5g2a}
                >
                  <span className="close" onClick={this.P1closeModal5g2a}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>
            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5g2a.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5g2av.click()}>Upload Video  </button>
                  <div id="loader5g2a" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput5g2a = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange5g2a}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput5g2av = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange5g2a}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        <div id="ResourceCenter5g2-3">
          <div className="hotspot1">
            {/* <div className="out"></div> */}
            <div className="in1" onClick={this.P1openModal5g2b}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen5g2b}
                  style={customStyles}
                  onRequestClose={this.P1closeModal5g2b}
                >
                  <span className="close" onClick={this.P1closeModal5g2b}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>
            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5g2b.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5g2bv.click()}>Upload Video  </button>
                  <div id="loader5g2b" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput5g2b = ref)}
            type="file"
            name="file"
            id="vid5g2b"
            onChange={this.onFileChange5g2b}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput5g2bv = ref)}
            type="file"
            name="file"
            id="vid5g2b"
            onChange={this.onFileChange5g2b}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>
        <div id="ResourceCenter5g2-4">
          <div className="hotspot1">
            {/* <div className="out"></div> */}
            <div className="in1" onClick={this.P1openModal5g2c}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen5g2c}
                  style={customStyles}
                  onRequestClose={this.P1closeModal5g2c}
                >
                  <span className="close" onClick={this.P1closeModal5g2c}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>
            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5g2c.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5g2c.click()}>Upload Video  </button>
                  <div id="loader5g2c" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput5g2c = ref)}
            type="file"
            name="file"
            id="vid5g2c"
            onChange={this.onFileChange5g2c}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput5g2cv = ref)}
            type="file"
            name="file"
            id="vid5g2c"
            onChange={this.onFileChange5g2c}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>
        <div id="ResourceCenter5g2-5">
          <div className="hotspot1">
            {/* <div className="out"></div> */}
            <div className="in1" onClick={this.P1openModal5g2d}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen5g2d}
                  style={customStyles}
                  onRequestClose={this.P1closeModal5g2d}
                >
                  <span className="close" onClick={this.P1closeModal5g2d}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>
            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5g2d.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput5g2dv.click()}>Upload Video  </button>
                  <div id="loader5g2d" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput5g2d = ref)}
            type="file"
            name="file"
            id="vid5g2d"
            onChange={this.onFileChange5g2d}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput5g2dv = ref)}
            type="file"
            name="file"
            id="vid5g2d"
            onChange={this.onFileChange5g2d}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>
        {/* <div id="Resource8">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap8"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R4poster6path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput6 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange06}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div> */}
            </div>
          </div>
        </div>
      </div>
        );
    }
}
export default ResorceEdit4;