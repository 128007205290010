import React, { PureComponent } from "react";
import Marzipano from "marzipano";
import EventService from "../services/event.service";
import PPTPathService from "../services/pptpath.service";
import UnAuthorizedService from "../common/unauthorized";
import Modal from "react-modal";
import Preloader from "./preloader.component";
import "./styles/styles.css";

import $ from "jquery";
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "70vh",
    marginRight: "-50%",
    width: "calc(60% - 10px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    border: "unset !important",
    margintop: "calc(85vh - 10vh - 10px)",
    zIndex: "2",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(50% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding:"0 0 0 0"
  },
};
const { aws_s3_url } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
const linkToPPTFile =
  "https://scholar.harvard.edu/files/torman_personal/files/samplepptx.pptx";

class Innovation1 extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // Initially, no file is selected
      selectedFile: null,
      poster1: false,
      poster2: false,
      poster3: false,
      poster4: false,
      poster5: false,
      poster6: false,
      poster7: false,
      poster8: false,
      poster9: false,
      poster10: false,


      I2poster1path : "",
      I2poster2path : "",
      I2poster3path : "",
      I2poster4path : "",
      I2poster5path : "",
      I2poster6path : "",
      I2poster7path : "",
      I2poster8path : "",
      I2poster9path : "",
      I2poster10path : "",
      modalIsOpen: false,
      SecmodalIsOpen: false,
      TmodalIsOpen: false,
      FmodalIsOpen: false,
      FimodalIsOpen: false,

      P6modalIsOpen: false,
      P7modalIsOpen: false,
      P8modalIsOpen: false,
      P9modalIsOpen: false,
      P10modalIsOpen: false,
    };
  }
  P1openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  P1closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  P2openModal = () => {
    this.setState({ SecmodalIsOpen: true });
  };
  P2closeModal = () => {
    this.setState({ SecmodalIsOpen: false });
  };
  P3closeModal = () => {
    this.setState({ TmodalIsOpen: false });
  };
  P3openModal = () => {
    this.setState({ TmodalIsOpen: true });
  };
  P4closeModal = () => {
    this.setState({ FmodalIsOpen: false });
  };
  P4openModal = () => {
    this.setState({ FmodalIsOpen: true });
  };
  P5closeModal = () => {
    this.setState({ FimodalIsOpen: false });
  };
  P5openModal = () => {
    this.setState({ FimodalIsOpen: true });
  };
  P6closeModal = () => {
    this.setState({ P6modalIsOpen: false });
  };
  P6openModal = () => {
    this.setState({ P6modalIsOpen: true });
  };
  P7closeModal = () => {
    this.setState({ P7modalIsOpen: false });
  };
  P7openModal = () => {
    this.setState({ P7modalIsOpen: true });
  };
  P8closeModal = () => {
    this.setState({ P8modalIsOpen: false });
  };
  P8openModal = () => {
    this.setState({ P8modalIsOpen: true });
  };
  P9closeModal = () => {
    this.setState({ P9modalIsOpen: false });
  };
  P9openModal = () => {
    this.setState({ P9modalIsOpen: true });
  };
  P10closeModal = () => {
    this.setState({ P10modalIsOpen: false });
  };
  P10openModal = () => {
    this.setState({ P10modalIsOpen: true });
  };

  static displayName = "Pano";
  componentDidMount() {
    var num = Math.random();
    const eventname = EventService.getCurrentEvent();
    console.log(eventname);
    PPTPathService.getFiletype(eventname, "IN2").then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster6") {
          this.setState({
            I2poster6path : AWS_S3_URL + "capgemini/" + eventname + "/IN2" + "/poster6"+ "." +filedetails.filetype + "?v="+ num,

            poster6: true,
            // I2poster6type: filedetails.filetype,
          });
          if (
            filedetails.filetype === "mp4" ||
            filedetails.filetype === "mov" ||
            filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
          ) {
            this.setState({ I2poster6type: "video" });
          }
          if (
            filedetails.filetype === "pptx" ||
            filedetails.filetype === "ppt"
          ) {
            {
              this.setState({
                I2poster6path:filedetails.path,
                poste6: true,

              });
              this.setState({ I2poster6type: "ppt" });
            }
          }
          if (
            filedetails.filetype === "pdf"

          ) {
            {
              this.setState({ I2poster6type: "pdf" });
            }
          }
          console.log(this.state.I2poster6path);
        }
    if (filedetails.filename === "poster7") {
        this.setState({
          I2poster7path : AWS_S3_URL + "capgemini/" + eventname + "/IN2" + "/poster7"+ "." +filedetails.filetype + "?v="+ num,

          poster7: true,
          // I2poster7type: filedetails.filetype,
        });
        if (
          filedetails.filetype === "mp4" ||
          filedetails.filetype === "mov" ||
          filedetails.filetype === "webm" ||
          filedetails.filetype === "ogv"
        ) {
          this.setState({ I2poster7type: "video" });
        }
        if (
          filedetails.filetype === "pptx" ||
          filedetails.filetype === "ppt"
        ) {
          {
            this.setState({
              I2poster7path:filedetails.path,
              poste7: true,

            });
            this.setState({ I2poster7type: "ppt" });
          }
        }
        if (
          filedetails.filetype === "pdf"

        ) {
          {
            this.setState({ I2poster7type: "pdf" });
          }
        }
        console.log(this.state.I2poster7path);
      }
      if (filedetails.filename === "poster8") {
        this.setState({
          I2poster8path : AWS_S3_URL + "capgemini/" + eventname + "/IN2" + "/poster8"+ "." +filedetails.filetype + "?v="+ num,

          poster8: true,
          // I2poster8type: filedetails.filetype,
        });
        if (
          filedetails.filetype === "mp4" ||
          filedetails.filetype === "mov" ||
          filedetails.filetype === "webm" ||
          filedetails.filetype === "ogv"
        ) {
          this.setState({ I2poster8type: "video" });
        }
        if (
          filedetails.filetype === "pptx" ||
          filedetails.filetype === "ppt"
        ) {
          {
            this.setState({
              I2poster8path:filedetails.path,
              poste8: true,

            });
            this.setState({ I2poster8type: "ppt" });
          }
        }
        if (
          filedetails.filetype === "pdf"

        ) {
          {
            this.setState({ I2poster8type: "pdf" });
          }
        }
        console.log(this.state.I2poster8path);
      }
      if (filedetails.filename === "poster9") {
        this.setState({
          I2poster9path : AWS_S3_URL + "capgemini/" + eventname + "/IN2" + "/poster9"+ "." +filedetails.filetype + "?v="+ num,

          poster9: true,
          // I2poster9type: filedetails.filetype,
        });
        if (
          filedetails.filetype === "mp4" ||
          filedetails.filetype === "mov" ||
          filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
        ) {
          this.setState({ I2poster9type: "video" });
        }
        if (
          filedetails.filetype === "pptx" ||
          filedetails.filetype === "ppt"
        ) {
          {
            this.setState({
              I2poster9path:filedetails.path,
              poste9: true,

            });
            this.setState({ I2poster9type: "ppt" });
          }
        }
        if (
          filedetails.filetype === "pdf"

        ) {
          {
            this.setState({ I2poster9type: "pdf" });
          }
        }
        console.log(this.state.I2poster9path);
      }
      if (filedetails.filename === "poster10") {
        this.setState({
          Iposter10path : AWS_S3_URL + "capgemini/" + eventname + "/IN2" + "/poster10"+ "." +filedetails.filetype + "?v="+ num,

          poster10: true,
          // I2poster10type: filedetails.filetype,
        });
        if (
          filedetails.filetype === "mp4" ||
          filedetails.filetype === "mov" ||
          filedetails.filetype === "webm" ||
          filedetails.filetype === "ogv"
        ) {
          this.setState({ I2poster10type: "video" });
        }
        if (
          filedetails.filetype === "pptx" ||
          filedetails.filetype === "ppt"
        ) {
          {
            this.setState({
              I2poster10path:filedetails.path,
              poste10: true,

            });
            this.setState({ I2poster10type: "ppt" });
          }
        }
        if (
          filedetails.filetype === "pdf"

        ) {
          {
            this.setState({ I2poster10type: "pdf" });
          }
        }
        console.log(this.state.I2poster10path);
      }

    });
    })
    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });
    this.setState({

    I2poster1path : AWS_S3_URL + "capgemini/" + eventname + "/IN2" + "/poster1.png?v=" +
    num,
    I2poster2path : AWS_S3_URL + "capgemini/" + eventname + "/IN2" + "/poster2.png?v=" +
    num,
    I2poster3path : AWS_S3_URL + "capgemini/" +eventname + "/IN2" + "/poster3.png?v=" +
    num,
    I2poster4path : AWS_S3_URL + "capgemini/" +eventname + "/IN2" + "/poster4.png?v=" +
    num,
    I2poster5path: AWS_S3_URL + "capgemini/" + eventname + "/IN2" + "/poster5.png?v=" +
    num,
    poster1: true,
    poster2: true,
    poster3: true,
    poster4: true,
    poster5: true,
    // poster6: true,
    // poster7: true,
    // poster8: true,
    // poster9: true,
    // poster10: true,
    // poster11: true,

  })

    this.viewer = new Marzipano.Viewer(this.pano); //, viewerOpts);

    // Create source.
    const source = Marzipano.ImageUrlSource.fromString("https://d2e8va4puks5pl.cloudfront.net/innovationlab.jpg");

    // Create geometry.
    const geometry = new Marzipano.EquirectGeometry([{ width: 5000 }]);

    const limiter = Marzipano.RectilinearView.limit.traditional(
      4*1024,
      (100 * Math.PI) / 180
    );
    const view = new Marzipano.RectilinearView({ yaw: Math.PI }, limiter);

    // Get the zview values

    var yaw = view.yaw();
    var pitch = view.pitch();
    var fov = view.fov(); // fov is horizontal
    /*var vfov = view.vfov();
var hfov = view.hfov();    // same as view.fov()*/

    // Change the values

    view.setYaw((0 * Math.PI) / 180);
    view.setPitch((0 * Math.PI) / 180);
    view.setFov((120 * Math.PI) / 180);

    const scene = this.viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      pinFirstLevel: true,
    });

    scene.switchTo();

    // Get the hotspot container for scene.
    var container = scene.hotspotContainer();

    container.createHotspot(
      this.poster1,
      { yaw: 3.135, pitch: -0.159 },
      {
        perspective: {
          radius: 1200,
          extraTransforms: "rotateX(10deg) rotateY(0deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster2,
      { yaw: 3.997, pitch: -0.06 },
      {
        perspective: {
          radius: 1860,
          extraTransforms: "rotateX(5deg) rotateY(-30deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster3,
      { yaw: 5.455, pitch: -0.0593 },
      {
        perspective: {
          radius: 1990,
          extraTransforms: "rotateX(2deg) rotateY(30deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster4,
      { yaw: 6.28, pitch: -0.158},
      {
        perspective: {
          radius: 910,
          extraTransforms: "rotateX(5deg) rotateY(0deg) rotateZ(0deg)",
        },
      }
    );

    container.createHotspot(
      this.poster5,
      { yaw: 7.848, pitch: -0.16},
      {
        perspective: {
          radius: 980,
          extraTransforms: "rotateX(8deg) rotateY(0deg) rotateZ(0deg)",
        },
      }
    );

    scene.hotspotContainer().createHotspot(document.querySelector("#pptview"), {
      yaw: -0.039,
      pitch: 0.045,
    });
    scene.hotspotContainer().createHotspot(document.querySelector("#Videostream1"), {
      yaw: -0.039,
      pitch: 0.045,
    });
    scene.hotspotContainer().createHotspot(document.querySelector("#pptview5"), {
      yaw: -0.039,
      pitch: 0.045,
    });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview1"), {
        yaw: -0.85,
        pitch: 0.042,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview6"), {
        yaw: -0.85,
        pitch: 0.042,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream2"), {
        yaw: -0.85,
        pitch: 0.042,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview2"), {
        yaw: 1.54,
        pitch: 0.1,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview7"), {
        yaw: 1.54,
        pitch: 0.1,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream3"), {
        yaw: 1.54,
        pitch: 0.1,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview3"), {
        yaw: 3.11,
        pitch: 0.039,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview8"), {
        yaw: 3.11,
        pitch: 0.039,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream4"), {
        yaw: 3.11,
        pitch: 0.039,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview4"), {
        yaw: 3.966,
        pitch: 0.036,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview9"), {
        yaw: 3.966,
        pitch: 0.036,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream5"), {
        yaw: 3.966,
        pitch: 0.036,
      });
      var autorotate = Marzipano.autorotate({
        yawSpeed: 0.1, // Yaw rotation speed
        targetPitch: 0, // Pitch value to converge to
        targetFov: Math.PI / 2, // Fov value to converge to
      });

      // Autorotate will start after 3s of idle time
      this.viewer.setIdleMovement(3000, autorotate);
      // Disable idle movement
      //this.viewer.setIdleMovement(Infinity);

      // Start autorotation immediately
      this.viewer.startMovement(autorotate);
  }

  render() {

    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }
    function hideImg3() {
      document.getElementById("myImg3").style.display = "none";
    }
    function hideImg4() {
      document.getElementById("myImg4").style.display = "none";
    }
    function hideImg5() {
      document.getElementById("myImg5").style.display = "none";
    }
    const{
      poster1,
      poster2,
      poster3,
      poster4,
      poster5,
      poster6,
      poster7,
      poster8,
      poster9,
      poster10,
      poster11,
      I2poster1path,
      I2poster2path,
      I2poster3path,
      I2poster4path,
      I2poster5path,
      I2poster6path,
      I2poster7path,
      I2poster8path,
      I2poster9path,
      I2poster10path,
      I2poster6type,
      I2poster7type,
      I2poster8type,
      I2poster9type,
      I2poster10type,


    }=this.state;
    return (
      <div className="pano-container" ref={(pano) => (this.pano = pano)}>
        <Preloader/>
          <div id="Inoposter1" ref={(poster1) => (this.poster1 = poster1)}>
          <label>
          {poster1 && (
            <form enctype="multipart/form-data">
              <div id="richList"></div>

              <div id="loader3" class="lds-dual-ring hidden overlay"></div>
              <div id="Imap11">
              <img
                src={I2poster1path}
                onError={() => hideImg1()}
                id="myImg1"
                alt=""
              />
            </div>
            </form>
          )}
          </label>
        </div>
        <div id="Inoposter2" ref={(poster2) => (this.poster2 = poster2)}>
          <label>
          {poster2 && (
            <form enctype="multipart/form-data">
              <div id="richList"></div>

              <div id="loader3" class="lds-dual-ring hidden overlay"></div>
              <div id="Imap22">
              <img
                src={I2poster2path}
                onError={() => hideImg2()}
                id="myImg2"
              /></div>
            </form>
          )}
          </label>
        </div>
       <div id="Inoposter3" ref={(poster3) => (this.poster3 = poster3)}>
          <label>
          {poster3 && (
            <form enctype="multipart/form-data">
              <div id="richList"></div>

              <div id="loader3" class="lds-dual-ring hidden overlay"></div>
              <div  id="Imap33">
              <img
                src={I2poster3path}
                onError={() => hideImg3()}
                id="myImg3"
              /></div>
            </form>
          )}
          </label>
        </div>
       <div id="Inoposter4" ref={(poster4) => (this.poster4 = poster4)}>
          <label>
          {poster4 && (
            <form enctype="multipart/form-data">
              <div id="richList"></div>

              <div id="loader3" class="lds-dual-ring hidden overlay"></div>
              <div id="Imap44">
              <img
                src={I2poster4path}
                onError={() => hideImg4()}
                id="myImg4"
              />
              </div>
            </form>
          )}
          </label>
        </div>

          <div id="Inoposter6" ref={(poster5) => (this.poster5 = poster5)}>
          <label>
          {poster5 && (
            <form enctype="multipart/form-data">
              <div id="richList"></div>

              <div id="loader3" class="lds-dual-ring hidden overlay"></div>
              <div  id="Imap66">
              <img
                src={I2poster5path}
                onError={() => hideImg5()}
                id="myImg5"
              /></div>
            </form>
          )}
          </label>
        </div>

        <div id="pptview">
          <div className="hotspot">
          {poster6 && I2poster6type === "ppt" && (
            <div className="inn" onClick={this.P1openModal}></div>
          )}
          </div>
          {poster6 && (
          <Modal
            isOpen={this.state.modalIsOpen}
            style={customStyles}
            onRequestClose={this.P1closeModal}
          >
            <span className="close" onClick={this.P1closeModal}>
              x
            </span>

            <iframe
             src={I2poster6path}
              // src={Iposter1path}
              // id="if1"
              frameborder="0"
            ></iframe>
          </Modal>
    )}

        </div>

        <div id="pptview5">
          <div className="hotspot5">
          {poster6 && I2poster6type === "pdf" && (
            <div className="inn55" onClick={this.P1openModal}></div>
          )}
          </div>
          {poster6 && (
          <Modal
            isOpen={this.state.modalIsOpen}
            style={customStyles}
            onRequestClose={this.P1closeModal}
          >
            <span className="close" onClick={this.P1closeModal}>
              x
            </span>

            <iframe
             src={ I2poster6path}
              // src={Iposter1path}
              // id="if1"
              frameborder="0"
            ></iframe>
          </Modal>
    )}

        </div>
        <div id="Videostream1">
          <div className="hotspot2">
          {poster6 && I2poster6type === "video" && (
            <div className="inn2" onClick={this.P6openModal}></div>
          )}
          </div>
          {poster6 && (
          <Modal
            isOpen={this.state.P6modalIsOpen}
            style={customStylesV1}
            onRequestClose={this.P6closeModal}
          >
            <span className="close" onClick={this.P6closeModal}>
              x
            </span>
            <video id="video1" autoplay controls controlsList="nodownload">
                <source src={I2poster6path} />
              </video>


          </Modal>
    )}

        </div>

        <div id="pptview1">
          <div className="hotspot1">
          {poster7 && I2poster7type === "ppt" && (
            <div className="inn1" onClick={this.P2openModal}></div>
          )}
          </div>
          {poster7 &&(
          <Modal
            isOpen={this.state.SecmodalIsOpen}
            style={customStyles}
            onRequestClose={this.P2closeModal}
          >
            <span className="close" onClick={this.P2closeModal}>
              x
            </span>

            <iframe
              src={I2poster7path}
              // src={Iposter2path}
              // id="if1"
              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="pptview6">
          <div className="hotspot6">
          {poster7 && I2poster7type === "pdf" && (
            <div className="inn66" onClick={this.P2openModal}></div>
          )}
          </div>
          {poster7 &&(
          <Modal
            isOpen={this.state.SecmodalIsOpen}
            style={customStyles}
            onRequestClose={this.P2closeModal}
          >
            <span className="close" onClick={this.P2closeModal}>
              x
            </span>

            <iframe
              src={I2poster7path}
              // src={Iposter2path}
              // id="if1"
              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="Videostream2">
          <div className="hotspot3">
          {poster7 && I2poster7type === "video" && (
            <div className="inn3" onClick={this.P7openModal}></div>
          )}
          </div>
          {poster7 &&(
          <Modal
            isOpen={this.state.P7modalIsOpen}
            style={customStylesV1}
            onRequestClose={this.P7closeModal}
          >
            <span className="close" onClick={this.P7closeModal}>
              x
            </span>

            <video id="video2" autoplay controls controlsList="nodownload">
                <source src={I2poster7path} />
              </video>
          </Modal>
          )}
        </div>

        <div id="pptview2">
          <div className="hotspot2">
          {poster8 && I2poster8type === "ppt" && (
            <div className="inn22" onClick={this.P3openModal}></div>
          )}
          </div>
          {poster8 && (
          <Modal
            isOpen={this.state.TmodalIsOpen}
            style={customStyles}
            onRequestClose={this.P3closeModal}
          >
            <span className="close" onClick={this.P3closeModal}>
              x
            </span>

            <iframe
               src={I2poster8path}

              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="pptview7">
          <div className="hotspot7">
          {poster8 && I2poster8type === "pdf" && (
            <div className="inn77" onClick={this.P3openModal}></div>
          )}
          </div>
          {poster8 && (
          <Modal
            isOpen={this.state.TmodalIsOpen}
            style={customStyles}
            onRequestClose={this.P3closeModal}
          >
            <span className="close" onClick={this.P3closeModal}>
              x
            </span>

            <iframe
               src={I2poster8path}

              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="Videostream3">
          <div className="hotspot4">
          {poster8 && I2poster8type === "video" && (
            <div className="inn4" onClick={this.P8openModal}></div>
          )}
          </div>
          {poster8 && (
          <Modal
            isOpen={this.state.P8modalIsOpen}
            style={customStylesV1}
            onRequestClose={this.P8closeModal}
          >
            <span className="close" onClick={this.P8closeModal}>
              x
            </span>
            <video id="video2" autoplay controls controlsList="nodownload">
                <source src={I2poster8path} />
              </video>

          </Modal>
          )}
        </div>

        <div id="pptview3">
          <div className="hotspot3">
          {poster9 && I2poster9type === "ppt" && (
            <div className="inn33" onClick={this.P4openModal}></div>
          )}
          </div>
          {poster9 && (
          <Modal
            isOpen={this.state.FmodalIsOpen}
            style={customStyles}
            onRequestClose={this.P4closeModal}
          >
            <span className="close" onClick={this.P4closeModal}>
              x
            </span>

            <iframe
               src={ I2poster9path}
              // src={Iposter4path}
              // id="if1"
              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="pptview8">
          <div className="hotspot8">
          {poster9 && I2poster9type === "pdf" && (
            <div className="inn88" onClick={this.P4openModal}></div>
          )}
          </div>
          {poster9 && (
          <Modal
            isOpen={this.state.FmodalIsOpen}
            style={customStyles}
            onRequestClose={this.P4closeModal}
          >
            <span className="close" onClick={this.P4closeModal}>
              x
            </span>

            <iframe
               src={I2poster9path}
              // src={Iposter4path}
              // id="if1"
              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="Videostream4">
          <div className="hotspot5">
          {poster9 && I2poster9type === "video" && (
            <div className="inn5" onClick={this.P9openModal}></div>
          )}
          </div>
          {poster9 && (
          <Modal
            isOpen={this.state.P9modalIsOpen}
            style={customStylesV1}
            onRequestClose={this.P9closeModal}
          >
            <span className="close" onClick={this.P9closeModal}>
              x
            </span>
            <video id="video4" autoplay controls controlsList="nodownload">
                <source src={I2poster9path} />
              </video>

          </Modal>
          )}
        </div>

        <div id="pptview4">
          <div className="hotspot4">
          {poster10 && I2poster10type === "ppt" && (
            <div className="inn44" onClick={this.P5openModal}></div>
          )}
          </div>
          {poster10 && (
          <Modal
            isOpen={this.state.FimodalIsOpen}
            style={customStyles}
            onRequestClose={this.P5closeModal}
          >
            <span className="close" onClick={this.P5closeModal}>
              x
            </span>

            <iframe
               src={ I2poster10path}
              // src={Iposter5path}
              // id="if1"
              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>

        <div id="pptview9">
          <div className="hotspot9">
          {poster10 && I2poster10type === "pdf" && (
            <div className="inn99" onClick={this.P5openModal}></div>
          )}
          </div>
          {poster10 && (
          <Modal
            isOpen={this.state.FimodalIsOpen}
            style={customStyles}
            onRequestClose={this.P5closeModal}
          >
            <span className="close" onClick={this.P5closeModal}>
              x
            </span>

            <iframe
               src={I2poster10path}
              // src={Iposter5path}
              // id="if1"
              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="Videostream5">
          <div className="hotspot6">
          {poster10 && I2poster10type === "video" && (
            <div className="inn6" onClick={this.P10openModal}></div>
          )}
          </div>
          {poster10 && (
          <Modal
            isOpen={this.state.P10modalIsOpen}
            style={customStylesV1}
            onRequestClose={this.P10closeModal}
          >
            <span className="close" onClick={this.P10closeModal}>
              x
            </span>
            <video id="video5" autoplay controls controlsList="nodownload">
                <source src={I2poster10path} />
              </video>

          </Modal>
          )}
        </div>
      </div>
    );
  }
}

export default Innovation1;
