import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../services/auth.service";

export default class ContactUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: { username: "" },
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (!currentUser) this.setState({ redirect: "/home" });
    this.setState({ currentUser: currentUser, userReady: true });
  }

  render() {
    if (this.state.redirect) {
      // return <Redirect to={this.state.redirect} />;
      return <Navigate replace to={this.state.redirect} />;
    }

    const { currentUser } = this.state;

    return (
      <div className="container">
        {this.state.userReady ? (
          <div>
            <header className="jumbotron">
              <h2>
                {/* <strong>Contact Us</strong> */}
                <strong>Email:</strong> {"cvebsv.in@capgemini.com"}
              </h2>
            </header>
            <p>
              {/* <strong>Token:</strong>{" "}
          {currentUser.accessToken.substring(0, 20)} ...{" "}
          {currentUser.accessToken.substr(currentUser.accessToken.length - 20)} */}
            </p>
            {/* <p>
              <strong>Email:</strong> {"cvebsv.in@capgemini.com"}
            </p> */}
            {/* <strong>Authorities:</strong> */}
            {/* <ul>
              {currentUser.roles &&
                currentUser.roles.map((role, index) => (
                  <li key={index}>{role}</li>
                ))}
            </ul> */}
          </div>
        ) : null}
      </div>
    );
  }
}
