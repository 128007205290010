import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AuthService from "../services/auth.service";
// const Main = styled("div")`
//   font-family: sans-serif;
//   background: #f0f0f0;
//   height: 100vh;
// `;

const DropDownContainer = styled("div")`
  width: 19em;
  margin: 0 auto; 
`;

const DropDownHeader = styled("div")`
  margin-bottom: 0.2em;
  padding: 0.1em 1em 0.1em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
  color: #3faffa;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  
`;

const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.2em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.2em;
`;

var options = [];

export default function ClientDropdownList({ onChangeTenant, datalist }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggling = () => setIsOpen(!isOpen);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      if (user.roles.includes("ROLE_SUPERADMIN")) {
        options = datalist;
        setSelectedOption(datalist[0].tenantname);
      }
    }
  }, [datalist]);

  const onOptionClicked = (name, id) => () => {
    console.log(`inside onOptionClicked ${name}`);
    setSelectedOption(name);
    setIsOpen(false);
    onChangeTenant(name, id);
  };

  return (
    <div>
      <DropDownContainer>
        <DropDownHeader onClick={toggling}>{selectedOption}</DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {options.map((option) => (
                <ListItem
                  onClick={onOptionClicked(option.tenantname, option.id)}
                  key={Math.random()}
                >
                  {option.tenantname}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </div>
  );
}
