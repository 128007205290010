import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import stall1 from "./img/VR-01/VR-1 5 GRID.jpg";
import image from "./img/rs1.png";
import image1 from "./img/rs2.png";
import Modal from "react-modal";
import AWSService from "../services/aws.service";
import EventService from "../services/event.service";
import PPTPathService from "../services/pptpath.service";
import UnAuthorizedService from "../common/unauthorized";
import AuthService from "../services/auth.service";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./styles/maping.css";
import $ from "jquery";
import "../App.css";
var lobbyeventname = "";
let tenant = "";
const { aws_s3_url, aws_s3_bucket_name } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "70vh",
    marginRight: "-50%",
    width: "calc(60% - 0px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    border: "unset !important",
    margintop: "calc(85vh - 10vh - 10px)",
    zIndex: "99999",
    backgroundcolor: "unset !important",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(50% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding: "0 0 0 0",
  },
};
class Resource4G2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initially, no file is selected
      selectedFile: null,
      poster1: false,
      poster2: false,
      poster3: false,
      poster4: false,
      poster6: false,
      poster4r: false,
      poster4r1: false,
      poster4r2: false,
      poster4r3: false,
      poster64g21: false,
      poster64g22: false,

      poster64g24: false,
      poster64g23: false,
      poster4g2pdf: false,
      poster4g2apdf: false,
      poster4g2bpdf: false,
      poster4g2cpdf: false,









      R4poster1path : "",
      R4poster2path : "",
      R4poster3path : "",
      R4poster4path : "",
      R4poster6path : "",
      R4poster4rpath : "",
      R4poster4r1path : "",
      R4poster4r2path : "",
      R4poster4r3path : "",
      R4poster64g21path : "",
      R4poster64g22path : "",

      R4poster64g22path : "",
      R4poster64g23path : "",
      R4poster64g24path : "",

      R4poster4g2pdfpath : "",
      R4poster4g2apdfpath : "",
      R4poster4g2bpdfpath : "",
      R4poster4g2cpdfpath : "",








      R4poster3type: "",
      R4poster64g21type: "",
      R4poster64g22type: "",
      R4poster64g23type: "",
 R4poster64g24type: "",



      R4poster4type: "",
      R4poster4g2pdftype: "",
      R4poster4g2apdftype: "",
      R4poster4g2bpdftype: "",
      R4poster4g2cpdftype: "",

      modalIsOpen: false,
      modal1IsOpen: false,
      modal2IsOpen: false,
      tenantname: tenant,
    };
  }

  P1openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  P1closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  P2openModal = () => {
    this.setState({ modal1IsOpen: true });
  };

  P2closeModal = () => {
    this.setState({ modal1IsOpen: false });
  };
  P3openModal = () => {
    this.setState({ modal2IsOpen: true });
  };

  P3closeModal = () => {
    this.setState({ modal2IsOpen: false });
  };




  P1openModal1 = () => {
    this.setState({ modalIsOpen1: true });
  };

  P1closeModal1 = () => {
    this.setState({ modalIsOpen1: false });
  };

  P2openModal1 = () => {
    this.setState({ modal1IsOpen1: true });
  };

  P2closeModal1 = () => {
    this.setState({ modal1IsOpen1: false });
  };
  P3openModal1 = () => {
    this.setState({ modal2IsOpen1: true });
  };

  P3closeModal1 = () => {
    this.setState({ modal2IsOpen1: false });
  };



  P1openModal2 = () => {
    this.setState({ modalIsOpen2: true });
  };

  P1closeModal2 = () => {
    this.setState({ modalIsOpen2: false });
  };

  P2openModal2 = () => {
    this.setState({ modal1IsOpen2: true });
  };

  P2closeModal2 = () => {
    this.setState({ modal1IsOpen2: false });
  };
  P3openModal2 = () => {
    this.setState({ modal2IsOpen2: true });
  };

  P3closeModal2 = () => {
    this.setState({ modal2IsOpen2: false });
  };



  P1openModal3 = () => {
    this.setState({ modalIsOpen3: true });
  };

  P1closeModal3 = () => {
    this.setState({ modalIsOpen3: false });
  };

  P2openModal3 = () => {
    this.setState({ modal1IsOpen3: true });
  };

  P2closeModal3 = () => {
    this.setState({ modal1IsOpen3: false });
  };
  P3openModal3 = () => {
    this.setState({ modal2IsOpen3: true });
  };

  P3closeModal3 = () => {
    this.setState({ modal2IsOpen3: false });
  };



  P1openModal4 = () => {
    this.setState({ modalIsOpen4: true });
  };

  P1closeModal4 = () => {
    this.setState({ modalIsOpen4: false });
  };

  P2openModal4 = () => {
    this.setState({ modal1IsOpen4: true });
  };

  P2closeModal4 = () => {
    this.setState({ modal1IsOpen4: false });
  };
  P3openModal4 = () => {
    this.setState({ modal2IsOpen4: true });
  };

  P3closeModal4 = () => {
    this.setState({ modal2IsOpen4: false });
  };
  componentDidMount() {

    localStorage.setItem("intro", 0);
    const eventname = EventService.getCurrentEvent();
    console.log(eventname);
    var num = Math.random();
    PPTPathService.getFiletype(eventname, "Rs4")
      .then((response) => {
        response.data.forEach((filedetails) => {
          if (filedetails.filename === "poster34g2") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R4poster3path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs4" +
                "/poster34g2" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster34g2: true,
            });

            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R4poster3path:filedetails.path,
                  poster3: true,

                });
              this.setState({ R4poster3type: "txt" });
            }
          }

          if (filedetails.filename === "poster64g21") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R4poster64g21path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs4" +
              "/poster64g21" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster64g21: true,
            });

            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R4poster64g21path:filedetails.path,
                  poster64g21: true,

                });
              this.setState({ R4poster64g21type: "txt" });
            }
          }

          if (filedetails.filename === "poster64g22") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R4poster64g22path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs4" +
                "/poster64g22" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster64g22: true,
            });

            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R4poster64g22path:filedetails.path,
                  poster64g22: true,

                });
              this.setState({ R4poster64g22type: "txt" });
            }
          }

          // if (filedetails.filename === "poster64g211") {
          //   console.log("filepath got from db is sssssssssssssssssssssssss");
          //   console.log(filedetails);
          //   console.log(filedetails.path);
          //   this.setState({
          //     R4poster64g211path:
          //       AWS_S3_URL +
          //       "capgemini/" +
          //       eventname +
          //       "/Rs4" +
          //     "/poster64g211" +
          //     "." +
          //     filedetails.filetype +
          //     "?v=" +
          //     num,
          //   poster64g211: true,
          //   });

          //   if (
          //     filedetails.filetype === "txt"

          //   )  {
          //       this.setState({
          //         R4poster64g211path:filedetails.path,
          //         poster64g211: true,

          //       });
          //     this.setState({ R4poster64g22type: "txt" });
          //   }
          // }
          if (filedetails.filename === "poster64g23") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R4poster64g23path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs4" +
                "/poster64g23" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster64g23: true,
            });

            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R4poster64g23path:filedetails.path,
                  poster64g23: true,

                });
              this.setState({ R4poster64g23type: "txt" });
            }
          }

          if (filedetails.filename === "poster64g24") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R4poster64g24path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs4" +
              "/poster64g24" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster64g24: true,
            });

            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R4poster64g24path:filedetails.path,
                  poster64g24: true,

                });
              this.setState({ R4poster64g24type: "txt" });
            }
          }
        if (filedetails.filename == "poster4") {
            this.setState({
              R4poster4path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs4g2" +
                "/poster4" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster4: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R4poster4type: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R2poster4path:filedetails.path,
                  poster4: true,

                });
                this.setState({ R4poster4type: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R4poster4type: "pdf" });
              }
            }
            console.log(this.state.R4poster4path);
          }



          if (filedetails.filename == "poster4g2pdf") {
            this.setState({
              R4poster4g2pdfpath:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3" +
                "/poster4g2pdf" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
                poster4g2pdf: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R4poster4g2pdftype: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R2poster4g2pdfpath:filedetails.path,
                  poster4g2pdf: true,

                });
                this.setState({ R4poster4g2pdftype: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R4poster4g2pdftype: "pdf" });
              }
            }
            console.log(this.state.R4poster4g2pdfpath);
          }



          if (filedetails.filename == "poster4g2apdf") {
            this.setState({
              R4poster4g2apdfpath:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3" +
                "/poster4g2apdf" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
                poster4g2apdf: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R4poster4g2apdftype: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R2poster4g2apdfpath:filedetails.path,
                  poster4g2apdf: true,

                });
                this.setState({ R4poster4g2apdftype: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R4poster4g2apdftype: "pdf" });
              }
            }
            console.log(this.state.R4poster4g2apdfpath);
          }



          if (filedetails.filename == "poster4g2bpdf") {
            this.setState({
              R4poster4g2bpdfpath:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3" +
                "/poster4g2bpdf" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
                poster4g2bpdf: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R4poster4g2bpdftype: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R2poster4g2bpdfpath:filedetails.path,
                  poster4g2bpdf: true,

                });
                this.setState({ R4poster4g2bpdftype: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R4poster4g2bpdftype: "pdf" });
              }
            }
            console.log(this.state.R4poster4g2bpdfpath);
          }




          if (filedetails.filename == "poster4g2cpdf") {
            this.setState({
              R4poster4g2cpdfpath:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs4" +
                "/poster4g2cpdf" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
                poster4g2cpdf: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R4poster4g2cpdftype: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R2poster4g2cpdfpath:filedetails.path,
                  poster4g2cpdf: true,

                });
                this.setState({ R4poster4g2cpdftype: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R4poster4g2cpdftype: "pdf" });
              }
            }
            console.log(this.state.R4poster4g2cpdfpath);
          }
        })
      })

      .catch((err) => {
        UnAuthorizedService.unauthorized(err);
      });

    EventService.getSpecificEvent()
    .then((response) => {
      console.log("Eventname from getSpecificEvent");
      console.log(response.data);
      console.log(response.data.eventname);
      const eventpath = "/" + response.data.eventname;
      this.setState({ tenantname: eventpath, showLoginPage: true });
      localStorage.setItem("eventname", response.data.eventname);
      console.log(response.data.eventname);

      const user=AuthService.getCurrentUser();

      AuthService.getCurrentRole().then((roles)=>{
        if (roles) {
          console.log("Got current role from server");
          this.setState({
            currentUser: user,
            showModeratorBoard: roles.data.includes("ROLE_MODERATOR"),
            showAdminBoard: roles.data.includes("ROLE_ADMIN"),
            showAddTenant: roles.data.includes("ROLE_SUPERADMIN"),
            showAddUser: roles.data.includes("ROLE_SUPERADMIN"),
            showRegModerators:
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showRegAdmins:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN"),
            showRegSuperAdmins: roles.data.includes("ROLE_SUPERADMIN"),
            showRegUsers:
              roles.data.includes("ROLE_MODERATOR") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showCreateEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showDetailLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showFeedbackReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEdit:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showNormalUser: roles.data.includes("ROLE_USER"),
            showTenants:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            //tenantname: tenant,
          });
        }
        lobbyeventname = EventService.getCurrentEvent();
        console.log(lobbyeventname);

        this.setState({
          R4poster1path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs4" +
          "/poster44g2.png?v=" + num,
          R4poster2path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs4j" +
          "/poster2j.png?v=" + num,
          R4poster6path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs4" + "/poster6.png?v=" + num,
          R4poster4rpath :
          AWS_S3_URL + "capgemini/" + eventname  + "/Rs4" +
          "/poster4g21.png?v=" + num,
          R4poster4r1path :
          AWS_S3_URL + "capgemini/" + eventname  + "/Rs4" +
          "/poster4g22.png?v=" + num,
          R4poster4r2path :
          AWS_S3_URL + "capgemini/" + eventname  + "/Rs4" +
          "/poster4g23.png?v=" + num,
          R4poster4r3path :
          AWS_S3_URL + "capgemini/" + eventname  + "/Rs4" +
          "/poster4g24.png?v=" + num,



          poster1: true,
          poster2: true,
          poster6: true,
          poster4r: true,
          poster4r1: true,
          poster4r2: true,
          poster4r3: true,





    });
    })
  }
    )
  }

  render() {
    function hideImg() {
      document.getElementById("myImg").style.display = "none";
    }
    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }
    function hideImg4r() {
      document.getElementById("myImg4r").style.display = "none";
    }
    function hideImg4r1() {
      document.getElementById("myImg4r1").style.display = "none";
    }
    function hideImg4r2() {
      document.getElementById("myImg4r2").style.display = "none";
    }
    function hideImg4r3() {
      document.getElementById("myImg4r3").style.display = "none";
    }



    const {

      poster1,
      poster2,
      poster3,
      poster4,
      poster6,
      poster4r,
      poster4r1,
      poster4r2,
      poster4r3,
      poster64g21,
      poster64g22,
      poster64g24,
      poster64g23,
      poster4g2pdf,
      poster4g2apdf,
      poster4g2bpdf,
      poster4g2cpdf,







      R4poster1path,
      R4poster2path,
      R4poster3path,
      R4poster4path,
      R4poster4g2pdfpath,
      R4poster4g2apdfpath,
      R4poster4g2bpdfpath,
      R4poster4g2cpdfpath,

      R4poster6path,
      R4poster64g21type,
      R4poster64g24type,
      R4poster64g22type,
      R4poster64g23type,



      R4poster4type,
      R4poster4g2pdftype,
      R4poster4g2apdftype,
      R4poster4g2bpdftype,
      R4poster4g2cpdftype,

      R4poster4rpath,
      R4poster4r1path,
      R4poster4r2path,
      R4poster4r3path,
      R4poster64g21path,
      R4poster64g24path,
      R4poster64g22path,
      R4poster64g23path,








      tenantname


    } = this.state;

    return (
      <div className="container-fluide">


        <div className="page-content">
          <div id="content-agenda">
            <div id="bg">
              <img src="https://d2e8va4puks5pl.cloudfront.net/VR-01/VR-1%205%20GRID.jpg" id="bg-image" className="mb-3" />

              <div className="sidenavogation">
                  {/* <Link to={tenantname + "/Resource"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Resource Center1</span>
                  </Link>
                  <Link to={tenantname + "/Resource1"} className="Rs2  text-white buttonRes">
                              <span className="hide-menu">Resource Center2</span>
                  </Link>
                  <Link to={tenantname + "/Resource2"} className="Rs3  text-white buttonRes">
                              <span className="hide-menu">Resource Center3</span>
                  </Link>
                  <Link to={tenantname + "/Resource3"} className="Rs4  text-white buttonRes">
                              <span className="hide-menu">Resource Center4</span>
                  </Link>
                  <Link to={tenantname + "/Resource4"} className="Rs5  text-white buttonRes">
                              <span className="hide-menu">Resource Center5</span>
                  </Link> */}
                   <Link to={tenantname + "/agenda"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Back To Agenda</span>
                  </Link>

                  </div>
              <div id="Resource4res">
              {poster1 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap41" >
                  <img
                  src={R4poster1path}
                  // src={image}
                   alt=""  onError={() => hideImg()}
                   id="myImg"/>
                </div>

              </form>
            )}
              </div>
              <div id="Resource4rres">
              {poster4r && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap41r" >
                  <img
                  src={R4poster4rpath}
                  // src={image}
                   alt=""  onError={() => hideImg4r()}
                   id="myImg4r"/>
                </div>

              </form>
            )}
              </div>
              <div id="Resource4r1res">
              {poster4r1 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap41r1" >
                  <img
                  src={R4poster4r1path}
                  // src={image}
                   alt=""  onError={() => hideImg4r1()}
                   id="myImg4r1"/>
                </div>

              </form>
            )}
              </div>
              <div id="Resource4r2res">
              {poster4r2 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap41r2" >
                  <img
                  src={R4poster4r2path}
                  // src={image}
                   alt=""  onError={() => hideImg4r2()}
                   id="myImg4r2"/>
                </div>

              </form>
            )}
              </div>
              <div id="Resource4r3res">
              {poster4r3 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap41r3" >
                  <img
                  src={R4poster4r3path}
                  // src={image}
                   alt=""  onError={() => hideImg4r3()}
                   id="myImg4r3"/>
                </div>

              </form>
            )}
              </div>


              <div id="Resource5">
              {poster2 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader2"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap51" >
                  <img
                  src={R4poster2path}
                 // src={image1}
                   onError={() => hideImg1()}
                   id="myImg1"/>
                </div>

              </form>
            )}
              </div>

              <div id="Resource64g2">
              {poster3 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap61" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R4poster3path}
                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>
              <div id="Resource6r">
              {poster64g21 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap61r" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R4poster64g21path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>

              <div id="Resource64g21">
              {poster64g22 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap61" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R4poster64g22path}
                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>
              <div id="Resource64g22">
              {poster64g23 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap61" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R4poster64g23path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>
              <div id="Resource64g23">
              {poster64g24 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap61" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R4poster64g24path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>





        <div id="ResourceCenter">
          <div>
            <div className="hotspot">
              {/* <div className="out"></div> */}
              {poster4 && R4poster4type === "ppt" && (
                <div className="in11" onClick={this.P1openModal} >
                  {" "}
                </div>
              )}
            </div>
            {poster4 && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen}
                  style={customStyles}
                  onRequestClose={this.P1closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal}>
                    x
                  </span>
                  <iframe
                   src={R4poster4path}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterres4">
          <div>
            <div className="hotspot1">
              {/* <div className="out"></div> */}
              {poster4 && R4poster4type === "ppt" && (
                <div className="in11" onClick={this.P1openModal} >
                  {" "}
                </div>
              )}
            </div>
            {poster4 && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen}
                  style={customStyles}
                  onRequestClose={this.P1closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal}>
                    x
                  </span>
                  <iframe
                   src={R4poster4path}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterres4">
          <div>
            <div className="hotspot1">
              {poster4 && R4poster4type === "video" && (
                <div className="in11" onClick={this.P2openModal}></div>
              )}
            </div>
            {poster4 && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal}
                >
                  <span className="close1" onClick={this.P2closeModal}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R4poster4path} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterres4">
          <div>
            <div className="hotspot1">
              {poster4 && R4poster4type === "pdf" && (
                <div className="in11" onClick={this.P3openModal}></div>
              )}
            </div>
            {poster4 && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen}
                  style={customStyles}
                  onRequestClose={this.P3closeModal}
                >
                  <span className="close1" onClick={this.P3closeModal}>
                    x
                  </span>

                  <iframe
                    src={R4poster4path}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>

           </div>

           <div id="ResourceCenterres41">
          <div>
            <div className="hotspot1">
              {/* <div className="out"></div> */}
              {poster4g2pdf && R4poster4g2pdftype === "ppt" && (
                <div className="in11" onClick={this.P1openModal1} >
                  {" "}
                </div>
              )}
            </div>
            {poster4g2pdf && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen1}
                  style={customStyles}
                  onRequestClose={this.P1closeModal1}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal1}>
                    x
                  </span>
                  <iframe
                   src={R4poster4g2pdfpath}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterres41">
          <div>
            <div className="hotspot1">
              {poster4g2pdf && R4poster4g2pdftype === "video" && (
                <div className="in11" onClick={this.P2openModal1}></div>
              )}
            </div>
            {poster4g2pdf && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen1}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal1}
                >
                  <span className="close1" onClick={this.P2closeModal1}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R4poster4g2pdfpath} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterres41">
          <div>
            <div className="hotspot1">
              {poster4g2pdf && R4poster4g2pdftype === "pdf" && (
                <div className="in11" onClick={this.P3openModal1}></div>
              )}
            </div>
            {poster4g2pdf && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen1}
                  style={customStyles}
                  onRequestClose={this.P3closeModal1}
                >
                  <span className="close1" onClick={this.P3closeModal1}>
                    x
                  </span>

                  <iframe
                    src={R4poster4g2pdfpath}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>

           </div>

           <div id="ResourceCenterres42">
          <div>
            <div className="hotspot1">
              {/* <div className="out"></div> */}
              {poster4g2apdf && R4poster4g2apdftype === "ppt" && (
                <div className="in11" onClick={this.P1openModal2} >
                  {" "}
                </div>
              )}
            </div>
            {poster4g2apdf && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen2}
                  style={customStyles}
                  onRequestClose={this.P1closeModal2}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal2}>
                    x
                  </span>
                  <iframe
                   src={R4poster4g2apdfpath}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterres42">
          <div>
            <div className="hotspot1">
              {poster4g2apdf && R4poster4g2apdftype === "video" && (
                <div className="in11" onClick={this.P2openModal2}></div>
              )}
            </div>
            {poster4g2apdf && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen2}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal2}
                >
                  <span className="close1" onClick={this.P2closeModal2}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R4poster4g2apdfpath} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterres42">
          <div>
            <div className="hotspot1">
              {poster4g2apdf && R4poster4g2apdftype === "pdf" && (
                <div className="in11" onClick={this.P3openModal2}></div>
              )}
            </div>
            {poster4g2apdf && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen2}
                  style={customStyles}
                  onRequestClose={this.P3closeModal2}
                >
                  <span className="close1" onClick={this.P3closeModal2}>
                    x
                  </span>

                  <iframe
                    src={R4poster4g2apdfpath}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>

           </div>

           <div id="ResourceCenterres43">
          <div>
            <div className="hotspot1">
              {/* <div className="out"></div> */}
              {poster4g2bpdf && R4poster4g2bpdftype === "ppt" && (
                <div className="in11" onClick={this.P1openModal3} >
                  {" "}
                </div>
              )}
            </div>
            {poster4g2bpdf && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen3}
                  style={customStyles}
                  onRequestClose={this.P1closeModal3}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal3}>
                    x
                  </span>
                  <iframe
                   src={R4poster4g2bpdfpath}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterres43">
          <div>
            <div className="hotspot1">
              {poster4g2bpdf && R4poster4g2bpdftype === "video" && (
                <div className="in11" onClick={this.P2openModal3}></div>
              )}
            </div>
            {poster4g2bpdf && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen3}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal3}
                >
                  <span className="close1" onClick={this.P2closeModal3}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R4poster4g2bpdfpath} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterres43">
          <div>
            <div className="hotspot1">
              {poster4g2bpdf && R4poster4g2bpdftype === "pdf" && (
                <div className="in11" onClick={this.P3openModal3}></div>
              )}
            </div>
            {poster4g2bpdf && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen3}
                  style={customStyles}
                  onRequestClose={this.P3closeModal3}
                >
                  <span className="close1" onClick={this.P3closeModal3}>
                    x
                  </span>

                  <iframe
                    src={R4poster4g2bpdfpath}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>

           </div>
           <div id="ResourceCenterres44">
          <div>
            <div className="hotspot1">
              {/* <div className="out"></div> */}
              {poster4g2cpdf && R4poster4g2cpdftype === "ppt" && (
                <div className="in11" onClick={this.P1openModal4} >
                  {" "}
                </div>
              )}
            </div>
            {poster4g2cpdf && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen4}
                  style={customStyles}
                  onRequestClose={this.P1closeModal4}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal4}>
                    x
                  </span>
                  <iframe
                   src={R4poster4g2cpdfpath}
                   allowFullScreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterres44">
          <div>
            <div className="hotspot1">
              {poster4g2cpdf && R4poster4g2cpdftype === "video" && (
                <div className="in11" onClick={this.P2openModal4}></div>
              )}
            </div>
            {poster4g2cpdf && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen4}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal4}
                >
                  <span className="close1" onClick={this.P2closeModal4}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R4poster4g2cpdfpath} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenterres44">
          <div>
            <div className="hotspot1">
              {poster4g2cpdf && R4poster4g2cpdftype === "pdf" && (
                <div className="in11" onClick={this.P3openModal4}></div>
              )}
            </div>
            {poster4g2cpdf && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen4}
                  style={customStyles}
                  onRequestClose={this.P3closeModal4}
                >
                  <span className="close1" onClick={this.P3closeModal4}>
                    x
                  </span>

                  <iframe
                    src={R4poster4g2cpdfpath}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>
           </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Resource4G2;
