import axios from "axios";
import authHeader from "./auth-header";

const {
  server_port,
  domain,
  react_env,
  subdomain,
} = require("../config/config");
let API_URL = "";
let API_EXCEL_URL = "";
if (react_env === "local") {
  API_URL = domain + ":" + server_port + "/";
  API_EXCEL_URL = domain + ":" + server_port + "/api/excel/";
} else {
  API_URL = domain + "/";
  API_EXCEL_URL = domain + "/api/excel/";
}

class PPTPathService {
  // setPPTPath(rating, comments) {
  //   return axios.post(
  //     API_URL + "setpath",
  //     { rating, comments, subdomain },
  //     { headers: authHeader() }
  //   );
  // }

  // setFiletype(eventname, pagename, filename, filetype) {
  //   return axios.post(
  //     API_URL + "setfiletype",
  //     { subdomain, eventname, pagename, filename, filetype },
  //     { headers: authHeader() }
  //   );
  // }

  setFiletype(eventname, pagename, filename, filetype, filepath) {
    console.log("Inside setFiletype function");
    return axios.post(
      API_URL + "setfiletype",
      { subdomain, eventname, pagename, filename, filetype, filepath },
      { headers: authHeader() }
    );
  }

  getFiletype(eventname, pagename, filename) {
    console.log("Inside getPPTPath");
    console.log(eventname);
    return axios.get(
      API_URL +
        "getfiletype" +
        "?subdomain=" +
        subdomain +
        "&eventname=" +
        eventname +
        "&pagename=" +
        pagename,
      // "&filename=" +
      // filename,
      {
        headers: authHeader(),
      }
    );
  }
}

export default new PPTPathService();
