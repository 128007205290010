import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import AuthService from "../services/auth.service";
import EventService from "../services/event.service";
const PrivateRouteUser = () => {
  const user = AuthService.getCurrentUser();
  const eventname = EventService.getCurrentEvent();
  let login = true;
  let ename = "";
  if (!user) {
    login = false;
  }
  if (eventname != null) ename = "/" + eventname + "/login";
  else ename = "/";

  return login ? <Outlet /> : <Navigate to={ename} />;
};

export default PrivateRouteUser;
