import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import Dropdown from "./Dropdown";
import ClientDropdownList from "./ClientDropdownList";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vfirstname = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The firstname must be between 3 and 20 characters.
      </div>
    );
  }
};

const vlastname = (value) => {
  if (value.length < 1 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The lastname must be between 1 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangefirstname = this.onChangefirstname.bind(this);
    this.onChangelastname = this.onChangelastname.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.onChangeTenant = this.onChangeTenant.bind(this);

    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      successful: false,
      message: "",
      roles: this.props.userRole,
      roleselected: ["user"],
      tenantid: this.props.tenantid,
      tenants: this.props.tenants,
      emailsblocked: this.props.emailsblocked,
      tenantname: "",
      initiatorrole: this.props.initiatorrole,
      options: this.props.options,
    };

    console.log(this.state.tenantid);
    console.log(this.state.tenants);
    console.log(this.state.emailsblocked);
    console.log(this.state.initiatorrole);
    console.log(this.state.roles);
    console.log(this.state.options);
    console.log(this.props.userRole);
  }

  onChangeTenant(name, id) {
    this.setState({ tenantname: name });
    this.setState({ tenantid: id });
    console.log(this.state.tenantname);
    console.log(this.state.tenantid);
  }

  onChangeRole(data) {
    this.setState({
      roleselected: [data.toLowerCase()],
    });
    console.log(data);
  }

  onChangefirstname(e) {
    this.setState({
      firstname: e.target.value,
    });
  }

  onChangelastname(e) {
    this.setState({
      lastname: e.target.value,
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      console.log(`inside Register.js props.tenantid ${this.props.tenantid}`);
      console.log(
        `inside Register.js this.state.tenantid ${this.state.tenantid}`
      );
      console.log(
        `inside Register.js this.props.emailsblocked ${this.props.emailsblocked}`
      );
      var ind = this.state.email.indexOf("@");
      var domain = this.state.email.slice(ind + 1, this.state.email.length);

      console.log(this.props.emailsblocked);
      console.log(domain);

      console.log(this.state.tenantid);
      console.log(this.state.tenants);
      console.log(this.state.emailsblocked);
      console.log(this.state.initiatorrole);
      console.log(this.state.roles);
      console.log(this.state.options);

      AuthService.registertest(
        this.state.firstname,
        this.state.lastname,
        ["user"],
        this.state.email,
        this.state.password,
        null
      ).then(
        (response) => {
          UserService.usersetpassword(this.state.email).then(
            () => {
              this.setState({
                loading: false,
                message: "Reset Password sent to your mail id",
              });
            },
            (error) => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              this.setState({
                loading: false,
                message: resMessage,
              });
            }
          );

          this.setState({
            message: response.data.message,
            successful: true,
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage,
          });
        }
      );
    }
  }

  render() {
    return (
      <div className="col-md-12">
        <div className="card card-container">
          <img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
          />

          <Form
            onSubmit={this.handleRegister}
            ref={(c) => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="firstname">First Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="firstname"
                    value={this.state.firstname}
                    onChange={this.onChangefirstname}
                    validations={[required, vfirstname]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="lasttname">Last Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="lastname"
                    value={this.state.lastname}
                    onChange={this.onChangelastname}
                    validations={[required, vlastname]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                    validations={[required, email]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Input
                    type="password"
                    className="form-control"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    validations={[required, vpassword]}
                  />
                </div>

                <div className="form-group">
                  <button className="btn btn-primary btn-block">Sign Up</button>
                </div>
              </div>
            )}

            {this.state.message && (
              <div className="form-group">
                <div
                  className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}
