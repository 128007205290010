import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles/styles.css";
import EventBus from "./../common/EventBus";
import AuthService from "./../services/auth.service";
import EventService from "./../services/event.service";
import Preloader from "./preloader.component";
//class Enter extends Component {
  const { aws_s3_url } = require("./../config/config");
  const AWS_S3_URL = aws_s3_url;
   //const videourl=AWS_S3_URL+"venue-entry.mp4";
 const videourl="https://d2e8va4puks5pl.cloudfront.net/Intro Video.mp4";

const Enter = () => {
  localStorage.setItem("intro", 1);
  console.log(
    "Inside Enter main method............................................"
  );
  //render() {
  const navigate = useNavigate();
  var entryVideo = document.getElementById("gotoentryvideo");
  if (entryVideo) {
    entryVideo.addEventListener("ended", entryEnd, false);
    entryVideo.play();
  }

  // $("#btnEnter").click(function () {
  //   $(".welcome").hide();
  //   $("#gotoentry").show();
  //   gotoEntry();
  // });
  // function gotoEntry() {
  //   console.log("Inside gotoEntry............................................");
  //   // window.entryVideo.currentTime = 0;

  // }

  function entryEnd(e) {
    console.log("before sending data in eventbus");
    EventBus.dispatch("login");
    localStorage.setItem("intro", 0);
    // var currentuser = AuthService.getCurrentUser();
    // if (currentuser.roles.includes("ROLE_USER")) {
    //   navigate("/resource");
    //   //window.location.href = "/resource";
    // } else {
      const eventname = "/" + EventService.getCurrentEvent();
      navigate(eventname + "/lobby");
      //window.location.href = "/lobby";
    //}
    // window.location.reload();
  }

  function skipEntry() {
    console.log("before sending data in eventbus skipEntry");
    EventBus.dispatch("login");
    localStorage.setItem("intro", 0);
    var currentuser = AuthService.getCurrentUser();
    // if (currentuser.roles.includes("ROLE_USER")) {
    //   navigate("/resource");
    //   //window.location.href = "/resource";
    // } else {
      const eventname = "/" + EventService.getCurrentEvent();
      navigate(eventname + "/lobby");
      //window.location.href = "/lobby";
   // }
    // window.location.reload();
  }
  return (
    <div>
      {/* <div className="welcome" style={{ display: "block" }}>
        <div className="welcome-message d-flex p-4 h-100">
          <span className="align-self-end text-white">
            <h2>We are so glad you could make it!</h2>
            <button className="btn btn-enter" id="btnEnter">
              Enter <i className="far fa-arrow-alt-circle-right ml-3"></i>
            </button>
          </span>
        </div>
      </div> */}
      <section
        className="videotoplay"
        id="gotoentry"
        // style={{ display: "none" }}
      >
        {/* <Preloader /> */}
        <video
          className="videoplayer"
          id="gotoentryvideo"

          autoPlay
          preload="auto"
        >
          {/* <source src="../../venue-entry.mp4" type="video/mp4" /> */}
          <source src={videourl} type="video/mp4" />

        </video>
        <div className="skip">
          <a href="#" onClick={() => skipEntry()}>
            SKIP
          </a>
        </div>
      </section>


    </div>
  );
};
//}
export default Enter;
