import React, { PureComponent } from "react";
import Marzipano from "marzipano";
import "./styles/styles.css";
import "./styles/maping.css";
import Modal from "react-modal";
import AWSService from "../services/aws.service";
import EventService from "../services/event.service";
import PPTPathService from "../services/pptpath.service";
import UnauthorizedService from "../common/unauthorized";
import $ from "jquery";
import Swal from "sweetalert2";
import { Uploader } from "../utils/upload";
import bp1 from "./img/bp1.png";
import bp2 from "./img/bp2.png";
import pptpathService from "../services/pptpath.service";
import Progressbar from "../common/progressbar";
const { aws_s3_url ,aws_s3_bucket_name } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
var lobbyeventname = "";
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "25vh",
    marginRight: "-50%",
    width: "calc(18% - 0px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "rgba(0,0,0,.9)",
    background: "unset !important",
    border: "unset !important",

    zIndex: "99999",
    backgroundcolor: "unset !important",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(20% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding: "0 0 0 0",
  },
};
class Boardroomedit1 extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      poster1: false,
      poster2: false,
      poster3: false,
      poster4: false,
      poster5: false,
      poster6: false,
      poster7: false,
      poster8: false,
      poster9: false,
      poster15: false,
      poster16: false,


      Bposter1path :"",
      Bposter2path : "",
      Bposter3path : "",
      Bposter4path :"",
      Bposter5path : "",
      Bposter6path : "",
      Bposter7path : "",
      Bposter8path : "",
      Bposter9path : "",
      Bposter10path: "",
      Bposter11path: "",
      Bposter12path: "",
      Bposter13path: "",
      Bposter14path: "",
      Bposter15path: "",
      Bposter16path: "",
      modalIsOpen: false,
      modal2IsOpen: false,
      modal3IsOpen: false,
      modal4IsOpen: false,
      modal5IsOpen: false,
      modal6IsOpen: false,
      modal7IsOpen: false,
      modal8IsOpen: false,

      localuploader: undefined,
      progressbar: 0,
    };
    // this.onCancel = this.onCancel.bind(this);
    // this.onFileChange010 = this.onFileChange010.bind(this);
  }
  P1openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  P1closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  P2openModal = () => {
    this.setState({ modal2IsOpen: true });
  };

  P2closeModal = () => {
    this.setState({ modal2IsOpen: false });
  };
  P3openModal = () => {
    this.setState({ modal3IsOpen: true });
  };

  P3closeModal = () => {
    this.setState({ modal3IsOpen: false });
  };
  P4openModal = () => {
    this.setState({ modal4IsOpen: true });
  };

  P4closeModal = () => {
    this.setState({ modal4IsOpen: false });
  };
  P5openModal = () => {
    this.setState({ modal5IsOpen: true });
  };

  P5closeModal = () => {
    this.setState({ modal5IsOpen: false });
  };
  P6openModal = () => {
    this.setState({ modal6IsOpen: true });
  };

  P6closeModal = () => {
    this.setState({ modal6IsOpen: false });
  };
  P7openModal = () => {
    this.setState({ modal7IsOpen: true });
  };

  P7closeModal = () => {
    this.setState({ modal7IsOpen: false });
  };

  P8openModal = () => {
    this.setState({ modal8IsOpen: true });
  };

  P8closeModal = () => {
    this.setState({ modal8IsOpen: false });
  };
  onCancel = async (event) => {
    console.log("OnCancel called");
    console.log(this.state.localuploader);
    if (this.state.localuploader) {
      this.state.localuploader.abort();
      //setFile(undefined);
    }
  };

 // On file select (from the pop up)
 onFileChange01 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function () {
    if (this.width != 1096|| this.height != 234) {
      alert(
        // "Uploaded File Dimension: Width= " +
        //   this.width +
        //   "  Height= " +
        //   this.height +
        "Required FIle Dimension: Width = " + 1096 + " Height= " + 234
      );
    } else {
      console.log("Inside file dimension are proper loop");
      uploadfile();
      //alert("File Dimensions are Proper");
    }
    _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
    var fileName = "capgemini/" + lobbyeventname + "/bd" + "/poster1.png";

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(file);

    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);

    this.setState({ localuploader: uploader }, () => {
      console.log(
        `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
      );
    });
    $("#loader1").addClass("hidden");
    this.setState({ poster: false });

    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {

            percentage = newPercentage;
            console.log(`${percentage}%`);
            $("#poster2bar").show();
           $(".poster2barbtn").hide();

          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");
            $("#loader1").addClass("hidden");
            this.setState({ poster: true });
            var num = Math.random();

            this.setState({
              Bposter1path:
                AWS_S3_URL +
                "capgemini/" +
                lobbyeventname +
                "/bd" +
                "/poster1.png?v=" +
                num,
            });
            PPTPathService.setFiletype(
              lobbyeventname,
              "bd",
              "poster1",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: "File Uploaded Successfully!.",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
            window.location.reload(false);
          } else if (upstatus === "failed") {
            $("#loader1").addClass("hidden");
            Swal.fire({
              // position: 'top-end',
              icon: "failure",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
};
 // On file select (from the pop up)

 onFileChange02 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function () {
    if (this.width != 1526|| this.height != 294) {
      alert(
        // "Uploaded File Dimension: Width= " +
        //   this.width +
        //   "  Height= " +
        //   this.height +
        "Required FIle Dimension: Width = " + 1526 + " Height= " + 294
      );
    } else {
      console.log("Inside file dimension are proper loop");
      uploadfile();
      //alert("File Dimensions are Proper");
    }
    _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
    var fileName = "capgemini/" + lobbyeventname + "/bd" + "/poster2.png";

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(file);

    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);

    this.setState({ localuploader: uploader }, () => {
      console.log(
        `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
      );
    });
    $("#loader2").addClass("hidden");
    this.setState({ poster: false });

    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {

            percentage = newPercentage;
            console.log(`${percentage}%`);
            $("#poster3bar").show();
           $(".poster3barbtn").hide();

          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");
            $("#loader2").addClass("hidden");
            this.setState({ poster: true });
            var num = Math.random();

            this.setState({
              Bposter2path:
                AWS_S3_URL +
                "capgemini/" +
                lobbyeventname +
                "/bd" +
                "/poster2.png?v=" +
                num,
            });
            PPTPathService.setFiletype(
              lobbyeventname,
              "bd",
              "poster2",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: "File Uploaded Successfully!.",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
            window.location.reload(false);
          } else if (upstatus === "failed") {
            $("#loader2").addClass("hidden");
            Swal.fire({
              // position: 'top-end',
              icon: "failure",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
};
 // On file select (from the pop up)
 onFileChange03  = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function () {
    if (this.width != 2428 || this.height != 1668) {
      alert(
        // "Uploaded File Dimension: Width= " +
        //   this.width +
        //   "  Height= " +
        //   this.height +
        "Required FIle Dimension: Width = " + 2428 + " Height= " + 1668
      );
    } else {
      console.log("Inside file dimension are proper loop");
      uploadfile();
      //alert("File Dimensions are Proper");
    }
    _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
    var fileName = "capgemini/" + lobbyeventname + "/bd" + "/poster3.png";

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(file);

    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);

    this.setState({ localuploader: uploader }, () => {
      console.log(
        `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
      );
    });
    $("#loader3").addClass("hidden");
    this.setState({ poster: false });

    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {

            percentage = newPercentage;
            console.log(`${percentage}%`);
            $("#poster4bar").show();
           $(".poster4barbtn").hide();

          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");
            $("#loader3").addClass("hidden");
            this.setState({ poster: true });
            var num = Math.random();

            this.setState({
              Bposter3path:
                AWS_S3_URL +
                "capgemini/" +
                lobbyeventname +
                "/bd" +
                "/poster3.png?v=" +
                num,
            });
            PPTPathService.setFiletype(
              lobbyeventname,
              "bd",
              "poster3",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: "File Uploaded Successfully!.",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
            window.location.reload(false);
          } else if (upstatus === "failed") {
            $("#loader3").addClass("hidden");
            Swal.fire({
              // position: 'top-end',
              icon: "failure",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
};
 // On file select (from the pop up)
 onFileChange04 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function () {
    if (this.width != 1078 || this.height != 612) {
      alert(
        // "Uploaded File Dimension: Width= " +
        //   this.width +
        //   "  Height= " +
        //   this.height +
        "Required FIle Dimension: Width = " + 1078 + " Height= " + 612
      );
    } else {
      console.log("Inside file dimension are proper loop");
      uploadfile();
      //alert("File Dimensions are Proper");
    }
    _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
    var fileName = "capgemini/" + lobbyeventname + "/bd" + "/poster4.png";

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(file);

    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);

    this.setState({ localuploader: uploader }, () => {
      console.log(
        `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
      );
    });
    $("#loader4").addClass("hidden");
    this.setState({ poster: false });

    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {

            percentage = newPercentage;
            console.log(`${percentage}%`);
            $("#poster5bar").show();
           $(".poster5barbtn").hide();

          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");
            $("#loader4").addClass("hidden");
            this.setState({ poster: true });
            var num = Math.random();

            this.setState({
              Bposter4path:
                AWS_S3_URL +
                "capgemini/" +
                lobbyeventname +
                "/bd" +
                "/poster4.png?v=" +
                num,
            });
            PPTPathService.setFiletype(
              lobbyeventname,
              "bd",
              "poster4",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: "File Uploaded Successfully!.",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
            window.location.reload(false);
          } else if (upstatus === "failed") {
            $("#loader4").addClass("hidden");
            Swal.fire({
              // position: 'top-end',
              icon: "failure",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
};
 // On file select (from the pop up)
 onFileChange05 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function () {
    if (this.width != 1096|| this.height != 628) {
      alert(
        // "Uploaded File Dimension: Width= " +
        //   this.width +
        //   "  Height= " +
        //   this.height +
        "Required FIle Dimension: Width = " + 1096 + " Height= " + 628
      );
    } else {
      console.log("Inside file dimension are proper loop");
      uploadfile();
      //alert("File Dimensions are Proper");
    }
    _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
    var fileName = "capgemini/" + lobbyeventname + "/bd" + "/poster5.png";

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(file);

    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);

    this.setState({ localuploader: uploader }, () => {
      console.log(
        `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
      );
    });
    $("#loader5").addClass("hidden");
    this.setState({ poster: false });

    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {

            percentage = newPercentage;
            console.log(`${percentage}%`);
            $("#poster6bar").show();
           $(".poster6barbtn").hide();

          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");
            $("#loader5").addClass("hidden");
            this.setState({ poster: true });
            var num = Math.random();

            this.setState({
              Bposter5path:
                AWS_S3_URL +
                "capgemini/" +
                lobbyeventname +
                "/bd" +
                "/poster5.png?v=" +
                num,
            });
            PPTPathService.setFiletype(
              lobbyeventname,
              "bd",
              "poster5",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: "File Uploaded Successfully!.",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
            window.location.reload(false);
          } else if (upstatus === "failed") {
            $("#loader5").addClass("hidden");
            Swal.fire({
              // position: 'top-end',
              icon: "failure",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
};
 // On file select (from the pop up)
 onFileChange06 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function () {
    if (this.width != 1078 || this.height != 612) {
      alert(
        // "Uploaded File Dimension: Width= " +
        //   this.width +
        //   "  Height= " +
        //   this.height +
        "Required FIle Dimension: Width = " + 1078 + " Height= " + 612
      );
    } else {
      console.log("Inside file dimension are proper loop");
      uploadfile();
      //alert("File Dimensions are Proper");
    }
    _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
    var fileName = "capgemini/" + lobbyeventname + "/bd" + "/poster6.png";

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(file);

    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);

    this.setState({ localuploader: uploader }, () => {
      console.log(
        `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
      );
    });
    $("#loader6").addClass("hidden");
    this.setState({ poster: false });

    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {

            percentage = newPercentage;
            console.log(`${percentage}%`);
            $("#poster7bar").show();
           $(".poster7barbtn").hide();

          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");
            $("#loader6").addClass("hidden");
            this.setState({ poster: true });
            var num = Math.random();

            this.setState({
              Bposter6path:
                AWS_S3_URL +
                "capgemini/" +
                lobbyeventname +
                "/bd" +
                "/poster6.png?v=" +
                num,
            });
            PPTPathService.setFiletype(
              lobbyeventname,
              "bd",
              "poster6",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: "File Uploaded Successfully!.",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
            window.location.reload(false);
          } else if (upstatus === "failed") {
            $("#loader6").addClass("hidden");
            Swal.fire({
              // position: 'top-end',
              icon: "failure",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
};
 // On file select (from the pop up)
 onFileChange07 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function () {
    if (this.width != 1078|| this.height != 612) {
      alert(
        // "Uploaded File Dimension: Width= " +
        //   this.width +
        //   "  Height= " +
        //   this.height +
        "Required FIle Dimension: Width = " + 1078 + " Height= " + 612
      );
    } else {
      console.log("Inside file dimension are proper loop");
      uploadfile();
      //alert("File Dimensions are Proper");
    }
    _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
    var fileName = "capgemini/" + lobbyeventname + "/bd" + "/poster7.png";

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(file);

    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);

    this.setState({ localuploader: uploader }, () => {
      console.log(
        `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
      );
    });
    $("#loader7").addClass("hidden");
    this.setState({ poster: false });

    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {

            percentage = newPercentage;
            console.log(`${percentage}%`);
            $("#poster8bar").show();
           $(".poster8barbtn").hide();

          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");
            $("#loader7").addClass("hidden");
            this.setState({ poster: true });
            var num = Math.random();

            this.setState({
              Bposter7path:
                AWS_S3_URL +
                "capgemini/" +
                lobbyeventname +
                "/bd" +
                "/poster7.png?v=" +
                num,
            });
            PPTPathService.setFiletype(
              lobbyeventname,
              "bd",
              "poster7",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: "File Uploaded Successfully!.",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
            window.location.reload(false);
          } else if (upstatus === "failed") {
            $("#loader7").addClass("hidden");
            Swal.fire({
              // position: 'top-end',
              icon: "failure",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
};
  // On file select (from the pop up)
  onFileChange08 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 1076|| this.height != 728) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 1076 + " Height= " + 728
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/bd" + "/poster8.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader8").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster9bar").show();
             $(".poster9barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader8").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                Bposter8path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/bd" +
                  "/poster8.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "bd",
                "poster8",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader8").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChange09= async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0]);
    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    // img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    // img.src = objectUrl;
    //var fileType = event.target.files[0].type;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    // const eventname = EventService.getCurrentEvent();
    // let uploadfile = () => {
    var fileName =
      "capgemini/" + lobbyeventname + "/bd" + "/poster9" + "." + fileType;

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(fileType);
    console.log(event.target.files);
    console.log(fileType);
    var ctype = fileType;
    if (fileType == "pdf") {
      ctype = "application/pdf";
      console.log("filetype=pdf");
    }
    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      //contentType: ctype,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);
    this.setState({ localuploader: uploader });

    this.setState({ poster: false });
    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            this.setState({ progressbar: newPercentage });
            console.log(`${percentage}%`);
            $("#poster1bar").show();
            $(".modal-container").hide();
          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");

            this.setState({ poster: true });
            var num = Math.random();

            PPTPathService.setFiletype(
              lobbyeventname,
              "bd",
              "poster9",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                // Swal.fire({
                //   // position: 'top-end',
                //   icon: "success",
                //   title: "File Uploaded Successfully!.",
                //   showConfirmButton: false,
                //   timer: 2000,
                // });
               //$(".poster1barbtn").hide();
                $("#poster1bar").hide();
               // $(".modal-container").hide();
                // $(".progress").hide();
                $("#complete").show();

                // window.location.reload();
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
          } else if (upstatus === "failed") {

            Swal.fire({
              // position: 'top-end',
              icon: "danger",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
  onFileChange010 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0]);
    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    // img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    // img.src = objectUrl;
    //var fileType = event.target.files[0].type;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    // const eventname = EventService.getCurrentEvent();
    // let uploadfile = () => {
    var fileName =
      "capgemini/" + lobbyeventname + "/bd" + "/poster10" + "." + fileType;

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(fileType);
    console.log(event.target.files);
    console.log(fileType);
    var ctype = fileType;
    if (fileType == "pdf") {
      ctype = "application/pdf";
      console.log("filetype=pdf");
    }
    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      //contentType: ctype,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);
    this.setState({ localuploader: uploader });

    this.setState({ poster: false });
    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            this.setState({ progressbar: newPercentage });
            console.log(`${percentage}%`);
            $("#poster1bar").show();
            $(".modal-container").hide();
          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");

            this.setState({ poster: true });
            var num = Math.random();

            PPTPathService.setFiletype(
              lobbyeventname,
              "bd",
              "poster10",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                // Swal.fire({
                //   // position: 'top-end',
                //   icon: "success",
                //   title: "File Uploaded Successfully!.",
                //   showConfirmButton: false,
                //   timer: 2000,
                // });
               //$(".poster1barbtn").hide();
                $("#poster1bar").hide();
               // $(".modal-container").hide();
                // $(".progress").hide();
                $("#complete").show();

                // window.location.reload();
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
          } else if (upstatus === "failed") {

            Swal.fire({
              // position: 'top-end',
              icon: "danger",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
  onFileChange011 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0]);
    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    // img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    // img.src = objectUrl;
    //var fileType = event.target.files[0].type;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    // const eventname = EventService.getCurrentEvent();
    // let uploadfile = () => {
    var fileName =
      "capgemini/" + lobbyeventname + "/bd" + "/poster11" + "." + fileType;

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(fileType);
    console.log(event.target.files);
    console.log(fileType);
    var ctype = fileType;
    if (fileType == "pdf") {
      ctype = "application/pdf";
      console.log("filetype=pdf");
    }
    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      //contentType: ctype,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);
    this.setState({ localuploader: uploader });

    this.setState({ poster: false });
    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            this.setState({ progressbar: newPercentage });
            console.log(`${percentage}%`);
            $("#poster1bar").show();
            $(".modal-container").hide();
          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");

            this.setState({ poster: true });
            var num = Math.random();

            PPTPathService.setFiletype(
              lobbyeventname,
              "bd",
              "poster11",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                // Swal.fire({
                //   // position: 'top-end',
                //   icon: "success",
                //   title: "File Uploaded Successfully!.",
                //   showConfirmButton: false,
                //   timer: 2000,
                // });
               //$(".poster1barbtn").hide();
                $("#poster1bar").hide();
               // $(".modal-container").hide();
                // $(".progress").hide();
                $("#complete").show();

                // window.location.reload();
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
          } else if (upstatus === "failed") {

            Swal.fire({
              // position: 'top-end',
              icon: "danger",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
  onFileChange012 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0]);
    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    // img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    // img.src = objectUrl;
    //var fileType = event.target.files[0].type;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    // const eventname = EventService.getCurrentEvent();
    // let uploadfile = () => {
    var fileName =
      "capgemini/" + lobbyeventname + "/bd" + "/poster12" + "." + fileType;

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(fileType);
    console.log(event.target.files);
    console.log(fileType);
    var ctype = fileType;
    if (fileType == "pdf") {
      ctype = "application/pdf";
      console.log("filetype=pdf");
    }
    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      //contentType: ctype,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);
    this.setState({ localuploader: uploader });

    this.setState({ poster: false });
    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            this.setState({ progressbar: newPercentage });
            console.log(`${percentage}%`);
            $("#poster1bar").show();
            $(".modal-container").hide();
          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");

            this.setState({ poster: true });
            var num = Math.random();

            PPTPathService.setFiletype(
              lobbyeventname,
              "bd",
              "poster12",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                // Swal.fire({
                //   // position: 'top-end',
                //   icon: "success",
                //   title: "File Uploaded Successfully!.",
                //   showConfirmButton: false,
                //   timer: 2000,
                // });
               //$(".poster1barbtn").hide();
                $("#poster1bar").hide();
               // $(".modal-container").hide();
                // $(".progress").hide();
                $("#complete").show();

                // window.location.reload();
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
          } else if (upstatus === "failed") {

            Swal.fire({
              // position: 'top-end',
              icon: "danger",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
  onFileChange013 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0]);
    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    // img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    // img.src = objectUrl;
    //var fileType = event.target.files[0].type;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    // const eventname = EventService.getCurrentEvent();
    // let uploadfile = () => {
    var fileName =
      "capgemini/" + lobbyeventname + "/bd" + "/poster13" + "." + fileType;

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(fileType);
    console.log(event.target.files);
    console.log(fileType);
    var ctype = fileType;
    if (fileType == "pdf") {
      ctype = "application/pdf";
      console.log("filetype=pdf");
    }
    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      //contentType: ctype,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);
    this.setState({ localuploader: uploader });

    this.setState({ poster: false });
    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            this.setState({ progressbar: newPercentage });
            console.log(`${percentage}%`);
            $("#poster1bar").show();
            $(".modal-container").hide();
          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");

            this.setState({ poster: true });
            var num = Math.random();

            PPTPathService.setFiletype(
              lobbyeventname,
              "bd",
              "poster13",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                // Swal.fire({
                //   // position: 'top-end',
                //   icon: "success",
                //   title: "File Uploaded Successfully!.",
                //   showConfirmButton: false,
                //   timer: 2000,
                // });
               //$(".poster1barbtn").hide();
                $("#poster1bar").hide();
               // $(".modal-container").hide();
                // $(".progress").hide();
                $("#complete").show();

                // window.location.reload();
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
          } else if (upstatus === "failed") {

            Swal.fire({
              // position: 'top-end',
              icon: "danger",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
  onFileChange014 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0]);
    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    // img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    // img.src = objectUrl;
    //var fileType = event.target.files[0].type;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    // const eventname = EventService.getCurrentEvent();
    // let uploadfile = () => {
    var fileName =
      "capgemini/" + lobbyeventname + "/bd" + "/poster14" + "." + fileType;

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(fileType);
    console.log(event.target.files);
    console.log(fileType);
    var ctype = fileType;
    if (fileType == "pdf") {
      ctype = "application/pdf";
      console.log("filetype=pdf");
    }
    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      //contentType: ctype,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);
    this.setState({ localuploader: uploader });

    this.setState({ poster: false });
    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            this.setState({ progressbar: newPercentage });
            console.log(`${percentage}%`);
            $("#poster1bar").show();
            $(".modal-container").hide();
          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");

            this.setState({ poster: true });
            var num = Math.random();

            PPTPathService.setFiletype(
              lobbyeventname,
              "bd",
              "poster14",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                // Swal.fire({
                //   // position: 'top-end',
                //   icon: "success",
                //   title: "File Uploaded Successfully!.",
                //   showConfirmButton: false,
                //   timer: 2000,
                // });
               //$(".poster1barbtn").hide();
                $("#poster1bar").hide();
               // $(".modal-container").hide();
                // $(".progress").hide();
                $("#complete").show();

                // window.location.reload();
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
          } else if (upstatus === "failed") {

            Swal.fire({
              // position: 'top-end',
              icon: "danger",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };

  onFileChange015 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0]);
    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    // img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    // img.src = objectUrl;
    //var fileType = event.target.files[0].type;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    // const eventname = EventService.getCurrentEvent();
    // let uploadfile = () => {
    var fileName =
      "capgemini/" + lobbyeventname + "/bd" + "/poster15" + "." + fileType;

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(fileType);
    console.log(event.target.files);
    console.log(fileType);
    var ctype = fileType;
    if (fileType == "pdf") {
      ctype = "application/pdf";
      console.log("filetype=pdf");
    }
    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      //contentType: ctype,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);
    this.setState({ localuploader: uploader });

    this.setState({ poster: false });
    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            this.setState({ progressbar: newPercentage });
            console.log(`${percentage}%`);
            $("#poster1bar").show();
            $(".modal-container").hide();
          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");

            this.setState({ poster: true });
            var num = Math.random();

            PPTPathService.setFiletype(
              lobbyeventname,
              "bd",
              "poster15",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                // Swal.fire({
                //   // position: 'top-end',
                //   icon: "success",
                //   title: "File Uploaded Successfully!.",
                //   showConfirmButton: false,
                //   timer: 2000,
                // });
               //$(".poster1barbtn").hide();
                $("#poster1bar").hide();
               // $(".modal-container").hide();
                // $(".progress").hide();
                $("#complete").show();

                // window.location.reload();
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
          } else if (upstatus === "failed") {

            Swal.fire({
              // position: 'top-end',
              icon: "danger",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
  onFileChange016 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0]);
    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    // img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    // img.src = objectUrl;
    //var fileType = event.target.files[0].type;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    // const eventname = EventService.getCurrentEvent();
    // let uploadfile = () => {
    var fileName =
      "capgemini/" + lobbyeventname + "/bd" + "/poster16" + "." + fileType;

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(fileType);
    console.log(event.target.files);
    console.log(fileType);
    var ctype = fileType;
    if (fileType == "pdf") {
      ctype = "application/pdf";
      console.log("filetype=pdf");
    }
    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      //contentType: ctype,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);
    this.setState({ localuploader: uploader });

    this.setState({ poster: false });
    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            this.setState({ progressbar: newPercentage });
            console.log(`${percentage}%`);
            $("#poster1bar").show();
            $(".modal-container").hide();
          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");

            this.setState({ poster: true });
            var num = Math.random();

            PPTPathService.setFiletype(
              lobbyeventname,
              "bd",
              "poster16",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                // Swal.fire({
                //   // position: 'top-end',
                //   icon: "success",
                //   title: "File Uploaded Successfully!.",
                //   showConfirmButton: false,
                //   timer: 2000,
                // });
               //$(".poster1barbtn").hide();
                $("#poster1bar").hide();
               // $(".modal-container").hide();
                // $(".progress").hide();
                $("#complete").show();

                // window.location.reload();
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
          } else if (upstatus === "failed") {

            Swal.fire({
              // position: 'top-end',
              icon: "danger",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };

  static displayName = "Pano";
  componentDidMount() {
    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const filepath = "capgemini/" + lobbyeventname + "/bd" + "/Bposter";
    console.log(`filepath= ${filepath}`);
    this.setState({
      Bposter1path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/bd" + "/poster1.png?v=" +
      num,
    Bposter2path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/bd" + "/poster2.png?v=" +
      num,
    Bposter3path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/bd" + "/poster3.png?v=" +
      num,
    Bposter4path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/bd" + "/poster4.png?v=" +
      num,
    Bposter5path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/bd" + "/poster5.png?v=" +
      num,
    Bposter6path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/bd" + "/poster6.png?v=" +
      num,
    Bposter7path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/bd" + "/poster7.png?v=" +
      num,
    Bposter8path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/bd" + "/poster8.png?v=" +
      num,
    Bposter9path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/bd" + "/poster9.pptx?v=" +
      num,
      Bposter10path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/bd" + "/poster10.pptx?v=" +
      num,
      Bposter11path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/bd" + "/poster11.pptx?v=" +
      num,
      Bposter12path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/bd" + "/poster12.pptx?v=" +
      num,
      Bposter13path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/bd" + "/poster13.pptx?v=" +
      num,
      Bposter14path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/bd" + "/poster14.pptx?v=" +
      num,
      Bposter15path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/bd" + "/poster15.pptx?v=" +
      num,
      Bposter16path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/bd" + "/poster16.pptx?v=" +
      num,
      poster1: true,
      poster2: true,
      poster3: true,
      poster4: true,
      poster5: true,
      poster6: true,
      poster7: true,
      poster8: true,
      poster9: true,
      poster10: true,
      poster11: true,
      poster12: true,
      poster13: true,
      poster14: true,
      poster15: true,
      poster16: true,

    })
    $(document).on("click", "#pptupload", function(){

      const { value: text }= Swal.fire({
        input: 'text',
        inputLabel: ' LINK',
        inputPlaceholder: 'copy the link',
      }).then((text) => {
        if (text.isConfirmed) {
          let data=Object.entries(text)[3][1];
          var parser = new DOMParser();
          var parsedIframe = parser.parseFromString(data, "text/html");
           let iFrame = parsedIframe.getElementsByTagName("iframe");
           let src = iFrame[0].src;
          console.log(src);
          Swal.fire(src);
        PPTPathService.setFiletype(
        lobbyeventname,
        "bd",
        "poster9",
        // fileType,
        "ppt",
       src
        //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
      ) .then((result) => {
        console.log(result);
        console.log(
          "Uploaded successfully........................................"
        );

      })
      .catch(text => {
        if (text.isDenied) {
          Swal.fire('required input');
        }
      })

        }
      // .catch((err) => {
      //   UnauthorizedService.unauthorized(err);
      // })
    }
      )


   });
   $(document).on("click", "#pptupload1", function(){

    const { value: text }= Swal.fire({
      input: 'text',
      inputLabel: ' LINK',
      inputPlaceholder: 'copy the link',
    }).then((text) => {
      if (text.isConfirmed) {
        let data=Object.entries(text)[3][1];
        var parser = new DOMParser();
        var parsedIframe = parser.parseFromString(data, "text/html");
         let iFrame = parsedIframe.getElementsByTagName("iframe");
         let src = iFrame[0].src;
        console.log(src);
        Swal.fire(src);
      PPTPathService.setFiletype(
      lobbyeventname,
      "bd",
      "poster10",
      // fileType,
      "ppt",
     src
      //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
    ) .then((result) => {
      console.log(result);
      console.log(
        "Uploaded successfully........................................"
      );

    })
    .catch(text => {
      if (text.isDenied) {
        Swal.fire('required input');
      }
    })

      }
    // .catch((err) => {
    //   UnauthorizedService.unauthorized(err);
    // })
  }
    )


 });
 $(document).on("click", "#pptupload2", function(){

  const { value: text }= Swal.fire({
    input: 'text',
    inputLabel: ' LINK',
    inputPlaceholder: 'copy the link',
  }).then((text) => {
    if (text.isConfirmed) {
      let data=Object.entries(text)[3][1];
      var parser = new DOMParser();
      var parsedIframe = parser.parseFromString(data, "text/html");
       let iFrame = parsedIframe.getElementsByTagName("iframe");
       let src = iFrame[0].src;
      console.log(src);
      Swal.fire(src);
    PPTPathService.setFiletype(
    lobbyeventname,
    "bd",
    "poster11",
    // fileType,
    "ppt",
   src
    //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
  ) .then((result) => {
    console.log(result);
    console.log(
      "Uploaded successfully........................................"
    );

  })
  .catch(text => {
    if (text.isDenied) {
      Swal.fire('required input');
    }
  })

    }
  // .catch((err) => {
  //   UnauthorizedService.unauthorized(err);
  // })
}
  )


});
$(document).on("click", "#pptupload3", function(){

  const { value: text }= Swal.fire({
    input: 'text',
    inputLabel: ' LINK',
    inputPlaceholder: 'copy the link',
  }).then((text) => {
    if (text.isConfirmed) {
      let data=Object.entries(text)[3][1];
      var parser = new DOMParser();
      var parsedIframe = parser.parseFromString(data, "text/html");
       let iFrame = parsedIframe.getElementsByTagName("iframe");
       let src = iFrame[0].src;
      console.log(src);
      Swal.fire(src);
    PPTPathService.setFiletype(
    lobbyeventname,
    "bd",
    "poster12",
    // fileType,
    "ppt",
   src
    //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
  ) .then((result) => {
    console.log(result);
    console.log(
      "Uploaded successfully........................................"
    );

  })
    }
  // .catch((err) => {
  //   UnauthorizedService.unauthorized(err);
  // })
}
  )


});
$(document).on("click", "#pptupload4", function(){

  const { value: text }= Swal.fire({
    input: 'text',
    inputLabel: ' LINK',
    inputPlaceholder: 'copy the link',
  }).then((text) => {
    if (text.isConfirmed) {
      let data=Object.entries(text)[3][1];
      var parser = new DOMParser();
      var parsedIframe = parser.parseFromString(data, "text/html");
       let iFrame = parsedIframe.getElementsByTagName("iframe");
       let src = iFrame[0].src;
      console.log(src);
      Swal.fire(src);
    PPTPathService.setFiletype(
    lobbyeventname,
    "bd",
    "poster13",
    // fileType,
    "ppt",
   src
    //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
  ) .then((result) => {
    console.log(result);
    console.log(
      "Uploaded successfully........................................"
    );

  })
  .catch(text => {
    if (text.isDenied) {
      Swal.fire('required input');
    }
  })

    }
  // .catch((err) => {
  //   UnauthorizedService.unauthorized(err);
  // })
}
  )


});
$(document).on("click", "#pptupload5", function(){

  const { value: text }= Swal.fire({
    input: 'text',
    inputLabel: ' LINK',
    inputPlaceholder: 'copy the link',
  }).then((text) => {
    if (text.isConfirmed) {
      let data=Object.entries(text)[3][1];
      var parser = new DOMParser();
      var parsedIframe = parser.parseFromString(data, "text/html");
       let iFrame = parsedIframe.getElementsByTagName("iframe");
       let src = iFrame[0].src;
      console.log(src);
      Swal.fire(src);
    PPTPathService.setFiletype(
    lobbyeventname,
    "bd",
    "poster14",
    // fileType,
    "ppt",
    src
    //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
  ) .then((result) => {
    console.log(result);
    console.log(
      "Uploaded successfully........................................"
    );

  })
  .catch(text => {
    if (text.isDenied) {
      Swal.fire('required input');
    }
  })

    }
  // .catch((err) => {
  //   UnauthorizedService.unauthorized(err);
  // })
}
  )


});
$(document).on("click", "#pptupload6", function(){

  const { value: text }= Swal.fire({
    input: 'text',
    inputLabel: ' LINK',
    inputPlaceholder: 'copy the link',
  }).then((text) => {
    if (text.isConfirmed) {
      let data=Object.entries(text)[3][1];
      var parser = new DOMParser();
      var parsedIframe = parser.parseFromString(data, "text/html");
       let iFrame = parsedIframe.getElementsByTagName("iframe");
       let src = iFrame[0].src;
      console.log(src);
      Swal.fire(src);
    PPTPathService.setFiletype(
    lobbyeventname,
    "bd",
    "poster15",
    // fileType,
    "ppt",
    src
    //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
  ) .then((result) => {
    console.log(result);
    console.log(
      "Uploaded successfully........................................"
    );

  })
  .catch(text => {
    if (text.isDenied) {
      Swal.fire('required input');
    }
  })

    }
  // .catch((err) => {
  //   UnauthorizedService.unauthorized(err);
  // })
}
  )


});
$(document).on("click", "#pptupload7", function(){

  const { value: text }= Swal.fire({
    input: 'text',
    inputLabel: ' LINK',
    inputPlaceholder: 'copy the link',
  }).then((text) => {
    if (text.isConfirmed) {
      let data=Object.entries(text)[3][1];
      var parser = new DOMParser();
      var parsedIframe = parser.parseFromString(data, "text/html");
       let iFrame = parsedIframe.getElementsByTagName("iframe");
       let src = iFrame[0].src;
      console.log(src);
      Swal.fire(src);
    PPTPathService.setFiletype(
    lobbyeventname,
    "bd",
    "poster16",
    // fileType,
    "ppt",
    src
    //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
  ) .then((result) => {
    console.log(result);
    console.log(
      "Uploaded successfully........................................"
    );

  })
  .catch(text => {
    if (text.isDenied) {
      Swal.fire('required input');
    }
  })

    }
  // .catch((err) => {
  //   UnauthorizedService.unauthorized(err);
  // })
}
  )


});

    this.viewer = new Marzipano.Viewer(this.pano); //, viewerOpts);
    // Create source.
    const source = Marzipano.ImageUrlSource.fromString("https://d2e8va4puks5pl.cloudfront.net/boardroom.jpg");

    // Create geometry.
    const geometry = new Marzipano.EquirectGeometry([{ width: 5000 }]);
    const limiter = Marzipano.RectilinearView.limit.traditional(
      4*1024,
      (100 * Math.PI) / 180
    );
    const view = new Marzipano.RectilinearView({ yaw: Math.PI }, limiter);

    // Get the view values

    var yaw = view.yaw();
    var pitch = view.pitch();
    var fov = view.fov(); // fov is horizontal
    /*var vfov = view.vfov();
var hfov = view.hfov();    // same as view.fov()*/

    // Change the values

    view.setYaw((0 * Math.PI) / 180);
    view.setPitch((0 * Math.PI) / 180);
    view.setFov((120 * Math.PI) / 180);

    const scene = this.viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      pinFirstLevel: true,
    });

    scene.switchTo();

    // Get the hotspot container for scene.
    var container = scene.hotspotContainer();

    container.createHotspot(
      this.poster1,
      { yaw: 0.131, pitch: -0.13 },
      {
        perspective: {
          radius: 2100,
          extraTransforms: "rotateX(5deg) rotateY(17deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster2,
      { yaw: 0.634, pitch: -0.12 },
      {
        perspective: {
          radius: 1290,
          extraTransforms: "rotateX(4deg) rotateY(-50deg) rotateZ(-2deg)",
        },
      }
    );
    container.createHotspot(
      this.poster3,
      { yaw: 1.58, pitch: -0.068 },
      {
        perspective: {
          radius: 1490,
          extraTransforms: "rotateX(5deg) rotateY(0deg) rotateZ(0deg)",
        },
      }
    );
    // container.createHotspot(
    //   this.poster4,
    //   { yaw: 2.625, pitch: -0.001 },
    //   {
    //     perspective: {
    //       radius: 1190,
    //       extraTransforms: "rotateX(0deg) rotateY(-20deg) rotateZ(0deg)",
    //     },
    //   }
    // );
    container.createHotspot(
      this.poster5,
      { yaw: 3.58, pitch: -0.050 },
      {
        perspective: {
          radius: 1680,
          extraTransforms: "rotateX(4deg) rotateY(22deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster6,
      { yaw: 4.239, pitch: -0.059 },
      {
        perspective: {
          radius: 1350,
          extraTransforms: "rotateX(4deg) rotateY(-20deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster7,
      { yaw: 5.126, pitch: -0.0644 },
      {
        perspective: {
          radius: 1430,
          extraTransforms: "rotateX(5deg) rotateY(20deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster8,
      {  yaw: -0.76,
       pitch: -0.058 },
      {
        perspective: {
          radius: 1960,
          extraTransforms: "rotateX(5deg) rotateY(40deg) rotateZ(0deg)",
        },
      }
    );
    scene.hotspotContainer().createHotspot(document.querySelector("#pptview"), {
      yaw: 0.21,
      pitch: -0.1,
    });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview1"), {
        yaw: 0.6,
        pitch: -0.1,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview2"), {
        yaw: 1.53,
        pitch: -0.05,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview3"), {
        yaw: 2.58,
        pitch: -0.09,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview4"), {
        yaw: 3.54,
        pitch: -0.01,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview5"), {
        yaw: -0.76,
        pitch: -0.01,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview13"), {
        yaw: -2.065,
        pitch: -0.01,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview14"), {
        yaw: -1.2,
        pitch: -0.01,
      });
  }
  render() {
    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }
    function hideImg3() {
      document.getElementById("myImg3").style.display = "none";
    }
    function hideImg4() {
      document.getElementById("myImg4").style.display = "none";
    }
    function hideImg5() {
      document.getElementById("myImg5").style.display = "none";
    }
    function hideImg6() {
      document.getElementById("myImg6").style.display = "none";
    }
    function hideImg7() {
      document.getElementById("myImg7").style.display = "none";
    }
    function hideImg8() {
      document.getElementById("myImg8").style.display = "none";
    }
    const{
      poster1,
      poster2,
      poster3,
      poster4,
      poster5,
      poster6,
      poster7,
      poster8,
      poster9,
      Bposter1path,
      Bposter2path,
      Bposter3path,
      Bposter4path,
      Bposter5path,
      Bposter6path,
      Bposter7path,
      Bposter8path,
      Bposter9path,
      Bposter10path,
      Bposter11path,
      Bposter12path,
      Bposter13path,
      Bposter14path,
      Bposter15path,
      Bposter16path,
      progressbar,

    }=this.state;
    return (
      <div className="pano-container" ref={(pano) => (this.pano = pano)}>
       <div id="Boardposter01" ref={(poster1) => (this.poster1 = poster1)}>
       <div id="poster2bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster2barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete2">
            <button
                className="btn btn-xl complete2"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster1 && (
            <form encType="multipart/form-data">
              <div id="richList"></div>

              <div id="loader1" className="lds-dual-ring hidden overlay"></div>
              <div  id="Bmap1">
              <img
                src={ Bposter1path}
                //src={bp1}
                style={{ cursor: "pointer" }}
                onError={() => hideImg1()}
                id="myImg1"
              /></div>
              <input
                ref={this.imgRef}
                type="file"
                name="file"
                id="posterimg1"
                accept="image/png, image/gif, image/jpeg"
                onChange={this.onFileChange01}
                style={{ display: "none" }}
              />
            </form>
          )}
          </label>
        </div>
         <div id="Boardposter02" ref={(poster2) => (this.poster2 = poster2)}>
         <div id="poster3bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster3barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete3">
            <button
                className="btn btn-xl complete3"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster2 && (

            <form enctype="multipart/form-data">
              <div id="richList"></div>

              <div id="loader2" className="lds-dual-ring hidden overlay"></div>
              <div  id="Bmap2">
              <img
                  src={ Bposter2path}
                 //src={bp2}
                style={{ cursor: "pointer" }}
                onError={() => hideImg2()}
                id="myImg2"
              /></div>
              <input
                ref={this.imgRef}
                type="file"
                name="file"
                id="posterimg1"
                accept="image/png, image/gif, image/jpeg"
                onChange={this.onFileChange02}
                style={{ display: "none" }}
              />
            </form>
          )}
          </label>
        </div>

        <div id="Boardposter03" ref={(poster3) => (this.poster3 = poster3)}>
        <div id="poster4bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster4barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete4">
            <button
                className="btn btn-xl complete4"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster3 && (
            <form enctype="multipart/form-data">
              <div id="richList"></div>
              <div id="loader3" className="lds-dual-ring hidden overlay"></div>
              <div id="Bmap3">
              <img
                src={ Bposter3path}
                style={{ cursor: "pointer" }}
                onError={() => hideImg3()}
                id="myImg3"
              />
              </div>
              <input
                ref={this.imgRef}
                type="file"
                name="file"
                id="posterimg1"
                accept="image/png, image/gif, image/jpeg"
                onChange={this.onFileChange03}
                style={{ display: "none" }}
              />
            </form>
          )}
          </label>
        </div>
        {/* <div id="Boardposter04" ref={(poster4) => (this.poster4 = poster4)}>
          <label>
          {poster4 && (
            <form enctype="multipart/form-data">
              <div id="richList"></div>

              <div id="loader4" className="lds-dual-ring hidden overlay"></div>
              <div id="Bmap4">
                <img src={Bposter4path}   style={{ cursor: "pointer" }}  onError={() => hideImg4()}
                    id="myImg4" />
              </div>
              <input
                ref={this.imgRef}
                type="file"
                name="file"
                id="posterimg1"

                accept="image/png, image/gif, image/jpeg"
                onChange={this.onFileChange04}
                style={{ display: "none" }}
              />
            </form>
          )}
          </label>
        </div> */}

        <div id="Boardposter05" ref={(poster5) => (this.poster5 = poster5)}>
        <div id="poster6bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster6barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete6">
            <button
                className="btn btn-xl complete6"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster5 && (

            <form enctype="multipart/form-data">
              <div id="richList"></div>

              <div id="loader5" className="lds-dual-ring hidden overlay"></div>
              <div  id="Bmap5">
              <img
                src={ Bposter5path}
                style={{ cursor: "pointer" }}
                onError={() => hideImg5()}
                id="myImg5"
              /></div>
              <input
                ref={this.imgRef}
                type="file"
                name="file"
                id="posterimg1"
                accept="image/png, image/gif, image/jpeg"
                onChange={this.onFileChange05}
                style={{ display: "none" }}
              />
            </form>
          )}
          </label>
        </div>
        <div id="Boardposter06" ref={(poster6) => (this.poster6 = poster6)}>
        <div id="poster7bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster7barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete7">
            <button
                className="btn btn-xl complete7"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster6 && (
            <form enctype="multipart/form-data">
              <div id="richList"></div>
              <div id="loader6" className="lds-dual-ring hidden overlay"></div>
              <div id="Bmap6">
                <img src={Bposter6path} style={{ cursor: "pointer" }}  onError={() => hideImg6()}
                    id="myImg6" />
              </div>
              <input
                ref={this.imgRef}
                type="file"
                name="file"
                id="posterimg2"
                accept="image/png, image/gif, image/jpeg"
                onChange={this.onFileChange06}
                style={{ display: "none" }}
              />
            </form>
          )}
          </label>
        </div>
        <div id="Boardposter07" ref={(poster7) => (this.poster7 = poster7)}>
        <div id="poster8bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster8barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete8">
            <button
                className="btn btn-xl complete8"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster7 && (
            <form enctype="multipart/form-data">
              <div id="richList"></div>

              <div id="loader7" className="lds-dual-ring hidden overlay"></div>
              <div id="Bmap7">
                <img src={Bposter7path} style={{ cursor: "pointer" }}  onError={() => hideImg7()}
                    id="myImg7" />
              </div>
              <input
                ref={this.imgRef}
                type="file"
                name="file"
                id="posterimg3"
                accept="image/png, image/gif, image/jpeg"
                onChange={this.onFileChange07}
                style={{ display: "none" }}
              />
            </form>
          )}
          </label>
        </div>

        <div id="Boardposter08" ref={(poster8) => (this.poster8 = poster8)}>
        <div id="poster9bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster9barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete9">
            <button
                className="btn btn-xl complete9"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster8 && (

            <form enctype="multipart/form-data">
              <div id="richList"></div>

              <div id="loader8" className="lds-dual-ring hidden overlay"></div>
              <div id="Bmap8">
              <img
                // src={board8}
                src={ Bposter8path}
                style={{ cursor: "pointer" }}
                onError={() => hideImg8()}
                    id="myImg8"
              /></div>
              <input
                ref={this.imgRef}
                type="file"
                name="file"
                id="posterimg1"
                accept="image/png, image/gif, image/jpeg"
                onChange={this.onFileChange08}
                style={{ display: "none" }}
              />
            </form>
          )}
          </label>
        </div>
        <div id="pptview">
          <div className="hotspot">
            <div className="in"   onClick={this.P1openModal}>

            </div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen}
                  style={customStyles}
                  onRequestClose={this.P1closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput11.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput22.click()}>Upload Video  </button>
                  <div id="loader9" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>

          <input
            ref={(ref) => (this.myInput11 = ref)}
            type="file"
            name="file"
            id="pptv1"
            accept=".pdf"
            onChange={this.onFileChange09}
            style={{ display: "none" }}
          />
           <input
            ref={(ref) => (this.myInput22 = ref)}
            type="file"
            name="file"
            id="pptv1"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange09}
            style={{ display: "none" }}
          />
        </div>

        <div id="pptview1">
          <div className="hotspot1">
            <div className="in1" onClick={this.P2openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modal2IsOpen}
                  style={customStyles}
                  onRequestClose={this.P2closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P2closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P2closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P2closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload1" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput23.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput24.click()}>Upload Video  </button>
                  <div id="loader10" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>
          <div id="loader10" className="lds-dual-ring hidden overlay"></div>
          <input
            ref={(ref) => (this.myInput23 = ref)}
            type="file"
            name="file"
            id="pptv2"
            accept=".pdf"
            onChange={this.onFileChange010}
            style={{ display: "none" }}
          />
           <input
            ref={(ref) => (this.myInput24 = ref)}
            type="file"
            name="file"
            id="pptv2"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange010}
            style={{ display: "none" }}
          />
        </div>

        <div id="pptview2">
          <div className="hotspot2">
            <div className="in2" onClick={this.P3openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modal3IsOpen}
                  style={customStyles}
                  onRequestClose={this.P3closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P3closeModal}>
                    x
                  </span>

                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P3closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P3closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload2" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput25.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput26.click()}>Upload Video  </button>
                  <div id="loader11" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>

          <input
            ref={(ref) => (this.myInput25 = ref)}
            type="file"
            name="file"
            id="pptv2"
            accept=".pdf"
            onChange={this.onFileChange011}
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput26 = ref)}
            type="file"
            name="file"
            id="pptv2"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange011}
            style={{ display: "none" }}
          />
        </div>
        <div id="pptview3">
          <div className="hotspot3">
            {/* <div className="out"></div> */}
            <div className="in3" onClick={this.P4openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modal4IsOpen}
                  style={customStyles}
                  onRequestClose={this.P4closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P4closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P4closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P4closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload3" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput27.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput28.click()}>Upload Video  </button>
                  <div id="loader12" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput27 = ref)}
            type="file"
            name="file"
            id="pptv2"
            accept=".pdf"
            onChange={this.onFileChange012}
            style={{ display: "none" }}
          />
           <input
            ref={(ref) => (this.myInput28 = ref)}
            type="file"
            name="file"
            id="pptv2"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange012}
            style={{ display: "none" }}
          />
        </div>

        <div id="pptview4">
          <div className="hotspot4">
            {/* <div className="out"></div> */}
            <div className="in4"  onClick={this.P5openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modal5IsOpen}
                  style={customStyles}
                  onRequestClose={this.P5closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P5closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P5closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P5closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload4" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput29.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput299.click()}>Upload Video  </button>
                  <div id="loader13" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput29 = ref)}
            type="file"
            name="file"
            id="pptv2"
            accept=".pdf"
            onChange={this.onFileChange013}
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput299 = ref)}
            type="file"
            name="file"
            id="pptv2"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange013}
            style={{ display: "none" }}
          />
        </div>
        <div id="pptview5">
          <div className="hotspot5">
            <div className="in5" onClick={this.P6openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modal6IsOpen}
                  style={customStyles}
                  onRequestClose={this.P6closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P6closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P6closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P6closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload5" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput31.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput32.click()}>Upload Video  </button>
                  <div id="loader14" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput31 = ref)}
            type="file"
            name="file"
            id="pptv2"
            accept=".pdf"
            onChange={this.onFileChange014}
            style={{ display: "none" }}
          />
           <input
            ref={(ref) => (this.myInput32 = ref)}
            type="file"
            name="file"
            id="pptv2"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange014}
            style={{ display: "none" }}
          />
        </div>
        <div id="pptview13">
          <div className="hotspot13">
            <div className="in13" onClick={this.P7openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modal7IsOpen}
                  style={customStyles}
                  onRequestClose={this.P7closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P7closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P7closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P7closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload6" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput41.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput42.click()}>Upload Video  </button>
                  <div id="loader15" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput41 = ref)}
            type="file"
            name="file"
            id="pptv2"
            accept=".pdf"
            onChange={this.onFileChange015}
            style={{ display: "none" }}
          />
           <input
            ref={(ref) => (this.myInput42 = ref)}
            type="file"
            name="file"
            id="pptv2"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange015}
            style={{ display: "none" }}
          />
        </div>

        <div id="pptview14">
          <div className="hotspot14">
            <div className="in14" onClick={this.P8openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modal8IsOpen}
                  style={customStyles}
                  onRequestClose={this.P8closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P8closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P8closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P8closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload7" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput61.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput62.click()}>Upload Video  </button>
                  <div id="loader16" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput61 = ref)}
            type="file"
            name="file"
            id="pptv2"
            accept=".pdf"
            onChange={this.onFileChange016}
            style={{ display: "none" }}
          />
           <input
            ref={(ref) => (this.myInput62 = ref)}
            type="file"
            name="file"
            id="pptv2"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange016}
            style={{ display: "none" }}
          />
        </div>


      </div>
    );
  }
}

export default Boardroomedit1;
