import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import stall1 from "./img/VR-02/VR-2 4 GRIDS.jpg";
import image from "./img/rs1.png";
import image1 from "./img/rs2.png";
import Modal from "react-modal";
import AWSService from "../services/aws.service";
import EventService from "../services/event.service";
import PPTPathService from "../services/pptpath.service";
import UnAuthorizedService from "../common/unauthorized";
import AuthService from "../services/auth.service";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./styles/maping.css";
import $ from "jquery";
import "../App.css";
var lobbyeventname = "";
let tenant = "";
const { aws_s3_url, aws_s3_bucket_name } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "70vh",
    marginRight: "-50%",
    width: "calc(60% - 0px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    border: "unset !important",
    margintop: "calc(85vh - 10vh - 10px)",
    zIndex: "99999",
    backgroundcolor: "unset !important",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(50% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding: "0 0 0 0",
  },
};
class Resorce3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initially, no file is selected
      selectedFile: null,
      poster1: false,
      poster2: false,
      poster3: false,
      poster4: false,
      poster3apdf: false,
      poster3bpdf: false,
      poster3cpdf: false,



      poster6: false,
      poster1r: false,
      poster1r1: false,
      poster1r2: false,
      poster31g1:false,
      poster31g2:false,

      poster31g3:false,





      R3poster1path : "",
      R3poster2path : "",
      R3poster3path : "",
      R3poster4path : "",
      R3poster3apdfpath : "",
      R3poster3bpdfpath : "",
      R3poster3cpdfpath : "",





      R3poster6path : "",
      R3poster1rpath : "",
      R3poster1r1path : "",
      R3poster1r2path : "",

      R3poster31g1path : "",
      R3poster31g2path : "",
      R3poster31g3path : "",





      R3poster3type: "",
      R3poster31g1type: "",
      R3poster31g2type: "",
      R3poster31g3type: "",



      R3poster4type: "",
      R3poster3apdftype: "",
      R3poster3bpdftype: "",
      R3poster3cpdftype: "",



      modalIsOpen: false,
      modal1IsOpen: false,
      modal2IsOpen: false,
      tenantname: tenant,
    };
  }

  P1openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  P1closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  P2openModal = () => {
    this.setState({ modal1IsOpen: true });
  };

  P2closeModal = () => {
    this.setState({ modal1IsOpen: false });
  };
  P3openModal = () => {
    this.setState({ modal2IsOpen: true });
  };

  P3closeModal = () => {
    this.setState({ modal2IsOpen: false });
  };


  P1openModal3 = () => {
    this.setState({ modalIsOpen3: true });
  };

  P1closeModal3 = () => {
    this.setState({ modalIsOpen3: false });
  };

  P2openModal3 = () => {
    this.setState({ modal1IsOpen3: true });
  };

  P2closeModal3 = () => {
    this.setState({ modal1IsOpen3: false });
  };
  P3openModal3 = () => {
    this.setState({ modal2IsOpen3: true });
  };

  P3closeModal3 = () => {
    this.setState({ modal2IsOpen3: false });
  };



  P1openModal4 = () => {
    this.setState({ modalIsOpen4: true });
  };

  P1closeModal4 = () => {
    this.setState({ modalIsOpen4: false });
  };

  P2openModal4 = () => {
    this.setState({ modal1IsOpen4: true });
  };

  P2closeModal4 = () => {
    this.setState({ modal1IsOpen4: false });
  };
  P3openModal4 = () => {
    this.setState({ modal2IsOpen4: true });
  };

  P3closeModal4 = () => {
    this.setState({ modal2IsOpen4: false });
  };


  P1openModal5 = () => {
    this.setState({ modalIsOpen5: true });
  };

  P1closeModal5 = () => {
    this.setState({ modalIsOpen5: false });
  };

  P2openModal5 = () => {
    this.setState({ modal1IsOpen5: true });
  };

  P2closeModal5 = () => {
    this.setState({ modal1IsOpen5: false });
  };
  P3openModal5 = () => {
    this.setState({ modal2IsOpen5: true });
  };

  P3closeModal5 = () => {
    this.setState({ modal2IsOpen5: false });
  };
  componentDidMount() {

    localStorage.setItem("intro", 0);
    const eventname = EventService.getCurrentEvent();
    console.log(eventname);
    var num = Math.random();
    PPTPathService.getFiletype(eventname, "Rs3")
      .then((response) => {
        response.data.forEach((filedetails) => {
          if (filedetails.filename === "poster3") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R3poster3path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3" +
                "/poster3" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster3: true,
            });
            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R3poster3path:filedetails.path,
                  poster3: true,

                });
              this.setState({ R3poster3type: "txt" });
            }
          }
          if (filedetails.filename === "poster31g1") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R3poster31g1path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3" +
              "/poster31g1" +
              "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster31g1: true,
            });
            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R3poster31g1path:filedetails.path,
                  poster31g1: true,

                });
              this.setState({ R3poster31g1type: "txt" });
            }
          }
          if (filedetails.filename === "poster31g2") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R3poster31g2path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3" +
                "/poster31g2" +
                "."  +
                filedetails.filetype +
                "?v=" +
                num,
              poster31g2: true,
            });
            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R3poster31g2path:filedetails.path,
                  poster31g2: true,

                });
              this.setState({ R3poster31g2type: "txt" });
            }
          }

          if (filedetails.filename === "poster31g3") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R3poster31g3path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3" +
                "/poster31g3" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster31g3: true,
            });
            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R3poster31g3path:filedetails.path,
                  poster31g3: true,

                });
              this.setState({ R3poster31g3type: "txt" });
            }
          }



        if (filedetails.filename == "poster4") {
            this.setState({
              R3poster4path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3" +
                "/poster4" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster4: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R3poster4type: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R3poster4path:filedetails.path,
                  poster4: true,

                });
                this.setState({ R3poster4type: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R3poster4type: "pdf" });
              }
            }
            console.log(this.state.R3poster4path);
          }
          if (filedetails.filename == "poster3apdf") {
            this.setState({
              R3poster3apdfpath:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3" +
                "/poster3apdf" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
                poster3apdf: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R3poster3apdftype: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R3poster3apdfpath:filedetails.path,
                  poster3apdf: true,

                });
                this.setState({ R3poster3apdftype: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R3poster3apdftype: "pdf" });
              }
            }
            console.log(this.state.R3poster3apdfpath);
          }


          if (filedetails.filename == "poster3bpdf") {
            this.setState({
              R3poster3bpdfpath:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3" +
                "/poster3bpdf" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
                poster3bpdf: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R3poster3bpdftype: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R3poster3bpdfpath:filedetails.path,
                  poster3bpdf: true,

                });
                this.setState({ R3poster3bpdftype: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R3poster3bpdftype: "pdf" });
              }
            }
            console.log(this.state.R3poster3bpdfpath);
          }
          if (filedetails.filename == "poster3cpdf") {
            this.setState({
              R3poster3cpdfpath:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs3" +
                "/poster3cpdf" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
                poster3cpdf: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R3poster3cpdftype: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R3poster3cpdfpath:filedetails.path,
                  poster3cpdf: true,

                });
                this.setState({ R3poster3cpdftype: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R3poster3cpdftype: "pdf" });
              }
            }
            console.log(this.state.R3poster3cpdfpath);
          }

        })
      })

      .catch((err) => {
        UnAuthorizedService.unauthorized(err);
      });

    EventService.getSpecificEvent()
    .then((response) => {
      console.log("Eventname from getSpecificEvent");
      console.log(response.data);
      console.log(response.data.eventname);
      const eventpath = "/" + response.data.eventname;
      this.setState({ tenantname: eventpath, showLoginPage: true });
      localStorage.setItem("eventname", response.data.eventname);
      console.log(response.data.eventname);

      const user=AuthService.getCurrentUser();

      AuthService.getCurrentRole().then((roles)=>{
        if (roles) {
          console.log("Got current role from server");
          this.setState({
            currentUser: user,
            showModeratorBoard: roles.data.includes("ROLE_MODERATOR"),
            showAdminBoard: roles.data.includes("ROLE_ADMIN"),
            showAddTenant: roles.data.includes("ROLE_SUPERADMIN"),
            showAddUser: roles.data.includes("ROLE_SUPERADMIN"),
            showRegModerators:
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showRegAdmins:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN"),
            showRegSuperAdmins: roles.data.includes("ROLE_SUPERADMIN"),
            showRegUsers:
              roles.data.includes("ROLE_MODERATOR") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showCreateEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showDetailLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showFeedbackReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEdit:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showNormalUser: roles.data.includes("ROLE_USER"),
            showTenants:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            //tenantname: tenant,
          });
        }
        lobbyeventname = EventService.getCurrentEvent();
        console.log(lobbyeventname);

        this.setState({
          R3poster1path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs3" + "/poster1.png?v=" + num,
          R3poster2path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs3d" +
          "/poster2d.png?v=" + num,
          R3poster6path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs3" + "/poster6.png?v=" + num,
          R3poster1rpath :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs3" +
          "/poster31.png?v=" + num,
          R3poster1r1path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs3" +
          "/poster32.png?v=" + num,
          R3poster1r2path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs3" +
          "/poster33.png?v=" + num,


          poster1: true,
          poster2: true,
          poster6: true,
          poster1r: true,
          poster1r1: true,
          poster1r2: true,
          poster31g1: true,
          poster31g2: true,

          poster31g3: true,






    });
    })
  }
    )
  }

  render() {
    function hideImg() {
      document.getElementById("myImg").style.display = "none";
    }
    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }
    function hideImgr() {
      document.getElementById("myImgr").style.display = "none";
    }
    function hideImgr1() {
      document.getElementById("myImgr1").style.display = "none";
    }
    function hideImgr2() {
      document.getElementById("myImgr2").style.display = "none";
    }




    const {

      poster1,
      poster2,
      poster3,
      poster4,
      poster3apdf,
      poster3bpdf,
      poster3cpdf,


      poster6,
      poster1r,
      poster1r1,
      poster1r2,
      poster31g1,
      poster31g2,
      poster31g3,





      R3poster1path,
      R3poster2path,
      R3poster3path,
      R3poster4path,
      R3poster3apdfpath,
      R3poster3bpdfpath,
       R3poster3cpdfpath,


      R3poster6path,
      R3poster1rpath,
      R3poster1r1path,
      R3poster1r2path,
      R3poster31g1path,
      R3poster31g2path,
      R3poster31g3path,





      R3poster3type,
      R3poster31g1type,
      R3poster31g2type,
      R3poster31g3type,


      R3poster4type,
      R3poster3apdftype,
      R3poster3bpdftype,
      R3poster3cpdftype,



      tenantname


    } = this.state;

    return (
      <div className="container-fluide">



        <div className="page-content">
          <div id="content-agenda">
            <div id="bg">
              <img src="https://d2e8va4puks5pl.cloudfront.net/VR-02/VR-2%204%20GRIDS.jpg" id="bg-image" className="mb-3" />
              <div className="sidenavogation">
                    {/* <Link to={tenantname + "/Resource"} className="Rs1  text-white buttonRes">
                                <span className="hide-menu">Resource Center1</span>
                    </Link>
                    <Link to={tenantname + "/Resource1"} className="Rs2  text-white buttonRes">
                                <span className="hide-menu">Resource Center2</span>
                    </Link>
                    <Link to={tenantname + "/Resource2"} className="Rs3  text-white buttonRes">
                                <span className="hide-menu">Resource Center3</span>
                    </Link>
                    <Link to={tenantname + "/Resource3"} className="Rs4  text-white buttonRes">
                                <span className="hide-menu">Resource Center4</span>
                    </Link>
                    <Link to={tenantname + "/Resource4"} className="Rs5  text-white buttonRes">
                                <span className="hide-menu">Resource Center5</span>
                    </Link> */}
                       <Link to={tenantname + "/agenda"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Back To Agenda</span>
                  </Link>

                    </div>

              <div id="Resource12">
              {poster1 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap122" >
                  <img
                  src={R3poster1path}
                  // src={image}
                   alt=""  onError={() => hideImg()}
                   id="myImg"/>
                </div>

              </form>
            )}
              </div>
              <div id="Resource12r">
              {poster1r && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap122r" >
                  <img
                  src={R3poster1rpath}
                  // src={image}
                   alt=""  onError={() => hideImgr()}
                   id="myImgr"/>
                </div>

              </form>
            )}
              </div>

              <div id="Resource12r1">
              {poster1r1 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap122r1" >
                  <img
                  src={R3poster1r1path}
                  // src={image}
                   alt=""  onError={() => hideImgr1()}
                   id="myImgr1"/>
                </div>

              </form>
            )}
              </div>
              <div id="Resource12r2">
              {poster1r2 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap122r2" >
                  <img
                  src={R3poster1r2path}
                  // src={image}
                   alt=""  onError={() => hideImgr2()}
                   id="myImgr2"/>
                </div>

              </form>
            )}
              </div>

              <div id="Resource21">
              {poster2 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader2"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap211" >
                  <img
                  src={R3poster2path}
                 // src={image1}
                   onError={() => hideImg1()}
                   id="myImg1"/>
                </div>

              </form>
            )}
              </div>


              <div id="Resource31edit3res">
              {poster3 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap311" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R3poster3path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>
              <div id="Resource31edit3res1">
              {poster31g1 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap311" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R3poster31g1path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>
              <div id="Resource31edit3res2">
              {poster31g2 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap311" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R3poster31g2path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText2"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>
              <div id="Resource31edit3res3">
              {poster31g3 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap311" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R3poster31g3path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>

        <div id="ResourceCenteredit3">
          <div>
            <div className="hotspot">
              {/* <div className="out"></div> */}
              {poster4 && R3poster4type === "ppt" && (
                <div className="in11" onClick={this.P1openModal} >
                  {" "}
                </div>
              )}
            </div>
            {poster4 && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen}
                  style={customStyles}
                  onRequestClose={this.P1closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal}>
                    x
                  </span>
                  <iframe
                   src={R3poster4path}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenteredit3">
          <div>
            <div className="hotspot1">
              {poster4 && R3poster4type === "video" && (
                <div className="in11" onClick={this.P2openModal}></div>
              )}
            </div>
            {poster4 && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal}
                >
                  <span className="close1" onClick={this.P2closeModal}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R3poster4path} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenteredit3">
          <div>
            <div className="hotspot1">
              {poster4 && R3poster4type === "pdf" && (
                <div className="in11" onClick={this.P3openModal}></div>
              )}
            </div>
            {poster4 && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen}
                  style={customStyles}
                  onRequestClose={this.P3closeModal}
                >
                  <span className="close1" onClick={this.P3closeModal}>
                    x
                  </span>

                  <iframe
                    src={R3poster4path}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>
           {/* <div id="Resource92">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap922"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R3poster6path}
                        // src={image}

                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>

                  </form>
                )}
              </div> */}
           </div>

           <div id="ResourceCenteredit31">
          <div>
            <div className="hotspot">
              {/* <div className="out"></div> */}
              {poster3apdf&& R3poster3apdftype === "ppt" && (
                <div className="in11" onClick={this.P1openModal3} >
                  {" "}
                </div>
              )}
            </div>
            {poster3apdf&& (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen3}
                  style={customStyles}
                  onRequestClose={this.P1closeModal3}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal3}>
                    x
                  </span>
                  <iframe
                   src={R3poster3apdfpath}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenteredit31">
          <div>
            <div className="hotspot1">
              {poster3apdf && R3poster3apdftype === "video" && (
                <div className="in11" onClick={this.P2openModal3}></div>
              )}
            </div>
            {poster3apdf && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen3}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal3}
                >
                  <span className="close1" onClick={this.P2closeModal3}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R3poster3apdfpath} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenteredit31">
          <div>
            <div className="hotspot1">
              {poster3apdf && R3poster3apdftype === "pdf" && (
                <div className="in11" onClick={this.P3openModal3}></div>
              )}
            </div>
            {poster3apdf && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen3}
                  style={customStyles}
                  onRequestClose={this.P3closeModal3}
                >
                  <span className="close1" onClick={this.P3closeModal3}>
                    x
                  </span>

                  <iframe
                    src={R3poster3apdfpath}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>
           {/* <div id="Resource92">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap922"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R3poster6path}
                        // src={image}

                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>

                  </form>
                )}
              </div> */}
           </div>

           <div id="ResourceCenteredit32">
          <div>
            <div className="hotspot">
              {/* <div className="out"></div> */}
              {poster3bpdf && R3poster3bpdftype === "ppt" && (
                <div className="in11" onClick={this.P1openModal4} >
                  {" "}
                </div>
              )}
            </div>
            {poster3bpdf && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen4}
                  style={customStyles}
                  onRequestClose={this.P1closeModal4}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal4}>
                    x
                  </span>
                  <iframe
                   src={R3poster3bpdfpath}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenteredit32">
          <div>
            <div className="hotspot1">
              {poster3bpdf && R3poster3bpdftype === "video" && (
                <div className="in11" onClick={this.P2openModal4}></div>
              )}
            </div>
            {poster3bpdf && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen4}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal4}
                >
                  <span className="close1" onClick={this.P2closeModal4}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R3poster3bpdfpath} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenteredit32">
          <div>
            <div className="hotspot1">
              {poster3bpdf && R3poster3bpdftype === "pdf" && (
                <div className="in11" onClick={this.P3openModal4}></div>
              )}
            </div>
            {poster3bpdf && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen4}
                  style={customStyles}
                  onRequestClose={this.P3closeModal4}
                >
                  <span className="close1" onClick={this.P3closeModal4}>
                    x
                  </span>

                  <iframe
                    src={R3poster3bpdfpath}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>
           {/* <div id="Resource92">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap922"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R3poster6path}
                        // src={image}

                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>

                  </form>
                )}
              </div> */}
           </div>

           <div id="ResourceCenteredit33">
          <div>
            <div className="hotspot">
              {/* <div className="out"></div> */}
              {poster3cpdf && R3poster3cpdftype === "ppt" && (
                <div className="in11" onClick={this.P1openModal5} >
                  {" "}
                </div>
              )}
            </div>
            {poster3cpdf && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen5}
                  style={customStyles}
                  onRequestClose={this.P1closeModal5}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal5}>
                    x
                  </span>
                  <iframe
                   src={R3poster3cpdfpath}
                    allowfullscreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenteredit33">
          <div>
            <div className="hotspot1">
              {poster3cpdf && R3poster3cpdftype === "video" && (
                <div className="in11" onClick={this.P2openModal5}></div>
              )}
            </div>
            {poster3cpdf && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen5}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal5}
                >
                  <span className="close1" onClick={this.P2closeModal5}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R3poster3cpdfpath} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenteredit33">
          <div>
            <div className="hotspot1">
              {poster3cpdf && R3poster3cpdftype === "pdf" && (
                <div className="in11" onClick={this.P3openModal5}></div>
              )}
            </div>
            {poster3cpdf && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen5}
                  style={customStyles}
                  onRequestClose={this.P3closeModal5}
                >
                  <span className="close1" onClick={this.P3closeModal5}>
                    x
                  </span>

                  <iframe
                    src={R3poster3cpdfpath}
                    allowfullscreen
                  />

                </Modal>

              </div>
            )}
           </div>
           {/* <div id="Resource92">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap922"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R3poster6path}
                        // src={image}

                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>

                  </form>
                )}
              </div> */}
           </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Resorce3;
