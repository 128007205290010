import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import stall1 from "./img/VR-02/VR-2 3 GRIDS.jpg";
import image from "./img/rs1.png";
import image1 from "./img/rs2.png";
import Modal from "react-modal";
import AWSService from "../services/aws.service";
import EventService from "../services/event.service";
import PPTPathService from "../services/pptpath.service";
import UnAuthorizedService from "../common/unauthorized";
import AuthService from "../services/auth.service";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./styles/maping.css";
import $ from "jquery";
import "../App.css";
var lobbyeventname = "";
let tenant = "";
const { aws_s3_url, aws_s3_bucket_name } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "70vh",
    marginRight: "-50%",
    width: "calc(60% - 0px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    border: "unset !important",
    margintop: "calc(85vh - 10vh - 10px)",
    zIndex: "99999",
    backgroundcolor: "unset !important",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(50% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding: "0 0 0 0",
  },
};
class Resource2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initially, no file is selected
      selectedFile: null,
      poster1: false,
      poster2: false,
      poster3: false,
      poster4: false,
      poster6: false,
      poster133pdf:false,
      poster134pdf:false,

      poster541g2res: false,
      poster541g2res1: false,
      poster541g2res2: false,
      poster3resol1: false,
      poster3edit2: false,
      poster3edit22: false,





      R2poster1path : "",
      R2poster2path : "",
      R2poster3path : "",
      R2poster4path : "",
      R2poster133pdfpath : "",

      R2poster6path : "",
      R2poster541g2respath : "",
      R2poster541g2res1path : "",
      R2poster541g2res2path : "",
      R2poster3resol1path : "",
      R2poster3edit2path : "",
      R2poster3edit22path : "",






      R2poster3type: "",
      R2poster3edit2type: "",
      R2poster3edit22type: "",
      R2poster133pdftype: "",


      R2poster4type: "",
      R2poster3resol1type: "",

      modalIsOpen: false,
      modal1IsOpen: false,
      modal2IsOpen: false,
      tenantname: tenant,
    };
  }

  P1openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  P1closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  P2openModal = () => {
    this.setState({ modal1IsOpen: true });
  };

  P2closeModal = () => {
    this.setState({ modal1IsOpen: false });
  };
  P3openModal = () => {
    this.setState({ modal2IsOpen: true });
  };

  P3closeModal = () => {
    this.setState({ modal2IsOpen: false });
  };



  P1openModaled2 = () => {
    this.setState({ modalIsOpened2: true });
  };

  P1closeModaled2 = () => {
    this.setState({ modalIsOpened2: false });
  };

  P2openModaled2 = () => {
    this.setState({ modal1IsOpened2: true });
  };

  P2closeModaled2 = () => {
    this.setState({ modal1IsOpened2: false });
  };
  P3openModaled2 = () => {
    this.setState({ modal2IsOpened2: true });
  };

  P3closeModaled2 = () => {
    this.setState({ modal2IsOpened2: false });
  };


  P1openModalg3 = () => {
    this.setState({ modalIsOpeng3: true });
  };

  P1closeModalg3 = () => {
    this.setState({ modalIsOpeng3: false });
  };

  P2openModalg3 = () => {
    this.setState({ modal1IsOpeng3: true });
  };

  P2closeModalg3 = () => {
    this.setState({ modal1IsOpeng3: false });
  };
  P3openModalg3 = () => {
    this.setState({ modal2IsOpeng3: true });
  };

  P3closeModalg3 = () => {
    this.setState({ modal2IsOpeng3: false });
  };
  componentDidMount() {

    localStorage.setItem("intro", 0);
    const eventname = EventService.getCurrentEvent();
    console.log(eventname);
    var num = Math.random();
    PPTPathService.getFiletype(eventname, "Rs2")
      .then((response) => {
        response.data.forEach((filedetails) => {
          if (filedetails.filename === "poster3") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R2poster3path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs2" +
            "/poster3.txt?v=" +
              filedetails.filetype +
              "?v=" +
              num,
              poster3: true,
            });
            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R2poster3path:filedetails.path,
                  poster3: true,

                });
              this.setState({ R2poster3type: "txt" });
            }
          }
          if (filedetails.filename === "poster74g1") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R2poster3edit2path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs2" +
            "/poster74g1.txt?v=" +
              filedetails.filetype +
              "?v=" +
              num,
              poster74g1: true,
            });
            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R2poster3edit2path:filedetails.path,
                  poster3edit2: true,

                });
              this.setState({ R2poster3edit2type: "txt" });
            }
          }
          if (filedetails.filename === "poster74g2") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R2poster3edit22path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs2" +
            "/poster74g2.txt?v=" +
              filedetails.filetype +
              "?v=" +
              num,
              poster74g2: true,
            });
            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R2poster3edit22path:filedetails.path,
                  poster3edit22: true,

                });
              this.setState({ R2poster3edit22type: "txt" });
            }
          }

          if (filedetails.filename === "poster31g21") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              R2poster3resol1path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs1" +
                "/poster31g21" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster31g21: true,
            });
            if (
              filedetails.filetype === "txt"

            )  {
                this.setState({
                  R2poster3resol1path:filedetails.path,
                  poster3resol1: true,

                });
              this.setState({ R2poster3resol1type: "txt" });
            }
          }



        if (filedetails.filename == "poster4") {
            this.setState({
              R2poster4path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs2" + "/poster4" + "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster4: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R2poster4type: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R2poster4path:filedetails.path,
                  poster4: true,

                });
                this.setState({ R2poster4type: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R2poster4type: "pdf" });
              }
            }
            console.log(this.state.R2poster4path);
          }




            if (filedetails.filename == "poster133pdf") {
            this.setState({
              R2poster133pdfpath:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/Rs2" + "/poster133pdf" + "." +
                filedetails.filetype +
                "?v=" +
                num,
                poster133pdf: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
            ) {
              this.setState({ R2poster133pdftype: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  R2poster133pdfpath:filedetails.path,
                  poster133pdf: true,

                });
                this.setState({ R2poster133pdftype: "ppt" });
              }
            }
            if (
              filedetails.filetype === "pdf"

            ) {
              {
                this.setState({ R2poster133pdftype: "pdf" });
              }
            }
            console.log(this.state.R2poster133pdfpath);
          }





      if (filedetails.filename == "poster134pdf") {
        this.setState({
          R2poster134pdfpath:
            AWS_S3_URL +
            "capgemini/" +
            eventname +
            "/Rs2" + "/poster134pdf" + "." +
            filedetails.filetype +
            "?v=" +
            num,
            poster134pdf: true,
        });
        if (
          filedetails.filetype === "mp4" ||
          filedetails.filetype === "mov" ||
          filedetails.filetype === "webm" ||
        filedetails.filetype === "ogv"
        ) {
          this.setState({ R2poster134pdftype: "video" });
        }
        if (
          filedetails.filetype === "pptx" ||
          filedetails.filetype === "ppt"
        ) {
          {
            this.setState({
              R2poster134pdfpath:filedetails.path,
              poster134pdf: true,

            });
            this.setState({ R2poster134pdftype: "ppt" });
          }
        }
        if (
          filedetails.filetype === "pdf"

        ) {
          {
            this.setState({ R2poster134pdftype: "pdf" });
          }
        }
        console.log(this.state.R2poster134pdfpath);
      }



    })
  })

      .catch((err) => {
        UnAuthorizedService.unauthorized(err);
      });

    EventService.getSpecificEvent()
    .then((response) => {
      console.log("Eventname from getSpecificEvent");
      console.log(response.data);
      console.log(response.data.eventname);
      const eventpath = "/" + response.data.eventname;
      this.setState({ tenantname: eventpath, showLoginPage: true });
      localStorage.setItem("eventname", response.data.eventname);
      console.log(response.data.eventname);

      const user=AuthService.getCurrentUser();

      AuthService.getCurrentRole().then((roles)=>{
        if (roles) {
          console.log("Got current role from server");
          this.setState({
            currentUser: user,
            showModeratorBoard: roles.data.includes("ROLE_MODERATOR"),
            showAdminBoard: roles.data.includes("ROLE_ADMIN"),
            showAddTenant: roles.data.includes("ROLE_SUPERADMIN"),
            showAddUser: roles.data.includes("ROLE_SUPERADMIN"),
            showRegModerators:
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showRegAdmins:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN"),
            showRegSuperAdmins: roles.data.includes("ROLE_SUPERADMIN"),
            showRegUsers:
              roles.data.includes("ROLE_MODERATOR") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showCreateEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showDetailLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showFeedbackReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEdit:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showNormalUser: roles.data.includes("ROLE_USER"),
            showTenants:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            //tenantname: tenant,
          });
        }
        lobbyeventname = EventService.getCurrentEvent();
        console.log(lobbyeventname);

        this.setState({
          R2poster1path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs2" + "/poster1.png?v=" + num,
          R2poster2path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs2b" +
          "/poster2b.png?v=" + num,
          R2poster6path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs2" + "/poster6.png?v=" + num,
          R2poster541g2respath :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs2" + "/poster1.png?v=" + num,
          R2poster541g2res1path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs2" +
          "/poster22.png?v=" + num,

          R2poster541g2res2path :
          AWS_S3_URL + "capgemini/" + eventname + "/Rs2" +
          "/poster21.png?v=" + num,



          poster1: true,
          poster2: true,
          poster6: true,
          poster541g2res: true,
          poster541g2res1: true,
          poster541g2res2: true,



    });
    })
  }
    )
  }

  render() {
    function hideImg() {
      document.getElementById("myImg").style.display = "none";
    }
    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }
    function hideImg541g2res() {
      document.getElementById("myImg541g2res").style.display = "none";
    }
    function hideImg541g2res1() {
      document.getElementById("myImg541g2res1").style.display = "none";
    }

    function hideImg541g2res2() {
      document.getElementById("myImg541g2res2").style.display = "none";
    }



    const {

      poster1,
      poster2,
      poster3,
      poster4,
      poster133pdf,
      poster134pdf,

      poster6,
      poster541g2res,
      poster541g2res1,
      poster541g2res2,
      poster3resol1,
      poster3edit2,
      poster3edit22,





      R2poster1path,
      R2poster2path,
      R2poster3path,
      R2poster4path,
      R2poster133pdfpath,
      R2poster134pdfpath,


      R2poster6path,
      R2poster3edit2path,
      R2poster3edit22path,


      R2poster541g2respath,
      R2poster541g2res1path,
      R2poster541g2res2path,
      R2poster3resol1path,



      R2poster3type,
      R2poster3edit2type,
      R2poster3edit22type,


      R2poster4type,
      R2poster133pdftype,
      R2poster134pdftype,


      poster3resol1type,

      tenantname


    } = this.state;

    return (
      <div className="container-fluide">


        <div className="page-content">
          <div id="content-agenda">
            <div id="bg">
              <img src="https://d2e8va4puks5pl.cloudfront.net/VR-02/VR-2%203%20GRIDS.jpg" id="bg-image" className="mb-3" />
              <div className="sidenavogation">
                  {/* <Link to={tenantname + "/Resource"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Resource Center1</span>
                  </Link>
                  <Link to={tenantname + "/Resource1"} className="Rs2  text-white buttonRes">
                              <span className="hide-menu">Resource Center2</span>
                  </Link>
                  <Link to={tenantname + "/Resource2"} className="Rs3  text-white buttonRes">
                              <span className="hide-menu">Resource Center3</span>
                  </Link>
                  <Link to={tenantname + "/Resource3"} className="Rs4  text-white buttonRes">
                              <span className="hide-menu">Resource Center4</span>
                  </Link>
                  <Link to={tenantname + "/Resource4"} className="Rs5  text-white buttonRes">
                              <span className="hide-menu">Resource Center5</span>
                  </Link> */}
                  <Link to={tenantname + "/agenda"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Back To Agenda</span>
                  </Link>
                  </div>

              <div id="Resource54">
              {poster541g2res && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap544" >
                  <img
                  src={R2poster541g2respath}
                  // src={image}
                   alt=""  onError={() => hideImg541g2res()}
                   id="myImg541g2res"/>
                </div>

              </form>
            )}
              </div>
              <div id="Resource54_g1">
              {poster541g2res1 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap544" >
                  <img
                  src={R2poster541g2res1path}
                  // src={image}
                   alt=""  onError={() => hideImg541g2res1()}
                   id="myImg541g2res1"/>
                </div>

              </form>
            )}
              </div>

              <div id="Resource54_g2">
              {poster541g2res2 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap544" >
                  <img
                  src={R2poster541g2res2path}
                  // src={image}
                   alt=""  onError={() => hideImg541g2res2()}
                   id="myImg541g2res2"/>
                </div>

              </form>
            )}
              </div>

              <div id="Resource21edit2">
              {poster2 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader2"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap644" >
                  <img
                  src={R2poster2path}
                 // src={image1}
                   onError={() => hideImg1()}
                   id="myImg1"/>
                </div>

              </form>
            )}
              </div>

              <div id="Resource74">
              {poster3 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap744">
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R2poster3path}
                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>

              <div id="Resource74_g1">
              {poster3edit2 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap744">
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R2poster3edit2path}
                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange03edit2}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>

              <div id="Resource74_g2">
              {poster3edit22 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap744">
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R2poster3edit22path}
                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange03edit22}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div>


              {/* <div id="Resource31edit3zb">
              {poster3resol1 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap744">
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={Rposter3path}
                        value={R2poster3resol1path}
                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>

              </form>
              )}
              </div> */}


           <div id="ResourceCenter_g1">
          <div>
            <div className="hotspot1_g1">
              {/* <div className="out"></div> */}
              {poster4 && R2poster4type === "ppt" && (
                <div className="in1_g1" onClick={this.P1openModal} >
                  {" "}
                </div>
              )}
            </div>
            {poster4 && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpen}
                  style={customStyles}
                  onRequestClose={this.P1closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal}>
                    x
                  </span>
                  <iframe
                   src={R2poster4path}
                    allowFullScreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenter_g1">
          <div>
            <div className="hotspot1_g1">
              {poster4 && R2poster4type === "video" && (
                <div className="in1_g1" onClick={this.P2openModal}></div>
              )}
            </div>
            {poster4 && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal}
                >
                  <span className="close1" onClick={this.P2closeModal}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R2poster4path} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenter_g1">
          <div>
            <div className="hotspot1_g1">
              {poster4 && R2poster4type === "pdf" && (
                <div className="in1_g1 bg-dark q" onClick={this.P3openModal}></div>
              )}
            </div>
            {poster4 && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpen}
                  style={customStyles}
                  onRequestClose={this.P3closeModal}
                >
                  <span className="close1" onClick={this.P3closeModal}>
                    x
                  </span>

                  <iframe
                    src={R2poster4path}
                    allowFullScreen
                  />

                </Modal>

              </div>
            )}
           </div>

           </div>

        <div id="ResourceCenter_g2">
          <div>
            <div className="hotspot_g2">
              {/* <div className="out"></div> */}
              {poster133pdf && R2poster133pdftype === "ppt" && (
                <div className="in1_g2" onClick={this.P1openModaled2} >
                  {" "}
                </div>
              )}
            </div>
            {poster133pdf && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpened2}
                  style={customStyles}
                  onRequestClose={this.P1closeModaled2}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModaled2}>
                    x
                  </span>
                  <iframe
                   src={R2poster133pdfpath}
                    allowFullScreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenter_g2">
          <div>
            <div className="hotspot1_g2">
              {poster133pdf && R2poster133pdftype === "video" && (
                <div className="in1_g2" onClick={this.P2openModaled2}></div>
              )}
            </div>
            {poster133pdf && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpened2}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModal}
                >
                  <span className="close1" onClick={this.P2closeModaled2}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R2poster133pdfpath} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenter_g2">
          <div>
            <div className="hotspot1_g2">
              {poster133pdf && R2poster133pdftype === "pdf" && (
                <div className="in1_g2 bg-dark q" onClick={this.P3openModaled2}></div>
              )}
            </div>
            {poster133pdf && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpened2}
                  style={customStyles}
                  onRequestClose={this.P3closeModaled2}
                >
                  <span className="close1" onClick={this.P3closeModaled2}>
                    x
                  </span>

                  <iframe
                    src={R2poster133pdfpath}
                    allowFullScreen
                  />

                </Modal>

              </div>
            )}
           </div>
           {/* <div id="Resource91">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap911"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R2poster6path}
                        // src={image}

                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>

                  </form>
                )}
              </div> */}
           </div>


           <div id="ResourceCenter_g3">
          <div>
            <div className="hotspot_g3">
              {/* <div className="out"></div> */}
              {poster134pdf && R2poster134pdftype === "ppt" && (
                <div className="in1_g4" onClick={this.P1openModalg3} >
                  {" "}
                </div>
              )}
            </div>
            {poster134pdf && (
              <div>
                <Modal
                  isOpen={this.state.modalIsOpeng3}
                  style={customStyles}
                  onRequestClose={this.P1closeModalg3}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModalg3}>
                    x
                  </span>
                  <iframe
                   src={R2poster134pdfpath}
                    allowFullScreen

                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenter_g3">
          <div>
            <div className="hotspot1_g3">
              {poster134pdf && R2poster134pdftype === "video" && (
                <div className="in1_g4" onClick={this.P2openModalg3}></div>
              )}
            </div>
            {poster134pdf && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpeng3}
                  style={customStylesV1}
                  onRequestClose={this.P2closeModalg3}
                >
                  <span className="close1" onClick={this.P2closeModalg3}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={R2poster134pdfpath} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="ResourceCenter_g3">
          <div>
            <div className="hotspot1_g3">
              {poster134pdf && R2poster134pdftype === "pdf" && (
                <div className="in1_g4 bg-dark q" onClick={this.P3openModalg3}></div>
              )}
            </div>
            {poster134pdf && (
              <div>
                <Modal
                  isOpen={this.state.modal2IsOpeng3}
                  style={customStyles}
                  onRequestClose={this.P3closeModalg3}
                >
                  <span className="close1" onClick={this.P3closeModalg3}>
                    x
                  </span>

                  <iframe
                    src={R2poster134pdfpath}
                    allowFullScreen
                  />

                </Modal>

              </div>
            )}
           </div>
           {/* <div id="Resource91">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap911"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R2poster6path}
                        // src={image}

                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>

                  </form>
                )}
              </div> */}
           </div>



            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Resource2;