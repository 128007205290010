import React, { PureComponent } from "react";
import Marzipano from "marzipano";
import Modal from "react-modal";
import EventService from "../services/event.service";
import UnAuthorizedService from "../common/unauthorized";
import "./styles/styles.css";
import "./styles/maping.css";
import PPTPathService from "../services/pptpath.service";
import $ from "jquery";
import Preloader from "./preloader.component";
import board1 from "./img/boardposter1.png";
import board2 from "./img/boardposter2.png";
import board3 from "./img/boardposter3.png";
import board4 from "./img/boardposter4.png";
import board5 from "./img/boardposter5.png";
import board6 from "./img/boardposter6.png";
import board7 from "./img/boardposter7.png";
import board8 from "./img/boardposter8.png";

const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "70vh",
    marginRight: "-50%",
    width: "calc(60% - 10px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    border: "unset !important",
    margintop: "calc(85vh - 10vh - 10px)",
    zIndex: "2",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(50% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding:"0 0 0 0"
  },
};
const { aws_s3_url } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
const linkToPPTFile =
  "https://scholar.harvard.edu/files/torman_personal/files/samplepptx.pptx";
class Boardroom extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      poster1: false,
      poster2: false,
      poster3: false,
      poster4: false,
      poster5: false,
      poster6: false,
      poster7: false,
      poster8: false,
      poster9: false,
      Bposter1path : "",
      Bposter2path : "",
      Bposter3path : "",
      Bposter4path : "",
      Bposter5path : "",
      Bposter6path : "",
      Bposter7path : "",
      Bposter8path : "",
      Bposter9path : "",
      Bposter10path: "",
      Bposter11path: "",
      Bposter12path: "",
      Bposter13path: "",
      Bposter14path: "",
      Bposter15path: "",
      Bposter16path: "",
      // Initially, no file is selected

      modalIsOpen: false,
      SecmodalIsOpen: false,
      TmodalIsOpen: false,
      FmodalIsOpen: false,
      FimodalIsOpen: false,
      SmodalIsOpen: false,
      V1modalIsOpen: false,
      V2modalIsOpen: false,
      V3modalIsOpen: false,
      V4modalIsOpen: false,
      V5modalIsOpen: false,
      V6modalIsOpen: false,
      V14modalIsOpen: false,
      P13modalIsOpen: false,
      P15modalIsOpen: false,
      P16modalIsOpen: false,


    };
  }
  P1openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  P1closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  P2openModal = () => {
    this.setState({ SecmodalIsOpen: true });
  };
  P2closeModal = () => {
    this.setState({ SecmodalIsOpen: false });
  };
  P3closeModal = () => {
    this.setState({ TmodalIsOpen: false });
  };
  P3openModal = () => {
    this.setState({ TmodalIsOpen: true });
  };
  P4closeModal = () => {
    this.setState({ FmodalIsOpen: false });
  };
  P4openModal = () => {
    this.setState({ FmodalIsOpen: true });
  };
  P5closeModal = () => {
    this.setState({ FimodalIsOpen: false });
  };
  P5openModal = () => {
    this.setState({ FimodalIsOpen: true });
  };
  P6closeModal = () => {
    this.setState({ SmodalIsOpen: false });
  };
  P6openModal = () => {
    this.setState({ SmodalIsOpen: true });
  };
  P7closeModal = () => {
    this.setState({ V1modalIsOpen: false });
  };
  P7openModal = () => {
    this.setState({ V1modalIsOpen: true });
  };
  P8closeModal = () => {
    this.setState({ V2modalIsOpen: false });
  };
  P8openModal = () => {
    this.setState({ V2modalIsOpen: true });
  };
  P9closeModal = () => {
    this.setState({ V3modalIsOpen: false });
  };
  P9openModal = () => {
    this.setState({ V3modalIsOpen: true });
  };
  P10closeModal = () => {
    this.setState({ V4modalIsOpen: false });
  };
  P10openModal = () => {
    this.setState({ V4modalIsOpen: true });
  };
  P11closeModal = () => {
    this.setState({ V5modalIsOpen: false });
  };
  P11openModal = () => {
    this.setState({ V5modalIsOpen: true });
  };
  P12closeModal = () => {
    this.setState({ V6modalIsOpen: false });
  };
  P12openModal = () => {
    this.setState({ V6modalIsOpen: true });
  };
  P14closeModal = () => {
    this.setState({ V14modalIsOpen: false });
  };
  P14openModal = () => {
    this.setState({ V14modalIsOpen: true });
  };
  P13closeModal = () => {
    this.setState({ P13modalIsOpen: false });
  };
  P13openModal = () => {
    this.setState({ P13modalIsOpen: true });
  };
  P15closeModal = () => {
    this.setState({ P15modalIsOpen: false });
  };
  P15openModal = () => {
    this.setState({ P15modalIsOpen: true });
  };
  P16closeModal = () => {
    this.setState({ P16modalIsOpen: false });
  };
  P16openModal = () => {
    this.setState({ P16modalIsOpen: true });
  };


  static displayName = "Pano";
  componentDidMount() {
    const eventname = EventService.getCurrentEvent();
    var num = Math.random();
    console.log(eventname);

    PPTPathService.getFiletype(eventname, "bd").then((response) => {
      response.data.forEach((filedetails) => {
        console.log(filedetails);
        console.log(filedetails.path);
        if (filedetails.filename === "poster9") {
          this.setState({
            Bposter9path :
            AWS_S3_URL + "capgemini/" + eventname + "/bd" + "/poster9"+ "." +filedetails.filetype + "?v="+ num,

            poster9: true,
            // Bposter9type: filedetails.filetype,
          });

          if (
            filedetails.filetype === "mp4" ||
            filedetails.filetype === "mov" ||
            filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
          ) {
            this.setState({ Bposter9type: "video" });
          }
          if (
            filedetails.filetype === "pdf"
          ) {

              this.setState({ Bposter9type: "pdf" });
            }
          if (
            filedetails.filetype === "pptx" ||
            filedetails.filetype === "ppt"
          ) {
            {
              this.setState({
                Bposter9path:filedetails.path,
                poster9: true,

              });
              this.setState({ Bposter9type: "ppt" });
            }
        }

          console.log(this.state.Bposter9path);
        }
        if (filedetails.filename === "poster10") {
          this.setState({
            Bposter10path : AWS_S3_URL + "capgemini/" + eventname + "/bd" + "/poster10"+ "." +filedetails.filetype + "?v="+ num,

            poster10: true,
            // B2poster10type: filedetails.filetype,
          });
          if (
            filedetails.filetype === "mp4" ||
            filedetails.filetype === "mov" ||
            filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
          ) {
            this.setState({ Bposter10type: "video" });
          }
          if (
            filedetails.filetype === "pptx" ||
            filedetails.filetype === "ppt"
          ) {
            {
              this.setState({
                Bposter10path:filedetails.path,
                poster10: true,

              });
              this.setState({ Bposter10type: "ppt" });
            }

          }
          if (
            filedetails.filetype === "pdf"
          ) {

              this.setState({ Bposter10type: "pdf" });
            }
          console.log(this.state.Bposter10path);
        }
      if (filedetails.filename === "poster11") {
        this.setState({
          Bposter11path : AWS_S3_URL + "capgemini/" + eventname + "/bd" + "/poster11"+ "." +filedetails.filetype + "?v="+ num,

          poster11: true,
          // Bposter11type: filedetails.filetype,
        });
        if (
          filedetails.filetype === "mp4" ||
          filedetails.filetype === "mov" ||
          filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
        ) {
          this.setState({ Bposter11type: "video" });
        }
        if (
          filedetails.filetype === "pptx" ||
          filedetails.filetype === "ppt"
        ) {
          {
            this.setState({
              Bposter11path:filedetails.path,
              poster11: true,

            });
            this.setState({ Bposter11type: "ppt" });
          }
        }
        if (
          filedetails.filetype === "pdf"
        ) {

            this.setState({ Bposter11type: "pdf" });
          }
        console.log(this.state.Bposter11path);
      }
      if (filedetails.filename === "poster12") {
        this.setState({
          Bposter12path : AWS_S3_URL + "capgemini/" + eventname + "/bd" + "/poster12"+ "." +filedetails.filetype + "?v="+ num,

          poster12: true,
          // Bposter12type: filedetails.filetype,
        });
        if (
          filedetails.filetype === "mp4" ||
          filedetails.filetype === "mov" ||
          filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
        ) {
          this.setState({ Bposter12type: "video" });
        }
        if (
          filedetails.filetype === "pptx" ||
          filedetails.filetype === "ppt"
        ) {
          {
            this.setState({
              Bposter12path:filedetails.path,
              poster12: true,

            });
            this.setState({ Bposter12type: "ppt" });
          }
        }
        if (
          filedetails.filetype === "pdf"
        ) {

            this.setState({ Bposter12type: "pdf" });
          }
        console.log(this.state.Bposter12path);
      }
      if (filedetails.filename === "poster13") {
        this.setState({
          Bposter13path : AWS_S3_URL + "capgemini/" + eventname + "/bd" + "/poster13"+ "." +filedetails.filetype + "?v="+ num,

          poster13: true,
          // Bposter13type: filedetails.filetype,
        });
        if (
          filedetails.filetype === "mp4" ||
          filedetails.filetype === "mov" ||
          filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
        ) {
          this.setState({ Bposter13type: "video" });
        }
        if (
          filedetails.filetype === "pptx" ||
          filedetails.filetype === "ppt"
        ) {
          {
            this.setState({
              Bposter13path:filedetails.path,
              poster13: true,

            });
            this.setState({ Bposter13type: "ppt" });
          }
        }
        if (
          filedetails.filetype === "pdf"
        ) {

            this.setState({ Bposter13type: "pdf" });
          }
        console.log(this.state.Bposter13path);
      }
      if (filedetails.filename === "poster14") {
        this.setState({
          Bposter14path : AWS_S3_URL + "capgemini/" + eventname + "/bd" + "/poster14"+ "." +filedetails.filetype + "?v="+ num,

          poster14: true,
          // Bposter14type: filedetails.filetype,
        });
        if (
          filedetails.filetype === "mp4" ||
          filedetails.filetype === "mov" ||
          filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
        ) {
          this.setState({ Bposter14type: "video" });
        }
        if (
          filedetails.filetype === "pptx" ||
          filedetails.filetype === "ppt"
        ) {
          {
            this.setState({
              Bposter14path:filedetails.path,
              poster14: true,

            });
            this.setState({ Bposter14type: "ppt" });
          }
        }
        if (
          filedetails.filetype === "pdf"
        ) {

            this.setState({ Bposter14type: "pdf" });
          }
        console.log(this.state.Bposter14path);
      }

      if (filedetails.filename === "poster15") {
        this.setState({
          Bposter15path : AWS_S3_URL + "capgemini/" + eventname + "/bd" + "/poster15"+ "." +filedetails.filetype + "?v="+ num,

          poster15: true,
          // Bposter14type: filedetails.filetype,
        });
        if (
          filedetails.filetype === "mp4" ||
          filedetails.filetype === "mov" ||
          filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
        ) {
          this.setState({ Bposter15type: "video" });
        }
        if (
          filedetails.filetype === "pptx" ||
          filedetails.filetype === "ppt"
        ) {
          {
            this.setState({
              Bposter15path:filedetails.path,
              poster15: true,

            });
            this.setState({ Bposter15type: "ppt" });
          }
        }
        if (
          filedetails.filetype === "pdf"
        ) {

            this.setState({ Bposter15type: "pdf" });
          }
        console.log(this.state.Bposter15path);
      }


      if (filedetails.filename === "poster16") {
        this.setState({
          Bposter16path : AWS_S3_URL + "capgemini/" + eventname + "/bd" + "/poster16"+ "." +filedetails.filetype + "?v="+ num,

          poster16: true,
          // Bposter14type: filedetails.filetype,
        });
        if (
          filedetails.filetype === "mp4" ||
          filedetails.filetype === "mov" ||
          filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
        ) {
          this.setState({ Bposter16type: "video" });
        }
        if (
          filedetails.filetype === "pptx" ||
          filedetails.filetype === "ppt"
        ) {
          {
            this.setState({
              Bposter16path:filedetails.path,
              poster16: true,

            });
            this.setState({ Bposter16type: "ppt" });
          }
        }
        if (
          filedetails.filetype === "pdf"
        ) {

            this.setState({ Bposter16type: "pdf" });
          }
        console.log(this.state.Bposter16path);
      }
    });
    }).catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });
    this.setState({
    Bposter1path :
      AWS_S3_URL + "capgemini/" + eventname + "/bd" + "/poster1.png?v=" + num,
    Bposter2path :
      AWS_S3_URL + "capgemini/" + eventname + "/bd" + "/poster2.png?v=" + num,
    Bposter3path :
      AWS_S3_URL + "capgemini/" + eventname + "/bd" + "/poster3.png?v=" + num,
    Bposter4path : AWS_S3_URL + "capgemini/" + eventname + "/bd" + "/poster4.png?v=" + num,
    Bposter5path : AWS_S3_URL + "capgemini/" + eventname + "/bd" + "/poster5.png?v=" + num,
    Bposter6path : AWS_S3_URL + "capgemini/" + eventname + "/bd" + "/poster6.png?v=" + num,
    Bposter7path : AWS_S3_URL + "capgemini/" + eventname + "/bd" + "/poster7.png?v=" + num,
    Bposter8path : AWS_S3_URL + "capgemini/" + eventname + "/bd" + "/poster8.png?v=" + num,
    poster1: true,
    poster2: true,
    poster3: true,
    poster4: true,
    poster5: true,
    poster6: true,
    poster7: true,
    poster8: true,
    // poster9: true,
    // poster10:true,
    //   poster11:true,
    //   poster12:true,
    //   poster13:true,
    //   poster14:true,

  });
    // this.setState({ poster1: true });
    // console.log(Bposter1path);

    this.viewer = new Marzipano.Viewer(this.pano); //, viewerOpts);

    // Create source.
    const source = Marzipano.ImageUrlSource.fromString("https://d2e8va4puks5pl.cloudfront.net/boardroom.jpg");

    // Create geometry.
    const geometry = new Marzipano.EquirectGeometry([{ width: 5000 }]);

    const limiter = Marzipano.RectilinearView.limit.traditional(
     4* 1024,
      (100 * Math.PI) / 180
    );
    const view = new Marzipano.RectilinearView({ yaw: Math.PI }, limiter);

    // Get the view values

    var yaw = view.yaw();
    var pitch = view.pitch();
    var fov = view.fov(); // fov is horizontal
    /*var vfov = view.vfov();
var hfov = view.hfov();    // same as view.fov()*/

    // Change the values

    view.setYaw((0 * Math.PI) / 180);
    view.setPitch((0 * Math.PI) / 180);
    view.setFov((120 * Math.PI) / 180);

    const scene = this.viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      pinFirstLevel: true,
    });

    scene.switchTo();

    // Get the hotspot container for scene.
    var container = scene.hotspotContainer();

    container.createHotspot(
      this.poster1,
      { yaw: 0.131, pitch: -0.13 },
      {
        perspective: {
          radius: 2100,
          extraTransforms: "rotateX(5deg) rotateY(17deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster2,
      { yaw: 0.634, pitch: -0.12 },
      {
        perspective: {
          radius: 1290,
          extraTransforms: "rotateX(4deg) rotateY(-50deg) rotateZ(-2deg)",
        },
      }
    );
    container.createHotspot(
      this.poster3,
      { yaw: 1.58, pitch: -0.068 },
      {
        perspective: {
          radius: 1490,
          extraTransforms: "rotateX(5deg) rotateY(0deg) rotateZ(0deg)",
        },
      }
    );
    // container.createHotspot(
    //   this.poster4,
    //   { yaw: 2.625, pitch: -0.001 },
    //   {
    //     perspective: {
    //       radius: 1190,
    //       extraTransforms: "rotateX(0deg) rotateY(-20deg) rotateZ(0deg)",
    //     },
    //   }
    // );
    container.createHotspot(
      this.poster5,
      { yaw: 3.58, pitch: -0.050 },
      {
        perspective: {
          radius: 1680,
          extraTransforms: "rotateX(4deg) rotateY(20deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster6,
      { yaw: 4.233, pitch: -0.06 },
      {
        perspective: {
          radius: 1350,
          extraTransforms: "rotateX(4deg) rotateY(-20deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster7,
      { yaw: 5.126, pitch: -0.0644 },
      {
        perspective: {
          radius: 1430,
          extraTransforms: "rotateX(5deg) rotateY(20deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster8,
      {  yaw: -0.755,
       pitch: -0.058 },
      {
        perspective: {
          radius: 1960,
          extraTransforms: "rotateX(5deg) rotateY(40deg) rotateZ(0deg)",
        },
      }
    );
    scene.hotspotContainer().createHotspot(document.querySelector("#pptview"), {
      yaw: 0.21,
      pitch: -0.1,
    });
    scene.hotspotContainer().createHotspot(document.querySelector("#Videostream1"), {
      yaw: 0.21,
      pitch: -0.1,
    });
    scene.hotspotContainer().createHotspot(document.querySelector("#pptview6"), {
      yaw: 0.21,
      pitch: -0.1,
    });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview1"), {
        yaw: 0.6,
        pitch: -0.1,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream2"), {
        yaw: 0.6,
        pitch: -0.1,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview7"), {
        yaw: 0.6,
        pitch: -0.1,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview2"), {
        yaw: 1.53,
        pitch: -0.05,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream3"), {
        yaw: 1.53,
        pitch: -0.05,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview8"), {
        yaw: 1.53,
        pitch: -0.05,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview3"), {
        yaw: 2.58,
        pitch: -0.09,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream4"), {
        yaw: 2.58,
        pitch: -0.09,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview9"), {
        yaw: 2.58,
        pitch: -0.09,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview4"), {
        yaw: 3.54,
        pitch: -0.01,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream5"), {
        yaw: 3.54,
        pitch: -0.01,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview10"), {
        yaw: 3.54,
        pitch: -0.01,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview5"), {
        yaw: -0.76,
        pitch: -0.01,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream6"), {
        yaw: -0.76,
        pitch: -0.01,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview11"), {
        yaw: -0.76,
        pitch: -0.01,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview12"), {
        yaw: -2.065,
        pitch: -0.01,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream7"), {
        yaw: -2.065,
        pitch: -0.01,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview13"), {
        yaw: -2.065,
        pitch: -0.01,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview14"), {
        yaw: -1.2,
        pitch: -0.01,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview15"), {
        yaw: -1.2,
        pitch: -0.01,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream8"), {
        yaw: -1.2,
        pitch: -0.01,
      });
      var autorotate = Marzipano.autorotate({
        yawSpeed: 0.1, // Yaw rotation speed
        targetPitch: 0, // Pitch value to converge to
        targetFov: Math.PI / 2, // Fov value to converge to
      });

      // Autorotate will start after 3s of idle time
      this.viewer.setIdleMovement(3000, autorotate);
      // Disable idle movement
      //this.viewer.setIdleMovement(Infinity);

      // Start autorotation immediately
      this.viewer.startMovement(autorotate);
  }

  render() {

    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }
    function hideImg3() {
      document.getElementById("myImg3").style.display = "none";
    }
    function hideImg4() {
      document.getElementById("myImg4").style.display = "none";
    }
    function hideImg5() {
      document.getElementById("myImg5").style.display = "none";
    }
    function hideImg6() {
      document.getElementById("myImg6").style.display = "none";
    }
    function hideImg7() {
      document.getElementById("myImg7").style.display = "none";
    }
    function hideImg8() {
      document.getElementById("myImg8").style.display = "none";
    }
    const {
      poster1,
      poster2,
      poster3,
      poster4,
      poster5,
      poster6,
      poster7,
      poster8,
      poster9,
      poster10,
      poster11,
      poster12,
      poster13,
      poster14,
      poster15,
      poster16,

      Bposter1path,
      Bposter2path,
      Bposter3path,
      Bposter4path,
      Bposter5path,
      Bposter6path,
      Bposter7path,
      Bposter8path,
      Bposter9path,
      Bposter10path,
      Bposter11path,
      Bposter12path,
      Bposter13path,
      Bposter14path,
      Bposter15path,
      Bposter16path,
      Bposter9type,
      Bposter10type,
      Bposter11type,
      Bposter12type,
      Bposter13type,
      Bposter14type,
      Bposter15type,
      Bposter16type,


    } = this.state;
    return (
      <div className="pano-container" ref={(pano) => (this.pano = pano)}>
       < Preloader/>
        <div id="Boardposter01" ref={(poster1) => (this.poster1 = poster1)}>
          <label>
          {poster1 && (
            <form enctType="multipart/form-data">
              <div id="richList"></div>
              <div id="loader3" className="lds-dual-ring hidden overlay"></div>
              <div id="Bmap11">
              <img
                src={Bposter1path}
                onError={() => hideImg1()}
                id="myImg1"
              /></div>
            </form>
          )}
          </label>
        </div>
        <div id="Boardposter02" ref={(poster2) => (this.poster2 = poster2)}>
          <label>
          {poster2 && (
            <form encType="multipart/form-data">
              <div id="richList"></div>

              <div id="loader3" className="lds-dual-ring hidden overlay"></div>
              <div  id="Bmap22">
              <img
                src={Bposter2path}
                onError={() => hideImg2()}
                id="myImg2"
              /></div>
            </form>
          )}
          </label>
        </div>

        <div id="Boardposter03" ref={(poster3) => (this.poster3 = poster3)}>
          <label>
          {poster3 && (
            <form encType="multipart/form-data">
              <div id="richList"></div>

              <div id="loader3" className="lds-dual-ring hidden overlay"></div>
              <div  id="Bmap33">
              <img
                src={Bposter3path}
                onError={() => hideImg3()}
                id="myImg3"
              /></div>
            </form>
          )}
          </label>
        </div>
        {/* <div id="Boardposter04" ref={(poster4) => (this.poster4 = poster4)}>
          <label>
          {poster4 && (
            <form encType="multipart/form-data">
              <div id="richList"></div>

              <div id="loader1" className="lds-dual-ring hidden overlay"></div>
              <div id="Bmap44">
                <img
                  src={Bposter4path}
                  onError={() => hideImg4()}
                  id="myImg4"
                />
              </div>
            </form>
          )}
          </label>
        </div> */}

        <div id="Boardposter05" ref={(poster5) => (this.poster5 = poster5)}>
          <label>
          {poster5 && (
            <form encType="multipart/form-data">
              <div id="richList"></div>

              <div id="loader3" className="lds-dual-ring hidden overlay"></div>
             <div id="Bmap55">
              <img
                src={Bposter5path}
                onError={() => hideImg5()}
               id="myImg5"
              /></div>
            </form>
          )}
          </label>
        </div>
        <div id="Boardposter06" ref={(poster6) => (this.poster6 = poster6)}>
          <label>
          {poster6 && (
            <form encType="multipart/form-data">
              <div id="richList"></div>

              <div id="loader2" className="lds-dual-ring hidden overlay"></div>
              <div id="Bmap66">
                <img
                  src={Bposter6path}
                  id="myImg6"
                  onError={() => hideImg6()}
                />
              </div>
            </form>
          )}
          </label>
        </div>
        <div id="Boardposter07" ref={(poster7) => (this.poster7 = poster7)}>
          <label>
          {poster7 && (
            <form encType="multipart/form-data">
              <div id="richList"></div>

              <div id="loader3" className="lds-dual-ring hidden overlay"></div>
              <div id="Bmap77">
                <img
                  src={Bposter7path}
                  id="myImg7"
                  onError={() => hideImg7()}
                />
              </div>
            </form>
          )}
          </label>
        </div>

        <div id="Boardposter08" ref={(poster8) => (this.poster8 = poster8)}>
          <label>
          {poster8 && (
            <form encType="multipart/form-data">
              <div id="richList"></div>

              <div id="loader3" className="lds-dual-ring hidden overlay"></div>
              <div  id="Bmap88">
              <img
                src={Bposter8path}
                id="myImg8"
                onError={() => hideImg8()}
              /></div>
            </form>
          )}
          </label>
        </div>
        <div id="pptview">
          <div className="hotspot">
          {poster9 && Bposter9type === "ppt" && (
            <div className="inn" onClick={this.P1openModal}></div>
          )}
          </div>
          {poster9 && (
          <Modal
            isOpen={this.state.modalIsOpen}
            style={customStyles}
            onRequestClose={this.P1closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P1closeModal}>
              x
            </span>

            <iframe
              src={Bposter9path}
              allowfullscreen
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="pptview6">
          <div className="hotspot6">
          {poster9 && Bposter9type === "pdf" && (
            <div className="inn66" onClick={this.P1openModal}></div>
          )}
          </div>
          {poster9 && (
          <Modal
            isOpen={this.state.modalIsOpen}
            style={customStyles}
            onRequestClose={this.P1closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P1closeModal}>
              x
            </span>

            <iframe
              src={Bposter9path}
              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="Videostream1">
          <div className="hotspot2">
          {poster9 && Bposter9type === "video" && (
            <div className="inn2" onClick={this.P7openModal}></div>
          )}
          </div>
          {poster9 && (
          <Modal
            isOpen={this.state.V1modalIsOpen}
            style={customStylesV1}
            onRequestClose={this.P7closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P7closeModal}>
              x
            </span>
            <video id="video" autoplay controls controlsList="nodownload">
                <source src={Bposter9path} />
              </video>
          </Modal>
          )}
        </div>

        <div id="pptview1">
          <div className="hotspot1">
          {poster10 && Bposter10type === "ppt" && (
            <div className="inn1" onClick={this.P2openModal}></div>
          )}
          </div>
          {poster10 && (
          <Modal
            isOpen={this.state.SecmodalIsOpen}
            style={customStyles}
            onRequestClose={this.P2closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P2closeModal}>
              x
            </span>

            <iframe
              src={Bposter10path}
              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="pptview7">
          <div className="hotspot7">
          {poster10 && Bposter10type === "pdf" && (
            <div className="inn77" onClick={this.P2openModal}></div>
          )}
          </div>
          {poster10 && (
          <Modal
            isOpen={this.state.SecmodalIsOpen}
            style={customStyles}
            onRequestClose={this.P2closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P2closeModal}>
              x
            </span>

            <iframe
              src={Bposter10path}
              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="Videostream2">
          <div className="hotspot3">
          {poster10 && Bposter10type === "video" && (
            <div className="inn3" onClick={this.P8openModal}></div>
          )}
          </div>
          {poster10 && (
          <Modal
            isOpen={this.state.V2modalIsOpen}
            style={customStylesV1}
            onRequestClose={this.P8closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P8closeModal}>
              x
            </span>

            <video id="video1" autoplay controls controlsList="nodownload">
                <source src={Bposter10path} />
              </video>
          </Modal>
          )}
        </div>

        <div id="pptview2">
          <div className="hotspot2">
          {poster11 && Bposter11type === "ppt" && (
            <div className="inn22" onClick={this.P3openModal}></div>
          )}
          </div>
          {poster11 && (
          <Modal
            isOpen={this.state.TmodalIsOpen}
            style={customStyles}
            onRequestClose={this.P3closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P3closeModal}>
              x
            </span>
            <iframe
              src={Bposter11path}
              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="pptview8">
          <div className="hotspot8">
          {poster11 && Bposter11type === "pdf" && (
            <div className="inn88" onClick={this.P3openModal}></div>
          )}
          </div>
          {poster11 && (
          <Modal
            isOpen={this.state.TmodalIsOpen}
            style={customStyles}
            onRequestClose={this.P3closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P3closeModal}>
              x
            </span>
            <iframe
              src={Bposter11path}
              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="Videostream3">
          <div className="hotspot4">
          {poster11 && Bposter11type === "video" && (
            <div className="inn4" onClick={this.P9openModal}></div>
          )}
          </div>
          {poster11 && (
          <Modal
            isOpen={this.state.V3modalIsOpen}
            style={customStylesV1}
            onRequestClose={this.P9closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P9closeModal}>
              x
            </span>
            <video id="video2" autoplay controls controlsList="nodownload">
                <source src={Bposter11path} />
              </video>
          </Modal>
          )}
        </div>

        <div id="pptview3">
          <div className="hotspot3">
          {poster12 && Bposter12type === "ppt" && (
            <div className="inn33" onClick={this.P4openModal}></div>
          )}
          </div>
          {poster12 && (
          <Modal
            isOpen={this.state.FmodalIsOpen}
            style={customStyles}
            onRequestClose={this.P4closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P4closeModal}>
              x
            </span>

            <iframe
              src={Bposter12path}

              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="pptview9">
          <div className="hotspot9">
          {poster12 && Bposter12type === "pdf" && (
            <div className="inn99" onClick={this.P4openModal}></div>
          )}
          </div>
          {poster12 && (
          <Modal
            isOpen={this.state.FmodalIsOpen}
            style={customStyles}
            onRequestClose={this.P4closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P4closeModal}>
              x
            </span>

            <iframe
              src={Bposter12path}

              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="Videostream4">
          <div className="hotspot5">
          {poster12 && Bposter12type === "video" && (
            <div className="inn5" onClick={this.P10openModal}></div>
          )}
          </div>
          {poster12 && (
          <Modal
            isOpen={this.state.V4modalIsOpen}
            style={customStylesV1}
            onRequestClose={this.P10closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P10closeModal}>
              x
            </span>
            <video id="video3" autoplay controls controlsList="nodownload">
                <source src={Bposter12path} />
              </video>
          </Modal>
          )}
        </div>

        <div id="pptview4">
          <div className="hotspot4">
          {poster13 && Bposter13type === "ppt" && (
            <div className="inn44" onClick={this.P5openModal}></div>
          )}
          </div>
          {poster13 && (
          <Modal
            isOpen={this.state.FimodalIsOpen}
            style={customStyles}
            onRequestClose={this.P5closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P5closeModal}>
              x
            </span>

            <iframe
              src={Bposter13path}

              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="pptview10">
          <div className="hotspot10">
          {poster13 && Bposter13type === "pdf" && (
            <div className="inn10" onClick={this.P5openModal}></div>
          )}
          </div>
          {poster13 && (
          <Modal
            isOpen={this.state.FimodalIsOpen}
            style={customStyles}
            onRequestClose={this.P5closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P5closeModal}>
              x
            </span>

            <iframe
              src={Bposter13path}

              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="Videostream5">
          <div className="hotspot6">
          {poster13 && Bposter13type === "video" && (
            <div className="inn6" onClick={this.P11openModal}></div>
          )}
          </div>
          {poster13 && (
          <Modal
            isOpen={this.state.V5modalIsOpen}
            style={customStylesV1}
            onRequestClose={this.P11closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P11closeModal}>
              x
            </span>
            <video id="video4" autoplay controls controlsList="nodownload">
                <source src={Bposter13path} />
              </video>


          </Modal>
          )}
        </div>

        <div id="pptview5">
          <div className="hotspot5">
          {poster14 && Bposter14type === "ppt" && (
            <div className="inn55" onClick={this.P6openModal}></div>
          )}
          </div>
          {poster14 && (
          <Modal
            isOpen={this.state.SmodalIsOpen}
            style={customStyles}
            onRequestClose={this.P6closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P6closeModal}>
              x
            </span>

            <iframe
               src={Bposter14path}

              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>

        <div id="pptview11">
          <div className="hotspot11">
          {poster14 && Bposter14type === "pdf" && (
            <div className="inn111" onClick={this.P6openModal}></div>
          )}
          </div>
          {poster14 && (
          <Modal
            isOpen={this.state.SmodalIsOpen}
            style={customStyles}
            onRequestClose={this.P6closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P6closeModal}>
              x
            </span>

            <iframe
               src={Bposter14path}

              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="Videostream6">
          <div className="hotspot7">
          {poster14 && Bposter14type === "video" && (
            <div className="inn7" onClick={this.P12openModal}></div>
          )}
          </div>
          {poster14 && (
          <Modal
            isOpen={this.state.V6modalIsOpen}
            style={customStylesV1}
            onRequestClose={this.P12closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P12closeModal}>
              x
            </span>
            <video id="video5" autoplay controls controlsList="nodownload">
                <source src={Bposter14path} />
              </video>
          </Modal>
          )}
        </div>

        <div id="pptview12">
          <div className="hotspot12">
          {poster15 && Bposter15type === "ppt" && (
            <div className="inn112 " onClick={this.P13openModal}></div>
          )}
          </div>
          {poster15 && (
          <Modal
            isOpen={this.state.P13modalIsOpen}
            style={customStyles}
            onRequestClose={this.P13closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P13closeModal}>
              x
            </span>

            <iframe
               src={Bposter15path}

              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>

        <div id="pptview13">
          <div className="hotspot13">
          {poster15 && Bposter15type === "pdf" && (
            <div className="inn113" onClick={this.P13openModal}></div>
          )}
          </div>
          {poster15 && (
          <Modal
            isOpen={this.state.P13modalIsOpen}
            style={customStyles}
            onRequestClose={this.P13closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P13closeModal}>
              x
            </span>

            <iframe
               src={Bposter15path}

              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="Videostream7">
          <div className="hotspot8">
          {poster15 && Bposter15type === "video" && (
            <div className="inn8" onClick={this.P14openModal}></div>
          )}
          </div>
          {poster15 && (
          <Modal
            isOpen={this.state.V14modalIsOpen}
            style={customStylesV1}
            onRequestClose={this.P14closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P14closeModal}>
              x
            </span>
            <video id="video5" autoplay controls controlsList="nodownload">
                <source src={Bposter15path} />
              </video>
          </Modal>
          )}
        </div>


        <div id="pptview14">
          <div className="hotspot14">
          {poster16 && Bposter16type === "ppt" && (
            <div className="inn114 " onClick={this.P15openModal}></div>
          )}
          </div>
          {poster16 && (
          <Modal
            isOpen={this.state.P15modalIsOpen}
            style={customStyles}
            onRequestClose={this.P13closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P15closeModal}>
              x
            </span>

            <iframe
               src={Bposter16path}

              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>

        <div id="pptview15">
          <div className="hotspot15">
          {poster16 && Bposter16type === "pdf" && (
            <div className="inn115" onClick={this.P15openModal}></div>
          )}
          </div>
          {poster16 && (
          <Modal
            isOpen={this.state.P15modalIsOpen}
            style={customStyles}
            onRequestClose={this.P15closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P15closeModal}>
              x
            </span>

            <iframe
               src={Bposter16path}

              frameborder="0"
            ></iframe>
          </Modal>
          )}
        </div>
        <div id="Videostream8">
          <div className="hotspot9">
          {poster16 && Bposter16type === "video" && (
            <div className="inn9" onClick={this.P16openModal}></div>
          )}
          </div>
          {poster15 && (
          <Modal
            isOpen={this.state.P16modalIsOpen}
            style={customStylesV1}
            onRequestClose={this.P16closeModal}
            ariaHideApp={false}
          >
            <span className="close" onClick={this.P16closeModal}>
              x
            </span>
            <video id="video5" autoplay controls controlsList="nodownload">
                <source src={Bposter16path} />
              </video>
          </Modal>
          )}
        </div>

      </div>
    );
  }
}

export default Boardroom;
