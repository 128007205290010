import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Agenda from "./img/CAPGEMINI AGENDA REVISED F-04 REVISED.jpg";
import AWSService from "../services/aws.service";
import EventService from "../services/event.service";
import Preloader from "./preloader.component";
import "./styles/maping.css";
import PPTPathService from "../services/pptpath.service";
import { Link } from "react-router-dom";

import $ from "jquery";
import "../App.css";
let tenant = "";
const { aws_s3_url } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
// const AWS_S3_URL =
//   "https://coact-stream-bucket.s3.ap-southeast-2.amazonaws.com/";

class AgendafunctionEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initially, no file is selected
      selectedFile: null,
      poster01: false,
      poster02: false,
      poster3: false,
      poster4: false,
      poster5: false,
      poster6: false,
      poster7: false,
      poster8: false,
      poster9: false,
      poster10: false,
      poster11: false,
      poster12: false,
      poster13: false,
      poster14: false,
      poster15: false,
      poster16: false,
      poster17: false,
      poster18: false,
      R1poster3path: "",
      R1poster02path: "",
      R1poster01path: "",
      R1poster4path: "",
      R1poster5path: "",
      R1poster6path: "",
      R1poster7path: "",
      R1poster8path: "",
      R1poster9path: "",
      R1poster10path: "",
      R1poster11path: "",
      R1poster12path: "",
      R1poster13path: "",
      R1poster14path: "",
      R1poster15path: "",
      R1poster16path: "",
      R1poster17path: "",
      R1poster18path: "",
      R1poster3type: "",
      edit1: "",
      RC1: true,
      RC2: true,
      RC3: true,
      RC4: true,
      RC5: true,
      RC6: true,
      RC7: true,
      RC8: true,
      RC9: true,
      RC10: true,
      RC11: true,
      RC12: true,
      RC13: true,
      RC14: true,
      RC15: true,
      RC16: true,
      RC17: true,
      RC18: true,
      tenantname: tenant,
    };
  }

  componentDidMount() {
    EventService.getSpecificEvent().then((response) => {
      console.log("Eventname from getSpecificEvent");
      console.log(response.data);
      console.log(response.data.eventname);
      const eventpath = "/" + response.data.eventname;
      this.setState({ tenantname: eventpath, showLoginPage: true });
      localStorage.setItem("eventname", response.data.eventname);
      console.log(response.data.eventname);
    });
    EventService.getEvents1().then((response) => {
      // setevent(response.data);
      console.log(response.data);

      console.log(response.data.RC1);
      if (response.data.RC1 != null) {
        // console.log( response.data[0].id);
        this.setState({
          RC1: false,
          edit1: "ResourceEdit",
        });
      }
      if (response.data.RC2 != null) {
        this.setState({
          RC2: false,
          // RC1:false
        });
      }
      // }
      // {

      // }

      if (response.data.RC3 != null) {
        console.log("heloooooooooooooooo");
        console.log(response.data.RC3);
        // console.log( response.data[0].id);
        // setRC2(!RC2);
        this.setState({
          RC3: false,
          // RC1:false
        });
      }
      if (response.data.RC4 != null) {
        // setRC2(!RC2);
        // setRC3(!RC3);
        this.setState({
          RC4: false,
          // RC1:false
        });
      }
      if (response.data.RC5 != null) {
        // console.log( response.data[0].id);
        // setRC4(!RC4);
        this.setState({
          RC5: false,
          // RC1:false
        });
      }
      if (response.data.RC6 != null) {
        // console.log( response.data[0].id);
        // setRC4(!RC4);
        this.setState({
          RC6: false,
          // RC1:false
        });
      }

      if (response.data.RC7 != null) {
        // console.log( response.data[0].id);
        // setRC4(!RC4);
        this.setState({
          RC7: false,
          // RC1:false
        });
      }

      if (response.data.RC8 != null) {
        // console.log( response.data[0].id);
        // setRC4(!RC4);
        this.setState({
          RC8: false,
          // RC1:false
        });
      }

      if (response.data.RC9 != null) {
        // console.log( response.data[0].id);
        // setRC4(!RC4);
        this.setState({
          RC9: false,
          // RC1:false
        });
      }

      if (response.data.RC10 != null) {
        // console.log( response.data[0].id);
        // setRC4(!RC4);
        this.setState({
          RC10: false,
          // RC1:false
        });
      }

      if (response.data.RC11 != null) {
        // console.log( response.data[0].id);
        // setRC4(!RC4);
        this.setState({
          RC11: false,
          // RC1:false
        });
      }

      if (response.data.RC12 != null) {
        // console.log( response.data[0].id);
        // setRC4(!RC4);
        this.setState({
          RC12: false,
          // RC1:false
        });
      }

      if (response.data.RC13 != null) {
        // console.log( response.data[0].id);
        // setRC4(!RC4);
        this.setState({
          RC13: false,
          // RC1:false
        });
      }

      if (response.data.RC14 != null) {
        // console.log( response.data[0].id);
        // setRC4(!RC4);
        this.setState({
          RC14: false,
          // RC1:false
        });
      }

      if (response.data.RC15 != null) {
        // console.log( response.data[0].id);
        // setRC4(!RC4);
        this.setState({
          RC15: false,
          // RC1:false
        });
      }

      if (response.data.RC16 != null) {
        // console.log( response.data[0].id);
        // setRC4(!RC4);
        this.setState({
          RC16: false,
          // RC1:false
        });
      }

      if (response.data.RC17 != null) {
        // console.log( response.data[0].id);
        // setRC4(!RC4);
        this.setState({
          RC17: false,
          // RC1:false
        });
      }

      if (response.data.RC18 != null) {
        // console.log( response.data[0].id);
        // setRC4(!RC4);
        this.setState({
          RC18: false,
          // RC1:false
        });
      }
      //  if(response.data.innovationLabNum == '2'){
      //   setisInnovationLab1(!isInnovationLab1);
      //   setisInnovationLab2(!isInnovationLab2);
    });

    const eventname = EventService.getCurrentEvent();
    //  this.setState({ tenantname: eventpath, showLoginPage: true });

    console.log(eventname);
    var num = Math.random();
    PPTPathService.getFiletype(eventname, "Rs1").then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster3") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster3path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster3" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster3: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster3path: filedetails.path,
              poster3: true,
            });
            this.setState({ R1poster3type: "txt" });
          }
        }
      });
    });

    PPTPathService.getFiletype(eventname, "Rs1").then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster01") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster01path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster01" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster01: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster01path: filedetails.path,
              poster01: true,
            });
            this.setState({ R1poster01type: "txt" });
          }
        }

        if (filedetails.filename === "poster02") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster02path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster02" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster02: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster02path: filedetails.path,
              poster02: true,
            });
            this.setState({ R1poster02type: "txt" });
          }
        }

        if (filedetails.filename === "poster4") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster4path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster4" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster4: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster4path: filedetails.path,
              poster4: true,
            });
            this.setState({ R1poster4type: "txt" });
          }
        }
        if (filedetails.filename === "poster5") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster5path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster5" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster5: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster5path: filedetails.path,
              poster5: true,
            });
            this.setState({ R1poster5type: "txt" });
          }
        }

        if (filedetails.filename === "poster6") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster6path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster6" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster6: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster6path: filedetails.path,
              poster6: true,
            });
            this.setState({ R1poster6type: "txt" });
          }
        }

        if (filedetails.filename === "poster7") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster7path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster7" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster7: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster7path: filedetails.path,
              poster7: true,
            });
            this.setState({ R1poster7type: "txt" });
          }
        }

        if (filedetails.filename === "poster8") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster8path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster8" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster8: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster8path: filedetails.path,
              poster8: true,
            });
            this.setState({ R1poster8type: "txt" });
          }
        }

        if (filedetails.filename === "poster9") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster9path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster9" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster9: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster9path: filedetails.path,
              poster9: true,
            });
            this.setState({ R1poster9type: "txt" });
          }
        }

        if (filedetails.filename === "poster10") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster10path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster10" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster10: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster10path: filedetails.path,
              poster10: true,
            });
            this.setState({ R1poster10type: "txt" });
          }
        }

        if (filedetails.filename === "poster11") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster11path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster11" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster11: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster11path: filedetails.path,
              poster11: true,
            });
            this.setState({ R1poster11type: "txt" });
          }
        }

        if (filedetails.filename === "poster12") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster12path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster12" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster12: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster12path: filedetails.path,
              poster12: true,
            });
            this.setState({ R1poster12type: "txt" });
          }
        }

        if (filedetails.filename === "poster13") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster13path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster13" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster13: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster13path: filedetails.path,
              poster13: true,
            });
            this.setState({ R1poster13type: "txt" });
          }
        }

        if (filedetails.filename === "poster14") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster14path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster14" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster14: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster14path: filedetails.path,
              poster14: true,
            });
            this.setState({ R1poster14type: "txt" });
          }
        }

        if (filedetails.filename === "poster15") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster15path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster15" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster15: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster15path: filedetails.path,
              poster15: true,
            });
            this.setState({ R1poster15type: "txt" });
          }
        }

        if (filedetails.filename === "poster16") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster16path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster16" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster16: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster16path: filedetails.path,
              poster16: true,
            });
            this.setState({ R1poster16type: "txt" });
          }
        }

        if (filedetails.filename === "poster17") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster17path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster17" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster17: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster17path: filedetails.path,
              poster17: true,
            });
            this.setState({ R1poster17type: "txt" });
          }
        }

        if (filedetails.filename === "poster18") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster18path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster18" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster18: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster18path: filedetails.path,
              poster18: true,
            });
            this.setState({ R1poster18type: "txt" });
          }
        }
      });
    });

    this.setState({
      Aposter1path:
        AWS_S3_URL + "capgemini/" + eventname + "/Ag" + "/poster1.png?v=" + num,
      Aposter2path:
        AWS_S3_URL + "capgemini/" + eventname + "/Ag" + "/poster2.png?v=" + num,
      Aposter3path:
        AWS_S3_URL + "capgemini/" + eventname + "/Ag" + "/poster3.png?v=" + num,
      Aposter4path:
        AWS_S3_URL + "capgemini/" + eventname + "/Ag" + "/poster4.png?v=" + num,
      Aposter5path:
        AWS_S3_URL + "capgemini/" + eventname + "/Ag" + "/poster5.png?v=" + num,
      Aposter6path:
        AWS_S3_URL + "capgemini/" + eventname + "/Ag" + "/poster6.png?v=" + num,
      Aposter7path:
        AWS_S3_URL + "capgemini/" + eventname + "/Ag" + "/poster7.png?v=" + num,
      poster1: true,
      poster2: true,
      poster3: true,
      poster4: true,
      poster5: true,
      poster6: true,
      poster7: true,
    });
  }
  render() {
    function hideImg() {
      document.getElementById("myImg").style.display = "none";
    }
    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }
    function hideImg3() {
      document.getElementById("myImg3").style.display = "none";
    }
    function hideImg4() {
      document.getElementById("myImg4").style.display = "none";
    }
    function hideImg5() {
      document.getElementById("myImg5").style.display = "none";
    }
    function hideImg7() {
      document.getElementById("myImg7").style.display = "none";
    }
    const {
      poster01,
      poster02,
      poster3,
      poster4,
      poster5,
      poster6,
      poster7,
      poster8,
      poster9,
      poster10,
      poster11,
      poster12,
      poster13,
      poster14,
      poster15,
      poster16,
      poster17,
      poster18,

      R1poster3path,
      R1poster02path,
      Aposter7path,
      R1poster01path,
      R1poster4path,
      R1poster5path,
      R1poster6path,
      R1poster7path,
      R1poster8path,
      R1poster9path,
      R1poster10path,
      R1poster11path,
      R1poster12path,
      R1poster13path,
      R1poster14path,
      R1poster15path,
      R1poster16path,
      R1poster17path,
      R1poster18path,
      R1poster3type,

      RC1,
      RC2,
      RC3,
      RC4,
      RC5,
      RC6,
      RC7,
      RC8,
      RC9,
      RC10,
      RC11,
      RC12,
      RC13,
      RC14,
      RC15,
      RC16,
      RC17,
      RC18,
      tenantname,
      edit1,
    } = this.state;
    console.log("hello");
    console.log(RC1);
    console.log(RC2);
    console.log(RC3);

    return (
      <div className="container-fluide">
        <div className="page-content">
          <div id="content-agenda">
            <div id="bg">
              <img src={Agenda} id="bg-image" className="mb-3" />
              <div class="resource-btn">
                {!RC1 ? (
                  <Link
                    to={tenantname + "/Resource"}
                    className="Rs1 button-1  text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}

                {!RC2 ? (
                  <Link
                    to={tenantname + "/Resourceg2"}
                    className="Rs2 button-2 text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}
                {!RC3 ? (
                  <Link
                    to={tenantname + "/Resourceg3"}
                    className="Rs3 button-3 text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}
                {!RC4 ? (
                  <Link
                    to={tenantname + "/Resource1"}
                    className="Rs4 button-4 text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}

                {!RC5 ? (
                  <Link
                    to={tenantname + "/Resource1g2"}
                    className="Rs5 button-5 text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}

                {!RC6 ? (
                  <Link
                    to={tenantname + "/resource1g3"}
                    className="Rs5 button-6 text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}

                {!RC7 ? (
                  <Link
                    to={tenantname + "/Resource2"}
                    className="Rs5 button-7 text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}

                {!RC8 ? (
                  <Link
                    to={tenantname + "/Resource2g2"}
                    className="Rs5 button-8 text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}

                {!RC9 ? (
                  <Link
                    to={tenantname + "/Resource2g3"}
                    className="Rs5 button-9 text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}

                {!RC10 ? (
                  <Link
                    to={tenantname + "/Resource3"}
                    className="Rs5 button-10 text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}

                {/* {!RC11  ?   (
            <Link to={tenantname + "/Resource3g2"} className="Rs5 button-11 text-white buttonRes">
                              <span className="hide-menu">Resource Center11</span>
                  </Link>
          ):  null}   

          {!RC12  ?   (
            <Link to={tenantname + "/Resource3g3"} className="Rs5 button-12 text-white buttonRes">
                              <span className="hide-menu">Resource Center12</span>
                  </Link>
          ):  null}  

{!RC13  ?   (
            <Link to={tenantname + "/Resource4"} className="Rs5 button-13 text-white buttonRes">
                              <span className="hide-menu">Resource Center13</span>
                  </Link>
          ):  null}  

{!RC14  ?   (
            <Link to={tenantname + "/Resource4g2"} className="Rs5 button-14 text-white buttonRes">
                              <span className="hide-menu">Resource Center14</span>
                  </Link>
          ):  null} 

{!RC15  ?   (
            <Link to={tenantname + "/Resource4g3"} className="Rs5 button-15 text-white buttonRes">
                              <span className="hide-menu">Resource Center15</span>
                  </Link>
          ):  null} 

{!RC16  ?   (
            <Link to={tenantname + "/Resource5"} className="Rs5 button-16 text-white buttonRes">
                              <span className="hide-menu">Resource Center16</span>
                  </Link>
          ):  null} 

{!RC17  ?   (
            <Link to={tenantname + "/Resource5g2"} className="Rs5 button-17 text-white buttonRes">
                              <span className="hide-menu">Resource Center17</span>
                  </Link>
          ):  null} 

{!RC18  ?   (
            <Link to={tenantname + "/Resource5g3"} className="Rs5 button-18 text-white buttonRes">
                              <span className="hide-menu">Resource Center18</span>
                  </Link>
          ):  null}  */}
              </div>
              {/* <div className="sidenavogation">
           
                  {  (
                  
                    <Link to={tenantname + "/Agenda"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Back To Agenda</span>
                  </Link>
                  


            ) }
            
           
                  </div> */}

              {/* <div class="resource-btn">
                 
              <Link to={tenantname + "/Resource"} className="Rs1 button-1  text-white buttonRes">
                              <span className="hide-menu">Resource Center1</span>
                  </Link>
                            

                                <Link to={tenantname + "/Resource1"} className="Rs2 button-2 text-white buttonRes">
                              <span className="hide-menu">Resource Center2</span>
                  </Link>
    
            <Link to={tenantname + "/Resource2"} className="Rs3 button-3 text-white buttonRes">
                              <span className="hide-menu">Resource Center3</span>
                  </Link>
          
            <Link to={tenantname + "/Resource3"} className="Rs4 button-4 text-white buttonRes">
                              <span className="hide-menu">Resource Center4</span>
                              </Link>
          
            <Link to={tenantname + "/Resource4"} className="Rs5 button-5 text-white buttonRes">
                              <span className="hide-menu">Resource Center5</span>
                  </Link>
         
 
            <Link to={tenantname + "/Resource5"} className="Rs5 button-6 text-white buttonRes">
                              <span className="hide-menu">Resource Center6</span>
                  </Link>
       
            <Link to={tenantname + "/Resourceg2"} className="Rs5 button-7 text-white buttonRes">
                              <span className="hide-menu">Resource Center7</span>
                  </Link>
        
            <Link to={tenantname + "/Resource1g2"} className="Rs5 button-8 text-white buttonRes">
                              <span className="hide-menu">Resource Center8</span>
                  </Link>
        
            <Link to={tenantname + "/Resource2g2"} className="Rs5 button-9 text-white buttonRes">
                              <span className="hide-menu">Resource Center9</span>
                  </Link>
         
            <Link to={tenantname + "/Resource3g2"} className="Rs5 button-10 text-white buttonRes">
                              <span className="hide-menu">Resource Center10</span>
                  </Link>
         
            <Link to={tenantname + "/Resource4g2"} className="Rs5 button-11 text-white buttonRes">
                              <span className="hide-menu">Resource Center11</span>
                  </Link>
         
            <Link to={tenantname + "/Resource5g2"} className="Rs5 button-12 text-white buttonRes">
                              <span className="hide-menu">Resource Center12</span>
                  </Link>
         
            <Link to={tenantname + "/Resourceg3"} className="Rs5 button-13 text-white buttonRes">
                              <span className="hide-menu">Resource Center13</span>
                  </Link>
         
            <Link to={tenantname + "/Resource1g3"} className="Rs5 button-14 text-white buttonRes">
                              <span className="hide-menu">Resource Center14</span>
                  </Link>
          
            <Link to={tenantname + "/Resource2g3"} className="Rs5 button-15 text-white buttonRes">
                              <span className="hide-menu">Resource Center15</span>
                  </Link>
          
            <Link to={tenantname + "/Resource3g3"} className="Rs5 button-16 text-white buttonRes">
                              <span className="hide-menu">Resource Center16</span>
                  </Link>
          
            <Link to={tenantname + "/Resource4g3"} className="Rs5 button-17 text-white buttonRes">
                              <span className="hide-menu">Resource Center17</span>
                  </Link>
         
            <Link to={tenantname + "/Resource5g3"} className="Rs5 button-18 text-white buttonRes">
                              <span className="hide-menu">Resource Center18</span>
                  </Link>
          
                  </div> */}
              {!RC1 ? (
                <div id="Resourceposter1">
                  {poster01 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap33">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          // name={R1poster3path}
                          value={R1poster01path}
                          // accept="image/png, image/gif, image/jpeg"
                          // onChange={this.onFileChange03}
                          id="ResText1"
                          readOnly
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {!RC2 ? (
                <div id="Resourceposter2">
                  {poster02 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap33">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          // name={R1poster3path}
                          value={R1poster02path}
                          // accept="image/png, image/gif, image/jpeg"
                          // onChange={this.onFileChange03}
                          id="ResText1"
                          readOnly
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {!RC3 ? (
                <div id="Resourceposter3">
                  {poster3 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap33">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          // name={R1poster3path}
                          value={R1poster3path}
                          // accept="image/png, image/gif, image/jpeg"
                          // onChange={this.onFileChange03}
                          id="ResText1"
                          readOnly
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {!RC4 ? (
                <div id="Resourceposter4">
                  {poster4 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap33">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          // name={R1poster3path}
                          value={R1poster4path}
                          // accept="image/png, image/gif, image/jpeg"
                          // onChange={this.onFileChange03}
                          id="ResText1"
                          readOnly
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {!RC5 ? (
                <div id="Resourceposter5">
                  {poster5 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap33">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          // name={R1poster3path}
                          value={R1poster5path}
                          // accept="image/png, image/gif, image/jpeg"
                          // onChange={this.onFileChange03}
                          id="ResText1"
                          readOnly
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {!RC6 ? (
                <div id="Resourceposter6">
                  {poster6 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap33">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          // name={R1poster3path}
                          value={R1poster6path}
                          // accept="image/png, image/gif, image/jpeg"
                          // onChange={this.onFileChange03}
                          id="ResText1"
                          readOnly
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {!RC7 ? (
                <div id="Resourceposter7">
                  {poster7 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap33">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          // name={R1poster3path}
                          value={R1poster7path}
                          // accept="image/png, image/gif, image/jpeg"
                          // onChange={this.onFileChange03}
                          id="ResText1"
                          readOnly
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {!RC8 ? (
                <div id="Resourceposter8">
                  {poster8 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap33">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          // name={R1poster3path}
                          value={R1poster8path}
                          // accept="image/png, image/gif, image/jpeg"
                          // onChange={this.onFileChange03}
                          id="ResText1"
                          readOnly
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {!RC9 ? (
                <div id="Resourceposter9">
                  {poster9 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap33">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          // name={R1poster3path}
                          value={R1poster9path}
                          // accept="image/png, image/gif, image/jpeg"
                          // onChange={this.onFileChange03}
                          id="ResText1"
                          readOnly
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {!RC10 ? (
                <div id="Resourceposter10">
                  {poster10 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap33">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          // name={R1poster3path}
                          value={R1poster10path}
                          // accept="image/png, image/gif, image/jpeg"
                          // onChange={this.onFileChange03}
                          id="ResText1"
                          readOnly
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {/* {!RC11  ?   (
              <div id="Resourceposter11">
              {poster11 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap33" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={R1poster3path}
                        value={R1poster11path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>
               
              </form>
              )}
              </div>
              ) :null}


               
{!RC12  ?   (
              <div id="Resourceposter12">
              {poster12 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap33" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={R1poster3path}
                        value={R1poster12path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>
               
              </form>
              )}
              </div>

              ) :null}


               
{!RC13 ?   (
              <div id="Resourceposter13">
              {poster13 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap33" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={R1poster3path}
                        value={R1poster13path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>
               
              </form>
              )}
              </div>

              ) :null}



               
{!RC14  ?   (
              <div id="Resourceposter14">
              {poster14 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap33" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={R1poster3path}
                        value={R1poster14path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>
               
              </form>
              )}
              </div>
              ) :null}


               
{!RC15  ?   (
              <div id="Resourceposter15">
              {poster15 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap33" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={R1poster3path}
                        value={R1poster15path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>
               
              </form>
              )}
              </div>
              ) :null}


               
{!RC16  ?   (
              <div id="Resourceposter16">
              {poster16 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap33" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={R1poster3path}
                        value={R1poster16path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>
               
              </form>
              )}
              </div>
              ) :null}


               
{!RC17  ?   (
              <div id="Resourceposter17">
              {poster17 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap33" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={R1poster3path}
                        value={R1poster17path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>
               
              </form>
              )}
              </div>
              ) :null}


               
{!RC18  ?   (
              <div id="Resourceposter18">
              {poster18 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="Resourcemap33" >
                <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        // name={R1poster3path}
                        value={R1poster18path}
                        // accept="image/png, image/gif, image/jpeg"
                        // onChange={this.onFileChange03}
                        id="ResText1"
                        readOnly
                      />
                </div>
               
              </form>
              )}
              </div>
              ) :null} */}
              {/* <div id="ses01">
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div>
                  <div className="agendamap11">
                  </div>
                </div>
              </div> */}

              {/* <div id="ses01">
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div>
                  <div className="agendamap11">
                  <img
                    src={Aposter1path}
                    id="myImg"
                   
                    onError={() => hideImg()}
                  /></div>
                </div>
              </div>
              <div id="ses02">
                <div
                  id="loader2"
                  className="lds-dual-ring hidden overlay"
                ></div>
              <div className="agendamap22">
                  <img
                    src={Aposter2path}
                    id="myImg1"
                   
                    onError={() => hideImg1()}
                  />
                   
                </div>
              </div>
              <div id="ses03">
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="agendamap33">
                  <img
                    src={Aposter3path}
                    id="myImg2"
                    
                    onError={() => hideImg2()}
                  /></div>
                
              </div>
              <div id="ses04">
                <div
                  id="loader4"
                  className="lds-dual-ring hidden overlay"
                ></div>
                 <div className="agendamap44">
                  <img
                    src={Aposter4path}
                    id="myImg3"
                   
                    onError={() => hideImg3()}
                  />
                </div>
              </div>
              <div id="ses05">
                <div
                  id="loader5"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="agendamap55">
                  <img
                    src={Aposter5path}
                    id="myImg4"
                   
                    onError={() => hideImg4()}
                  />
                </div>
              </div>
              <div id="ses06">
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                 <div className="agendamap66">
                  <img
                    src={Aposter6path}
                    id="myImg5"
                   
                    onError={() => hideImg5()}
                  />
                </div>
              </div> */}

              {/* <div id="ses07">
                <div
                  id="loader7"
                  className="lds-dual-ring hidden overlay"
                ></div>
                 <div className="agendamap77">
                  <img
                    src={Aposter7path}
                    id="myImg7"
                   
                    onError={() => hideImg7()}
                  />
                </div>
                
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AgendafunctionEdit;
