import axios from "axios";
import authHeader from "./auth-header";
const { server_port, domain, react_env } = require("../config/config");
let API_URL = "";
if (react_env == "local") API_URL = domain + ":" + server_port + "/";
else API_URL = domain + "/";

class TenantService {
  // getTenants() {
  //   return axios.get(API_URL + "gettenants", { headers: authHeader() });
  // }
  // setTenant(tenantname, adminnos, modnos, eventnos, status, usernos) {
  //   return axios.post(
  //     API_URL + "settenant",
  //     { tenantname, adminnos, modnos, eventnos, status, usernos },
  //     { headers: authHeader() }
  //   );
  // }
  // delTenant(id) {
  //   return axios.post(
  //     API_URL + "deletetenant",
  //     { id },
  //     { headers: authHeader() }
  //   );
  // }
}

export default new TenantService();
