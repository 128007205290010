import React, { PureComponent } from "react";
import Marzipano from "marzipano";
import EventService from "../services/event.service";
import "./styles/styles.css";
import Modal from "react-modal";
import PPTPathService from "../services/pptpath.service";
import Preloader from "./preloader.component";
import UnAuthorizedService from "../common/unauthorized";
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "70vh",
    marginRight: "-50%",
    width: "calc(60% - 10px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    border: "unset !important",
    margintop: "calc(85vh - 10vh - 10px)",
    zIndex: "2",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(50% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding:"0 0 0 0"
  },
};
const { aws_s3_url } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
const linkToPPTFile =
  "https://scholar.harvard.edu/files/torman_personal/files/samplepptx.pptx";
var lobbyeventname = ";";

class Engagement1 extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // Initially, no file is selected
      selectedFile: null,
      poster1: false,
      poster2: false,
      poster3: false,
      poster4: false,
      poster5: false,
      poster6: false,
      poster7: false,
      poster8: false,
      poster9: false,
      poster10: false,
      poster11: false,
      poster12: false,
      poster13: false,

      Eposter1path: "",
      Eposter2path: "",
      Eposter3path: "",
      Eposter4path: "",
      Eposter5path: "",
      Eposter6path: "",
      Eposter7path: "",
      Eposter8path: "",
      Eposter9path: "",
      Eposter10path: "",
      Eposter11path: "",
      Eposter12path: "",
      Eposter13path: "",
      modalIsOpen: false,
      P1modalIsOpen: false,
      P2modalIsOpen: false,
      P3modalIsOpen: false,
      P4modalIsOpen: false,
      P5modalIsOpen: false,
      P6modalIsOpen: false,
      P7modalIsOpen: false,
      P8modalIsOpen: false,
      P9modalIsOpen: false,
      P10modalIsOpen: false,
      P11modalIsOpen: false,

    };
  }
  PopenModal = () => {
    this.setState({ modalIsOpen: true });
  };

  PcloseModal = () => {
    this.setState({ modalIsOpen: false });
  };
  P1openModal = () => {
    this.setState({ P1modalIsOpen: true });
  };

  P1closeModal = () => {
    this.setState({ P1modalIsOpen: false });
  };
  P2openModal = () => {
    this.setState({ P2modalIsOpen: true });
  };

  P2closeModal = () => {
    this.setState({ P2modalIsOpen: false });
  };
  P3openModal = () => {
    this.setState({ P3modalIsOpen: true });
  };

  P3closeModal = () => {
    this.setState({ P3modalIsOpen: false });
  };

  P4openModal = () => {
    this.setState({ P4modalIsOpen: true });
  };

  P4closeModal = () => {
    this.setState({ P4modalIsOpen: false });
  };
  P5openModal = () => {
    this.setState({ P5modalIsOpen: true });
  };

  P5closeModal = () => {
    this.setState({ P5modalIsOpen: false });
  };
  P6openModal = () => {
    this.setState({ P6modalIsOpen: true });
  };

  P6closeModal = () => {
    this.setState({ P6modalIsOpen: false });
  };
  P7openModal = () => {
    this.setState({ P7modalIsOpen: true });
  };

  P7closeModal = () => {
    this.setState({ P7modalIsOpen: false });
  };
  P8openModal = () => {
    this.setState({ P8modalIsOpen: true });
  };

  P8closeModal = () => {
    this.setState({ P8modalIsOpen: false });
  };
  P9openModal = () => {
    this.setState({ P9modalIsOpen: true });
  };

  P9closeModal = () => {
    this.setState({ P9modalIsOpen: false });
  };
  P10openModal = () => {
    this.setState({ P10modalIsOpen: true });
  };

  P10closeModal = () => {
    this.setState({ P10modalIsOpen: false });
  };
  P11openModal = () => {
    this.setState({ P11modalIsOpen: true });
  };

  P11closeModal = () => {
    this.setState({ P11modalIsOpen: false });
  };

  static displayName = "Pano";
  componentDidMount() {
    var num = Math.random();
    const eventname = EventService.getCurrentEvent();
    console.log(eventname);
    PPTPathService.getFiletype(eventname, "Eng").then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster6") {
          this.setState({
            Eposter6path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Eng" +
              "/poster6" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,

            poster6: true,
            // Eposter6type: filedetails.filetype,
          });
          if (
            filedetails.filetype === "mp4" ||
            filedetails.filetype === "mov" ||
            filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
          ) {
            this.setState({ Eposter6type: "video" });
          }
          if (
            filedetails.filetype === "pptx" ||
            filedetails.filetype === "ppt"
          ) {
            {
              this.setState({
                Eposter6path:filedetails.path,
                poster6: true,

              });
              this.setState({ Eposter6type: "ppt" });
            }
          }
          if (
            filedetails.filetype === "pdf"
          ) {

              this.setState({ Eposter6type: "pdf" });
            }
          console.log(this.state.Eposter6path);
        }
        if (filedetails.filename === "poster7") {
          this.setState({
            Eposter7path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Eng" +
              "/poster7" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,

            poster7: true,
            // Eposter7type: filedetails.filetype,
          });
          if (
            filedetails.filetype === "mp4" ||
            filedetails.filetype === "mov" ||
            filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
          ) {
            this.setState({ Eposter7type: "video" });
          }
          if (
            filedetails.filetype === "pptx" ||
            filedetails.filetype === "ppt"
          ) {
            {
              this.setState({
                Eposter7path:filedetails.path,
                poster7: true,

              });
              this.setState({ Eposter7type: "ppt" });
            }
          }
          if (
            filedetails.filetype === "pdf"
          ) {

              this.setState({ Eposter7type: "pdf" });
            }
          console.log(this.state.Eposter7path);
        }
        if (filedetails.filename === "poster8") {
          this.setState({
            Eposter8path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Eng" +
              "/poster8" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,

            poster8: true,
            // Eposter8type: filedetails.filetype,
          });
          if (
            filedetails.filetype === "mp4" ||
            filedetails.filetype === "mov" ||
            filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
          ) {
            this.setState({ Eposter8type: "video" });
          }
          if (
            filedetails.filetype === "pptx" ||
            filedetails.filetype === "ppt"
          ) {
            {
              this.setState({
                Eposter8path:filedetails.path,
                poster8: true,

              });
              this.setState({ Eposter8type: "ppt" });
            }
          }
          if (
            filedetails.filetype === "pdf"
          ) {

              this.setState({ Eposter8type: "pdf" });
            }
          console.log(this.state.Eposter8path);
        }
        if (filedetails.filename === "poster9") {
          this.setState({
            Eposter9path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Eng" +
              "/poster9" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,

            poster9: true,
            // Eposter9type: filedetails.filetype,
          });
          if (
            filedetails.filetype === "mp4" ||
            filedetails.filetype === "mov" ||
            filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
          ) {
            this.setState({ Eposter9type: "video" });
          }
          if (
            filedetails.filetype === "pptx" ||
            filedetails.filetype === "ppt"
          ) {
            {
              this.setState({
                Eposter9path:filedetails.path,
                poster9: true,

              });
              this.setState({ Eposter9type: "ppt" });
            }
          }
          if (
            filedetails.filetype === "pdf"
          ) {

              this.setState({ Eposter9type: "pdf" });
            }
          console.log(this.state.Eposter9path);
        }
        if (filedetails.filename === "poster10") {
          this.setState({
            Eposter10path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Eng" +
              "/poster10" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,

            poster10: true,
            // Eposter10type: filedetails.filetype,
          });
          if (
            filedetails.filetype === "mp4" ||
            filedetails.filetype === "mov" ||
            filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
          ) {
            this.setState({ Eposter10type: "video" });
          }
          if (
            filedetails.filetype === "pptx" ||
            filedetails.filetype === "ppt"
          ) {
            {
              this.setState({
                Eposter10path:filedetails.path,
                poster10: true,

              });
              this.setState({ Eposter10type: "ppt" });
            }
          }
          if (
            filedetails.filetype === "pdf"
          ) {

              this.setState({ Eposter10type: "pdf" });
            }
          console.log(this.state.Eposter10path);
        }
        if (filedetails.filename === "poster11") {
          this.setState({
            Eposter11path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Eng" +
              "/poster11" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,

            poster11: true,
            // Eposter10type: filedetails.filetype,
          });
          if (
            filedetails.filetype === "mp4" ||
            filedetails.filetype === "mov" ||
            filedetails.filetype === "webm" ||
            filedetails.filetype === "ogv"
          ) {
            this.setState({ Eposter11type: "video" });
          }
          if (
            filedetails.filetype === "pptx" ||
            filedetails.filetype === "ppt"
          ) {
            {

                this.setState({
                  Eposter11path:filedetails.path,
                  poster11: true,

                });
              this.setState({ Eposter11type: "ppt" });
            }
          }
          if (
            filedetails.filetype === "pdf"
          ) {

              this.setState({ Eposter11type: "pdf" });
            }
          console.log(this.state.Eposter11path);
        }
      });
    }).catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });
    this.setState({
      Eposter1path:
        AWS_S3_URL +
        "capgemini/" +
        eventname +
        "/Eng" +
        "/poster1.png?v=" +
        num,
      Eposter2path:
        AWS_S3_URL +
        "capgemini/" +
        eventname +
        "/Eng" +
        "/poster2.png?v=" +
        num,
      Eposter3path:
        AWS_S3_URL +
        "capgemini/" +
        eventname +
        "/Eng" +
        "/poster3.png?v=" +
        num,
      Eposter4path:
        AWS_S3_URL +
        "capgemini/" +
        eventname +
        "/Eng" +
        "/poster4.png?v=" +
        num,
      Eposter5path:
        AWS_S3_URL +
        "capgemini/" +
        eventname +
        "/Eng" +
        "/poster5.png?v=" +
        num,
        Eposter12path:
        AWS_S3_URL +
        "capgemini/" +
        eventname +
        "/Eng" +
        "/poster12.png?v=" +
        num,
        Eposter13path:
        AWS_S3_URL +
        "capgemini/" +
        eventname +
        "/Eng" +
        "/poster13.png?v=" +
        num,
      poster1: true,
      poster2: true,
      poster3: true,
      poster4: true,
      poster5: true,
      poster12: true,
      poster13: true,
      // poster6: true,
      // poster7: true,
      // poster8: true,
      // poster9: true,
      // poster10: true,
      // poster11: true,
    });
    this.viewer = new Marzipano.Viewer(this.pano); //, viewerOpts);

    // Create source.
    const source = Marzipano.ImageUrlSource.fromString("https://d2e8va4puks5pl.cloudfront.net/engagement.png");

    // Create geometry.
    const geometry = new Marzipano.EquirectGeometry([{ width: 5000 }]);

    const limiter = Marzipano.RectilinearView.limit.traditional(
      4*1024,
      (100 * Math.PI) / 180
    );
    const view = new Marzipano.RectilinearView({ yaw: Math.PI }, limiter);

    // Get the view values

    var yaw = view.yaw();
    var pitch = view.pitch();
    var fov = view.fov(); // fov is horizontal
    /*var vfov = view.vfov();
var hfov = view.hfov();    // same as view.fov()*/

    // Change the values

    view.setYaw((0 * Math.PI) / 180);
    view.setPitch((0 * Math.PI) / 180);
    view.setFov((120 * Math.PI) / 180);

    const scene = this.viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      pinFirstLevel: true,
    });

    scene.switchTo();

    // Get the hotspot container for scene.
    var container = scene.hotspotContainer();

    container.createHotspot(
      this.poster1,
      { yaw: 2.464, pitch: -0.01 },
      {
        perspective: {
          radius: 1400,
          extraTransforms: "rotateX(0deg) rotateY(-40deg) rotateZ(0deg)",
        },
      }
    );

    container.createHotspot(
      this.poster2,
      { yaw: 4.147, pitch: -0.0257 },
      {
        perspective: {
          radius: 1380,
          extraTransforms: "rotateX(0deg) rotateY(30deg) rotateZ(-2deg)",
        },
      }
    );
    container.createHotspot(
      this.poster3,
      { yaw: 4.72, pitch: -0.019 },
      {
        perspective: {
          radius: 2550,
          extraTransforms: "rotateX(0deg) rotateY(0deg) rotate(0deg)",
        },
      }
    );

    container.createHotspot(
      this.poster4,
      { yaw: 6.852, pitch: -0.36 },
      {
        perspective: {
          radius: 1270,
          extraTransforms: "rotateX(20deg) rotateY(35deg) rotateZ(0deg)",
        },
      }
    );

    container.createHotspot(
      this.poster5,
      { yaw: 5.242, pitch: -0.23 },
      {
        perspective: {
          radius: 2390,
          extraTransforms: "rotateX(14deg) rotateY(-60deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster12,
      {   yaw: -4.97,
        pitch: -0.11, },
      {
        perspective: {
          radius: 2590,

          extraTransforms: "rotateX(6deg) rotateY(-10deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster13,
      {   yaw: -7.539,
        pitch: -0.11, },
      {
        perspective: {
          radius: 2590,
          extraTransforms: "rotateX(8deg) rotateY(4deg) rotateZ(0deg)",
        },
      }
    );
    scene.hotspotContainer().createHotspot(document.querySelector("#pptview"), {
      yaw: -4.199,
      pitch: -0.01,
    });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream1"), {
        yaw: -4.199,
        pitch: -0.01,
      });
      scene.hotspotContainer().createHotspot(document.querySelector("#pptview6"), {
        yaw: -4.199,
        pitch: -0.01,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview1"), {
        yaw: -3.86,
        pitch: 0.1,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream2"), {
        yaw: -3.86,
        pitch: 0.1,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview7"), {
        yaw: -3.86,
        pitch: 0.1,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview2"), {
        yaw: -2.18,
        pitch: 0.06,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream3"), {
        yaw: -2.18,
        pitch: 0.06,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview8"), {
        yaw: -2.18,
        pitch: 0.06,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview3"), {
        yaw: -0.15,
        pitch: -0.01,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream4"), {
        yaw: -0.15,
        pitch: -0.01,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview9"), {
        yaw: -0.15,
        pitch: -0.01,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview4"), {
        yaw: -4.78,
        pitch: -0.01,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream5"), {
        yaw: -4.78,
        pitch: -0.01,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview10"), {
        yaw: -4.78,
        pitch: -0.01,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview5"), {
        yaw: -7.45,
        pitch: -0.01,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream6"), {
        yaw: -7.45,
        pitch: -0.01,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview11"), {
        yaw: -7.45,
        pitch: -0.01,
      });
      var autorotate = Marzipano.autorotate({
        yawSpeed: 0.1, // Yaw rotation speed
        targetPitch: 0, // Pitch value to converge to
        targetFov: Math.PI / 2, // Fov value to converge to
      });

      // Autorotate will start after 3s of idle time
      this.viewer.setIdleMovement(3000, autorotate);
      // Disable idle movement
      //this.viewer.setIdleMovement(Infinity);

      // Start autorotation immediately
      this.viewer.startMovement(autorotate);
  }

  render() {

    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }
    function hideImg3() {
      document.getElementById("myImg3").style.display = "none";
    }
    function hideImg4() {
      document.getElementById("myImg4").style.display = "none";
    }
    function hideImg5() {
      document.getElementById("myImg5").style.display = "none";
    }
    function hideImg12() {
      document.getElementById("myImg12").style.display = "none";
    }
    function hideImg13() {
      document.getElementById("myImg13").style.display = "none";
    }

    const {
      poster1,
      poster2,
      poster3,
      poster4,
      poster5,
      poster6,
      poster7,
      poster8,
      poster9,
      poster10,
      poster11,
      poster12,
      poster13,
      Eposter1path,
      Eposter2path,
      Eposter3path,
      Eposter4path,
      Eposter5path,
      Eposter6path,
      Eposter7path,
      Eposter8path,
      Eposter9path,
      Eposter10path,
      Eposter11path,
      Eposter12path,
      Eposter13path,
      Eposter6type,
      Eposter7type,
      Eposter8type,
      Eposter9type,
      Eposter10type,
      Eposter11type,
    } = this.state;
    return (
      <div className="pano-container" ref={(pano) => (this.pano = pano)}>
         < Preloader/>
        <div id="Engposter2" ref={(poster1) => (this.poster1 = poster1)}>
          <label>
            {poster1 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div id="loader3" className="lds-dual-ring hidden overlay"></div>
                <div id="Emap22">
                  <img
                    src={Eposter1path}
                    onError={() => hideImg1()}
                    id="myImg1"
                    alt=""
                  />
                </div>
              </form>
            )}
          </label>
        </div>

        <div id="Engposter4" ref={(poster2) => (this.poster2 = poster2)}>
          {poster2 && (
            <label>
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div id="loader3" className="lds-dual-ring hidden overlay"></div>
                <div id="Emap44">
                  <img
                    src={Eposter2path}
                    onError={() => hideImg2()}
                    id="myImg2"

                  />
                </div>
              </form>
            </label>
          )}
        </div>
        <div id="Engposter5" ref={(poster3) => (this.poster3 = poster3)}>
          <label>
            {poster3 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div id="loader3" className="lds-dual-ring hidden overlay"></div>
                <div id="Emap55">
                  <img
                    src={Eposter3path}
                    onError={() => hideImg3()}
                    id="myImg3"
                    alt=""
                  />
                </div>
              </form>
            )}
          </label>
        </div>

        <div id="Engposter8" ref={(poster4) => (this.poster4 = poster4)}>
          <label>
            {poster4 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div id="loader3" className="lds-dual-ring hidden overlay"></div>
                <img
                  src={Eposter4path}
                  onError={() => hideImg4()}
                id="myImg4"
                  alt=""
                />
              </form>
            )}
          </label>
        </div>

        <div id="Engposter6a" ref={(poster5) => (this.poster5 = poster5)}>
          <label>
            {poster5 && (
              <form enctype="multipart/form-data">
                <div id="richList"></div>
                <div id="loader3" className="lds-dual-ring hidden overlay"></div>
                <div id="Emap6aa">
                  <img
                    src={Eposter5path}
                    onError={() => hideImg5()}
                    id="myImg5"
                    alt=""
                  />
                </div>
              </form>
            )}
          </label>
        </div>
        <div id="Engposter7a" ref={(poster12) => (this.poster12 = poster12)}>
          <label>
          {poster12 && (
            <form encType="multipart/form-data">
              <div id="richList"></div>
              <div id="loader12" className="lds-dual-ring hidden overlay"></div>
              <div id="Emap12aa">
                <img
                src={Eposter12path}
                // src={image}
                onError={() => hideImg12()}
                    id="myImg12"
                 style={{ cursor: "pointer" }} alt="" />
              </div>

            </form>
          )}
          </label>
        </div>
        <div id="pptview">
          <div className="hotspot">
            {poster6 && Eposter6type === "ppt" && (
              <div className="inn" onClick={this.PopenModal}></div>
            )}
          </div>
          {poster6 && (
            <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.PcloseModal}
              style={customStyles}
            >
              <span className="close" onClick={this.PcloseModal}>
                x
              </span>
              <iframe
                src={Eposter6path}
                title="slides"
              ></iframe>
            </Modal>
          )}
        </div>
        <div id="pptview6">
          <div className="hotspot6">
            {poster6 && Eposter6type === "pdf" && (
              <div className="inn66" onClick={this.PopenModal}></div>
            )}
          </div>
          {poster6 && (
            <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.PcloseModal}
              style={customStyles}
            >
              <span className="close" onClick={this.PcloseModal}>
                x
              </span>
              <iframe
                src={Eposter6path}
                title="slides"
              ></iframe>
            </Modal>
          )}
        </div>
        <div id="Videostream1">
          <div className="hotspot2">
            {poster6 && Eposter6type === "video" && (
              <div className="inn2" onClick={this.P5openModal}></div>
            )}
          </div>
          {poster6 && (
            <Modal
              isOpen={this.state.P5modalIsOpen}
              onRequestClose={this.P5closeModal}
              style={customStylesV1}
            >
              <span className="close" onClick={this.P5closeModal}>
                x
              </span>
              <video id="video" autoplay controls controlsList="nodownload">
                <source src={Eposter6path} />
              </video>
            </Modal>
          )}
        </div>

        <div id="pptview1">
          <div className="hotspot1">
            {poster7 && Eposter7type === "ppt" && (
              <div className="inn1" onClick={this.P1openModal}></div>
            )}
          </div>
          {poster7 && (
            <Modal
              isOpen={this.state.P1modalIsOpen}
              onRequestClose={this.P1closeModal}
              style={customStyles}
            >
              <span className="close" onClick={this.P1closeModal}>
                x
              </span>
              <iframe
                src={Eposter7path}
                title="slides"
              ></iframe>
            </Modal>
          )}
        </div>
        <div id="pptview7">
          <div className="hotspot7">
            {poster7 && Eposter7type === "pdf" && (
              <div className="inn77" onClick={this.P1openModal}></div>
            )}
          </div>
          {poster7 && (
            <Modal
              isOpen={this.state.P1modalIsOpen}
              onRequestClose={this.P1closeModal}
              style={customStyles}
            >
              <span className="close" onClick={this.P1closeModal}>
                x
              </span>
              <iframe
                src={Eposter7path}
                title="slides"
              ></iframe>
            </Modal>
          )}
        </div>
        <div id="Videostream2">
          <div className="hotspot3">
            {poster7 && Eposter7type === "video" && (
              <div className="inn3" onClick={this.P6openModal}></div>
            )}
          </div>
          {poster7 && (
            <Modal
              isOpen={this.state.P6modalIsOpen}
              onRequestClose={this.P6closeModal}
              style={customStylesV1}
            >
              <span className="close" onClick={this.P6closeModal}>
                x
              </span>
              <video id="video1" autoplay controls controlsList="nodownload">
                <source src={Eposter7path} />
              </video>
            </Modal>
          )}
        </div>

        <div id="pptview2">
          <div className="hotspot2">
            {poster8 && Eposter8type === "ppt" && (
              <div className="inn22" onClick={this.P2openModal}></div>
            )}
          </div>
          {poster8 && (
            <Modal
              isOpen={this.state.P2modalIsOpen}
              onRequestClose={this.P2closeModal}
              style={customStyles}
            >
              <span className="close" onClick={this.P2closeModal}>
                x
              </span>
              <iframe
                src={Eposter8path}
                title="slides"
              ></iframe>
            </Modal>
          )}
        </div>
        <div id="pptview8">
          <div className="hotspot8">
            {poster8 && Eposter8type === "pdf" && (
              <div className="inn88" onClick={this.P2openModal}></div>
            )}
          </div>
          {poster8 && (
            <Modal
              isOpen={this.state.P2modalIsOpen}
              onRequestClose={this.P2closeModal}
              style={customStyles}
            >
              <span className="close" onClick={this.P2closeModal}>
                x
              </span>
              <iframe
                src={Eposter8path}
                title="slides"
              ></iframe>
            </Modal>
          )}
        </div>
        <div id="Videostream3">
          <div className="hotspot4">
            {poster8 && Eposter8type === "video" && (
              <div className="inn4" onClick={this.P7openModal}></div>
            )}
          </div>
          {poster8 && (
            <Modal
              isOpen={this.state.P7modalIsOpen}
              onRequestClose={this.P7closeModal}
              style={customStylesV1}
            >
              <span className="close" onClick={this.P7closeModal}>
                x
              </span>
              <video id="video2" autoplay controls controlsList="nodownload">
                <source src={Eposter8path} />
              </video>
            </Modal>
          )}
        </div>


        <div id="pptview3">
          <div className="hotspot3">
            {poster9 && Eposter9type === "ppt" && (
              <div className="inn33" onClick={this.P3openModal}></div>
            )}
          </div>
          {poster9 && (
            <Modal
              isOpen={this.state.P3modalIsOpen}
              onRequestClose={this.P3closeModal}
              style={customStyles}
            >
              <span className="close" onClick={this.P3closeModal}>
                x
              </span>
              <iframe
                src={Eposter9path}
                title="slides"
              ></iframe>
            </Modal>
          )}
        </div>
        <div id="pptview9">
          <div className="hotspot9">
            {poster9 && Eposter9type === "pdf" && (
              <div className="inn99" onClick={this.P3openModal}></div>
            )}
          </div>
          {poster9 && (
            <Modal
              isOpen={this.state.P3modalIsOpen}
              onRequestClose={this.P3closeModal}
              style={customStyles}
            >
              <span className="close" onClick={this.P3closeModal}>
                x
              </span>
              <iframe
                src={Eposter9path}
                title="slides"
              ></iframe>
            </Modal>
          )}
        </div>
        <div id="Videostream4">
          <div className="hotspot5">
            {poster9 && Eposter9type === "video" && (
              <div className="inn5" onClick={this.P8openModal}></div>
            )}
          </div>
          {poster9 && (
            <Modal
              isOpen={this.state.P8modalIsOpen}
              onRequestClose={this.P3closeModal}
              style={customStylesV1}
            >
              <span className="close" onClick={this.P8closeModal}>
                x
              </span>
              <video id="video3" autoplay controls controlsList="nodownload">
                <source src={Eposter9path} />
              </video>

            </Modal>
          )}
        </div>

        <div id="pptview4">
          <div className="hotspot4">
            {poster10 && Eposter10type === "ppt" && (
              <div className="inn44" onClick={this.P4openModal}></div>
            )}
          </div>
          {poster10 && (
            <Modal
              isOpen={this.state.P4modalIsOpen}
              onRequestClose={this.P4closeModal}
              style={customStyles}
            >
              <span className="close" onClick={this.P4closeModal}>
                x
              </span>
              <iframe
                src={Eposter10path}
              />
            </Modal>
          )}
        </div>
         <div id="pptview10">
          <div className="hotspot10">
            {poster10 && Eposter10type === "pdf" && (
              <div className="inn10" onClick={this.P4openModal}></div>
            )}
          </div>
          {poster10 && (
            <Modal
              isOpen={this.state.P4modalIsOpen}
              onRequestClose={this.P4closeModal}
              style={customStyles}
            >
              <span className="close" onClick={this.P4closeModal}>
                x
              </span>
              <iframe
                src={Eposter10path}
              />
            </Modal>
          )}
        </div>

        <div id="Videostream5">
          <div className="hotspot6">
            {poster10 && Eposter10type === "video" && (
              <div className="inn6" onClick={this.P9openModal}></div>
            )}
          </div>
          {poster10 && (
            <Modal
              isOpen={this.state.P9modalIsOpen}
              onRequestClose={this.P9closeModal}
              style={customStylesV1}
            >
              <span className="close" onClick={this.P9closeModal}>
                x
              </span>
              <video id="video4" autoplay controls controlsList="nodownload">
                <source src={Eposter10path} />
              </video>
            </Modal>
          )}
        </div>
        <div id="pptview5">
          <div className="hotspot5">
            {poster11 && Eposter11type === "ppt" && (
              <div className="inn55" onClick={this.P10openModal}></div>
            )}
          </div>
          {poster11 && (
            <Modal
              isOpen={this.state.P10modalIsOpen}
              onRequestClose={this.P10closeModal}
              style={customStyles}
            >
              <span className="close" onClick={this.P10closeModal}>
                x
              </span>
              <iframe
                src={Eposter11path}
              />
            </Modal>
          )}
        </div>
        <div id="pptview11">
          <div className="hotspot11">
            {poster11 && Eposter11type === "pdf" && (
              <div className="inn111" onClick={this.P10openModal}></div>
            )}
          </div>
          {poster11 && (
            <Modal
              isOpen={this.state.P10modalIsOpen}
              onRequestClose={this.P10closeModal}
              style={customStyles}
            >
              <span className="close" onClick={this.P10closeModal}>
                x
              </span>
              <iframe
                src={Eposter11path}
              />
            </Modal>
          )}
        </div>
        <div id="Videostream6">
          <div className="hotspot7">
            {poster11 && Eposter11type === "video" && (
              <div className="inn7" onClick={this.P11openModal}></div>
            )}
          </div>
          {poster11 && (
            <Modal
              isOpen={this.state.P11modalIsOpen}
              onRequestClose={this.P11closeModal}
              style={customStylesV1}
            >
              <span className="close" onClick={this.P11closeModal}>
                x
              </span>
              <video id="video4" autoplay controls controlsList="nodownload">
                <source src={Eposter11path} />
              </video>
            </Modal>
          )}
        </div>
        <div id="Engposter13" ref={(poster13) => (this.poster13 = poster13)}>
          <label>
          {poster13 && (
            <form encType="multipart/form-data">
              <div id="richList"></div>
              <div id="loader13" className="lds-dual-ring hidden overlay"></div>
              <div id="Emap13a">
                <img
                src={Eposter13path}
                // src={Emg}
                  onError={() => hideImg13()}
                    id="myImg13"
                 style={{ cursor: "pointer" }} alt="" />
              </div>

            </form>
          )}
          </label>
        </div>

      </div>
    );
  }
}

export default Engagement1;
