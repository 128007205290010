import React, { useState, useEffect } from "react";
import EventTasks from "./EventTasks";
import EventService from "../services/event.service";
import EventBus from "../common/EventBus";
import swal from "sweetalert";
import {
  BrowserRouter as Router,
  useNavigate,
  useLocation,
} from "react-router-dom";

const { domain, client_port } = require("../config/config");

const Events = () => {
  const [event, setevent] = useState([]);
  const [link, setLink] = useState();
  const history = useNavigate();
  const location = useLocation();
  const { from } = location.state;
  const [dataupdated, setDataupdated] = useState(true);

  console.log(location);
  console.log(from);
  useEffect(() => {
    if (dataupdated) {
      EventService.getEvents().then((response) => {
        setevent(response.data);
        setLink(domain + ":" + client_port + "/");
        console.log(domain);
        console.log(client_port);
      });
      setDataupdated(false);
    }
  }, [dataupdated]);

  //Delet Task

  const deleteTask = async (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this user!",
      icon: "warning",
      dangerMode: true,
      buttons: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          text: "Deleted successfully!.",
          icon: "success",
        }).then(function () {
          console.log(id);
          EventService.delEvent(id).then((response) => {
            console.log(response.data);
            setevent(event.filter((task) => task.id !== id));
          });
        });
      } else {
        // swal("Canceled!.");
      }
    });
  };
  const UpdateEvent = async (
    id,
    topic,
    date,
    time,
    loc,
    eventname,
    eventlink
  ) => {
    console.log("Inside UpdateEvent.apply.............................");
    console.log(id);
    EventService.UpdateEvent(
      id,
      topic,
      date,
      time,
      loc,
      eventname,
      eventlink
    ).then((response) => {
      // console.log(response.data);
      setDataupdated(true);
    });
  };

  const onchangetenant = async (eventlink) => {
    console.log("Eventlist onchangetenant callback.......................");
    EventBus.dispatch("changetenant", eventlink);
  };

  return (
    <div>
      {/* <h3>{err}</h3> */}
      {event.length > 0 ? (
        <EventTasks
          tasks={event}
          onDelete={deleteTask}
          onSave={UpdateEvent}
          link={link}
          onchangetenant={onchangetenant}
        />
      ) : (
        "No Events"
      )}
    </div>
  );
};
export default Events;
