import React from "react";
import { BrowserRouter as Routes, Switch, Route } from "react-router-dom";

function Routesprivate({ routes }) {
  return (
    <Routes>
      {Object.keys(routes).map((key) => {
        const route = routes[key];
        return (
          <Route key={`route-${route.path}`} path={route.path} exact>
            <div>{route.component}</div>
          </Route>
        );
      })}
    </Routes>
  );
}

Routes.defaultProps = {
  routes: [],
};

export default Routesprivate;
