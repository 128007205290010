import React, { Component } from "react";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import EventService from "../services/event.service";
import UserService from "../services/user.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const isAllPresent = (str) => {
  // Regex to check if a string
  // contains uppercase, lowercase
  // special character & numeric value
  var pattern = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
  );

  // Print Yes If the string matches
  // with the Regex
  if (pattern.test(str)) {
    return true;
  } else {
    return false;
  }
  // return;
};

const vpassword = (value) => {
  if (isAllPresent(value)) {
    if (value.length < 6 || value.length > 40) {
      return (
        <div className="alert alert-danger" role="alert">
          The password must be between 6 and 40 characters.
        </div>
      );
    }
  } else {
    return (
      <div className="alert alert-danger" role="alert">
        Password must have minimum 1 Uppercase, 1 lowercase, 1 number, 1 symbol
      </div>
    );
  }
};

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangePassword1 = this.onChangePassword1.bind(this);
    this.onChangePassword2 = this.onChangePassword2.bind(this);
    this.state = {
      // username: "",
      password1: "",
      password2: "",
      loading: false,
      message: "",
      email: "",
      token: "",
    };
  }

  onChangePassword1(e) {
    this.setState({
      password1: e.target.value,
    });
  }

  onChangePassword2(e) {
    this.setState({
      password2: e.target.value,
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
    });

    this.form.validateAll();
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get("email");
    const token = queryParams.get("token");
    // this.setState({
    //   token: queryParams.get("token"),
    //   email: queryParams.get("email"),
    // });

    // this.state.email = queryParams.get("email");
    // this.state.token = queryParams.get("token");

    if (this.state.password1 !== this.state.password2) {
      this.setState({
        loading: false,
        message: "Password dont match",
      });
    } else {
      console.log(token);
      console.log(email);
      console.log(this.state.password1);

      if (this.checkBtn.context._errors.length === 0) {
        UserService.resetpassword(email, token, this.state.password1).then(
          () => {
            // this.props.history.push("/login");
            // window.location.reload();
            this.setState({
              loading: false,
              message: "Password reset successful",
            });

            const eventname = "/" + EventService.getCurrentEvent();
            //navigate(eventname + "/login");
            window.location.href = eventname + "/login";
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            this.setState({
              loading: false,
              message: resMessage,
            });
          }
        );
      } else {
        this.setState({
          loading: false,
        });
      }
    }
  }

  render() {
    return (
      <div className="col-md-12">
        <div className="card card-container">
          <img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
          />

          <Form
            onSubmit={this.handleLogin}
            ref={(c) => {
              this.form = c;
            }}
          >
            <div className="form-group">
              <label htmlFor="username">Password</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={this.state.username}
                onChange={this.onChangePassword1}
                validations={[required, vpassword]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Confirm Password</label>
              <Input
                type="password"
                className="form-control"
                name="confpassword"
                value={this.state.password}
                onChange={this.onChangePassword2}
                validations={[required, vpassword]}
              />
            </div>

            <div className="form-group">
              <button
                className="btn btn-primary btn-block"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Reset Password</span>
              </button>
            </div>

            {this.state.message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}
