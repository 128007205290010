import React, { useState, Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Agenda from "./img/CAPGEMINI AGENDA REVISED F-04 REVISED.jpg";
import AWSService from "../services/aws.service";
import EventService from "../services/event.service";
import PPTPathService from "../services/pptpath.service";
import UnauthorizedService from "../common/unauthorized";
import Swal from "sweetalert2";
import "./styles/maping.css";
import $ from "jquery";
import "../App.css";
import { Link } from "react-router-dom";
var lobbyeventname = "";
let tenant = "";
const { aws_s3_url, aws_s3_bucket_name } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
// const [event, setevent] = useState([]);
// // const [dataupdated, setDataupdated] = useState(true);
// const [RC1, setRC1] = useState(true);
// const [RC2, setRC2] = useState(true);
// const [RC3, setRC3] = useState(true);
// const [RC4, setRC4] = useState(true);
// const [RC5, setRC5] = useState(true);
// const [RC6, setRC6] = useState(true);
class AgendafunctionEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initially, no file is selected
      selectedFile: null,
      poster01: false,
      poster02: false,
      poster3: false,
      poster4: false,
      poster5: false,
      poster6: false,
      poster7: false,
      poster8: false,
      poster9: false,
      poster10: false,
      poster11: false,
      poster12: false,
      poster13: false,
      poster14: false,
      poster15: false,
      poster16: false,
      poster17: false,
      poster18: false,
      R1poster3path: "",
      R1poster02path: "",
      R1poster01path: "",
      R1poster4path: "",
      R1poster5path: "",
      R1poster6path: "",
      R1poster7path: "",
      R1poster8path: "",
      R1poster9path: "",
      R1poster10path: "",
      R1poster11path: "",
      R1poster12path: "",
      R1poster13path: "",
      R1poster14path: "",
      R1poster15path: "",
      R1poster16path: "",
      R1poster17path: "",
      R1poster18path: "",

      Aposter2path: "",
      Aposter3path: "",
      Aposter4path: "",
      Aposter5path: "",
      Aposter6path: "",
      Aposter7path: "",
      edit1: "",
      tenantname: tenant,
      RC1: true,
      RC2: true,
      RC3: true,
      RC4: true,
      RC5: true,
      RC6: true,
      RC7: true,
      RC8: true,
      RC9: true,
      RC10: true,
      RC11: true,
      RC12: true,
      RC13: true,
      RC14: true,
      RC15: true,
      RC16: true,
      RC17: true,
      RC18: true,
    };
  }

  onFileChange01 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(
      lobbyeventname,
      "Rs1",
      "poster01",
      "txt",
      filepath
    )
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange02 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(
      lobbyeventname,
      "Rs1",
      "poster02",
      "txt",
      filepath
    )
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange03 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(
      lobbyeventname,
      "Rs1",
      "poster3",
      "txt",
      filepath
    )
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange04 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(
      lobbyeventname,
      "Rs1",
      "poster4",
      "txt",
      filepath
    )
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange05 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(
      lobbyeventname,
      "Rs1",
      "poster5",
      "txt",
      filepath
    )
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange06 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(
      lobbyeventname,
      "Rs1",
      "poster6",
      "txt",
      filepath
    )
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange07 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(
      lobbyeventname,
      "Rs1",
      "poster7",
      "txt",
      filepath
    )
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange08 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(
      lobbyeventname,
      "Rs1",
      "poster8",
      "txt",
      filepath
    )
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange09 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(
      lobbyeventname,
      "Rs1",
      "poster9",
      "txt",
      filepath
    )
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange10 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(
      lobbyeventname,
      "Rs1",
      "poster10",
      "txt",
      filepath
    )
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange11 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(
      lobbyeventname,
      "Rs1",
      "poster11",
      "txt",
      filepath
    )
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange12 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(
      lobbyeventname,
      "Rs1",
      "poster12",
      "txt",
      filepath
    )
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange13 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(
      lobbyeventname,
      "Rs1",
      "poster13",
      "txt",
      filepath
    )
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange14 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(
      lobbyeventname,
      "Rs1",
      "poster14",
      "txt",
      filepath
    )
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange15 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(
      lobbyeventname,
      "Rs1",
      "poster15",
      "txt",
      filepath
    )
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange16 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(
      lobbyeventname,
      "Rs1",
      "poster16",
      "txt",
      filepath
    )
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange17 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(
      lobbyeventname,
      "Rs1",
      "poster17",
      "txt",
      filepath
    )
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange18 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(
      lobbyeventname,
      "Rs1",
      "poster18",
      "txt",
      filepath
    )
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  componentDidMount() {
    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname = EventService.getCurrentEvent();
    const filepath1 = "capgemini/" + lobbyeventname + "/Rs1" + "/poster01";
    console.log(`filepath= ${filepath1}`);
    PPTPathService.getFiletype(lobbyeventname, "Rs1").then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster01") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster1path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster01" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster01: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster01path: filedetails.path,
              poster01: true,
            });
            this.setState({ R1poster01type: "txt" });
          }
        }

        if (filedetails.filename === "poster02") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster02path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster02" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster02: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster02path: filedetails.path,
              poster02: true,
            });
            this.setState({ R1poster02type: "txt" });
          }
        }

        if (filedetails.filename === "poster3") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster3path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster3" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster3: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster3path: filedetails.path,
              poster3: true,
            });
            this.setState({ R1poster3type: "txt" });
          }
        }

        if (filedetails.filename === "poster4") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster4path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster4" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster4: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster4path: filedetails.path,
              poster4: true,
            });
            this.setState({ R1poster4type: "txt" });
          }
        }

        if (filedetails.filename === "poster5") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster5path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster5" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster5: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster5path: filedetails.path,
              poster5: true,
            });
            this.setState({ R1poster5type: "txt" });
          }
        }

        if (filedetails.filename === "poster6") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster6path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster6" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster6: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster6path: filedetails.path,
              poster6: true,
            });
            this.setState({ R1poster6type: "txt" });
          }
        }

        if (filedetails.filename === "poster7") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster7path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster7" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster7: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster7path: filedetails.path,
              poster7: true,
            });
            this.setState({ R1poster7type: "txt" });
          }
        }

        if (filedetails.filename === "poster8") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster8path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster8" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster8: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster8path: filedetails.path,
              poster8: true,
            });
            this.setState({ R1poster8type: "txt" });
          }
        }

        if (filedetails.filename === "poster9") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster9path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster9" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster9: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster9path: filedetails.path,
              poster9: true,
            });
            this.setState({ R1poster9type: "txt" });
          }
        }

        if (filedetails.filename === "poster10") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster10path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster2" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster10: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster10path: filedetails.path,
              poster10: true,
            });
            this.setState({ R1poster10type: "txt" });
          }
        }

        if (filedetails.filename === "poster11") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster11path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster11" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster11: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster11path: filedetails.path,
              poster11: true,
            });
            this.setState({ R1poster11type: "txt" });
          }
        }

        if (filedetails.filename === "poster12") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster12path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster12" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster12: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster12path: filedetails.path,
              poster12: true,
            });
            this.setState({ R1poster12type: "txt" });
          }
        }

        if (filedetails.filename === "poster13") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster13path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster13" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster13: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster13path: filedetails.path,
              poster13: true,
            });
            this.setState({ R1poster13type: "txt" });
          }
        }

        if (filedetails.filename === "poster14") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster14path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster14" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster14: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster14path: filedetails.path,
              poster14: true,
            });
            this.setState({ R1poster14type: "txt" });
          }
        }

        if (filedetails.filename === "poster15") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster15path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster15" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster15: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster15path: filedetails.path,
              poster15: true,
            });
            this.setState({ R1poster15type: "txt" });
          }
        }

        if (filedetails.filename === "poster16") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster16path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster16" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster16: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster16path: filedetails.path,
              poster16: true,
            });
            this.setState({ R1poster16type: "txt" });
          }
        }

        if (filedetails.filename === "poster17") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster17path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster17" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster17: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster17path: filedetails.path,
              poster17: true,
            });
            this.setState({ R1poster17type: "txt" });
          }
        }

        if (filedetails.filename === "poster18") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster18path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster18" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster18: true,
          });
          if (filedetails.filetype === "txt") {
            this.setState({
              R1poster18path: filedetails.path,
              poster18: true,
            });
            this.setState({ R1poster18type: "txt" });
          }
        }
      });
    });
    // PPTPathService.getFiletype(eventname, "Rs1")
    // .then((response) => {
    //   response.data.forEach((filedetails) => {

    //   });
    // })

    // PPTPathService.getFiletype(eventname, "Rs1")
    // .then((response) => {
    //   response.data.forEach((filedetails) => {

    //   });
    // })

    // PPTPathService.getFiletype(eventname, "Rs1")
    // .then((response) => {
    //   response.data.forEach((filedetails) => {

    //   });
    // })

    // PPTPathService.getFiletype(eventname, "Rs1")
    // .then((response) => {
    //   response.data.forEach((filedetails) => {

    //   });
    // })

    // PPTPathService.getFiletype(eventname, "Rs1")
    // .then((response) => {
    //   response.data.forEach((filedetails) => {

    //   });
    // })

    // PPTPathService.getFiletype(eventname, "Rs1")
    // .then((response) => {
    //   response.data.forEach((filedetails) => {

    //   });
    // })

    // PPTPathService.getFiletype(eventname, "Rs1")
    // .then((response) => {
    //   response.data.forEach((filedetails) => {

    //   });
    // })
    // .catch((err) => {
    //   UnAuthorizedService.unauthorized(err);
    // });

    EventService.getSpecificEvent().then((response) => {
      console.log("Eventname from getSpecificEvent");
      console.log(response.data);
      console.log(response.data.eventname);
      const eventpath = "/" + response.data.eventname;
      this.setState({ tenantname: eventpath, showLoginPage: true });
      localStorage.setItem("eventname", response.data.eventname);
      console.log(response.data.eventname);
    });

    // EventService.getEvents()
    // .then((response)=>{
    //   console.log("Eventname from neeraj");
    //   console.log(response.data);

    // })
    EventService.getEvents1().then((response) => {
      // setevent(response.data);
      console.log(response.data);

      console.log(response.data.RC1);
      // if(response.data.RC1 ==true){
      if (response.data.RC1 != null) {
        // console.log( response.data[0].id);
        this.setState({
          RC1: false,
          edit1: "ResourceEdit",
        });
      }
      if (response.data.RC2 != null) {
        this.setState({
          RC2: false,
          // RC1:false
        });

        // }
        // }
        // {

        // }

        if (response.data.RC3 != null) {
          // console.log( response.data[0].id);
          // setRC2(!RC2);
          this.setState({
            RC3: false,
            // RC1:false
          });
        }
        if (response.data.RC4 != null) {
          // setRC2(!RC2);
          // setRC3(!RC3);
          this.setState({
            RC4: false,
            // RC1:false
          });
        }
        if (response.data.RC5 != null) {
          // console.log( response.data[0].id);
          // setRC4(!RC4);
          this.setState({
            RC5: false,
            // RC1:false
          });
        }
        if (response.data.RC6 != null) {
          // console.log( response.data[0].id);
          // setRC4(!RC4);
          this.setState({
            RC6: false,
            // RC1:false
          });
        }

        if (response.data.RC7 != null) {
          // console.log( response.data[0].id);
          // setRC4(!RC4);
          this.setState({
            RC7: false,
            // RC1:false
          });
        }

        if (response.data.RC8 != null) {
          // console.log( response.data[0].id);
          // setRC4(!RC4);
          this.setState({
            RC8: false,
            // RC1:false
          });
        }

        if (response.data.RC9 != null) {
          // console.log( response.data[0].id);
          // setRC4(!RC4);
          this.setState({
            RC9: false,
            // RC1:false
          });
        }
        if (response.data.RC10 != null) {
          // console.log( response.data[0].id);
          // setRC4(!RC4);
          this.setState({
            RC10: false,
            // RC1:false
          });
        }

        if (response.data.RC11 != null) {
          // console.log( response.data[0].id);
          // setRC4(!RC4);
          this.setState({
            RC11: false,
            // RC1:false
          });
        }

        if (response.data.RC12 != null) {
          // console.log( response.data[0].id);
          // setRC4(!RC4);
          this.setState({
            RC12: false,
            // RC1:false
          });
        }

        if (response.data.RC13 != null) {
          // console.log( response.data[0].id);
          // setRC4(!RC4);
          this.setState({
            RC13: false,
            // RC1:false
          });
        }

        if (response.data.RC14 != null) {
          // console.log( response.data[0].id);
          // setRC4(!RC4);
          this.setState({
            RC14: false,
            // RC1:false
          });
        }

        if (response.data.RC15 != null) {
          // console.log( response.data[0].id);
          // setRC4(!RC4);
          this.setState({
            RC15: false,
            // RC1:false
          });
        }

        if (response.data.RC16 != null) {
          // console.log( response.data[0].id);
          // setRC4(!RC4);
          this.setState({
            RC16: false,
            // RC1:false
          });
        }

        if (response.data.RC17 != null) {
          // console.log( response.data[0].id);
          // setRC4(!RC4);
          this.setState({
            RC17: false,
            // RC1:false
          });
        }

        if (response.data.RC18 != null) {
          // console.log( response.data[0].id);
          // setRC4(!RC4);
          this.setState({
            RC18: false,
            // RC1:false
          });
        }
        //  if(response.data.innovationLabNum == '2'){
        //   setisInnovationLab1(!isInnovationLab1);
        //   setisInnovationLab2(!isInnovationLab2);
      }
    });

    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const filepath = "capgemini/" + lobbyeventname + "/Ag" + "/poster1";
    console.log(`filepath= ${filepath}`);

    this.setState({
      //   R1poster1path :
      //   AWS_S3_URL + "capgemini/" + lobbyeventname+ "/Ag" + "/posteragenda.txt?v=" + num,
      // Aposter2path :
      //   AWS_S3_URL + "capgemini/" + lobbyeventname + "/Ag" + "/poster2.png?v=" + num,
      // Aposter3path :
      //   AWS_S3_URL + "capgemini/" + lobbyeventname + "/Ag" + "/poster3.png?v=" + num,
      // Aposter4path :
      //   AWS_S3_URL + "capgemini/" + lobbyeventname + "/Ag" + "/poster4.png?v=" + num,
      // Aposter5path :
      //   AWS_S3_URL + "capgemini/" + lobbyeventname+ "/Ag" + "/poster5.png?v=" + num,
      // Aposter6path :
      //   AWS_S3_URL + "capgemini/" + lobbyeventname + "/Ag" + "/poster6.png?v=" + num,
      //   Aposter7path :
      //   AWS_S3_URL + "capgemini/" + lobbyeventname + "/Ag" + "/poster7.png?v=" + num,
      poster01: true,
      poster02: true,
      poster3: true,
      poster4: true,
      poster5: true,
      poster6: true,
      poster7: true,
      poster8: true,
      poster9: true,
      poster10: true,
      poster11: true,
      poster12: true,
      poster13: true,
      poster14: true,
      poster15: true,
      poster16: true,
      poster17: true,
      poster18: true,

      // RC1:true,
      // RC2:true,
      // RC3:true,
    });
  }

  render() {
    function hideImg() {
      document.getElementById("myImg").style.display = "none";
    }
    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }
    function hideImg3() {
      document.getElementById("myImg3").style.display = "none";
    }
    function hideImg4() {
      document.getElementById("myImg4").style.display = "none";
    }
    function hideImg5() {
      document.getElementById("myImg5").style.display = "none";
    }
    function hideImg7() {
      document.getElementById("myImg7").style.display = "none";
    }
    const {
      poster01,
      poster02,
      poster3,
      poster4,
      poster5,
      poster6,
      poster7,
      poster8,
      poster9,
      poster10,
      poster11,
      poster12,
      poster13,
      poster14,
      poster15,
      poster16,
      poster17,
      poster18,

      R1poster3path,
      R1poster02path,

      R1poster01path,
      R1poster4path,
      R1poster5path,
      R1poster6path,
      R1poster7path,
      R1poster8path,
      R1poster9path,
      R1poster10path,
      R1poster11path,
      R1poster12path,
      R1poster13path,
      R1poster14path,
      R1poster15path,
      R1poster16path,
      R1poster17path,
      R1poster18path,

      Aposter2path,
      Aposter3path,
      Aposter4path,
      Aposter5path,
      Aposter6path,
      Aposter7path,
      edit1,
      RC1,
      RC2,
      RC3,
      RC4,
      RC5,
      RC6,
      RC7,
      RC8,
      RC9,
      RC10,
      RC11,
      RC12,
      RC13,
      RC14,
      RC15,
      RC16,
      RC17,
      RC18,

      tenantname,
    } = this.state;

    return (
      <div className="container-fluide">
        <div className="page-content">
          <div id="content-agenda">
            <div id="bg">
              <img src={Agenda} id="bg-image" className="mb-3" />

              {/* <div className="sidenavogation">
           
                  {  (
                  
                    <Link to={tenantname + "/"+edit1} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Back To Agenda</span>
                  </Link>
                  


            ) }
            
           
                  </div> */}

              <div class="resource-btn">
                {!RC1 ? (
                  <Link
                    to={tenantname + "/ResourceEdit"}
                    className="Rs1 button-1  text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}

                {!RC2 ? (
                  <Link
                    to={tenantname + "/ResourceEditg2"}
                    className="Rs2 button-2 text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}
                {!RC3 ? (
                  <Link
                    to={tenantname + "/ResourceEditg3"}
                    className="Rs3 button-3 text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}
                {!RC4 ? (
                  <Link
                    to={tenantname + "/ResourceEdit1"}
                    className="Rs4 button-4 text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}

                {!RC5 ? (
                  <Link
                    to={tenantname + "/ResourceEdit1g2"}
                    className="Rs5 button-5 text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}

                {!RC6 ? (
                  <Link
                    to={tenantname + "/ResourceEdit1g3"}
                    className="Rs5 button-6 text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}

                {!RC7 ? (
                  <Link
                    to={tenantname + "/ResourceEdit2"}
                    className="Rs5 button-7 text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}

                {!RC8 ? (
                  <Link
                    to={tenantname + "/ResourceEdit2g2"}
                    className="Rs5 button-8 text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}

                {!RC9 ? (
                  <Link
                    to={tenantname + "/ResourceEdit2g3"}
                    className="Rs5 button-9 text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}

                {!RC10 ? (
                  <Link
                    to={tenantname + "/ResourceEdit3"}
                    className="Rs5 button-10 text-white buttonResbtn"
                  >
                    <span className="hide-menu">Enter</span>
                  </Link>
                ) : null}

                {/* {!RC11  ?   (
            <Link to={tenantname + "/ResourceEdit3g2"} className="Rs5 button-11 text-white buttonRes">
                              <span className="hide-menu">Resource Center11</span>
                  </Link>
          ):  null}   

          {!RC12  ?   (
            <Link to={tenantname + "/ResourceEdit3g3"} className="Rs5 button-12 text-white buttonRes">
                              <span className="hide-menu">Resource Center12</span>
                  </Link>
          ):  null}  

{!RC13  ?   (
            <Link to={tenantname + "/ResourceEdit4"} className="Rs5 button-13 text-white buttonRes">
                              <span className="hide-menu">Resource Center13</span>
                  </Link>
          ):  null}  

{!RC14  ?   (
            <Link to={tenantname + "/ResourceEdit4g2"} className="Rs5 button-14 text-white buttonRes">
                              <span className="hide-menu">Resource Center14</span>
                  </Link>
          ):  null} 

{!RC15  ?   (
            <Link to={tenantname + "/ResourceEdit4g3"} className="Rs5 button-15 text-white buttonRes">
                              <span className="hide-menu">Resource Center15</span>
                  </Link>
          ):  null} 

{!RC16  ?   (
            <Link to={tenantname + "/ResourceEdit5"} className="Rs5 button-16 text-white buttonRes">
                              <span className="hide-menu">Resource Center16</span>
                  </Link>
          ):  null} 

{!RC17  ?   (
            <Link to={tenantname + "/ResourceEdit5g2"} className="Rs5 button-17 text-white buttonRes">
                              <span className="hide-menu">Resource Center17</span>
                  </Link>
          ):  null} 

{!RC18  ?   (
            <Link to={tenantname + "/ResourceEdit5g3"} className="Rs5 button-18 text-white buttonRes">
                              <span className="hide-menu">Resource Center18</span>
                  </Link>
          ):  null}  */}
              </div>
              {!RC1 ? (
                <div id="Resourceposter1">
                  {poster01 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap32">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          name="text"
                          // accept="image/png, image/gif, image/jpeg"
                          onChange={this.onFileChange01}
                          id="ResText"
                          placeholder={R1poster01path}
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {!RC2 ? (
                <div id="Resourceposter2">
                  {poster02 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap32">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          name="text"
                          // accept="image/png, image/gif, image/jpeg"
                          onChange={this.onFileChange02}
                          id="ResText"
                          placeholder={R1poster02path}
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {!RC3 ? (
                <div id="Resourceposter3">
                  {poster3 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap74">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          name="text"
                          // accept="image/png, image/gif, image/jpeg"
                          onChange={this.onFileChange03}
                          id="ResText"
                          placeholder={R1poster3path}
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {!RC4 ? (
                <div id="Resourceposter4">
                  {poster4 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap74">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          name="text"
                          // accept="image/png, image/gif, image/jpeg"
                          onChange={this.onFileChange04}
                          id="ResText"
                          placeholder={R1poster4path}
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {!RC5 ? (
                <div id="Resourceposter5">
                  {poster5 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap74">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          name="text"
                          // accept="image/png, image/gif, image/jpeg"
                          onChange={this.onFileChange05}
                          id="ResText"
                          placeholder={R1poster5path}
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {!RC6 ? (
                <div id="Resourceposter6">
                  {poster6 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap74">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          name="text"
                          // accept="image/png, image/gif, image/jpeg"
                          onChange={this.onFileChange06}
                          id="ResText"
                          placeholder={R1poster6path}
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {!RC7 ? (
                <div id="Resourceposter7">
                  {poster7 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap74">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          name="text"
                          // accept="image/png, image/gif, image/jpeg"
                          onChange={this.onFileChange07}
                          id="ResText"
                          placeholder={R1poster7path}
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {!RC8 ? (
                <div id="Resourceposter8">
                  {poster8 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap74">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          name="text"
                          // accept="image/png, image/gif, image/jpeg"
                          onChange={this.onFileChange08}
                          id="ResText"
                          placeholder={R1poster8path}
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {!RC9 ? (
                <div id="Resourceposter9">
                  {poster9 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap74">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          name="text"
                          // accept="image/png, image/gif, image/jpeg"
                          onChange={this.onFileChange09}
                          id="ResText"
                          placeholder={R1poster9path}
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {!RC10 ? (
                <div id="Resourceposter10">
                  {poster10 && (
                    <form encType="multipart/form-data">
                      <div id="richList"></div>
                      <div
                        id="loader3"
                        className="lds-dual-ring hidden overlay"
                      ></div>
                      <div className="Resourcemap74">
                        <input
                          //  ref={(ref) => (this.myInput2 = ref)}
                          type="text"
                          name="text"
                          // accept="image/png, image/gif, image/jpeg"
                          onChange={this.onFileChange10}
                          id="ResText"
                          placeholder={R1poster10path}
                        />
                      </div>
                    </form>
                  )}
                </div>
              ) : null}

              {/* {!RC11  ?   (
            <div id="Resourceposter11">
                {poster11 && (
                <form encType="multipart/form-data">
                  <div id="richList"></div>
                  <div
                    id="loader3"
                    className="lds-dual-ring hidden overlay"
                  ></div>
                  <div className="Resourcemap74">
                    <input
                      //  ref={(ref) => (this.myInput2 = ref)}
                      type="text"
                      name="text"
                      // accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange11}
                      id="ResText"
                      placeholder={R1poster11path}
                    />
                  </div>
                </form>
                )}
              </div>


               ) :null}


               
{!RC12  ?   (
            <div id="Resourceposter12">
                {poster10 && (
                <form encType="multipart/form-data">
                  <div id="richList"></div>
                  <div
                    id="loader3"
                    className="lds-dual-ring hidden overlay"
                  ></div>
                  <div className="Resourcemap74">
                    <input
                      //  ref={(ref) => (this.myInput2 = ref)}
                      type="text"
                      name="text"
                      // accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange12}
                      id="ResText"
                      placeholder={R1poster12path}
                    />
                  </div>
                </form>
                )}
              </div>


               ) :null}


               
{!RC13 ?   (
            <div id="Resourceposter13">
                {poster13 && (
                <form encType="multipart/form-data">
                  <div id="richList"></div>
                  <div
                    id="loader3"
                    className="lds-dual-ring hidden overlay"
                  ></div>
                  <div className="Resourcemap74">
                    <input
                      //  ref={(ref) => (this.myInput2 = ref)}
                      type="text"
                      name="text"
                      // accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange13}
                      id="ResText"
                      placeholder={R1poster13path}
                    />
                  </div>
                </form>
                )}
              </div>


               ) :null}



               
{!RC14  ?   (
            <div id="Resourceposter14">
                {poster14 && (
                <form encType="multipart/form-data">
                  <div id="richList"></div>
                  <div
                    id="loader3"
                    className="lds-dual-ring hidden overlay"
                  ></div>
                  <div className="Resourcemap74">
                    <input
                      //  ref={(ref) => (this.myInput2 = ref)}
                      type="text"
                      name="text"
                      // accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange14}
                      id="ResText"
                      placeholder={R1poster14path}
                    />
                  </div>
                </form>
                )}
              </div>


               ) :null}


               
{!RC15  ?   (
            <div id="Resourceposter15">
                {poster15 && (
                <form encType="multipart/form-data">
                  <div id="richList"></div>
                  <div
                    id="loader3"
                    className="lds-dual-ring hidden overlay"
                  ></div>
                  <div className="Resourcemap74">
                    <input
                      //  ref={(ref) => (this.myInput2 = ref)}
                      type="text"
                      name="text"
                      // accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange15}
                      id="ResText"
                      placeholder={R1poster15path}
                    />
                  </div>
                </form>
                )}
              </div>


               ) :null}


               
{!RC16  ?   (
            <div id="Resourceposter16">
                {poster16 && (
                <form encType="multipart/form-data">
                  <div id="richList"></div>
                  <div
                    id="loader3"
                    className="lds-dual-ring hidden overlay"
                  ></div>
                  <div className="Resourcemap74">
                    <input
                      //  ref={(ref) => (this.myInput2 = ref)}
                      type="text"
                      name="text"
                      // accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange16}
                      id="ResText"
                      placeholder={R1poster16path}
                    />
                  </div>
                </form>
                )}
              </div>


               ) :null}


               
{!RC17  ?   (
            <div id="Resourceposter17">
                {poster17 && (
                <form encType="multipart/form-data">
                  <div id="richList"></div>
                  <div
                    id="loader3"
                    className="lds-dual-ring hidden overlay"
                  ></div>
                  <div className="Resourcemap74">
                    <input
                      //  ref={(ref) => (this.myInput2 = ref)}
                      type="text"
                      name="text"
                      // accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange17}
                      id="ResText"
                      placeholder={R1poster17path}
                    />
                  </div>
                </form>
                )}
              </div>


               ) :null}


               
{!RC18  ?   (
            <div id="Resourceposter18">
                {poster18 && (
                <form encType="multipart/form-data">
                  <div id="richList"></div>
                  <div
                    id="loader3"
                    className="lds-dual-ring hidden overlay"
                  ></div>
                  <div className="Resourcemap74">
                    <input
                      //  ref={(ref) => (this.myInput2 = ref)}
                      type="text"
                      name="text"
                      // accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange18}
                      id="ResText"
                      placeholder={R1poster18path}
                    />
                  </div>
                </form>
                )}
              </div>


               ) :null} */}
              {/* <div id="ses01">
              {poster1 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="agendamap1" >
                  <img src={R1poster1path} style={{ cursor: "pointer" }} onClick={(e) => this.myInput1.click()}  onError={() => hideImg()}
               id="myImg"/>
                </div>
                <input
                  ref={(ref) => (this.myInput1 = ref)}
                  type="file"
                  name="file"
                  id="posterimg1"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange01}
                  style={{ display: "none" }}
                />
                     <input
                      //  ref={(ref) => (this.myInput2 = ref)}
                      type="text"
                      name="text"
                      // accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange05}
                      id="ResText"
                      placeholder={R1poster3path}
                    />
                
              </form>
            )}
          
              </div> */}

              {/* <div id="ses02">
              {poster2 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader2"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="agendamap2" onClick={(e) => this.myInput2.click()}>
                  <img src={Aposter2path} style={{ cursor: "pointer" }} alt=""  onError={() => hideImg1()}
               id="myImg1"/>
                </div>
                <input
                   ref={(ref) => (this.myInput2 = ref)}
                  type="file"
                  name="file"
                  id="posterimg1"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange02}
                  style={{ display: "none" }}
                />
              </form>
            )}
            </div>
              <div id="ses03">
              {poster3 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="agendamap3" onClick={(e) => this.myInput3.click()}>
                  <img src={Aposter3path} style={{ cursor: "pointer" }} alt=""  onError={() => hideImg2()}
               id="myImg2"/>
                </div>
                <input
                   ref={(ref) => (this.myInput3 = ref)}
                  type="file"
                  name="file"
                  id="posterimg1"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange03}
                  style={{ display: "none" }}
                />
              </form>
            )}
              </div>
              <div id="ses04">
              {poster4 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader4"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="agendamap4" onClick={(e) => this.myInput4.click()}>
                  <img src={Aposter4path} style={{ cursor: "pointer" }} alt=""  onError={() => hideImg3()}
               id="myImg3"/>
                </div>
                <input
                   ref={(ref) => (this.myInput4 = ref)}
                  type="file"
                  name="file"
                  id="posterimg1"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange04}
                  style={{ display: "none" }}
                />
              </form>
            )}
              </div>
              <div id="ses05">
              {poster5 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader5"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="agendamap5" onClick={(e) => this.myInput5.click()}>
                  <img src={Aposter5path} style={{ cursor: "pointer" }} alt=""  onError={() => hideImg4()}
               id="myImg4"/>
                </div>
                <input
                   ref={(ref) => (this.myInput5 = ref)}
                  type="file"
                  name="file"
                  id="posterimg1"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange05}
                  style={{ display: "none" }}
                />
              </form>
            )}
              </div>
              <div id="ses06">
              {poster6 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader6"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="agendamap5" onClick={(e) => this.myInput6.click()}>
                  <img src={Aposter6path} style={{ cursor: "pointer" }} alt=""  onError={() => hideImg5()}
               id="myImg5"/>
                </div>
                <input
                   ref={(ref) => (this.myInput6 = ref)}
                  type="file"
                  name="file"
                  id="posterimg1"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange06}
                  style={{ display: "none" }}
                />
              </form>
            )}
              </div>

              <div id="ses07">
              {poster7 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader7"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div className="agendamap7" onClick={(e) => this.myInput7.click()}>
                  <img 
                  src={Aposter7path}
                  // src={image}
                   style={{ cursor: "pointer" }} alt=""  onError={() => hideImg7()}
               id="myImg7"/>
                </div>
                <input
                   ref={(ref) => (this.myInput7 = ref)}
                  type="file"
                  name="file"
                  id="posterimg1"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange07}
                  style={{ display: "none" }}
                />
              </form>
            )}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AgendafunctionEdit;
