import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import stall1 from "./img/VR-01/VR-1 4 GRID.jpg";
import image from "./img/rs1.png";
import image1 from "./img/rs2.png";
import Modal from "react-modal";
import AWSService from "../services/aws.service";
import EventService from "../services/event.service";
import PPTPathService from "../services/pptpath.service";
import UnauthorizedService from "../common/unauthorized";
import UnAuthorizedService from "../common/unauthorized";
import AuthService from "../services/auth.service";
import { Uploader } from "../utils/upload";
import Progressbar from "../common/progressbar";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./styles/maping.css";
import $ from "jquery";
import "../App.css";
var lobbyeventname = "";
let tenant = "";
const { aws_s3_url, aws_s3_bucket_name } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "25vh",
    marginRight: "-50%",
    width: "calc(18% - 0px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "rgba(0,0,0,.9)",
    background: "unset !important",
    border: "unset !important",

    zIndex: "99999",
    backgroundcolor: "unset !important",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(20% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding: "0 0 0 0",
  },
};
class ResorceEdit3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initially, no file is selected
      selectedFile: null,
      poster1: false,
      poster2: false,
      poster3: false,
      poster4: false,
      poster5: false,
      poster6: false,
      poster3g21: false,
      poster3g22: false,
      poster3g23: false,
      poster313g2: false,

      poster313g21: false,
      poster313g22: false,
      poster313g23: false,


      R3poster1path: "",
      R3poster2path: "",
      R3poster3path: "",
      R3poster4path: "",
      R3poster5path: "",
      R3poster6path: "",
      R3poster3g21path: "",
      R3poster3g22path: "",
      R3poster3g23path: "",
      R3poster313g21path: "",
      R3poster313g2path: "",

      R3poster313g22path: "",
      R3poster313g23path: "",


      modalIsOpen: false,
      modalIsOpen3g2: false,
      modalIsOpen3g2a: false,
      modalIsOpen3g2b: false,


      tenantname: tenant,
      localuploader: undefined,
      progressbar: 0,
    };
  }
  P1openModal = () => {
    this.setState({ modalIsOpen: true });
  };
  P1openModal3g2 = () => {
    this.setState({ modalIsOpen3g2: true });
  };
  P1openModal3g2a = () => {
    this.setState({ modalIsOpen3g2a: true });
  };
  P1openModal3g2b = () => {
    this.setState({ modalIsOpen3g2b: true });
  };
  P1closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  P1closeModal3g2 = () => {
    this.setState({ modalIsOpen3g2: false });
  };
  P1closeModal3g2a = () => {
    this.setState({ modalIsOpen3g2a: false });
  };
  P1closeModal3g2b = () => {
    this.setState({ modalIsOpen3g2b: false });
  };
  onFileChange01 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs3" + "/poster1.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader1").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster2bar").show();
             $(".poster2barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader1").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R3poster1path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs3" +
                  "/poster1.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs3",
                "poster1",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader1").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChange02 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  690 || this.height != 77) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 690 + " Height= " + 77
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs3i" + "/poster2i.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader2").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster2bar").show();
             $(".poster2barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader2").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R3poster2path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs3i" +
                  "/poster2i.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs3",
                "poster2",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader2").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };

  onFileChange03 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs3", "poster313g2", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };
  onFileChange313g21 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs3", "poster313g21", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };
  onFileChange313g22 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs3", "poster313g22", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange313g23 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs3", "poster313g23", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

 // On file select (from the pop up)
 onFileChange04 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs3g2" + "/poster4" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs3",
            "poster4",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange3g2 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs3" + "/poster3g2" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs3",
            "poster3g2",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange3g2a = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs3" + "/poster3g2a" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs3",
            "poster3g2a",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange3g2b = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs3" + "/poster3g2bpdf" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs3",
            "poster3g2bpdf",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange3g21 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function () {
    if (this.width !=  492 || this.height != 326) {
      alert(
        // "Uploaded File Dimension: Width= " +
        //   this.width +
        //   "  Height= " +
        //   this.height +
        "Required FIle Dimension: Width = " + 492 + " Height= " + 326
      );
    } else {
      console.log("Inside file dimension are proper loop");
      uploadfile();
      //alert("File Dimensions are Proper");
    }
    _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
    var fileName = "capgemini/" + lobbyeventname + "/Rs3" + "/poster3g21.png";

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(file);

    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);

    this.setState({ localuploader: uploader }, () => {
      console.log(
        `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
      );
    });
    $("#loader3g21").addClass("hidden");
    this.setState({ poster: false });

    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {

            percentage = newPercentage;
            console.log(`${percentage}%`);
            $("#poster3g21bar").show();
           $(".poster3g21barbtn").hide();

          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");
            $("#loader3g21").addClass("hidden");
            this.setState({ poster: true });
            var num = Math.random();

            this.setState({
              R3poster3g21path:
                AWS_S3_URL +
                "capgemini/" +
                lobbyeventname +
                "/Rs3" +
                "/poster3g21.png?v=" +
                num,
            });
            PPTPathService.setFiletype(
              lobbyeventname,
              "Rs3",
              "poster3g21",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: "File Uploaded Successfully!.",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
            //window.location.reload(false);
          } else if (upstatus === "failed") {
            $("#loader3g21").addClass("hidden");
            Swal.fire({
              // position: 'top-end',
              icon: "failure",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
};
onFileChange3g22 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function () {
    if (this.width !=  492 || this.height != 326) {
      alert(
        // "Uploaded File Dimension: Width= " +
        //   this.width +
        //   "  Height= " +
        //   this.height +
        "Required FIle Dimension: Width = " + 492 + " Height= " + 326
      );
    } else {
      console.log("Inside file dimension are proper loop");
      uploadfile();
      //alert("File Dimensions are Proper");
    }
    _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
    var fileName = "capgemini/" + lobbyeventname + "/Rs3" + "/poster3g22.png";

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(file);

    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);

    this.setState({ localuploader: uploader }, () => {
      console.log(
        `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
      );
    });
    $("#loader3g22").addClass("hidden");
    this.setState({ poster: false });

    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {

            percentage = newPercentage;
            console.log(`${percentage}%`);
            $("#poster3g22bar").show();
           $(".poster3g22barbtn").hide();

          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");
            $("#loader3g22").addClass("hidden");
            this.setState({ poster: true });
            var num = Math.random();

            this.setState({
              R3poster3g22path:
                AWS_S3_URL +
                "capgemini/" +
                lobbyeventname +
                "/Rs3" +
                "/poster3g22.png?v=" +
                num,
            });
            PPTPathService.setFiletype(
              lobbyeventname,
              "Rs3",
              "poster3g22",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: "File Uploaded Successfully!.",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
            //window.location.reload(false);
          } else if (upstatus === "failed") {
            $("#loader3g22").addClass("hidden");
            Swal.fire({
              // position: 'top-end',
              icon: "failure",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
};

onFileChange3g23 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function () {
    if (this.width !=  492 || this.height != 326) {
      alert(
        // "Uploaded File Dimension: Width= " +
        //   this.width +
        //   "  Height= " +
        //   this.height +
        "Required FIle Dimension: Width = " + 492 + " Height= " + 326
      );
    } else {
      console.log("Inside file dimension are proper loop");
      uploadfile();
      //alert("File Dimensions are Proper");
    }
    _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
    var fileName = "capgemini/" + lobbyeventname + "/Rs3" + "/poster3g23.png";

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(file);

    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);

    this.setState({ localuploader: uploader }, () => {
      console.log(
        `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
      );
    });
    $("#loader3g23").addClass("hidden");
    this.setState({ poster: false });

    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {

            percentage = newPercentage;
            console.log(`${percentage}%`);
            $("#poster3g23bar").show();
           $(".poster3g23barbtn").hide();

          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");
            $("#loader3g23").addClass("hidden");
            this.setState({ poster: true });
            var num = Math.random();

            this.setState({
              R3poster3g23path:
                AWS_S3_URL +
                "capgemini/" +
                lobbyeventname +
                "/Rs3" +
                "/poster3g23.png?v=" +
                num,
            });
            PPTPathService.setFiletype(
              lobbyeventname,
              "Rs3",
              "poster3g23",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: "File Uploaded Successfully!.",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
            //window.location.reload(false);
          } else if (upstatus === "failed") {
            $("#loader3g23").addClass("hidden");
            Swal.fire({
              // position: 'top-end',
              icon: "failure",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
};
  componentDidMount() {
    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname = EventService.getCurrentEvent();
    const filepath = "capgemini/" + lobbyeventname + "/Rs3" + "/poster313g2";
    console.log(`filepath= ${filepath}`);
    PPTPathService.getFiletype(eventname, "Rs3")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster313g2") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R3poster313g2path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs3" +
              "/poster313g2" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
              poster313g2: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R3poster313g2path:filedetails.path,
                poster313g2: true,

              });
            this.setState({ R3poster313g2type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });


    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname313g21 = EventService.getCurrentEvent();
    const filepath313g21 = "capgemini/" + lobbyeventname + "/Rs3" + "/poster313g21";
    console.log(`filepath= ${filepath313g21}`);
    PPTPathService.getFiletype(eventname313g21, "Rs3")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster313g21") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R3poster313g21path:
              AWS_S3_URL +
              "capgemini/" +
              eventname313g21 +
              "/Rs3" +
              "/poster313g21" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster313g21: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R3poster313g21path:filedetails.path,
                poster313g21: true,

              });
            this.setState({ R3poster313g21type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname313g22 = EventService.getCurrentEvent();
    const filepath313g22 = "capgemini/" + lobbyeventname + "/Rs3" + "/poster313g22";
    console.log(`filepath= ${filepath313g22}`);
    PPTPathService.getFiletype(eventname313g22, "Rs3")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster313g22") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R3poster313g22path:
              AWS_S3_URL +
              "capgemini/" +
              eventname313g22 +
              "/Rs3" +
              "/poster313g22" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster313g22: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R3poster313g22path:filedetails.path,
                poster313g22: true,

              });
            this.setState({ R3poster313g22type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });


    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname313g23 = EventService.getCurrentEvent();
    const filepath313g23 = "capgemini/" + lobbyeventname + "/Rs3" + "/poster313g23";
    console.log(`filepath= ${filepath313g23}`);
    PPTPathService.getFiletype(eventname313g23, "Rs3")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster313g23") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R3poster313g23path:
              AWS_S3_URL +
              "capgemini/" +
              eventname313g23 +
              "/Rs3" +
              "/poster313g23" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster313g23: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R3poster313g23path:filedetails.path,
                poster313g23: true,

              });
            this.setState({ R3poster313g23type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });


    EventService.getSpecificEvent()
    .then((response) => {
      console.log("Eventname from getSpecificEvent");
      console.log(response.data);
      console.log(response.data.eventname);
      const eventpath = "/" + response.data.eventname;
      this.setState({ tenantname: eventpath, showLoginPage: true });
      localStorage.setItem("eventname", response.data.eventname);
      console.log(response.data.eventname);

      const user=AuthService.getCurrentUser();

      AuthService.getCurrentRole().then((roles)=>{
        if (roles) {
          console.log("Got current role from server");
          this.setState({
            currentUser: user,
            showModeratorBoard: roles.data.includes("ROLE_MODERATOR"),
            showAdminBoard: roles.data.includes("ROLE_ADMIN"),
            showAddTenant: roles.data.includes("ROLE_SUPERADMIN"),
            showAddUser: roles.data.includes("ROLE_SUPERADMIN"),
            showRegModerators:
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showRegAdmins:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN"),
            showRegSuperAdmins: roles.data.includes("ROLE_SUPERADMIN"),
            showRegUsers:
              roles.data.includes("ROLE_MODERATOR") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showCreateEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showDetailLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showFeedbackReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEdit:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showNormalUser: roles.data.includes("ROLE_USER"),
            showTenants:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            //tenantname: tenant,
          });
        }



    this.setState({
      R3poster1path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs3" +
        "/poster1.png?v=" +
        num,
      R3poster2path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs3i" +
        "/poster2i.png?v=" +
        num,
        R3poster6path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs3" +
        "/poster6.png?v=" +
        num,
      // Rposter3path:
      //   AWS_S3_URL +
      //   "capgemini/" +
      //   lobbyeventname +
      //   "/Rs3" +
      //   "/poster3.txt?v=" +
      //   num,
        R3poster4path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs3" +
        "/poster4.pdf?v=" +
        num,
        R3poster3g21path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs3" +
        "/poster3g21.png?v=" +
        num,
        R3poster3g22path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs3" +
        "/poster3g22.png?v=" +
        num,
        R3poster3g23path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs3" +
        "/poster3g23.png?v=" +
        num,
        Rposter313g2path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs3" +
        "/poster313g2.txt?v=" +
        num,
         Rposter313g21path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs3" +
        "/poster313g21.txt?v=" +
        num,
        Rposter313g22path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs3" +
        "/poster313g22.txt?v=" +
        num,
        Rposter313g23path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs3" +
        "/poster313g23.txt?v=" +
        num,

      poster1: true,
      poster2: true,
      poster3: true,
      poster6: true,
      poster3g21: true,
      poster3g22: true,
      poster3g23: true,
      poster313g21: true,
      poster313g2: true,

      poster313g22: true,

      poster313g23: true,




    });
  })
}
    )
  }

  render() {
    function hideImg() {
      document.getElementById("myImg").style.display = "none";
    }
    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }
    function hideImg3g21() {
      document.getElementById("myImg3g21").style.display = "none";
    }
    function hideImg3g22() {
      document.getElementById("myImg3g22").style.display = "none";
    }
    function hideImg3g23() {
      document.getElementById("myImg3g23").style.display = "none";
    }



    const {
      poster1,
      poster2,
      poster3,
      poster6,
      poster3g21,
      poster3g22,
      poster3g23,
      poster313g21,
      poster313g2,

      poster313g22,

      poster313g23,



      R3poster1path,
      R3poster2path,
      R3poster3path,
      R3poster6path,
      R3poster3g21path,
      R3poster3g22path,
      R3poster3g23path,
      R3poster313g21path,
      R3poster313g2path,

      R3poster313g22path,

      R3poster313g23path,


      tenantname,
      progressbar,
    } = this.state;

    return (
      <div className="container-fluide">


        <div className="page-content">
          <div id="content-agenda">
            <div id="bg">
              <img src="https://d2e8va4puks5pl.cloudfront.net/VR-01/VR-1%204%20GRID.jpg" id="bg-image" className="mb-3" />
              <div className="sidenavogation">
                {/* <Link to={tenantname + "/ResourceEdit"} className="Rs1  text-white buttonRes">
                            <span className="hide-menu">Resource Center1</span>
                </Link>
                <Link to={tenantname + "/ResourceEdit1"} className="Rs2  text-white buttonRes">
                            <span className="hide-menu">Resource Center2</span>
                </Link>
                <Link to={tenantname + "/ResourceEdit2"} className="Rs3  text-white buttonRes">
                            <span className="hide-menu">Resource Center3</span>
                </Link>
                <Link to={tenantname + "/ResourceEdit3"} className="Rs4  text-white buttonRes">
                            <span className="hide-menu">Resource Center4</span>
                </Link>
                <Link to={tenantname + "/ResourceEdit4"} className="Rs5  text-white buttonRes">
                            <span className="hide-menu">Resource Center5</span>
                </Link> */}
                 <Link to={tenantname + "/agendaEdit"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Back To Agenda</span>
                  </Link>
                </div>
              <div id="Resource123g2">
                {poster1 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader1"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap12"
                      onClick={(e) => this.myInput.click()}
                    >
                      <img
                        src={R3poster1path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg()}
                        id="myImg"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange01}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource123g2-1">
                {poster3g21 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3g21"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap12"
                      onClick={(e) => this.myInput3g21.click()}
                    >
                      <img
                        src={R3poster3g21path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg3g21()}
                        id="myImg3g21"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput3g21 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange3g21}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource123g2-2">
                {poster3g22 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3g22"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap12"
                      onClick={(e) => this.myInput3g22.click()}
                    >
                      <img
                        src={R3poster3g22path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg3g22()}
                        id="myImg3g22"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput3g22 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange3g22}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource123g2-3">
                {poster3g23 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3g23"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap12"
                      onClick={(e) => this.myInput3g23.click()}
                    >
                      <img
                        src={R3poster3g23path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg3g23()}
                        id="myImg3g23"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput3g23 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange3g23}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource21g2edit1">
                {poster2 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader2"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap21"
                      onClick={(e) => this.myInput1.click()}
                    >
                      <img
                        src={R3poster2path}
                        // src={image1}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg1()}
                        id="myImg1"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput1 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange02}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource313g2">
                {poster313g2 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap31">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange03}
                        id="ResText"
                        placeholder={R3poster313g2path}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource313g2-1">
                {poster313g21 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap31">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange313g21}
                        id="ResText"
                        placeholder={R3poster313g21path}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource313g2-2">
                {poster313g22 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap31">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange313g22}
                        id="ResText"
                        placeholder={R3poster313g22path}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource313g2-3">
                {poster313g23 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap31">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange313g23}
                        id="ResText"
                        placeholder={R3poster313g23path}
                      />
                    </div>
                  </form>
                )}
              </div>
              {/* <div id="Resource92">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader2"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap92"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R3poster6path}
                        // src={image1}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput6 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange06}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div> */}

          <div id="ResourceCenter3g2">
          <div className="hotspot1">
            {/* <div className="out"></div> */}
            <div className="in1" onClick={this.P1openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen}
                  style={customStyles}
                  onRequestClose={this.P1closeModal}
                >
                  <span className="close" onClick={this.P1closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput23.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput24.click()}>Upload Video  </button>
                  <div id="loader4" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput23 = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange04}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput24 = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange04}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>
        <div id="ResourceCenter3g2-1">
          <div className="hotspot1">
            {/* <div className="out"></div> */}
            <div className="in1" onClick={this.P1openModal3g2}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen3g2}
                  style={customStyles}
                  onRequestClose={this.P1closeModal3g2}
                >
                  <span className="close" onClick={this.P1closeModal3g2}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput3g2.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput3g2v.click()}>Upload Video  </button>
                  <div id="loader3g2" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput3g2 = ref)}
            type="file"
            name="file"
            id="vid3g2"
            onChange={this.onFileChange3g2}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput23g2v = ref)}
            type="file"
            name="file"
            id="vid3g2"
            onChange={this.onFileChange3g2}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        <div id="ResourceCenter3g2-2">
          <div className="hotspot1">
            {/* <div className="out"></div> */}
            <div className="in1" onClick={this.P1openModal3g2a}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen3g2a}
                  style={customStyles}
                  onRequestClose={this.P1closeModal3g2a}
                >
                  <span className="close" onClick={this.P1closeModal3g2a}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput3g2a.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput3g2av.click()}>Upload Video  </button>
                  <div id="loader3g2a" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput3g2a = ref)}
            type="file"
            name="file"
            id="vid3g2a"
            onChange={this.onFileChange3g2a}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput3g2av = ref)}
            type="file"
            name="file"
            id="vid3g2a"
            onChange={this.onFileChange3g2a}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        <div id="ResourceCenter3g2-3">
          <div className="hotspot1">
            {/* <div className="out"></div> */}
            <div className="in1" onClick={this.P1openModal3g2b}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen3g2b}
                  style={customStyles}
                  onRequestClose={this.P1closeModal3g2b}
                >
                  <span className="close" onClick={this.P1closeModal3g2b}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput3g2b.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput3g2bv.click()}>Upload Video  </button>
                  <div id="loader3g2b" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput3g2b = ref)}
            type="file"
            name="file"
            id="vid3g2b "
            onChange={this.onFileChange3g2b}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput3g2bv = ref)}
            type="file"
            name="file"
            id="vid3g2b "
            onChange={this.onFileChange3g2b}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ResorceEdit3;
