import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import stall1 from "./img/VR-03/VR-03 1 GRIDS.jpg";
import image from "./img/rs1.png";
import image1 from "./img/rs2.png";
import Modal from "react-modal";
import AWSService from "../services/aws.service";
import EventService from "../services/event.service";
import PPTPathService from "../services/pptpath.service";
import UnauthorizedService from "../common/unauthorized";
import UnAuthorizedService from "../common/unauthorized";
import AuthService from "../services/auth.service";
import { Uploader } from "../utils/upload";
import Progressbar from "../common/progressbar";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./styles/maping.css";
import $ from "jquery";
import "../App.css";
var lobbyeventname = "";
let tenant = "";
const { aws_s3_url, aws_s3_bucket_name } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "25vh",
    marginRight: "-50%",
    width: "calc(18% - 0px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "rgba(0,0,0,.9)",
    background: "unset !important",
    border: "unset !important",

    zIndex: "99999",
    backgroundcolor: "unset !important",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(20% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding: "0 0 0 0",
  },
};
class ResorceEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initially, no file is selected
      selectedFile: null,
      poster1: false,
      poster2: false,
      poster3: false,
      poster3editg3:false,
      poster4: false,
      poster5: false,
      poster6: false,

      R1poster1path: "",
      R1poster2path: "",
      R1poster3path: "",
      R1poster4path: "",
      R1poster5path: "",
      R1poster6path: "",
      R1poster3editg3path: "",

      modalIsOpen: false,
      tenantname: tenant,

      localuploader: undefined,
      progressbar: 0,
    };
  }
  P1openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  P1closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  onFileChange01 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs1" + "/poster1.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader1").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster2bar").show();
             $(".poster2barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader1").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R1poster1path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs1" +
                  "/poster1.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs1",
                "poster1",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader1").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChange02 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  690 || this.height != 77) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 690 + " Height= " + 77
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs1s" + "/poster2s.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader2").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster2bar").show();
             $(".poster2barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader2").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R1poster2path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs1s" +
                  "/poster2s.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs1",
                "poster2",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader2").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };

  onFileChange03editg3 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs1", "poster3editg3", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };


 // On file select (from the pop up)
 onFileChange04 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rsg3" + "/poster4" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs1",
            "poster4",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};



  componentDidMount() {
    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname = EventService.getCurrentEvent();
    const filepath = "capgemini/" + lobbyeventname + "/Rs1" + "/poster3editg3";
    console.log(`filepath= ${filepath}`);
    PPTPathService.getFiletype(eventname, "Rs1")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster3editg3") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R1poster3editg3path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs1" +
              "/poster3editg3" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
              poster3editg3: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R1poster3editg3path:filedetails.path,
                poster3editg3: true,

              });
            this.setState({ R1poster3editg3type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    EventService.getSpecificEvent()
    .then((response) => {
      console.log("Eventname from getSpecificEvent");
      console.log(response.data);
      console.log(response.data.eventname);
      const eventpath = "/" + response.data.eventname;
      this.setState({ tenantname: eventpath, showLoginPage: true });
      localStorage.setItem("eventname", response.data.eventname);
      console.log(response.data.eventname);

      const user=AuthService.getCurrentUser();

      AuthService.getCurrentRole().then((roles)=>{
        if (roles) {
          console.log("Got current role from server");
          this.setState({
            currentUser: user,
            showModeratorBoard: roles.data.includes("ROLE_MODERATOR"),
            showAdminBoard: roles.data.includes("ROLE_ADMIN"),
            showAddTenant: roles.data.includes("ROLE_SUPERADMIN"),
            showAddUser: roles.data.includes("ROLE_SUPERADMIN"),
            showRegModerators:
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showRegAdmins:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN"),
            showRegSuperAdmins: roles.data.includes("ROLE_SUPERADMIN"),
            showRegUsers:
              roles.data.includes("ROLE_MODERATOR") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showCreateEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showDetailLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showFeedbackReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEdit:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showNormalUser: roles.data.includes("ROLE_USER"),
            showTenants:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            //tenantname: tenant,
          });
        }



    this.setState({
      R1poster1path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs1" +
        "/poster1.png?v=" +
        num,
      R1poster2path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs1s" +
        "/poster2s.png?v=" +
        num,
        R1poster6path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs1" +
        "/poster6.png?v=" +
        num,
      // Rposter3path:
      //   AWS_S3_URL +
      //   "capgemini/" +
      //   lobbyeventname +
      //   "/Rs1" +
      //   "/poster3.txt?v=" +
      //   num,
        R1poster4path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs1" +
        "/poster4.pdf?v=" +
        num,


      poster1: true,
      poster2: true,
      poster3: true,
      poster3editg3:true,
      poster4: true,
      poster6: true,

    });
  })
}
    )
  }

  render() {
    function hideImg() {
      document.getElementById("myImg").style.display = "none";
    }
    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }

    const {
      poster1,
      poster2,
      poster3,
      poster3editg3,
      poster6,

      R1poster1path,
      R1poster2path,
      R1poster3path,
      R1poster3editg3path,
      R1poster6path,
      tenantname,
      progressbar,
    } = this.state;

    return (
      <div className="container-fluide">


        <div className="page-content">
          <div id="content-agenda">
            <div id="bg">
              <img src="https://d2e8va4puks5pl.cloudfront.net/VR-03/VR-03%201%20GRIDS.jpg" id="bg-image" className="mb-3" />
              <div className="sidenavogation">
                  {/* <Link to={tenantname + "/ResourceEdit"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Resource Center1</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit1"} className="Rs2  text-white buttonRes">
                              <span className="hide-menu">Resource Center2</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit2"} className="Rs3  text-white buttonRes">
                              <span className="hide-menu">Resource Center3</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit3"} className="Rs4  text-white  buttonRes">
                              <span className="hide-menu">Resource Center4</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit4"} className="Rs5  text-white buttonRes">
                              <span className="hide-menu">Resource Center5</span>
                  </Link> */}

                  <Link to={tenantname + "/agendaEdit"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Back To Agenda</span>
                  </Link>

                  </div>
              <div id="Resource12editg3one">
                {poster1 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader1"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap12"
                      onClick={(e) => this.myInput.click()}
                    >
                      <img
                        src={R1poster1path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg()}
                        id="myImg"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange01}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>
              <div id="Resource21editg3one">
                {poster2 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader2"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap21"
                      onClick={(e) => this.myInput1.click()}
                    >
                      <img
                        src={R1poster2path}
                        // src={image1}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg1()}
                        id="myImg1"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput1 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange02}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource31editg3one1">
                {poster3editg3 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap31">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange03editg3}
                        id="ResText"
                        placeholder={R1poster3editg3path}
                      />
                    </div>
                  </form>
                )}
              </div>

          <div id="ResourceCenter">
          <div className="hotspot1editg3one">
            {/* <div className="out"></div> */}
            <div className="in1editg3one" onClick={this.P1openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen}
                  style={customStyles}
                  onRequestClose={this.P1closeModal}
                >
                  <span className="close" onClick={this.P1closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div className="modal-container btn-group-vertical">
                  <button type="button" className="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput23.click()}>Upload PDF  </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput24.click()}>Upload Video  </button>
                  <div id="loader4" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput23 = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange04}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput24 = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange04}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        {/* <div id="Resource9">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap9"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R1poster6path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput6 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange06}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ResorceEdit;
