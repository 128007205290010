import React, { Component } from "react";
import EventService from "../services/event.service";
import Marzipano from "marzipano";
import PPTPathService from "../services/pptpath.service";
import UnAuthorizedService from "../common/unauthorized";
import Preloader from "./preloader.component";
import "./styles/styles.css";
import "./styles/maping.css";
import Modal from "react-modal";
import "./styles/videopopup.css";
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "70vh",
    marginRight: "-50%",
    width: "calc(60% - 0px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    border: "unset !important",
    margintop: "calc(85vh - 10vh - 10px)",
    zIndex: "99999",
    backgroundcolor: "unset !important",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(50% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding: "0 0 0 0",
  },
};
const { aws_s3_url } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
const article_url = "https://www.youtube.com/embed/qb-i1eb3Ff8";
const linkToPPTFile =
  "https://coact-capgemini-stagings3.s3.ap-northeast-1.amazonaws.com/capgemini/samsung/lb/poster9";

//var poster10path = "";
//var poster11path = "";
class Lobby extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initially, no file is selected
      selectedFile: null,
      poster1: false,
      poster2: false,
      poster3: false,
      poster4: false,
      poster5: false,
      poster6: false,
      poster7: false,
      poster8: false,
      poster9: false,
      poster10: false,
      poster11: false,
      poster12: false,
      poster13: false,
      poster14: false,
      poster15: false,

      poster1path: "",
      poster2path: "",
      poster3path: "",
      poster4path: "",
      poster5path: "",
      poster6path: "",
      poster7path: "",
      poster8path: "",
      poster9path: "",
      poster10path: "",
      poster12path: "",
      poster13path: "",
      poster14path: "",
      poster15path: "",

      poster10type: "",
      poster11type: "",
      poster12type: "",
      poster13type: "",
      poster14type: "",
      poster15type: "",

      modal1IsOpen: false,
      modal2IsOpen: false,
      modal1PIsOpen: false,
      modal1VIsOpen: false,
      modal2VIsOpen: false,
      modal2PIsOpen: false,

      // Vid2modalIsOpen: false,
    };
  }
  P1openModal = () => {
    this.setState({ modal1IsOpen: true });
  };

  P1closeModal = () => {
    this.setState({ modal1IsOpen: false });
  };
  P1VopenModal = () => {
    this.setState({ modal1VIsOpen: true });
  };

  P1VcloseModal = () => {
    this.setState({ modal1VIsOpen: false });
  };
  P1PopenModal = () => {
    this.setState({ modal1PIsOpen: true });
  };

  P1PcloseModal = () => {
    this.setState({ modal1PIsOpen: false });
  };
  P2openModal = () => {
    this.setState({ modal2IsOpen: true });
  };

  P2closeModal = () => {
    this.setState({ modal2IsOpen: false });
  };
  P2VopenModal = () => {
    this.setState({ modal2VIsOpen: true });
  };

  P2VcloseModal = () => {
    this.setState({ modal2VIsOpen: false });
  };
  P2PopenModal = () => {
    this.setState({ modal2PIsOpen: true });
  };

  P2PcloseModal = () => {
    this.setState({ modal2PIsOpen: false });
  };
  static displayName = "Pano";
  componentDidMount() {
    console.log("Inside Lobby component componentDidMount");
    localStorage.setItem("intro", 0);
    const eventname = EventService.getCurrentEvent();
    console.log(eventname);
    PPTPathService.getFiletype(eventname, "lb")
      .then((response) => {
        // console.log(response.data[0].filetype);
        // console.log(response.data[0].filename);
        // console.log(response.data[1].filetype);
        // console.log(response.data[1].filename);
        response.data.forEach((filedetails) => {
          if (filedetails.filename === "poster10") {
            console.log("filepath got from db is sssssssssssssssssssssssss");
            console.log(filedetails);
            console.log(filedetails.path);
            this.setState({
              poster10path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/lb" +
                "/poster10" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster10: true,
              // poster10path:"https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2",
              // poster10: true,
            });
            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
              filedetails.filetype === "ogv"
            ) {
              this.setState({ poster10type: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  poster10path: filedetails.path,
                  poster10: true,
                });
                this.setState({ poster10type: "ppt" });
              }
            }
            if (filedetails.filetype === "pdf") {
              {
                this.setState({ poster10type: "pdf" });
              }
            }
            console.log(this.state.poster10path);
          }

          if (filedetails.filename == "poster11") {
            this.setState({
              poster11path:
                AWS_S3_URL +
                "capgemini/" +
                eventname +
                "/lb" +
                "/poster11" +
                "." +
                filedetails.filetype +
                "?v=" +
                num,
              poster11: true,
            });

            if (
              filedetails.filetype === "mp4" ||
              filedetails.filetype === "mov" ||
              filedetails.filetype === "webm" ||
              filedetails.filetype === "ogv"
            ) {
              this.setState({ poster11type: "video" });
            }
            if (
              filedetails.filetype === "pptx" ||
              filedetails.filetype === "ppt"
            ) {
              {
                this.setState({
                  poster11path: filedetails.path,
                  poster11: true,
                });
                this.setState({ poster11type: "ppt" });
              }
            }
            if (filedetails.filetype === "pdf") {
              {
                this.setState({ poster11type: "pdf" });
              }
            }
            console.log(this.state.poster11path);
          }
        });
      })
      .catch((err) => {
        UnAuthorizedService.unauthorized(err);
      });

    var num = Math.random();
    //var imgSrc ="https://coact-stream-bucket.s3.ap-southeast-2.amazonaws.com/Audi.png?v=" +num;
    this.setState({
      poster1path:
        AWS_S3_URL + "capgemini/" + eventname + "/lb" + "/poster1.png?v=" + num,
      poster2path:
        AWS_S3_URL + "capgemini/" + eventname + "/lb" + "/poster2.png?v=" + num,
      poster3path:
        AWS_S3_URL + "capgemini/" + eventname + "/lb" + "/poster3.png?v=" + num,
      poster4path:
        AWS_S3_URL + "capgemini/" + eventname + "/lb" + "/poster4.png?v=" + num,
      poster5path:
        AWS_S3_URL + "capgemini/" + eventname + "/lb" + "/poster5.png?v=" + num,
      poster6path:
        AWS_S3_URL + "capgemini/" + eventname + "/lb" + "/poster6.png?v=" + num,
      poster7path:
        AWS_S3_URL + "capgemini/" + eventname + "/lb" + "/poster7.png?v=" + num,
      poster8path:
        AWS_S3_URL + "capgemini/" + eventname + "/lb" + "/poster8.png?v=" + num,
      poster9path:
        AWS_S3_URL + "capgemini/" + eventname + "/lb" + "/poster9.png?v=" + num,
      poster12path:
        AWS_S3_URL +
        "capgemini/" +
        eventname +
        "/lb" +
        "/poster12.png?v=" +
        num,
      poster13path:
        AWS_S3_URL +
        "capgemini/" +
        eventname +
        "/lb" +
        "/poster13.png?v=" +
        num,

      poster1: true,
      poster2: true,
      poster3: true,
      poster4: true,
      poster5: true,
      poster6: true,
      poster7: true,
      poster8: true,
      poster9: true,
      poster12: true,
      poster13: true,
    });

    this.viewer = new Marzipano.Viewer(this.pano); //, viewerOpts);
    // Create source.
    const source = Marzipano.ImageUrlSource.fromString("https://d2e8va4puks5pl.cloudfront.net/lobby.jpg");

    // Create geometry.
    const geometry = new Marzipano.EquirectGeometry([{ width: 5000 }]);
    const limiter = Marzipano.RectilinearView.limit.traditional(
      4 * 1024,
      (100 * Math.PI) / 180,
      (120 * Math.PI) / 180
    );
    // const limiter = Marzipano.RectilinearView.limit.traditional(
    //   1024,
    //   (100 * Math.PI) / 180
    // );
    const view = new Marzipano.RectilinearView({ yaw: Math.PI }, limiter);

    // Get the view values

    // var yaw = view.yaw();
    // var pitch = view.pitch();
    // var fov = view.fov(); // fov is horizontal
    /*var vfov = view.vfov();
    var hfov = view.hfov();    // same as view.fov()*/
    var velocity = 0.7;
    var friction = 3;
    // Change the values
    view.setYaw((0 * Math.PI) / 180);
    view.setPitch((0 * Math.PI) / 180);
    view.setFov((120 * Math.PI) / 180);
    var viewInElement = document.querySelector("#viewIn");
    var viewOutElement = document.querySelector("#viewOut");
    var controls = this.viewer.controls();
    // controls.registerMethod('inElement',
    // new Marzipano.KeyControlMethod
    // ('2',  'zoom', -velocity, friction, viewInElement), true);
    // controls.registerMethod('outElement',
    // new Marzipano.KeyControlMethod
    // ('2', 'zoom',  velocity, friction, viewOutElement), true);

    const scene = this.viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      pinFirstLevel: true,
      viewControlButtons: true,
    });

    scene.switchTo();

    // Get the hotspot container for scene.
    var container = scene.hotspotContainer();
    container.createHotspot(
      this.poster01,
      { yaw: -0.569, pitch: -0.001 },
      {
        perspective: {
          radius: 1920,
          extraTransforms: "rotateX(0deg) rotateY(-28deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster02,
      { yaw: 2.15, pitch: 0.038 },
      {
        perspective: {
          radius: 2190,
          extraTransforms: "rotateX(-2deg) rotateY(34deg) rotateZ(0deg)",
        },
      }
    );
    var container = scene.hotspotContainer();
    container.createHotspot(
      this.poster03,
      { yaw: 0.848, pitch: -0.0015 },
      {
        perspective: {
          radius: 2300,
          extraTransforms: "rotateX(0deg) rotateY(45deg) rotateZ(0deg)",
        },
      }
    );
    var container = scene.hotspotContainer();
    container.createHotspot(
      this.poster04,
      { yaw: 0.05, pitch: -0.21 },
      {
        perspective: {
          radius: 2150,
          extraTransforms: "rotateX(12deg) rotateY(4deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster05,
      { yaw: 1.582, pitch: -0.273 },
      {
        perspective: {
          radius: 1190,
          extraTransforms: "rotateX(15deg) rotateY(2deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster06,
      { yaw: 2.998, pitch: -0.297 },
      {
        perspective: {
          radius: 1274,
          extraTransforms: "rotateX(22deg) rotateY(-4deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster07,
      { yaw: 2.612, pitch: 0.014 },
      {
        perspective: {
          radius: 2150,
          extraTransforms: "rotateX(60deg) rotateY(-5deg) rotateZ(12deg)",
        },
      }
    );
    container.createHotspot(
      this.poster08,
      { yaw: 3.387, pitch: 0.0199 },
      {
        perspective: {
          radius: 2158,
          extraTransforms: "rotateX(58deg) rotateY(8deg) rotateZ(-14deg)",
        },
      }
    );
    container.createHotspot(
      this.poster09,
      { yaw: 4.97, pitch: -0.12 },
      {
        perspective: {
          radius: 2240,
          extraTransforms: "rotateX(0deg) rotateY(0deg) rotateZ(1deg)",
        },
      }
    );
    var container = scene.hotspotContainer();
    container.createHotspot(
      this.poster012,
      { yaw: 0.98, pitch: 0.015 },
      {
        perspective: {
          radius: 2300,
          extraTransforms: "rotateX(-3deg) rotateY(38deg) rotateZ(0deg)",
        },
      }
    );
    var container = scene.hotspotContainer();
    container.createHotspot(
      this.poster013,
      { yaw: 1.07, pitch: 0.03 },
      {
        perspective: {
          radius: 1990,
          extraTransforms: "rotateX(15deg) rotateY(2deg) rotateZ(0deg)",
        },
      }
    );

    // container.createHotspot(
    //   this.poster010,
    //   { yaw: 4.999, pitch: 0.015 },
    //   {
    //     perspective: {
    //       radius: 1990,
    //       extraTransforms: "rotateX(6deg) rotateY(30deg) rotateZ(-4deg)",
    //     },
    //   }
    // );
    scene.hotspotContainer().createHotspot(document.querySelector("#pptview"), {
      yaw: 1.58,
      pitch: 0.11,
    });

    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview1"), {
        yaw: 1.58,
        pitch: 0.11,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview2"), {
        yaw: 1.58,
        pitch: 0.11,
      });
    // scene
    //   .hotspotContainer()
    //   .createHotspot(document.querySelector("#pptview1"), {
    //     yaw: 2.8,
    //     pitch: -0.02,
    //   });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream1"), {
        yaw: 2.99,
        pitch: -0.053,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream2"), {
        yaw: 2.99,
        pitch: -0.053,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream3"), {
        yaw: 2.99,
        pitch: -0.053,
      });

    var autorotate = Marzipano.autorotate({
      yawSpeed: 0.1, // Yaw rotation speed
      targetPitch: 0, // Pitch value to converge to
      targetFov: Math.PI / 2, // Fov value to converge to
    });

    // Autorotate will start after 3s of idle time
    this.viewer.setIdleMovement(3000, autorotate);
    // Disable idle movement
    //this.viewer.setIdleMovement(Infinity);

    // Start autorotation immediately
    this.viewer.startMovement(autorotate);
  }

  render() {
    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }
    function hideImg3() {
      document.getElementById("myImg3").style.display = "none";
    }
    function hideImg4() {
      document.getElementById("myImg4").style.display = "none";
    }
    function hideImg5() {
      document.getElementById("myImg5").style.display = "none";
    }
    function hideImg6() {
      document.getElementById("myImg6").style.display = "none";
    }
    function hideImg7() {
      document.getElementById("myImg7").style.display = "none";
    }
    function hideImg8() {
      document.getElementById("myImg8").style.display = "none";
    }
    function hideImg9() {
      document.getElementById("myImg9").style.display = "none";
    }
    function hideImg10() {
      document.getElementById("myImg10").style.display = "none";
    }
    function hideImg11() {
      document.getElementById("myImg11").style.display = "none";
    }
    const {
      poster1,
      poster2,
      poster3,
      poster4,
      poster5,
      poster6,
      poster7,
      poster8,
      poster9,
      poster10,
      poster11,
      poster12,
      poster13,
      poster14,
      poster1path,
      poster2path,
      poster3path,
      poster4path,
      poster5path,
      poster6path,
      poster7path,
      poster8path,
      poster9path,
      poster10path,
      poster11path,
      poster12path,
      poster13path,
      poster14path,
      poster10type,
      poster11type,
      poster14type,
    } = this.state;

    // function myFunction() {
    //   document.getElementById(".popupbox").style.display = "block";
    // }

    return (
      <div className="pano-container" ref={(pano) => (this.pano = pano)}>
        <Preloader />
        {/* <div id="Zoombutton">
        <a id="viewIn" class="viewControlButton viewControlButton-5">
            <FaPlus id="plus" style={{ color: "black", cursor: "pointer" }} />
        </a>
        <a  id="viewOut" class="viewControlButton1 viewControlButton-6">
        <FaMinus id="minus" style={{ color: "black", cursor: "pointer" }} />
        </a>
        </div> */}

        <div id="poster01" ref={(poster01) => (this.poster01 = poster01)}>
          <label>
            {poster1 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map11">
                  <img
                    src={poster1path}
                    onError={() => hideImg1()}
                    id="myImg1"
                    alt=""
                  />
                </div>
              </form>
            )}
          </label>
        </div>

        <div id="poster02" ref={(poster02) => (this.poster02 = poster02)}>
          <label>
            {poster2 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader2"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map22">
                  <img
                    src={poster2path}
                    onError={() => hideImg2()}
                    id="myImg2"
                    alt=""
                  />
                </div>
              </form>
            )}
          </label>
        </div>
        <div id="poster03" ref={(poster03) => (this.poster03 = poster03)}>
          <label>
            {poster3 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map33">
                  <img
                    src={poster3path}
                    onError={() => hideImg3()}
                    id="myImg3"
                    alt=""
                  />
                </div>
              </form>
            )}
          </label>
        </div>
        <div id="poster04" ref={(poster04) => (this.poster04 = poster04)}>
          <label>
            {poster4 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader4"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map44">
                  <img
                    src={poster4path}
                    onError={() => hideImg4()}
                    id="myImg4"
                  />
                </div>
              </form>
            )}
          </label>
        </div>

        <div id="poster05" ref={(poster05) => (this.poster05 = poster05)}>
          <label>
            {poster5 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader5"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map55">
                  <img
                    src={poster5path}
                    onError={() => hideImg5()}
                    id="myImg5"
                  />
                </div>
              </form>
            )}
          </label>
        </div>

        <div id="poster06" ref={(poster06) => (this.poster06 = poster06)}>
          <label>
            {poster6 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader6"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map66">
                  <img
                    src={poster6path}
                    onError={() => hideImg6()}
                    id="myImg6"
                  />
                </div>
              </form>
            )}
          </label>
        </div>
        <div id="poster07" ref={(poster07) => (this.poster07 = poster07)}>
          <label>
            {poster7 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader7"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map77">
                  <img
                    src={poster7path}
                    onError={() => hideImg7()}
                    id="myImg7"
                  />
                </div>
              </form>
            )}
          </label>
        </div>

        <div id="poster08" ref={(poster08) => (this.poster08 = poster08)}>
          <label>
            {poster8 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader8"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map88">
                  <img
                    src={poster8path}
                    onError={() => hideImg8()}
                    id="myImg8"
                    alt=""
                  />
                </div>
              </form>
            )}
          </label>
        </div>

        <div id="poster09" ref={(poster09) => (this.poster09 = poster09)}>
          <label>
            {poster9 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader9"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map99">
                  <img
                    src={poster9path}
                    alt=""
                    onError={() => hideImg9()}
                    id="myImg9"
                  />
                </div>
              </form>
            )}
          </label>
        </div>

        <div id="pptview">
          <div>
            <div className="hotspot">
              {/* <div className="out"></div> */}
              {poster10 && poster10type === "ppt" && (
                <div className="inn" onClick={this.P1openModal}>
                  {" "}
                </div>
              )}
            </div>
            {poster10 && (
              <div>
                <Modal
                  isOpen={this.state.modal1IsOpen}
                  style={customStyles}
                  onRequestClose={this.P1closeModal}
                  ariaHideApp={false}
                >
                  <span className="close" onClick={this.P1closeModal}>
                    x
                  </span>
                  <iframe
                    // src={"https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"}
                    src={poster10path}
                    // src="https://docs.google.com/presentation/d/1zEOxbg33xDX2KLigAdyQmYWbdj3kvbBG/edit#slide=id.p1"
                    allowfullscreen
                    // Content-Type="application/vnd.ms-powerpoint"
                  />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="pptview1">
          <div>
            <div className="hotspot1">
              {poster10 && poster10type === "video" && (
                <div className="inn1" onClick={this.P1VopenModal}></div>
              )}
            </div>
            {poster10 && (
              <div>
                <Modal
                  isOpen={this.state.modal1VIsOpen}
                  style={customStylesV1}
                  onRequestClose={this.P1VcloseModal}
                >
                  <span className="close" onClick={this.P1VcloseModal}>
                    x
                  </span>
                  <video
                    id="video1"
                    autoplay
                    controls
                    controlsList="nodownload"
                  >
                    <source src={poster10path} />
                  </video>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="pptview2">
          <div>
            <div className="hotspot2">
              {poster10 && poster10type === "pdf" && (
                <div className="inn22" onClick={this.P1PopenModal}></div>
              )}
            </div>
            {poster10 && (
              <div>
                <Modal
                  isOpen={this.state.modal1PIsOpen}
                  style={customStyles}
                  onRequestClose={this.P1PcloseModal}
                >
                  <span className="close" onClick={this.P1PcloseModal}>
                    x
                  </span>

                  <iframe src={poster10path} allowfullscreen />
                </Modal>
              </div>
            )}
          </div>
        </div>
        <div id="Videostream1">
          <div className="hotspot2">
            {poster11 && poster11type === "video" && (
              <div className="inn2" onClick={this.P2VopenModal}></div>
            )}
          </div>

          {poster11 && (
            <div>
              <Modal
                isOpen={this.state.modal2VIsOpen}
                style={customStylesV1}
                onRequestClose={this.P2VcloseModal}
              >
                <span className="close" onClick={this.P2VcloseModal}>
                  x
                </span>
                <video id="video" autoplay controls controlsList="nodownload">
                  <source src={poster11path} />
                </video>
              </Modal>
            </div>
          )}
        </div>

        <div id="Videostream2">
          <div className="hotspot3">
            {poster11 && poster11type === "ppt" && (
              <div className="inn3" onClick={this.P2openModal}></div>
            )}
          </div>

          {poster11 && (
            <div>
              <Modal
                isOpen={this.state.modal2IsOpen}
                style={customStyles}
                onRequestClose={this.P2closeModal}
                ariaHideApp={false}
              >
                <span className="close" onClick={this.P2closeModal}>
                  x
                </span>
                <iframe src={poster11path} allowFullScreen />
              </Modal>
            </div>
          )}
        </div>
        <div id="Videostream3">
          <div className="hotspot4">
            {poster11 && poster11type === "pdf" && (
              <div className="inn4" onClick={this.P2PopenModal}></div>
            )}
          </div>

          {poster11 && (
            <div>
              <Modal
                isOpen={this.state.modal2PIsOpen}
                style={customStyles}
                onRequestClose={this.P2PcloseModal}
              >
                <span className="close" onClick={this.P2PcloseModal}>
                  x
                </span>
                <iframe src={poster11path} allowfullscreen />
              </Modal>
            </div>
          )}
        </div>
        <div id="poster012" ref={(poster012) => (this.poster012 = poster012)}>
          <label>
            {poster12 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader12"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map122">
                  <img
                    src={poster12path}
                    style={{ cursor: "pointer" }}
                    onError={() => hideImg10()}
                    id="myImg10"
                  />
                </div>
                <input
                  ref={this.imgRef}
                  type="file"
                  name="file"
                  id="posterimg3"
                  accept="image/png, image/gif, image/jpeg"
                  style={{ display: "none" }}
                />
              </form>
            )}
          </label>
        </div>
        <div id="poster013" ref={(poster013) => (this.poster013 = poster013)}>
          <label>
            {poster13 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader13"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map133">
                  <img
                    src={poster13path}
                    style={{ cursor: "pointer" }}
                    onError={() => hideImg11()}
                    id="myImg11"
                  />
                </div>
                <input
                  ref={this.imgRef}
                  type="file"
                  name="file"
                  id="posterimg3"
                  accept="image/png, image/gif, image/jpeg"
                  style={{ display: "none" }}
                />
              </form>
            )}
          </label>
        </div>
      </div>
    );
  }
}

export default Lobby;
