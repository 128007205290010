import React, { Component } from "react";
import Marzipano from "marzipano";
import "./styles/styles.css";
import Modal from "react-modal";
import AWSService from "../services/aws.service";
import EventService from "../services/event.service";
import PPTPathService from "../services/pptpath.service";
import UnauthorizedService from "../common/unauthorized";
import { Uploader } from "../utils/upload";
import Swal from "sweetalert2";
import "./styles/maping.css";
import $ from "jquery";
import image from "./img/lp1.png";
import image1 from "./img/lp2.png";
import image3 from "./img/lp3.png";
import image12 from "./img/lp12.png";
import image9 from "./img/lp9.png";
import image13 from "./img/lp13.png";
import pptpathService from "../services/pptpath.service";
import Progressbar from "../common/progressbar";
const { aws_s3_url, aws_s3_bucket_name } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
var lobbyeventname = "";
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "25vh",
    marginRight: "-50%",
    width: "calc(18% - 0px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "rgba(0,0,0,.9)",
    background: "unset !important",
    border: "unset !important",
    zIndex: "99999",
    backgroundcolor: "unset !important",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(20% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding: "0 0 0 0",
  },
};

class LobbyEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initially, no file is selected
      selectedFile: null,
      poster1: false,
      poster2: false,
      poster3: false,
      poster4: false,
      poster5: false,
      poster6: false,
      poster7: false,
      poster8: false,
      poster9: false,
      poster10: false,
      poster11: false,
      poster12: false,
      poster13: false,

      poster1path: "",
      poster2path: "",
      poster3path: "",
      poster4path: "",
      poster5path: "",
      poster6path: "",
      poster7path: "",
      poster8path: "",
      poster9path: "",
      poster10path: "",
      poster11path: "",
      poster12path: "",
      poster13path: "",
      modalIsOpen: false,
      modal2IsOpen: false,
      modal3IsOpen: false,

      localuploader: undefined,
      progressbar: 0,
    };
    // this.onCancel = this.onCancel.bind(this);
    // this.onFileChange010 = this.onFileChange010.bind(this);
  }
  P1openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  P1closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  P2openModal = () => {
    this.setState({ modal2IsOpen: true });
  };

  P2closeModal = () => {
    this.setState({ modal2IsOpen: false });
  };
  P3openModal = () => {
    this.setState({ modal3IsOpen: true });
  };

  P3closeModal = () => {
    this.setState({ modal3IsOpen: false });
  };

  onCancel = async (event) => {
    console.log("OnCancel called");
    console.log(this.state.localuploader);
    if (this.state.localuploader) {
      this.state.localuploader.abort();
      //setFile(undefined);
    }
  };

  // On file select (from the pop up)
  onFileChange01 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 436 || this.height != 932) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 436 + " Height= " + 932
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/lb" + "/poster1.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader1").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster2bar").show();
             $(".poster2barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader1").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                poster1path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/lb" +
                  "/poster1.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "lb",
                "poster1",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader1").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
   // On file select (from the pop up)
   onFileChange02 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 436 || this.height != 904) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 436 + " Height= " + 904
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/lb" + "/poster2.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader2").addClass("hidden");
      this.setState({ poster: false });
      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster3bar").show();
              $(".poster3barbtn").hide();
            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader2").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();
              this.setState({
                poster2path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/lb" +
                  "/poster2.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "lb",
                "poster2",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader2").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
   // On file select (from the pop up)
   onFileChange03 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 436 || this.height != 1008) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 436 + " Height= " + 1008
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/lb" + "/poster3.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader3").addClass("hidden");
      this.setState({ poster: false });
      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster4bar").show();
              $(".poster4barbtn").hide();
            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader3").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();
              this.setState({
                poster3path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/lb" +
                  "/poster3.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "lb",
                "poster3",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader3").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };

  // On file select (from the pop up)
  onFileChange04 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 1284 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 1284 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/lb" + "/poster4.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader4").addClass("hidden");
      this.setState({ poster: false });
      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster5bar").show();
              $(".poster5barbtn").hide();
            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader4").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();
              this.setState({
                poster4path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/lb" +
                  "/poster4.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "lb",
                "poster4",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader4").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
   // On file select (from the pop up)
   onFileChange05 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 4024 || this.height != 1532) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 4024 + " Height= " + 1532
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/lb" + "/poster5.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader5").addClass("hidden");
      this.setState({ poster: false });
      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster6bar").show();
              $(".poster6barbtn").hide();
            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader5").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();
              this.setState({
                poster5path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/lb" +
                  "/poster5.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "lb",
                "poster1",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader5").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  // On file select (from the pop up)
  onFileChange06 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 1644 || this.height != 1016) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 1644 + " Height= " + 1016
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/lb" + "/poster6.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader6").addClass("hidden");
      this.setState({ poster: false });
      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster7bar").show();
              $(".poster7barbtn").hide();
            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader6").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();
              this.setState({
                poster6path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/lb" +
                  "/poster6.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "lb",
                "poster6",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader6").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
   // On file select (from the pop up)
   onFileChange07 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 220 || this.height != 260) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 220 + " Height= " + 260
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/lb" + "/poster7.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader7").addClass("hidden");
      this.setState({ poster: false });
      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster8bar").show();
              $(".poster8barbtn").hide();
            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader7").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();
              this.setState({
                poster7path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/lb" +
                  "/poster7.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "lb",
                "poster7",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader7").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
   // On file select (from the pop up)
   onFileChange08 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 265 || this.height != 281) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 265 + " Height= " + 281
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/lb" + "/poster8.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader8").addClass("hidden");
      this.setState({ poster: false });
      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster9bar").show();
              $(".poster9barbtn").hide();
            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader8").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();
              this.setState({
                poster8path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/lb" +
                  "/poster8.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "lb",
                "poster8",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader8").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
   // On file select (from the pop up)
   onFileChange09 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 436 || this.height != 268) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 436 + " Height= " + 268
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/lb" + "/poster9.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader9").addClass("hidden");
      this.setState({ poster: false });
      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster10bar").show();
              $(".poster10barbtn").hide();
            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader9").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();
              this.setState({
                poster9path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/lb" +
                  "/poster9.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "lb",
                "poster9",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader9").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };

  // On file select (from the pop up)
  onFileChange010 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0]);
    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    // img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    // img.src = objectUrl;
    //var fileType = event.target.files[0].type;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    // const eventname = EventService.getCurrentEvent();
    // let uploadfile = () => {
    var fileName =
      "capgemini/" + lobbyeventname + "/lb" + "/poster10" + "." + fileType;

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(fileType);
    console.log(event.target.files);
    console.log(fileType);
    var ctype = fileType;
    if (fileType == "pdf") {
      ctype = "application/pdf";
      console.log("filetype=pdf");
    }
    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      //contentType: ctype,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);
    this.setState({ localuploader: uploader });

    this.setState({ poster: false });
    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            this.setState({ progressbar: newPercentage });
            console.log(`${percentage}%`);
            $("#poster1bar").show();
            $(".modal-container").hide();
          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");

            this.setState({ poster: true });
            var num = Math.random();

            PPTPathService.setFiletype(
              lobbyeventname,
              "lb",
              "poster10",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                // Swal.fire({
                //   // position: 'top-end',
                //   icon: "success",
                //   title: "File Uploaded Successfully!.",
                //   showConfirmButton: false,
                //   timer: 2000,
                // });
               //$(".poster1barbtn").hide();
                $("#poster1bar").hide();
               // $(".modal-container").hide();
                // $(".progress").hide();
                $("#complete").show();

                // window.location.reload();
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
          } else if (upstatus === "failed") {

            Swal.fire({
              // position: 'top-end',
              icon: "danger",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };

  // onFileChange010 = async (event) => {
  //   // Update the state
  //   this.setState({ selectedFile: event.target.files[0] });
  //   console.log("Inside this.onFileChange()");
  //   console.log(event.target.files[0]);
  //   var _URL = window.URL || window.webkitURL;
  //   var file, img;
  //   file = event.target.files[0];
  //   // img = new Image();
  //   var objectUrl = _URL.createObjectURL(event.target.files[0]);
  //   // img.src = objectUrl;
  //   //var fileType = event.target.files[0].type;
  //   var fileType = event.target.files[0].name.split(".").pop();
  //   var filepath = null;
  //   // const eventname = EventService.getCurrentEvent();
  //   // let uploadfile = () => {
  //   var fileName =
  //     "capgemini/" + lobbyeventname + "/lb" + "/poster10" + "." + fileType;

  //   console.log(`filepath is ${fileName}`);
  //   console.log(fileName);
  //   console.log(fileType);
  //   console.log(event.target.files);
  //   console.log(fileType);
  //   var ctype = fileType;
  //   if (fileType == "pdf") {
  //     ctype = "application/pdf";
  //     console.log("filetype=pdf");
  //   }
  //   let data = JSON.stringify({
  //     fileName: fileName,
  //     contentType: fileType,
  //     //contentType: ctype,
  //     bucketname: aws_s3_bucket_name,
  //   });
  //   AWSService.getpredefinedURL(data).then((response) => {
  //     console.log("after getting response from predefinedurl");
  //     console.log(response);
  //     var posterurl = response.data.uploadURL;
  //     var formData = new FormData();
  //     formData.append("file", file);
  //     $("#loader10").removeClass("hidden");
  //     this.setState({ poster10: false });
  //     AWSService.uploadfile(posterurl, file, fileType).then(
  //       (resp) => {
  //         $("#loader10").addClass("hidden");
  //         this.setState({ poster10: true });

  //         PPTPathService.setFiletype(
  //           lobbyeventname,
  //           "lb",
  //           "poster10",
  //           fileType,
  //           filepath
  //         )
  //           .then((result) => {
  //             console.log(result);
  //             console.log(
  //               "Uploaded successfully........................................"
  //             );
  //             Swal.fire({
  //               // position: 'top-end',
  //               icon: "success",
  //               title: "File Uploaded Successfully!.",
  //               showConfirmButton: false,
  //               timer: 2000,
  //             });
  //           })
  //           .catch((err) => {
  //             UnauthorizedService.unauthorized(err);
  //           });
  //       },
  //       (err) => {
  //         $("#loader10").addClass("hidden");
  //         Swal.fire({
  //           // position: 'top-end',
  //           icon: "danger",
  //           title: "File Not Uploaded!.",
  //           showConfirmButton: false,
  //           // timer: 2000
  //         });
  //       }
  //     );
  //   });
  //   // };
  //   // }
  // };
  // On file select (from the pop up)
   // On file select (from the pop up)
   onFileChange011 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0]);
    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    // img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    // img.src = objectUrl;
    //var fileType = event.target.files[0].type;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    // const eventname = EventService.getCurrentEvent();
    // let uploadfile = () => {
    var fileName =
      "capgemini/" + lobbyeventname + "/lb" + "/poster11" + "." + fileType;

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(fileType);
    console.log(event.target.files);
    console.log(fileType);
    var ctype = fileType;
    if (fileType == "pdf") {
      ctype = "application/pdf";
      console.log("filetype=pdf");
    }
    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      //contentType: ctype,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);
    this.setState({ localuploader: uploader });

    this.setState({ poster: false });
    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            this.setState({ progressbar: newPercentage });
            console.log(`${percentage}%`);
            $("#poster1bar").show();
            $(".modal-container").hide();
          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");

            this.setState({ poster: true });
            var num = Math.random();

            PPTPathService.setFiletype(
              lobbyeventname,
              "lb",
              "poster11",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                // Swal.fire({
                //   // position: 'top-end',
                //   icon: "success",
                //   title: "File Uploaded Successfully!.",
                //   showConfirmButton: false,
                //   timer: 2000,
                // });
               //$(".poster1barbtn").hide();
                $("#poster1bar").hide();
               // $(".modal-container").hide();
                // $(".progress").hide();
                $("#complete").show();

                // window.location.reload();
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
          } else if (upstatus === "failed") {

            Swal.fire({
              // position: 'top-end',
              icon: "danger",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
   // On file select (from the pop up)
   onFileChange012 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 436 || this.height != 1296) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 436 + " Height= " + 1296
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/lb" + "/poster12.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader12").addClass("hidden");
      this.setState({ poster: false });
      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster11bar").show();
              $(".poster11barbtn").hide();
            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader12").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();
              this.setState({
                poster12path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/lb" +
                  "/poster12.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "lb",
                "poster12",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader12").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
   // On file select (from the pop up)
   onFileChange013 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 436 || this.height != 1152) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 436 + " Height= " + 1152
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/lb" + "/poster13.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader13").addClass("hidden");
      this.setState({ poster: false });
      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster12bar").show();
              $(".poster12barbtn").hide();
            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader13").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();
              this.setState({
                poster13path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/lb" +
                  "/poster13.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "lb",
                "poster13",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader13").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  static displayName = "Pano";
  componentDidMount() {
    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    const filepath = "capgemini/" + lobbyeventname + "/lb" + "/poster";
    var num = Math.random();
    console.log(`filepath= ${filepath}`);
    this.setState({
      poster1path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/lb" +
        "/poster1.png?v=" +
        num,
      poster2path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/lb" +
        "/poster2.png?v=" +
        num,
      poster3path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/lb" +
        "/poster3.png?v=" +
        num,
      poster4path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/lb" +
        "/poster4.png?v=" +
        num,
      poster5path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/lb" +
        "/poster5.png?v=" +
        num,
      poster6path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/lb" +
        "/poster6.png?v=" +
        num,
      poster7path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/lb" +
        "/poster7.png?v=" +
        num,
      poster8path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/lb" +
        "/poster8.png?v=" +
        num,
      poster9path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/lb" +
        "/poster9.png?v=" +
        num,
      poster10path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/lb" +
        "/poster10.pptx?v=" +
        num,
      poster11path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/lb" +
        "/poster11.mp4?v=" +
        num,
      poster12path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/lb" +
        "/poster12.png?v=" +
        num,
      poster13path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/lb" +
        "/poster13.png?v=" +
        num,
      poster1: true,
      poster2: true,
      poster3: true,
      poster4: true,
      poster5: true,
      poster6: true,
      poster7: true,
      poster8: true,
      poster9: true,
      poster10: true,
      poster11: true,
      poster12: true,
      poster13: true,
    });
    //ppt upload
    $(document).on("click", "#pptupload", function () {
      const { value: text } = Swal.fire({
        input: "text",
        inputPlaceholder: "copy the link",
      })
        .then((text) => {
          if (text.isConfirmed) {
            let data = Object.entries(text)[3][1];
            var parser = new DOMParser();
            var parsedIframe = parser.parseFromString(data, "text/html");
            let iFrame = parsedIframe.getElementsByTagName("iframe");
            let src = iFrame[0].src;
            console.log(src);
            Swal.fire(src);
            PPTPathService.setFiletype(
              lobbyeventname,
              "lb",
              "poster10",
              // fileType,
              "ppt",
              src
              //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
            ).then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
            });
          }
        })
        .catch((text) => {
          if (text.isDenied) {
            Swal.fire("required input");
          }
        });
    });

    //poster11

    $(document).on("click", "#pptupload1", function () {
      const { value: url } = Swal.fire({
        input: "text",
        inputLabel: " text",
        inputPlaceholder: "copy the link",
      }).then((text) => {
        if (text.isConfirmed) {
          let data = Object.entries(text)[3][1];
          var parser = new DOMParser();
          var parsedIframe = parser.parseFromString(data, "text/html");
          let iFrame = parsedIframe.getElementsByTagName("iframe");
          let src = iFrame[0].src;
          console.log(src);
          Swal.fire(src);
          //  console.log(Object.entries(text)[3][1]);

          PPTPathService.setFiletype(
            lobbyeventname,
            "lb",
            "poster11",
            // fileType,
            "ppt",
            // Object.entries(text)[3][1]
            src
            //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
            })
            .catch((text) => {
              if (text.isDenied) {
                Swal.fire("required input");
              }
            });
        }
        // .catch((err) => {
        //   UnauthorizedService.unauthorized(err);
        // })
      });
    });

    //this.setState({ poster1: true });
    // this.setState(
    //   { poster1path: "capgemini/" + eventname + "/poster.png" },
    //   console.log(`poster1path set ${this.poster1path}`)
    // );
    console.log(filepath);
    console.log(this.poster1path);
    this.viewer = new Marzipano.Viewer(this.pano); //, viewerOpts);

    // Create source.
    const source = Marzipano.ImageUrlSource.fromString("https://d2e8va4puks5pl.cloudfront.net/lobby.jpg");

    // Create geometry.
    const geometry = new Marzipano.EquirectGeometry([{ width: 5000 }]);

    const limiter = Marzipano.RectilinearView.limit.traditional(
      4 * 1024,
      (100 * Math.PI) / 180
    );

    const view = new Marzipano.RectilinearView({ yaw: Math.PI }, limiter);

    // Get the view values

    var yaw = view.yaw();
    var pitch = view.pitch();
    var fov = view.fov(); // fov is horizontal
    /*var vfov = view.vfov();
    var hfov = view.hfov();    // same as view.fov()*/

    // Change the values

    view.setYaw((0 * Math.PI) / 180);
    view.setPitch((0 * Math.PI) / 180);
    view.setFov((120 * Math.PI) / 180);

    const scene = this.viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      pinFirstLevel: true,
    });

    scene.switchTo();

    // Get the hotspot container for scene.
    var container = scene.hotspotContainer();
    container.createHotspot(
      this.poster01,
      { yaw: -0.569, pitch: -0.001 },
      {
        perspective: {
          radius: 1920,
          extraTransforms: "rotateX(0deg) rotateY(-28deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster02,
      { yaw: 2.15, pitch: 0.038 },
      {
        perspective: {
          radius: 2190,
          extraTransforms: "rotateX(-2deg) rotateY(34deg) rotateZ(0deg)",
        },
      }
    );
    var container = scene.hotspotContainer();
    container.createHotspot(
      this.poster03,
      { yaw: 0.848, pitch: -0.0015 },
      {
        perspective: {
          radius: 2300,
          extraTransforms: "rotateX(0deg) rotateY(45deg) rotateZ(0deg)",
        },
      }
    );
    var container = scene.hotspotContainer();
    container.createHotspot(
      this.poster04,
      { yaw: 0.05, pitch: -0.21 },
      {
        perspective: {
          radius: 2150,
          extraTransforms: "rotateX(12deg) rotateY(4deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster05,
      { yaw: 1.582, pitch: -0.273 },
      {
        perspective: {
          radius: 1190,
          extraTransforms: "rotateX(15deg) rotateY(2deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster06,
      { yaw: 2.998, pitch: -0.297 },
      {
        perspective: {
          radius: 1274,
          extraTransforms: "rotateX(22deg) rotateY(-4deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster07,
      { yaw: 2.612, pitch: 0.014 },
      {
        perspective: {
          radius: 2150,
          extraTransforms: "rotateX(60deg) rotateY(-5deg) rotateZ(12deg)",
        },
      }
    );
    container.createHotspot(
      this.poster08,
      { yaw: 3.387, pitch: 0.0199 },
      {
        perspective: {
          radius: 2158,
          extraTransforms: "rotateX(58deg) rotateY(8deg) rotateZ(-14deg)",
        },
      }
    );
    container.createHotspot(
      this.poster09,
      { yaw: 4.97, pitch: -0.12 },
      {
        perspective: {
          radius: 2240,
          extraTransforms: "rotateX(0deg) rotateY(0deg) rotateZ(1deg)",
        },
      }
    );
    // container.createHotspot(
    //   this.poster010,
    //   { yaw: 4.999, pitch: 0.015 },
    //   {
    //     perspective: {
    //       radius: 1990,
    //       extraTransforms: "rotateX(6deg) rotateY(30deg) rotateZ(-4deg)",
    //     },
    //   }
    // );
    scene.hotspotContainer().createHotspot(document.querySelector("#pptview"), {
      yaw: 1.58,
      pitch: 0.11,
    });
    // scene.hotspotContainer().createHotspot(document.querySelector("#pptview1"), {
    //   yaw: 1.64,
    //   pitch: 0.11,
    // });
    // scene
    //   .hotspotContainer()
    //   .createHotspot(document.querySelector("#pptview1"), {
    //     yaw: 2.8,
    //     pitch: -0.02,
    //   });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#Videostream1"), {
        yaw: 2.99,
        pitch: -0.053,
      });
    // scene
    //   .hotspotContainer()
    //   .createHotspot(document.querySelector("#Videostream2"), {
    //     yaw: 3.02,
    //     pitch: -0.04,
    //   });
    var container = scene.hotspotContainer();
    var autorotateToggleElement = document.querySelector('#autorotateToggle');
    container.createHotspot(
      this.poster012,
      { yaw: 0.98, pitch: 0.015 },
      {
        perspective: {
          radius: 2300,
          extraTransforms: "rotateX(-3deg) rotateY(38deg) rotateZ(0deg)",
        },
      }
    );
    var container = scene.hotspotContainer();
    container.createHotspot(
      this.poster013,
      { yaw: 1.07, pitch: 0.03 },
      {
        perspective: {
          radius: 1990,
          extraTransforms: "rotateX(15deg) rotateY(2deg) rotateZ(0deg)",
        },
      }
    );
  }

  render() {
    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }
    function hideImg3() {
      document.getElementById("myImg3").style.display = "none";
    }
    function hideImg4() {
      document.getElementById("myImg4").style.display = "none";
    }
    function hideImg5() {
      document.getElementById("myImg5").style.display = "none";
    }
    function hideImg6() {
      document.getElementById("myImg6").style.display = "none";
    }
    function hideImg7() {
      document.getElementById("myImg7").style.display = "none";
    }
    function hideImg8() {
      document.getElementById("myImg8").style.display = "none";
    }
    function hideImg9() {
      document.getElementById("myImg9").style.display = "none";
    }
    function hideImg10() {
      document.getElementById("myImg10").style.display = "none";
    }
    function hideImg11() {
      document.getElementById("myImg11").style.display = "none";
    }

    const {
      poster1,
      poster2,
      poster3,
      poster4,
      poster5,
      poster6,
      poster7,
      poster8,
      poster9,
      poster12,
      poster13,
      poster1path,
      poster2path,
      poster3path,
      poster4path,
      poster5path,
      poster6path,
      poster7path,
      poster8path,
      poster9path,
      poster12path,
      poster13path,
      progressbar,
    } = this.state;
    //console.log("poster1path isssssssssssss");

    return (
      <div className="pano-container" ref={(pano) => (this.pano = pano)}>
        <div id="poster01" ref={(poster01) => (this.poster01 = poster01)}>
        <div id="poster2bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster2barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete2">
            <button
                className="btn btn-xl complete2"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
            {poster1 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>
                <div
                  id="loader1"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map1">
                  <img
                    src={poster1path}
                    // src={image}
                    style={{ cursor: "pointer" }}
                    alt=""
                    onError={() => hideImg1()}
                    id="myImg1"
                  />
                </div>
                <input
                  ref={this.imgRef}
                  type="file"
                  name="file"
                  id="posterimg1"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange01}
                  style={{ display: "none" }}
                />
              </form>
            )}
          </label>
        </div>

        <div id="poster02" ref={(poster02) => (this.poster02 = poster02)}>
        <div id="poster3bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster3barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete3">
            <button
                className="btn btn-xl complete3"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
            {poster2 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader2"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map2">
                  <img
                    src={poster2path}
                    //src={image1}
                    style={{ cursor: "pointer" }}
                    onError={() => hideImg2()}
                    id="myImg2"
                  />
                </div>
                <input
                  ref={this.imgRef}
                  type="file"
                  name="file"
                  id="posterimg2"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange02}
                  style={{ display: "none" }}
                />
              </form>
            )}
          </label>
        </div>
        <div id="poster03" ref={(poster03) => (this.poster03 = poster03)}>
        <div id="poster4bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster4barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete4">
            <button
                className="btn btn-xl complete4"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
            {poster3 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader3"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map3">
                  <img
                    src={poster3path}
                    // src={image3}
                    style={{ cursor: "pointer" }}
                    onError={() => hideImg3()}
                    id="myImg3"
                  />
                </div>
                <input
                  ref={this.imgRef}
                  type="file"
                  name="file"
                  id="posterimg3"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange03}
                  style={{ display: "none" }}
                />
              </form>
            )}
          </label>
        </div>
        <div id="poster04" ref={(poster04) => (this.poster04 = poster04)}>
        <div id="poster5bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster5barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete5">
            <button
                className="btn btn-xl complete5"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
            {poster4 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader4"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map4">
                  <img
                    src={poster4path}
                    style={{ cursor: "pointer" }}
                    onError={() => hideImg4()}
                    id="myImg4"
                  />
                </div>
                <input
                  ref={this.imgRef}
                  type="file"
                  name="file"
                  id="posterimg4"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange04}
                  style={{ display: "none" }}
                />
              </form>
            )}
          </label>
        </div>

        <div id="poster05" ref={(poster05) => (this.poster05 = poster05)}>
        <div id="poster6bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster6barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete6">
            <button
                className="btn btn-xl complete6"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
            {poster5 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader5"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map5">
                  <img
                    src={poster5path}
                    style={{ cursor: "pointer" }}
                    onError={() => hideImg5()}
                    id="myImg5"
                  />
                </div>
                <input
                  ref={this.imgRef}
                  type="file"
                  name="file"
                  id="posterimg5"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange05}
                  style={{ display: "none" }}
                />
              </form>
            )}
          </label>
        </div>

        <div id="poster06" ref={(poster06) => (this.poster06 = poster06)}>
        <div id="poster7bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster7barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete7">
            <button
                className="btn btn-xl complete7"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
            {poster6 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader6"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map6">
                  <img
                    src={poster6path}
                    style={{ cursor: "pointer" }}
                    onError={() => hideImg6()}
                    id="myImg6"
                  />
                </div>
                <input
                  ref={this.imgRef}
                  type="file"
                  name="file"
                  id="posterimg6"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange06}
                  style={{ display: "none" }}
                />
              </form>
            )}
          </label>
        </div>
        <div id="poster07" ref={(poster07) => (this.poster07 = poster07)}>
        <div id="poster8bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster8barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete8">
            <button
                className="btn btn-xl complete8"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
            {poster7 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader7"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map7">
                  <img
                    src={poster7path}
                    style={{ cursor: "pointer" }}
                    onError={() => hideImg7()}
                    id="myImg7"
                  />
                </div>
                <input
                  ref={this.imgRef}
                  type="file"
                  name="file"
                  id="posterimg7"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange07}
                  style={{ display: "none" }}
                />
              </form>
            )}
          </label>
        </div>

        <div id="poster08" ref={(poster08) => (this.poster08 = poster08)}>
        <div id="poster9bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster9barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete9">
            <button
                className="btn btn-xl complete9"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
            {poster8 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader8"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map8">
                  <img
                    src={poster8path}
                    style={{ cursor: "pointer" }}
                    onError={() => hideImg8()}
                    id="myImg8"
                  />
                </div>
                <input
                  ref={this.imgRef}
                  type="file"
                  name="file"
                  id="posterimg8"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange08}
                  style={{ display: "none" }}
                />
              </form>
            )}
          </label>
        </div>

        <div id="poster09" ref={(poster09) => (this.poster09 = poster09)}>
        <div id="poster10bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster10barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete10">
            <button
                className="btn btn-xl complete10"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
            {poster9 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader9"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map9">
                  <img
                    src={poster9path}
                    // src={image9}
                    style={{ cursor: "pointer" }}
                    onError={() => hideImg9()}
                    id="myImg9"
                  />
                </div>
                <input
                  ref={this.imgRef}
                  type="file"
                  name="file"
                  id="posterimg9"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange09}
                  style={{ display: "none" }}
                />
              </form>
            )}
          </label>
        </div>

        <div id="pptview">
          <div className="hotspot">
            <div
              className="in"
              onClick={this.P1openModal}
              //  onClick={(e) => this.myInput.click()}
            ></div>
          </div>

          <Modal
            isOpen={this.state.modalIsOpen}
            style={customStyles}
            onRequestClose={this.P1closeModal}
            ariaHideApp={false}
          >
            <span className="close1" onClick={this.P1closeModal}>
              x
            </span>
            <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
            <div className="modal-container  btn-group-vertical">
              <button type="button" className="btn btn-primary " id="pptupload">
                Upload PPT{" "}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => this.myInput11.click()}
              >
                Upload PDF{" "}
              </button>

              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => this.myInput22.click()}
              >
                Upload Video{" "}
              </button>
              <div
                id="loader10"
                className="lds-dual-ring hidden overlay loader-modal "
              ></div>
            </div>
          </Modal>

          {/* <input
                ref={(ref) => (this.myInput = ref)}
                type="url"
                name="url"
                id="posterimg10"
                onChange={this.onFileChange010}
                style={{ display: "none" }}
                // accept=".ppt,.pptx"
              /> */}
          <input
            ref={(ref) => (this.myInput11 = ref)}
            type="file"
            name="file"
            id="posterimg10"
            onChange={this.onFileChange010}
            style={{ display: "none" }}
            accept=".pdf"
          />
          <input
            ref={(ref) => (this.myInput22 = ref)}
            type="file"
            name="file"
            id="posterimg10"
            onChange={this.onFileChange010}
            style={{ display: "none" }}
            accept="video/mp4,video/x-m4v,video/*"
          />
        </div>
        {/* <div id="pptview1">
          <div id="loader10" className="lds-dual-ring hidden overlay"></div>
          <div className="hotspot1">
            <div
              className="in1"
              title="upload a ppt file"
              onClick={(e) => this.myInput.click()}
            >
              {" "}
            </div>
          </div>

          <input
            ref={(ref) => (this.myInput = ref)}
            type="file"
            name="file"
            id="posterimg10"
            onChange={this.onFileChange010}
            style={{ display: "none" }}
            accept=".ppt,.pptx, video/mp4,video/x-m4v,video/*"
          />
        </div> */}

        <div id="Videostream1">
          <div className="hotspot2">
            {/* <div className="out"></div> */}
            <div className="in2" onClick={this.P2openModal}></div>
          </div>
          <Modal
            isOpen={this.state.modal2IsOpen}
            style={customStyles}
            onRequestClose={this.P2closeModal}
          >
            <span className="close1" onClick={this.P2closeModal}>
              x
            </span>
            <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P2closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P2closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

            <div class="modal-container btn-group-vertical">
              <button type="button" class="btn btn-primary" id="pptupload1">
                Upload PPT{" "}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) => this.myInput23.click()}
              >
                Upload PDF{" "}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) => this.myInput24.click()}
              >
                Upload Video{" "}
              </button>
              <div
                id="loader11"
                className="lds-dual-ring hidden overlay loader-modal "
              ></div>
            </div>
          </Modal>

          <input
            ref={(ref) => (this.myInput23 = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange011}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput24 = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange011}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }} />
          </div>
        </div>

        {/* <div id="Videostream2">
          <div className="hotspot3">

            <div
              className="in3"
              title="upload a video file."
              onClick={(e) => this.myInput2.click()}
            ></div>
          </div>
          <div id="loader11" className="lds-dual-ring hidden overlay"></div>
          <input
            ref={(ref) => (this.myInput2 = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange011}
            accept=".ppt,.pptx, video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
        </div> */}
        <div id="poster012" ref={(poster012) => (this.poster012 = poster012)}>
        <div id="poster11bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster11barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete11">
            <button
                className="btn btn-xl complete11"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
            {poster12 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader12"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map12">
                  <img
                    src={poster12path}
                    //src={image12}
                    style={{ cursor: "pointer" }}
                    onError={() => hideImg10()}
                    id="myImg10"
                  />
                </div>
                <input
                  ref={this.imgRef}
                  type="file"
                  name="file"
                  id="posterimg3"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange012}
                  style={{ display: "none" }}
                />
              </form>
            )}
          </label>
        </div>
        <div id="poster013" ref={(poster013) => (this.poster013 = poster013)}>
        <div id="poster12bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster12barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete12">
            <button
                className="btn btn-xl complete12"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
            {poster13 && (
              <form encType="multipart/form-data">
                <div id="richList"></div>

                <div
                  id="loader13"
                  className="lds-dual-ring hidden overlay"
                ></div>
                <div id="map13">
                  <img
                    src={poster13path}
                    //src={image1}
                    style={{ cursor: "pointer" }}
                    onError={() => hideImg11()}
                    id="myImg11"
                  />
                </div>
                <input
                  ref={this.imgRef}
                  type="file"
                  name="file"
                  id="posterimg3"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={this.onFileChange013}
                  style={{ display: "none" }}
                />
              </form>
            )}
          </label>
        </div>
      </div>
    );
  }
}

export default LobbyEdit;
