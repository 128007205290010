import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/styles.css";
import EventBus from "../../common/EventBus";
import AuthService from "../../services/auth.service";
import EventService from "../../services/event.service";
import Preloader from "../preloader.component";
import preloader from "../preloader.component";
//class Enter extends Component {
  const { aws_s3_url } = require("../../config/config");
  const AWS_S3_URL = aws_s3_url;
  // const videourl=AWS_S3_URL+"walkthrough.mp4";
  const videourl="https://d2e8va4puks5pl.cloudfront.net/Transition Video.mp4";
  const EnterBoard1 = () => {
    localStorage.setItem("intro", 1);
    console.log(
      "Inside Enter main method............................................"
    );
    //render() {
    const navigate = useNavigate();
    var entryVideo = document.getElementById("gotoboardentryvideo");
    if (entryVideo) {
      entryVideo.addEventListener("ended",  EntryEnd, false);
      entryVideo.play();
    }



  //   setTimeout(function () {
  //     console.log("before sending data in eventbus");
  //     EventBus.dispatch("login");
  //     localStorage.setItem("intro", 0);
  //     var currentuser = AuthService.getCurrentRole().then((roles)=>{
  //       if (roles.data.includes("ROLE_USER")) {
  //         localStorage.setItem("intro", 0);
  //         const eventname = "/" + EventService.getCurrentEvent();
  //         navigate(eventname + "/resource");
  //         //window.location.href = "/resource";
  //       } else {

  //         const eventname = "/" + EventService.getCurrentEvent();
  //         navigate(eventname + "/boardroom");// the redirect goes here
  //         //window.location.href = "/lobby";
  //       }

  //     })


  //  },20000);
  function EntryEnd() {
    console.log("before sending data in eventbus skipEntry");
    EventBus.dispatch("login");
    localStorage.setItem("intro", 1);
    // var currentuser = AuthService.getCurrentUser();
    // if (currentuser.roles.includes("ROLE_USER")) {
    //   navigate("/resource");
    //   //window.location.href = "/resource";
    // } else {
      const eventname = "/" + EventService.getCurrentEvent();
      navigate(eventname + "/boardroom");
      //window.location.href = "/lobby";
    //}
    // window.location.reload();
  }
  function skipEntry() {
    console.log("before sending data in eventbus skipEntry");
    EventBus.dispatch("login");
    localStorage.setItem("intro", 0);
    var currentuser = AuthService.getCurrentUser();
    // if (currentuser.roles.includes("ROLE_USER")) {
    //   navigate("/resource");
    //   //window.location.href = "/resource";
    // } else {
      const eventname = "/" + EventService.getCurrentEvent();
      navigate(eventname + "/boardroom");
      //window.location.href = "/lobby";
   // }
    // window.location.reload();
  }
  return (
    <div>


    <section
      className="videotoplay"
      id="gotoentry"
      // style={{ display: "none" }}
    >
      <Preloader />
      <video
        className="videoplayer"
        id="gotoboardentryvideo"
        autoPlay
        preload="auto"
      >
       <source src={videourl} type="video/mp4" />
      </video>
      <div className="skip">
        <a href="#" onClick={() => skipEntry()}>
          SKIP
        </a>
      </div>
    </section>
  </div>
);
};
//}
export default EnterBoard1;
