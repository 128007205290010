import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import stall1 from "./img/VR-03/VR-03 5 GRIDS.jpg";
import image from "./img/rs1.png";
import image1 from "./img/rs2.png";
import Modal from "react-modal";
import AWSService from "../services/aws.service";
import EventService from "../services/event.service";
import PPTPathService from "../services/pptpath.service";
import UnauthorizedService from "../common/unauthorized";
import UnAuthorizedService from "../common/unauthorized";
import AuthService from "../services/auth.service";
import { Uploader } from "../utils/upload";
import Progressbar from "../common/progressbar";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./styles/maping.css";
import $ from "jquery";
import "../App.css";
var lobbyeventname = "";
let tenant = "";
const { aws_s3_url, aws_s3_bucket_name } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "25vh",
    marginRight: "-50%",
    width: "calc(18% - 0px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "rgba(0,0,0,.9)",
    background: "unset !important",
    border: "unset !important",

    zIndex: "99999",
    backgroundcolor: "unset !important",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(20% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding: "0 0 0 0",
  },
};
class ResorceEdit4G3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initially, no file is selected
      selectedFile: null,
      poster1: false,
      poster2: false,
      poster3edit4g3: false,
      poster4: false,
      poster5: false,
      poster6: false,

      poster5g30: false,
      poster5g32: false,
      poster5g33: false,
      poster5g34: false,
      poster53aa: false,
      poster53bb: false,
      poster53cc: false,
      poster53dd: false,




      R4poster1path: "",
      R4poster2path: "",
      R4poster3edit4g3path: "",
      R4poster4path: "",
      R4poster5path: "",
      R4poster6path: "",
      R4poster5g30path: "",
      R4poster5g32path: "",
      R4poster5g33path: "",
      R4poster5g34path: "",
      R4poster53aapath: "",
      R4poster53bbpath: "",
      R4poster53ccpath: "",
      R4poster53ddpath: "",


      modalIsOpen: false,
      modalIsOpen46: false,
      modalIsOpen47: false,
      modalIsOpen48: false,
      modalIsOpen49: false,
      tenantname: tenant,
      localuploader: undefined,
      progressbar: 0,
    };
  }
  P1openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  P1closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  P1openModal46 = () => {
    this.setState({ modalIsOpen46: true });
  };

  P1closeModal46 = () => {
    this.setState({ modalIsOpen46: false });
  };
  P1openModal47 = () => {
    this.setState({ modalIsOpen47: true });
  };

  P1closeModal47 = () => {
    this.setState({ modalIsOpen47: false });
  };
  P1openModal48 = () => {
    this.setState({ modalIsOpen48: true });
  };

  P1closeModal48 = () => {
    this.setState({ modalIsOpen48: false });
  };

  P1openModal49 = () => {
    this.setState({ modalIsOpen49: true });
  };

  P1closeModal49 = () => {
    this.setState({ modalIsOpen49: false });
  };
  onFileChange01 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster1.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader1").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster2bar").show();
             $(".poster2barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader1").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R4poster1path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs4" +
                  "/poster1.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs3",
                "poster1",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader1").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChange02 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  690 || this.height != 77) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 690 + " Height= " + 77
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs4p" + "/poster2p.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader2").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster2bar").show();
             $(".poster2barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader2").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R4poster2path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs4p" +
                  "/poster2p.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs4",
                "poster2",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader2").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };

  onFileChange03 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster3edit4g3", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };
  onFileChange53aa = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster53aa", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange53bb = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster53bb", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };
  onFileChange5g30 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster5g30.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader5g30").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster5g30bar").show();
             $(".poster5g30barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader5g30").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R4poster1path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs4" +
                  "/poster5g30.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs4",
                "poster5g30",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader5g30").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChange5g32 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster5g32.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader5g32").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster5g32bar").show();
             $(".poster5g32barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader5g32").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R4poster1path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs4" +
                  "/poster5g32.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs4",
                "poster5g32",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader5g32").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChange5g33 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster5g33.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader5g33").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster5g33bar").show();
             $(".poster5g33barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader5g33").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R4poster1path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs4" +
                  "/poster5g33.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs4",
                "poster5g33",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader5g33").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };

  onFileChange5g34 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster5g34.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader5g34").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster5g34bar").show();
             $(".poster5g34barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader5g34").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R4poster1path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs4" +
                  "/poster5g34.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs4",
                "poster5g34",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader5g34").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
// On file select (from the pop up)
 onFileChange04 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs34g3" + "/poster4" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs34g3",
            "poster4",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};


onFileChange046 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange46()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs4" + "/poster46" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs4",
            "poster46",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange047 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange47()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs4" + "/poster47" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs4",
            "poster47",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange048 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange48()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs4" + "/poster48" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs4",
            "poster48",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};

// onFileChange04 = async (event) => {
//   // Update the state
//   this.setState({ selectedFile: event.target.files[0] });
//   console.log("Inside this.onFileChange()");
//   console.log(event.target.files[0]);
//   var _URL = window.URL || window.webkitURL;
//   var file, img;
//   file = event.target.files[0];
//   // img = new Image();
//   var objectUrl = _URL.createObjectURL(event.target.files[0]);
//   // img.src = objectUrl;
//   //var fileType = event.target.files[0].type;
//   var fileType = event.target.files[0].name.split(".").pop();
//   var filepath = null;
//   // const eventname = EventService.getCurrentEvent();
//   // let uploadfile = () => {
//   var fileName =
//     "capgemini/" + lobbyeventname + "/Rs34g3" + "/poster4" + "." + fileType;

//   console.log(`filepath is ${fileName}`);
//   console.log(fileName);
//   console.log(fileType);
//   console.log(event.target.files);
//   console.log(fileType);
//   var ctype = fileType;
//   if (fileType == "pdf") {
//     ctype = "application/pdf";
//     console.log("filetype=pdf");
//   }
//   let data = JSON.stringify({
//     fileName: fileName,
//     contentType: fileType,
//     //contentType: ctype,
//     bucketname: aws_s3_bucket_name,
//   });

//   const videoUploaderOptions = {
//     fileName: fileName,
//     file: file,
//   };

//   let percentage = undefined;
//   let upstatus = undefined;

//   const uploader = new Uploader(videoUploaderOptions);
//   this.setState({ localuploader: uploader });

//   this.setState({ poster: false });
//   uploader
//     .onProgress(
//       ({
//         percentage: newPercentage,
//         sent: sent,
//         total: total,
//         upstatus: upstatus,
//       }) => {
//         // to avoid the same percentage to be logged twice
//         if (newPercentage !== percentage) {
//           percentage = newPercentage;
//           this.setState({ progressbar: newPercentage });
//           console.log(`${percentage}%`);
//           $("#poster1bar").show();
//           $(".modal-container").hide();
//         }
//         if (upstatus === "complete") {
//           console.log("File uploaded successfully");

//           this.setState({ poster: true });
//           var num = Math.random();

//           PPTPathService.setFiletype(
//             lobbyeventname,
//             "Rs34g3",
//             "poster4",
//             fileType,
//             filepath
//           )
//             .then((result) => {
//               console.log(result);
//               console.log(
//                 "Uploaded successfully........................................"
//               );
//               // Swal.fire({
//               //   // position: 'top-end',
//               //   icon: "success",
//               //   title: "File Uploaded Successfully!.",
//               //   showConfirmButton: false,
//               //   timer: 2000,
//               // });
//              //$(".poster1barbtn").hide();
//               $("#poster1bar").hide();
//              // $(".modal-container").hide();
//               // $(".progress").hide();
//               $("#complete").show();

//               // window.location.reload();
//             })
//             .catch((err) => {
//               UnauthorizedService.unauthorized(err);
//             });
//         } else if (upstatus === "failed") {

//           Swal.fire({
//             // position: 'top-end',
//             icon: "danger",
//             title: "File Upload Failed!.",
//             showConfirmButton: false,
//             timer: 2000,
//           });
//         }
//       }
//     )
//     .onError((error) => {
//       //setFile(undefined);
//       console.error(error);
//     });

//   uploader.start();
// };
onFileChange036 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange36()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs3" + "/poster36" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs3",
            "poster36",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange037 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange37()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs3" + "/poster37" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs3",
            "poster37",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange049 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange49()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs4" + "/poster49" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs4",
            "poster49",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};

onFileChange53cc = async (event) => {
  console.log(`Text to be uploaded is ${event.target.value}`);
  var filepath = event.target.value;
  PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster53cc", "txt", filepath)
    .then((result) => {
      console.log(result);
      console.log(
        "Text Uploaded successfully........................................"
      );
      // Swal.fire({
      //   // position: 'top-end',
      //   icon: "success",
      //   title: "Text Uploaded Successfully!.",
      //   showConfirmButton: false,
      //   timer: 2000,
      // });
    })
    .catch((err) => {
      UnauthorizedService.unauthorized(err);
    });
};

onFileChange53dd = async (event) => {
  console.log(`Text to be uploaded is ${event.target.value}`);
  var filepath = event.target.value;
  PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster53dd", "txt", filepath)
    .then((result) => {
      console.log(result);
      console.log(
        "Text Uploaded successfully........................................"
      );
      // Swal.fire({
      //   // position: 'top-end',
      //   icon: "success",
      //   title: "Text Uploaded Successfully!.",
      //   showConfirmButton: false,
      //   timer: 2000,
      // });
    })
    .catch((err) => {
      UnauthorizedService.unauthorized(err);
    });
};
  componentDidMount() {
    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname = EventService.getCurrentEvent();
    const filepath = "capgemini/" + lobbyeventname + "/Rs4" + "/poster3edit4g3";
    console.log(`filepath= ${filepath}`);
    PPTPathService.getFiletype(eventname, "Rs4")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster3edit4g3") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4poster3path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs4" +
              "/poster3edit4g3" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster3edit4g3: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4poster3edit4g3path:filedetails.path,
                poster3edit4g3: true,

              });
            this.setState({ R4poster3edit4g3type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname53aa = EventService.getCurrentEvent();
    const filepath53aa = "capgemini/" + lobbyeventname + "/Rs4" + "/poster53aa";
    console.log(`filepath= ${filepath53aa}`);
    PPTPathService.getFiletype(eventname53aa, "Rs4")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster53aa") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4poster53aapath:
              AWS_S3_URL +
              "capgemini/" +
              eventname53aa +
              "/Rs4" +
              "/poster53aa" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster53aa: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4poster53aapath:filedetails.path,
                poster53aa: true,

              });
            this.setState({ R4poster53aatype: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });
    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname53bb = EventService.getCurrentEvent();
    const filepath53bb = "capgemini/" + lobbyeventname + "/Rs4" + "/poster53bb";
    console.log(`filepath= ${filepath53bb}`);
    PPTPathService.getFiletype(eventname53bb, "Rs4")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster53bb") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4poster53bbpath:
              AWS_S3_URL +
              "capgemini/" +
              eventname53bb +
              "/Rs4" +
              "/poster53bb" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster53bb: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4poster53bbpath:filedetails.path,
                poster53bb: true,

              });
            this.setState({ R4poster53bbtype: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname53cc = EventService.getCurrentEvent();
    const filepath53cc = "capgemini/" + lobbyeventname + "/Rs4" + "/poster53cc";
    console.log(`filepath= ${filepath53cc}`);
    PPTPathService.getFiletype(eventname53cc, "Rs4")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster53cc") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4poster53ccpath:
              AWS_S3_URL +
              "capgemini/" +
              eventname53cc +
              "/Rs4" +
              "/poster53cc" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster53cc: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4poster53ccpath:filedetails.path,
                poster53cc: true,

              });
            this.setState({ R4poster53cctype: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname53dd = EventService.getCurrentEvent();
    const filepath53dd = "capgemini/" + lobbyeventname + "/Rs4" + "/poster53dd";
    console.log(`filepath= ${filepath53dd}`);
    PPTPathService.getFiletype(eventname53dd, "Rs4")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster53dd") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4poster53ddpath:
              AWS_S3_URL +
              "capgemini/" +
              eventname53dd +
              "/Rs4" +
              "/poster53dd" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster53dd: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4poster53ddpath:filedetails.path,
                poster53dd: true,

              });
            this.setState({ R4poster53ddtype: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });
    EventService.getSpecificEvent()
    .then((response) => {
      console.log("Eventname from getSpecificEvent");
      console.log(response.data);
      console.log(response.data.eventname);
      const eventpath = "/" + response.data.eventname;
      this.setState({ tenantname: eventpath, showLoginPage: true });
      localStorage.setItem("eventname", response.data.eventname);
      console.log(response.data.eventname);

      const user=AuthService.getCurrentUser();

      AuthService.getCurrentRole().then((roles)=>{
        if (roles) {
          console.log("Got current role from server");
          this.setState({
            currentUser: user,
            showModeratorBoard: roles.data.includes("ROLE_MODERATOR"),
            showAdminBoard: roles.data.includes("ROLE_ADMIN"),
            showAddTenant: roles.data.includes("ROLE_SUPERADMIN"),
            showAddUser: roles.data.includes("ROLE_SUPERADMIN"),
            showRegModerators:
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showRegAdmins:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN"),
            showRegSuperAdmins: roles.data.includes("ROLE_SUPERADMIN"),
            showRegUsers:
              roles.data.includes("ROLE_MODERATOR") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showCreateEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showDetailLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showFeedbackReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEdit:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showNormalUser: roles.data.includes("ROLE_USER"),
            showTenants:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            //tenantname: tenant,
          });
        }



    this.setState({
      R4poster1path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster1.png?v=" +
        num,
      R4poster2path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4p" +
        "/poster2p.png?v=" +
        num,
        R4poster6path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster6.png?v=" +
        num,
      // Rposter3path:
      //   AWS_S3_URL +
      //   "capgemini/" +
      //   lobbyeventname +
      //   "/Rs4" +
      //   "/poster3.txt?v=" +
      //   num,
        R4poster4path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster4.pdf?v=" +
        num,
        R4poster5g30path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster5g30.png?v=" +
        num,
        R4poster5g32path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster5g32.png?v=" +
        num,
        R4poster5g33path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster5g33.png?v=" +
        num,
        R4poster5g34path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster5g34.png?v=" +
        num,
        Rposter53aapath:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster53aa.txt?v=" +
        num,
        Rposter53bbpath:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster53bb.txt?v=" +
        num,
        Rposter53ccpath:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster53cc.txt?v=" +
        num,
        Rposter53ddpath:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster53dd.txt?v=" +
        num,
        poster1: true,
        poster2: true,
        poster3edit4g3: true,
        poster6: true,
        poster5g30: true,
        poster5g32: true,
        poster5g33: true,
        poster5g34: true,
        poster53aa: true,
        poster53bb: true,
        poster53cc: true,
        poster53dd: true,




    });
  })
}
    )
  }

  render() {
    function hideImg() {
      document.getElementById("myImg").style.display = "none";
    }
    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }

    function hideImg5g30() {
      document.getElementById("myImg5g30").style.display = "none";
    }
    function hideImg5g32() {
      document.getElementById("myImg5g32").style.display = "none";
    }
    function hideImg5g33() {
      document.getElementById("myImg5g33").style.display = "none";
    }
    function hideImg5g34() {
      document.getElementById("myImg5g34").style.display = "none";
    }

    const {
      poster1,
      poster2,
      poster3edit4g3,
      poster6,
      poster5g30,
      poster5g32,
      poster5g33,
      poster5g34,
      poster53aa,
      poster53bb,

      poster53cc,
      poster53dd,




      R4poster1path,
      R4poster2path,
      R4poster3edit4g3path,
      R4poster6path,

      R4poster5g30path,
      R4poster5g32path,
      R4poster5g33path,
      R4poster5g34path,
      R4poster53aapath,
      R4poster53bbpath,
      R4poster53ccpath,
      R4poster53ddpath,




      tenantname,
      progressbar,
    } = this.state;

    return (
      <div className="container-fluide">


        <div className="page-content">
          <div id="content-agenda">
            <div id="bg">
              <img src="https://d2e8va4puks5pl.cloudfront.net/VR-03/VR-03%205%20GRIDS.jpg" id="bg-image" className="mb-3" />
              <div className="sidenavogation">
                  {/* <Link to={tenantname + "/ResourceEdit"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Resource Center1</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit1"} className="Rs2  text-white buttonRes">
                              <span className="hide-menu">Resource Center2</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit2"} className="Rs3  text-white buttonRes">
                              <span className="hide-menu">Resource Center3</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit3"} className="Rs4  text-white buttonRes">
                              <span className="hide-menu">Resource Center4</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit4"} className="Rs5  text-white buttonRes">
                              <span className="hide-menu">Resource Center5</span>
                  </Link> */}
                  <Link to={tenantname + "/agendaEdit"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Back To Agenda</span>
                  </Link>

                  </div>
              <div id="Resource4g3editfive1">
                {poster1 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader1"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInput.click()}
                    >
                      <img
                        src={R4poster1path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg()}
                        id="myImg"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange01}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource4g3editfive2">
                {poster5g30 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader5g30"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInput5g30.click()}
                    >
                      <img
                        src={R4poster5g30path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg5g30()}
                        id="myImg5g30"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput5g30 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange5g30}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource4g3editfive3">
                {poster5g32 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader5g32"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInput5g32.click()}
                    >
                      <img
                        src={R4poster5g32path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg5g32()}
                        id="myImg5g32"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput5g32 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange5g32}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource4g3editfive4">
                {poster5g33 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader5g33"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInput5g33.click()}
                    >
                      <img
                        src={R4poster5g33path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg5g33()}
                        id="myImg5g33"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput5g33 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange5g33}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource4g3editfive5">
                {poster5g34 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader5g34"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInput5g34.click()}
                    >
                      <img
                        src={R4poster5g34path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg5g34()}
                        id="myImg5g34"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput5g34 = ref)}
                      type="file"
                      name="file"
                      id="posterimg5g34"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange5g34}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource5g3editfive">
                {poster2 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader2"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap5"
                      onClick={(e) => this.myInput1.click()}
                    >
                      <img
                        src={R4poster2path}
                        // src={image1}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg1()}
                        id="myImg1"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput1 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange02}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource6g3editfive1">
                {poster3edit4g3 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange03}
                        id="ResText"
                        placeholder={R4poster3edit4g3path}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource6g3editfive2">
                {poster53aa && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange53aa}
                        id="ResText"
                        placeholder={R4poster53aapath}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource6g3editfive3">
                {poster53bb && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange53bb}
                        id="ResText"
                        placeholder={R4poster53bbpath}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource6g3editfive4">
                {poster53cc && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange53cc}
                        id="ResText"
                        placeholder={R4poster53ccpath}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource6g3editfive5">
                {poster53dd && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange53dd}
                        id="ResText"
                        placeholder={R4poster53ddpath}
                      />
                    </div>
                  </form>
                )}
              </div>

          <div id="ResourceCenterg3editfive1">
          <div className="hotspot1g3editfive1">
            {/* <div className="out"></div> */}
            <div className="in1g3editfive1" onClick={this.P1openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen}
                  style={customStyles}
                  onRequestClose={this.P1closeModal}
                >
                  <span className="close" onClick={this.P1closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput23.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput24.click()}>Upload Video  </button>
                  <div id="loader4" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput23 = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange04}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput24 = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange04}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        <div id="ResourceCenterg3editfive2">
          <div className="hotspot1g3editfive2">
            {/* <div className="out"></div> */}
            <div className="in1g3editfive2" onClick={this.P1openModal46}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen46}
                  style={customStyles}
                  onRequestClose={this.P1closeModal46}
                >
                  <span className="close" onClick={this.P1closeModal46}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal46}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal46}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput23v.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput24v.click()}>Upload Video  </button>
                  <div id="loader4" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput23v = ref)}
            type="file"
            name="file"
            id="vid46"
            onChange={this.onFileChange046}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput24v = ref)}
            type="file"
            name="file"
            id="vid46"
            onChange={this.onFileChange046}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        <div id="ResourceCenterg3editfive3">
          <div className="hotspot1g3editfive3">
            {/* <div className="out"></div> */}
            <div className="in1g3editfive3" onClick={this.P1openModal47}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen47}
                  style={customStyles}
                  onRequestClose={this.P1closeModal47}
                >
                  <span className="close" onClick={this.P1closeModal47}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal47}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal47}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput23b.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput24b.click()}>Upload Video  </button>
                  <div id="loader4" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput23b = ref)}
            type="file"
            name="file"
            id="vid47"
            onChange={this.onFileChange047}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput24b = ref)}
            type="file"
            name="file"
            id="vid47"
            onChange={this.onFileChange047}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        <div id="ResourceCenterg3editfive4">
          <div className="hotspot1g3editfive4">
            {/* <div className="out"></div> */}
            <div className="in1g3editfive4" onClick={this.P1openModal48}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen48}
                  style={customStyles}
                  onRequestClose={this.P1closeModal48}
                >
                  <span className="close" onClick={this.P1closeModal48}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal48}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal48}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput23n.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput24n.click()}>Upload Video  </button>
                  <div id="loader4" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput23n = ref)}
            type="file"
            name="file"
            id="vid48"
            onChange={this.onFileChange048}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput24n = ref)}
            type="file"
            name="file"
            id="vid48"
            onChange={this.onFileChange048}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        <div id="ResourceCenterg3editfive5">
          <div className="hotspot1g3editfive5">
            {/* <div className="out"></div> */}
            <div className="in1g3editfive5" onClick={this.P1openModal49}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen49}
                  style={customStyles}
                  onRequestClose={this.P1closeModal49}
                >
                  <span className="close" onClick={this.P1closeModal49}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal49}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal49}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput23m.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput24m.click()}>Upload Video  </button>
                  <div id="loader4" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput23m = ref)}
            type="file"
            name="file"
            id="vid49"
            onChange={this.onFileChange049}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput24m = ref)}
            type="file"
            name="file"
            id="vid49"
            onChange={this.onFileChange049}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>




            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ResorceEdit4G3;
