import axios from "axios";
import authHeader from "./auth-header";
const { domain, aws_upload_link } = require("../config/config");
const API_URL = aws_upload_link;
// "https://cj9k4nzvse.execute-api.ap-southeast-2.amazonaws.com/dev/fileUploadService";

class AWSService {
  // getEvents() {
  //   return axios.get(API_URL + "getevents", { headers: authHeader() });
  // }

  getpredefinedURL(data) {
    return axios.post(API_URL, data);
  }

  uploadfile(posterurl, file, fileType) {
    var ctype=fileType;
    if(fileType=="pdf"){
      ctype="application/pdf";
      console.log("filetype=pdf");
    }
    console.log(ctype);
    return axios.put(posterurl, file, {
      headers: {
        "Content-Type": ctype,
        //   processData: false,
        cache: false,
      },
    });
  }

  // delEvent(id) {
  //   return axios.post(
  //     API_URL + "deleteevent",
  //     { id },
  //     { headers: authHeader() }
  //   );
  // }
  // UpdateEvent(id, topic, date, time, location) {
  //   return axios.post(
  //     API_URL + "updateevent",
  //     { id, topic, date, time, location },
  //     { headers: authHeader() }
  //   );
  // }
}

export default new AWSService();
