import React, { Component } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";
import AuthService from "./services/auth.service";
import EventService from "./services/event.service";
import Routesprivate from "./components/Routesprivate";
import RotateDevice from "./components/rotatedevice.component";
import Login from "./components/login.component";
import Preloader from "./components/preloader.component";
import Register from "./components/Register";
import RegisterTest from "./components/RegisterTest";
import AddTenant from "./components/AddTenant";
import AddUser from "./components/AddUser";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import RegisteredModerators from "./components/Registered_Moderators";
import RegisteredAdmins from "./components/Registered_Admins";
import RegisteredSuperAdmins from "./components/Registered_SuperAdmins";
import RegisteredUsers from "./components/Registered_Users";
import ResetPassword from "./components/resetpassword.component";
import CreateEvent from "./components/CreateEvent";
import Events from "./components/EventsList";
import Tenants from "./components/TenantsList";
import EventBus from "./common/EventBus";
import Lobby from "./components/Lobby.component";
import LobbyEdit from "./components/LobbyEdit.component";
import DetailLoginReport from "./components/DetailLoginReport";
import FeedbackReport from "./components/FeedbackReport";
import UserLoginReport from "./components/UserLoginReport";
import ContactUs from "./components/contactus.component";
import Agenda from "./components/agenda.component";
import AgendaEdit from "./components/AgendaEdit.component";
import BoardRoom from "./components/boardroom.component";
import BoardRoom2 from "./components/boardroom2.component";
import EngagementArea from "./components/Engagementarea.component";
import EngagementArea2 from "./components/Engagementarea2.component";
import Innovation from "./components/Innovation.component";
import Innovation2 from "./components/Innovation2.component";
import Resource from "./components/Resource.component";
import Resource1 from "./components/Resource1.component";
import Resource2 from "./components/Resource2.component";
import Resource3 from "./components/Resource3.component";
import Resource4 from "./components/Resource4.component";

import ResourceG2 from "./components/ResourceG2.component";
import Resource1G2 from "./components/Resource1G2.component";
import Resource2G2 from "./components/Resource2G2.component";
import Resource3G2 from "./components/Resource3G2.component";
import Resource4G2 from "./components/Resource4G2.component";
import Resource5G2 from "./components/Resource5G2.component";
import Resource5 from "./components/Resource5.component";
import ResourceG3 from "./components/ResourceG3.component";
import Resource1G3 from "./components/Resource1G3.component";
import Resource2G3 from "./components/Resource2G3.component";
import Resource3G3 from "./components/Resource3G3.component";
import Resource4G3 from "./components/Resource4G3.component";
import Resource5G3 from "./components/Resource5G3.component";



import ResourceEdit from "./components/ResourceEdit.component";

import ResourceEdit1 from "./components/ResourceEdit1.component";
import ResourceEdit2 from "./components/ResourceEdit2.component";
import ResourceEdit3 from "./components/ResourceEdit3.component";
import ResourceEdit4 from "./components/ResourceEdit4.component";
import ResourceEdit5 from "./components/ResourceEdit5.component";
import ResourceEditG2 from "./components/ResourceEditG2.component";
import ResourceEdit1G2 from "./components/ResourceEdit1G2.component";
import ResourceEdit2G2 from "./components/ResourceEdit2G2.component";
import ResourceEdit3G2 from "./components/ResourceEdit3G2.component";
import ResourceEdit4G2 from "./components/ResourceEdit4G2.component";
import ResourceEdit5G2 from "./components/ResourceEdit5G2.component";
import ResourceEditG3 from "./components/ResourceEditG3.component";
import ResourceEdit1G3 from "./components/ResourceEdit1G3.component";
import ResourceEdit2G3 from "./components/ResourceEdit2G3.component";
import ResourceEdit3G3 from "./components/ResourceEdit3G3.component";
import ResourceEdit4G3 from "./components/ResourceEdit4G3.component";
import ResourceEdit5G3 from "./components/ResourceEdit5G3.component";
import Bottomnav from "./components/bottomnav.component";
import BottomnavEdit from "./components/bottomnavEdit.component";
import NotFound from "./components/NotFound";
import BoardRoomedit1 from "./components/BoardRoomedit1.component";
import BoardRoomedit2 from "./components/BoardRoomedit2.component";
import EngagementareaEdit1 from "./components/EngagementareaEdit1.component";
import EngagementareaEdit2 from "./components/EngagementareaEdit2.component";
import InnovationEdit1 from "./components/InnovationEdit1.component";
import InnovationEdit2 from "./components/InnovationEdit2.component";
import Enter from "./components/enter.component";
import EnterBoard from "./components/entryvideo/enterboard.component";
import EnterBoard2 from "./components/entryvideo/enterboard2.component";
import EnterEng from "./components/entryvideo/enterEng.component";
import EnterEng2 from "./components/entryvideo/enterEng2.component.";
import EnterIno from "./components/entryvideo/enterIno.component";
import EnterIno2 from "./components/entryvideo/enterIno2.component";
import PrivateRouteUser from "./config/checkloginuser";
import PrivateRouteAdmin from "./config/checkloginadmin";
import logo from "./clogo11.png";
const { domain, client_port } = require("./config/config");
let tenant = "";
let subtitle;
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(255, 255, 255, 0.10)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    width: "23%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-60%, -60%)",
    overflow: "hide",
  },
};
//const navigate = useNavigate();
class App extends Component {
  constructor(props) {
    console.log("Inside App.js constrcutor");
    super(props);
    this.onchangeModetoLive = this.onchangeModetoLive.bind(this);
    this.onchangeModetoEdit = this.onchangeModetoEdit.bind(this);
    this.onRoleSet = this.onRoleSet.bind(this);
    this.logOut = this.logOut.bind(this);
    this.onChangeIntro = this.onChangeIntro.bind(this);
    console.log(client_port);

    // if (client_port == 8081) {
    //   tenant = "/capgemini/virtualtour1";
    //   console.log(tenant);
    // }
    // if (client_port == 8082) {
    //   tenant = "/capgemini/virtualtour2";
    //   console.log(tenant);
    // }

    // if (client_port == 8083) {
    //   tenant = "/capgemini/virtualtour3";
    //   console.log(tenant);
    // }

    console.log(tenant);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      showAddTenant: false,
      showAddUser: false,
      showRegModerators: false,
      showRegUsers: false,
      showRegAdmins: false,
      showRegSuperAdmins: false,
      currentUser: undefined,
      showCreateEvents: false,
      showEvents: false,
      showLoginReport: false,
      showDetailLoginReport: false,
      showFeedbackReport: false,
      showEdit: false,
      showTenants: false,
      tenantname: tenant,
      showNormalUser: false,
      modalIsOpen: false,
      showLoginPage: false,
      role: [],
    };

    // const mode = AuthService.getCurrentMode();

    // if (mode == "live") {
    //   console.log("Default mode==live");
    //   this.state = {
    //     showEdit: false,
    //   };
    // } else {
    //   console.log("Default mode==edit");
    //   this.state = {
    //     showEdit: true,
    //   };
    // }
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  onRoleSet(role) {
    console.log("onRoleSet Called");
    this.setState({
      role: role,
    });
    console.log("role");
    //localStorage.setItem("mode", "live");
    //AuthService.setCurrentMode("live");
  }

  onchangeModetoLive(e) {
    console.log("mode changed");
    console.log(this.showEdit);
    this.setState({
      showEdit: false,
    });
    //localStorage.setItem("mode", "live");
    AuthService.setCurrentMode("live");
  }

  onchangeModetoEdit(e) {
    console.log("mode changed");
    console.log(this.showEdit);
    this.setState({
      showEdit: true,
    });
    //localStorage.setItem("mode", "edit");
    AuthService.setCurrentMode("edit");
  }

  componentDidMount() {
    console.log("App.js : Inside AppcomponentDidMount()");

    EventService.getSpecificEvent()
      .then((response) => {
        console.log("Eventname from getSpecificEvent");
        console.log(response.data);
        console.log(response.data.eventname);
        const eventpath = "/" + response.data.eventname;
        this.setState({ tenantname: eventpath, showLoginPage: true });
        localStorage.setItem("eventname", response.data.eventname);
        
        localStorage.setItem("eventid", response.data.eventid)
        console.log(response.data.eventid);

        const user = AuthService.getCurrentUser();

        AuthService.getCurrentRole().then((roles) => {
          if (roles) {
            console.log("Got current role from server");
            this.setState({
              currentUser: user,
              showModeratorBoard: roles.data.includes("ROLE_MODERATOR"),
              showAdminBoard: roles.data.includes("ROLE_ADMIN"),
              showAddTenant: roles.data.includes("ROLE_SUPERADMIN"),
              showAddUser: roles.data.includes("ROLE_SUPERADMIN"),
              showRegModerators:
                roles.data.includes("ROLE_ADMIN") ||
                roles.data.includes("ROLE_SUPERADMIN"),
              showRegAdmins:
                roles.data.includes("ROLE_SUPERADMIN") ||
                roles.data.includes("ROLE_ADMIN"),
              showRegSuperAdmins: roles.data.includes("ROLE_SUPERADMIN"),
              showRegUsers:
                roles.data.includes("ROLE_MODERATOR") ||
                roles.data.includes("ROLE_ADMIN") ||
                roles.data.includes("ROLE_SUPERADMIN"),
              showCreateEvents:
                roles.data.includes("ROLE_SUPERADMIN") ||
                roles.data.includes("ROLE_ADMIN") ||
                roles.data.includes("ROLE_MODERATOR"),
              showEvents:
                roles.data.includes("ROLE_SUPERADMIN") ||
                roles.data.includes("ROLE_ADMIN") ||
                roles.data.includes("ROLE_MODERATOR"),
              showLoginReport:
                roles.data.includes("ROLE_SUPERADMIN") ||
                roles.data.includes("ROLE_ADMIN") ||
                roles.data.includes("ROLE_MODERATOR"),
              showDetailLoginReport:
                roles.data.includes("ROLE_SUPERADMIN") ||
                roles.data.includes("ROLE_ADMIN") ||
                roles.data.includes("ROLE_MODERATOR"),
              showFeedbackReport:
                roles.data.includes("ROLE_SUPERADMIN") ||
                roles.data.includes("ROLE_ADMIN") ||
                roles.data.includes("ROLE_MODERATOR"),
              showEdit:
                roles.data.includes("ROLE_SUPERADMIN") ||
                roles.data.includes("ROLE_ADMIN") ||
                roles.data.includes("ROLE_MODERATOR"),
              showNormalUser: roles.data.includes("ROLE_USER"),
              showTenants:
                roles.data.includes("ROLE_SUPERADMIN") ||
                roles.data.includes("ROLE_ADMIN") ||
                roles.data.includes("ROLE_MODERATOR"),
              //tenantname: tenant,
            });
          }

          const intro = AuthService.getCurrentIntro();
          //if (intro === 0) this.setState({ showIntro: false, showEdit: false });
          if (intro === 0) this.setState({ showIntro: false });
          else this.setState({ showIntro: true });

          const mode = AuthService.getCurrentMode();
          console.log(mode);
          const curpage = window.location.pathname.substring(
            window.location.pathname.length - 5
          );
          console.log(
            `current page is ${window.location.pathname.substring(
              window.location.pathname.length - 5
            )}`
          );

          if (
            mode == "edit" ||
            curpage.includes("Edit") ||
            curpage.includes("edit")
          ) {
            console.log("Default mode==edit");
            this.setState({ showEdit: true });
          } else {
            console.log("Default mode==live");
            this.setState({ showEdit: false });
          }

          EventBus.on("logout", () => {
            this.logOut();
          });

          EventBus.on("login", () => {
            console.log("After login");
            this.onChangeIntro();
          });

          EventBus.on("changetenant", (data) => {
            console.log("Onchangetenant called");
            console.log(data);
            this.onChangeTenant(data);
          });
        });
      })
      .catch((err) => {
        console.log("Error in fetching eventname from server");
      });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
    EventBus.remove("changetenant");
    EventBus.remove("login");
  }

  // onChangeTenant(data) {
  //   this.setState({
  //     tenantname: data,
  //   });
  // }

  onChangeIntro() {
    this.setState({
      showIntro: false,
    });
  }

  logOut() {
    // const user = AuthService.getCurrentUser();

    AuthService.logout().then(() => {
      //console.log(user.id);
      localStorage.removeItem("user");
      //localStorage.setItem("mode", "live");
      AuthService.setCurrentMode("live");
      localStorage.removeItem("eventname");
      //this.navigate("/login");
    });
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      showAddTenant: false,
      showAddUser: false,
      showRegModerators: false,
      showRegAdmins: false,
      showRegSuperAdmins: false,
      showRegUsers: false,
      showNormalUser: false,
      showCreateEvents: false,
      showEvents: false,
      showLoginReport: false,
      showDetailLoginReport: false,
      showFeedbackReport: false,
      showEdit: false,
      showTenants: false,
      currentUser: undefined,
    });
    localStorage.removeItem("user");
    localStorage.removeItem("eventname");
    //localStorage.setItem("mode", "live");
    AuthService.setCurrentMode("live");
  }

  render() {
    const {
      currentUser,
      showEdit,
      showIntro,
      showNormalUser,
      tenantname,
      showLoginPage,
    } = this.state;

    return (
      <div>
        {/* {!showNormalUser &&   !currentUser &&  !showIntro &&  (
        <Preloader tenantname={tenantname} />
        )} */}
        {/* <Preloader/> */}

        {/* <EnterBoard/> */}
        <RotateDevice />

        {/* <Routesprivate routes={data.routes} /> */}
        {currentUser && !showIntro && (
          <nav
            className="navbar navbar-expand-sm navbar-dark navbar1"
            style={{ backgroundColor: "#0070AD" }}
          >
            <Link to={"#"} className="">
              {/* Capgemini */}
              <img src={logo} id="logoC" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarToggleExternalContent"
              aria-controls="navbarToggleExternalContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className=" mr-auto collapse navbar-collapse"
              id="navbarToggleExternalContent"
            >
              {/* <li className="nav-item">
              <Link to={"/home"} className="nav-link">
                Home
              </Link>
            </li> */}
              <ul className="navbar-nav">
                {showEdit ? (
                  <li className="nav-item">
                    <Link
                      to={tenantname + "/lobby"}
                      className="nav-link"
                      onClick={this.onchangeModetoLive}
                    >
                      Edit Mode ON
                    </Link>
                  </li>
                ) : (
                  <li className="nav-item">
                    {!showNormalUser && (
                      <Link
                        to={tenantname + "/lobbyEdit"}
                        className="nav-link"
                        onClick={this.onchangeModetoEdit}
                      >
                        Live Mode ON
                      </Link>
                    )}
                  </li>
                )}
              </ul>
            </div>
            {currentUser ? (
              <div className="navbar-nav ml-auto">
                {showNormalUser && (
                  <li className="nav-item">
                    <Link
                      to={"#"}
                      className="nav-link"
                      onClick={this.openModal}
                    >
                      Contact Us
                    </Link>
                  </li>
                )}

                <li className="nav-item">
                  {/* <Link to={tenantname + "/lobby"} className="nav-link"> */}
                  <Link to="#" className="nav-link">
                    {currentUser.firstname + " " + currentUser.lastname}
                  </Link>
                </li>

                <li className="nav-item">
                  <a
                    href={tenantname + "/login"}
                    className="nav-link"
                    onClick={this.logOut}
                  >
                    LogOut
                  </a>
                </li>
              </div>
            ) : (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  {/* <Link
                    to={tenantname + "/login"}
                    tenantname={tenantname}
                    className="nav-link"
                  > */}
                  <Link
                    to={{
                      pathname: tenantname + "/login",
                      state: { tenantname: tenantname },
                    }}
                  >
                    Login
                  </Link>
                </li>

                {/* <li className="nav-item">
                <Link to={"/register"} className="nav-link">
                  Sign Up
                </Link>
              </li> */}
              </div>
            )}
          </nav>
        )}
        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            style={customStyles}
            onRequestClose={this.closeModal}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title text-white ml-3"
                  id="sentimeterLabel"
                  ref={(_subtitle) => (subtitle = _subtitle)}
                >
                  ContactUs
                </h5>
                <button
                  type="button"
                  class="close"
                  onClick={this.closeModal}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" class="white-text">
                    ×
                  </span>
                </button>
              </div>
              <div className="conatiner-fluide mt-3">
                <a href="mailto:cvebsv.in@capgemini.com">
                  <i class="fa fa-envelope ml-3 mb-3">
                    &nbsp;cvebsv.in@capgemini.com
                  </i>
                  <br />
                </a>
              </div>
            </div>
          </Modal>
        </div>
        {currentUser &&
          !showIntro &&
          (showEdit ? (
            <BottomnavEdit tenantname={tenantname} />
          ) : (
            <Bottomnav tenantname={tenantname} mode={showNormalUser} />
          ))}

        {showLoginPage && (
          <div className="mt-0 ">
            <Routes>
              <Route exact path={tenantname + "/"} element={<Login />} />
              <Route exact path={tenantname + "/login"} element={<Login />} />
              <Route
                exact
                path={tenantname + "/enter"}
                element={<PrivateRouteUser />}
              >
                <Route exact path={tenantname + "/enter"} element={<Enter />} />
              </Route>

              <Route
                exact
                path={tenantname + "/register"}
                element={<Register />}
              />
              <Route
                path={tenantname + "/registertest"}
                element={<RegisterTest />}
              />
              <Route
                exact
                path={tenantname + "/Preloader"}
                element={<Preloader />}
              />
              <Route
                exact
                path={tenantname + "/RotateDevice"}
                element={<RotateDevice />}
              />

              <Route
                path={tenantname + "/contactus"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/contactus"}
                  element={<ContactUs />}
                />
              </Route>

              <Route
                //path={tenantname + "/resetpassword"}
                path={"/resetpassword"}
                element={<ResetPassword />}
              />

              <Route
                path={tenantname + "/Lobby"}
                element={<PrivateRouteUser />}
              >
                <Route path={tenantname + "/Lobby"} element={<Lobby />} />
              </Route>

              <Route
                path={tenantname + "/lobbyedit"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/lobbyedit"}
                  element={<LobbyEdit />}
                />
              </Route>

              <Route
                path={tenantname + "/Agenda"}
                element={<PrivateRouteUser />}
              >
                <Route path={tenantname + "/Agenda"} element={<Agenda />} />
              </Route>
              <Route
                path={tenantname + "/AgendaEdit"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/AgendaEdit"}
                  element={<AgendaEdit />}
                />
              </Route>
              <Route
                path={tenantname + "/BoardRoom"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/BoardRoom"}
                  element={<BoardRoom />}
                />
              </Route>

              <Route
                path={tenantname + "/BoardRoom2"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/BoardRoom2"}
                  element={<BoardRoom2 />}
                />
              </Route>

              <Route
                path={tenantname + "/EngagementArea"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/EngagementArea"}
                  element={<EngagementArea />}
                />
              </Route>

              <Route
                path={tenantname + "/EngagementArea2"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/EngagementArea2"}
                  element={<EngagementArea2 />}
                />
              </Route>

              <Route
                path={tenantname + "/Innovation"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/Innovation"}
                  element={<Innovation />}
                />
              </Route>

              <Route
                path={tenantname + "/Innovation2"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/Innovation2"}
                  element={<Innovation2 />}
                />
              </Route>

              <Route
                path={tenantname + "/Resource"}
                element={<PrivateRouteUser />}
              >
                <Route path={tenantname + "/Resource"} element={<Resource />} />
              </Route>
              <Route
                path={tenantname + "/Resource1"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/Resource1"}
                  element={<Resource1 />}
                />
              </Route>
              <Route
                path={tenantname + "/Resource2"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/Resource2"}
                  element={<Resource2 />}
                />
              </Route>
              <Route
                path={tenantname + "/Resource3"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/Resource3"}
                  element={<Resource3 />}
                />
              </Route>
              <Route
              path={tenantname + "/ResourceG3"}
              element={<PrivateRouteUser />}
            >
              <Route
                path={tenantname + "/ResourceG3"}
                element={<ResourceG3 />}
              />
            </Route>
              <Route
                path={tenantname + "/Resource4"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/Resource4"}
                  element={<Resource4 />}
                />
              </Route>
              <Route
                path={tenantname + "/ResourceG2"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/ResourceG2"}
                  element={<ResourceG2 />}
                />
              </Route>
              <Route
                path={tenantname + "/Resource1G2"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/Resource1G2"}
                  element={<Resource1G2 />}
                />
              </Route>
              <Route
                path={tenantname + "/Resource2G2"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/Resource2G2"}
                  element={<Resource2G2 />}
                />
              </Route>
              <Route
                path={tenantname + "/Resource3G2"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/Resource3G2"}
                  element={<Resource3G2 />}
                />
              </Route>
              <Route
                path={tenantname + "/Resource4G2"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/Resource4G2"}
                  element={<Resource4G2 />}
                />
              </Route>
              <Route
                path={tenantname + "/Resource5G2"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/Resource5G2"}
                  element={<Resource5G2 />}
                />
              </Route>
              <Route
                path={tenantname + "/Resource5"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/Resource5"}
                  element={<Resource5 />}
                />
              </Route>
             
              <Route
                path={tenantname + "/Resource1G3"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/Resource1G3"}
                  element={<Resource1G3 />}
                />
              </Route>
              <Route
                path={tenantname + "/Resource2G3"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/Resource2G3"}
                  element={<Resource2G3 />}
                />
              </Route>
              <Route
                path={tenantname + "/Resource3G3"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/Resource3G3"}
                  element={<Resource3G3 />}
                />
              </Route>
              <Route
                path={tenantname + "/Resource4G3"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/Resource4G3"}
                  element={<Resource4G3 />}
                />
              </Route>
              <Route
                path={tenantname + "/Resource5G3"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/Resource5G3"}
                  element={<Resource5G3 />}
                />
              </Route>

              <Route
                path={tenantname + "/Resource5"}
                element={<PrivateRouteUser />}
              >
                <Route
                  path={tenantname + "/Resource5"}
                  element={<Resource5 />}
                />
              </Route>



              <Route
                path={tenantname + "/lobbyedit"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/lobbyedit"}
                  element={<LobbyEdit />}
                />
              </Route>

              <Route
                path={tenantname + "/EnterBoard"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/EnterBoard"}
                  element={<EnterBoard />}
                />
              </Route>

              <Route
                path={tenantname + "/EnterBoard2"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/EnterBoard2"}
                  element={<EnterBoard2 />}
                />
              </Route>

              <Route
                path={tenantname + "/EnterEng"}
                element={<PrivateRouteAdmin />}
              >
                <Route path={tenantname + "/EnterEng"} element={<EnterEng />} />
              </Route>
              <Route
                path={tenantname + "/EnterEng2"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/EnterEng2"}
                  element={<EnterEng2 />}
                />
              </Route>

              <Route
                path={tenantname + "/EnterIno"}
                element={<PrivateRouteAdmin />}
              >
                <Route path={tenantname + "/EnterIno"} element={<EnterIno />} />
              </Route>
              <Route
                path={tenantname + "/EnterIno2"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/EnterIno2"}
                  element={<EnterIno2 />}
                />
              </Route>

              <Route
                path={tenantname + "/BoardRoomedit1"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/BoardRoomedit1"}
                  element={<BoardRoomedit1 />}
                />
              </Route>

              <Route
                path={tenantname + "/BoardRoomedit2"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/BoardRoomedit2"}
                  element={<BoardRoomedit2 />}
                />
              </Route>

              <Route
                path={tenantname + "/EngagementareaEdit1"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/EngagementareaEdit1"}
                  element={<EngagementareaEdit1 />}
                />
              </Route>

              <Route
                path={tenantname + "/EngagementareaEdit2"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/EngagementareaEdit2"}
                  element={<EngagementareaEdit2 />}
                />
              </Route>

              <Route
                path={tenantname + "/InnovationEdit1"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/InnovationEdit1"}
                  element={<InnovationEdit1 />}
                />
              </Route>
              <Route
                path={tenantname + "/InnovationEdit2"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/InnovationEdit2"}
                  element={<InnovationEdit2 />}
                />
              </Route>

              <Route
                path={tenantname + "/ResourceEdit"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/ResourceEdit"}
                  element={<ResourceEdit />}
                />
              </Route>

              <Route
                path={tenantname + "/ResourceEdit1"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/ResourceEdit1"}
                  element={<ResourceEdit1 />}
                />
              </Route>

              <Route
                path={tenantname + "/ResourceEdit2"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/ResourceEdit2"}
                  element={<ResourceEdit2 />}
                />
              </Route>

              <Route
                path={tenantname + "/ResourceEdit3"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/ResourceEdit3"}
                  element={<ResourceEdit3 />}
                />
              </Route>
              <Route
                path={tenantname + "/ResourceEdit4"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/ResourceEdit4"}
                  element={<ResourceEdit4 />}
                />
                
              </Route>
              <Route
                path={tenantname + "/ResourceEdit5"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/ResourceEdit5"}
                  element={<ResourceEdit5 />}
                />
                
              </Route>
              <Route
                path={tenantname + "/ResourceEditG2"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/ResourceEditG2"}
                  element={<ResourceEditG2 />}
                />
                
              </Route>
              <Route
                path={tenantname + "/ResourceEdit1G2"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/ResourceEdit1G2"}
                  element={<ResourceEdit1G2 />}
                />
                
              </Route>
              <Route
                path={tenantname + "/ResourceEdit2G2"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/ResourceEdit2G2"}
                  element={<ResourceEdit2G2 />}
                />
                
              </Route>
              <Route
                path={tenantname + "/ResourceEdit3G2"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/ResourceEdit3G2"}
                  element={<ResourceEdit3G2 />}
                />
                
              </Route>
              <Route
                path={tenantname + "/ResourceEdit4G2"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/ResourceEdit4G2"}
                  element={<ResourceEdit4G2 />}
                />
                
              </Route>
              <Route
                path={tenantname + "/ResourceEdit5G2"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/ResourceEdit5G2"}
                  element={<ResourceEdit5G2 />}
                />
                
              </Route>
              <Route
                path={tenantname + "/ResourceEditG3"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/ResourceEditG3"}
                  element={<ResourceEditG3 />}
                />
                
              </Route>
              <Route
                path={tenantname + "/ResourceEdit1G3"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/ResourceEdit1G3"}
                  element={<ResourceEdit1G3 />}
                />
                
              </Route>
              <Route
                path={tenantname + "/ResourceEdit2G3"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/ResourceEdit2G3"}
                  element={<ResourceEdit2G3 />}
                />
                
              </Route>
              <Route
                path={tenantname + "/ResourceEdit3G3"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/ResourceEdit3G3"}
                  element={<ResourceEdit3G3 />}
                />
                
              </Route>
              <Route
                path={tenantname + "/ResourceEdit4G3"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/ResourceEdit4G3"}
                  element={<ResourceEdit4G3 />}
                />
                
              </Route>
              <Route
                path={tenantname + "/ResourceEdit5G3"}
                element={<PrivateRouteAdmin />}
              >
                <Route
                  path={tenantname + "/ResourceEdit5G3"}
                  element={<ResourceEdit5G3 />}
                />
                
              </Route>
              {/* <Route path="/Leaderboard" element={Leaderboard}/> */}
              <Route element={<NotFound />} />
            </Routes>
          </div>
        )}
        {/*<AuthVerify logOut={this.logOut}/> */}
      </div>
    );
  }
}

export default App;
