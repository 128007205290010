import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import stall1 from "./img/VR-01/VR-1 5 GRID.jpg";
import image from "./img/rs1.png";
import image1 from "./img/rs2.png";
import Modal from "react-modal";
import AWSService from "../services/aws.service";
import EventService from "../services/event.service";
import PPTPathService from "../services/pptpath.service";
import UnauthorizedService from "../common/unauthorized";
import UnAuthorizedService from "../common/unauthorized";
import AuthService from "../services/auth.service";
import { Uploader } from "../utils/upload";
import Progressbar from "../common/progressbar";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./styles/maping.css";
import $ from "jquery";
import "../App.css";
var lobbyeventname = "";
let tenant = "";
const { aws_s3_url, aws_s3_bucket_name } = require("../config/config");
const AWS_S3_URL = aws_s3_url;
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "25vh",
    marginRight: "-50%",
    width: "calc(18% - 0px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "rgba(0,0,0,.9)",
    background: "unset !important",
    border: "unset !important",

    zIndex: "99999",
    backgroundcolor: "unset !important",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(20% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding: "0 0 0 0",
  },
};
class ResorceEdit4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initially, no file is selected
      selectedFile: null,
      poster1: false,
      poster2: false,
      poster3: false,
      poster4: false,
      poster5: false,
      poster6: false,
      poster4g21: false,
      poster4g22: false,
      poster4g23: false,
      poster4g24: false,
      poster64g21:false,
      poster64g22:false,
      poster64g23:false,
      poster64g24:false,
      poster44g2:false,
      poster34g2: false,



      R4poster1path: "",
      R4poster2path: "",
      R4poster3path: "",
      R4poster4path: "",
      R4poster5path: "",
      R4poster6path: "",
      R4poster4g21path: "",
      R4poster4g22path: "",
      R4poster4g23path: "",
      R4poster4g24path: "",
      R4poster64g21path: "",
      R4poster64g22path: "",
      R4poster64g23path: "",
      R4poster64g24path: "",
      R4poster44g2path: "",
      R4poster34g2path: "",





      modalIsOpen: false,
      modalIsOpen4g2: false,
      modalIsOpen4g2a: false,
      modalIsOpen4g2b: false,
      modalIsOpen4g2c: false,




      tenantname: tenant,
      localuploader: undefined,
      progressbar: 0,
    };
  }
  P1openModal = () => {
    this.setState({ modalIsOpen: true });
  };
  P1openModal4g2 = () => {
    this.setState({ modalIsOpen4g2: true });
  };
  P1openModal4g2a = () => {
    this.setState({ modalIsOpen4g2a: true });
  };
  P1openModal4g2b = () => {
    this.setState({ modalIsOpen4g2b: true });
  };
  P1openModal4g2c = () => {
    this.setState({ modalIsOpen4g2c: true });
  };


  P1closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  P1closeModal4g2 = () => {
    this.setState({ modalIsOpen4g2: false });
  };
  P1closeModal4g2a = () => {
    this.setState({ modalIsOpen4g2a: false });
  };
  P1closeModal4g2b = () => {
    this.setState({ modalIsOpen4g2b: false });
  };
  P1closeModal4g2c = () => {
    this.setState({ modalIsOpen4g2c: false });
  };
  onFileChange44g2 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  492 || this.height != 326) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 492 + " Height= " + 326
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster44g2.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader1").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster44g2bar").show();
             $(".poster44g2barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader1").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R4poster44g2path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs4" +
                  "/poster44g2.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs4",
                "poster44g2",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader1").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChange02 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width !=  690 || this.height != 77) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 690 + " Height= " + 77
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Rs4j" + "/poster2j.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader2").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster2bar").show();
             $(".poster2barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader2").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                R4poster2path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Rs4j" +
                  "/poster2j.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Rs4",
                "poster2",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              //window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader2").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };

  onFileChange034g2 = async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster34g2", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };

  onFileChange64g21= async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster64g21", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };
  onFileChange64g22= async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster64g22", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };
  onFileChange64g23= async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster64g23", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };
  onFileChange64g24= async (event) => {
    console.log(`Text to be uploaded is ${event.target.value}`);
    var filepath = event.target.value;
    PPTPathService.setFiletype(lobbyeventname, "Rs4", "poster64g24", "txt", filepath)
      .then((result) => {
        console.log(result);
        console.log(
          "Text Uploaded successfully........................................"
        );
        // Swal.fire({
        //   // position: 'top-end',
        //   icon: "success",
        //   title: "Text Uploaded Successfully!.",
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
      })
      .catch((err) => {
        UnauthorizedService.unauthorized(err);
      });
  };


// On file select (from the pop up)
 onFileChange04 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs4g2" + "/poster4" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs4g2",
            "poster4",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};

onFileChange4g2 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs4" + "/poster4g2pdf" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs4",
            "poster4g2pdf",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange4g2a = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs4" + "/poster4g2apdf" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs4",
            "poster4g2apdf",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};

onFileChange4g2b = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs4" + "/poster4g2bpdf" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs4",
            "poster4g2bpdf",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange4g2c = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Rs4" + "/poster4g2cpdf" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Rs4",
            "poster4g2cpdf",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange4g21 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function () {
    if (this.width !=  492 || this.height != 326) {
      alert(
        // "Uploaded File Dimension: Width= " +
        //   this.width +
        //   "  Height= " +
        //   this.height +
        "Required FIle Dimension: Width = " + 492 + " Height= " + 326
      );
    } else {
      console.log("Inside file dimension are proper loop");
      uploadfile();
      //alert("File Dimensions are Proper");
    }
    _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
    var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster4g21.png";

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(file);

    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);

    this.setState({ localuploader: uploader }, () => {
      console.log(
        `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
      );
    });
    $("#loader4g21").addClass("hidden");
    this.setState({ poster: false });

    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {

            percentage = newPercentage;
            console.log(`${percentage}%`);
            $("#poster4g21bar").show();
           $(".poster4g21barbtn").hide();

          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");
            $("#loader4g21").addClass("hidden");
            this.setState({ poster: true });
            var num = Math.random();

            this.setState({
              R4poster1path:
                AWS_S3_URL +
                "capgemini/" +
                lobbyeventname +
                "/Rs4" +
                "/poster4g21.png?v=" +
                num,
            });
            PPTPathService.setFiletype(
              lobbyeventname,
              "Rs4",
              "poster4g21",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: "File Uploaded Successfully!.",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
            //window.location.reload(false);
          } else if (upstatus === "failed") {
            $("#loader4g21").addClass("hidden");
            Swal.fire({
              // position: 'top-end',
              icon: "failure",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
};
onFileChange4g22 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function () {
    if (this.width !=  492 || this.height != 326) {
      alert(
        // "Uploaded File Dimension: Width= " +
        //   this.width +
        //   "  Height= " +
        //   this.height +
        "Required FIle Dimension: Width = " + 492 + " Height= " + 326
      );
    } else {
      console.log("Inside file dimension are proper loop");
      uploadfile();
      //alert("File Dimensions are Proper");
    }
    _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
    var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster4g22.png";

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(file);

    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);

    this.setState({ localuploader: uploader }, () => {
      console.log(
        `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
      );
    });
    $("#loader4g22").addClass("hidden");
    this.setState({ poster: false });

    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {

            percentage = newPercentage;
            console.log(`${percentage}%`);
            $("#poster4g22bar").show();
           $(".poster4g22barbtn").hide();

          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");
            $("#loader4g22").addClass("hidden");
            this.setState({ poster: true });
            var num = Math.random();

            this.setState({
              R4poster1path:
                AWS_S3_URL +
                "capgemini/" +
                lobbyeventname +
                "/Rs4" +
                "/poster4g22.png?v=" +
                num,
            });
            PPTPathService.setFiletype(
              lobbyeventname,
              "Rs4",
              "poster4g22",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: "File Uploaded Successfully!.",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
            //window.location.reload(false);
          } else if (upstatus === "failed") {
            $("#loader4g22").addClass("hidden");
            Swal.fire({
              // position: 'top-end',
              icon: "failure",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
};
onFileChange4g23 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function () {
    if (this.width !=  492 || this.height != 326) {
      alert(
        // "Uploaded File Dimension: Width= " +
        //   this.width +
        //   "  Height= " +
        //   this.height +
        "Required FIle Dimension: Width = " + 492 + " Height= " + 326
      );
    } else {
      console.log("Inside file dimension are proper loop");
      uploadfile();
      //alert("File Dimensions are Proper");
    }
    _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
    var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster4g23.png";

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(file);

    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);

    this.setState({ localuploader: uploader }, () => {
      console.log(
        `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
      );
    });
    $("#loader4g23").addClass("hidden");
    this.setState({ poster: false });

    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {

            percentage = newPercentage;
            console.log(`${percentage}%`);
            $("#poster4g23bar").show();
           $(".poster4g23barbtn").hide();

          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");
            $("#loader4g23").addClass("hidden");
            this.setState({ poster: true });
            var num = Math.random();

            this.setState({
              R4poster1path:
                AWS_S3_URL +
                "capgemini/" +
                lobbyeventname +
                "/Rs4" +
                "/poster4g23.png?v=" +
                num,
            });
            PPTPathService.setFiletype(
              lobbyeventname,
              "Rs4",
              "poster4g23",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: "File Uploaded Successfully!.",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
            //window.location.reload(false);
          } else if (upstatus === "failed") {
            $("#loader4g23").addClass("hidden");
            Swal.fire({
              // position: 'top-end',
              icon: "failure",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
};

onFileChange4g24 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function () {
    if (this.width !=  492 || this.height != 326) {
      alert(
        // "Uploaded File Dimension: Width= " +
        //   this.width +
        //   "  Height= " +
        //   this.height +
        "Required FIle Dimension: Width = " + 492 + " Height= " + 326
      );
    } else {
      console.log("Inside file dimension are proper loop");
      uploadfile();
      //alert("File Dimensions are Proper");
    }
    _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
    var fileName = "capgemini/" + lobbyeventname + "/Rs4" + "/poster4g24.png";

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(file);

    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);

    this.setState({ localuploader: uploader }, () => {
      console.log(
        `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
      );
    });
    $("#loader4g24").addClass("hidden");
    this.setState({ poster: false });

    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {

            percentage = newPercentage;
            console.log(`${percentage}%`);
            $("#poster4g24bar").show();
           $(".poster4g24barbtn").hide();

          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");
            $("#loader4g24").addClass("hidden");
            this.setState({ poster: true });
            var num = Math.random();

            this.setState({
              R4poster1path:
                AWS_S3_URL +
                "capgemini/" +
                lobbyeventname +
                "/Rs4" +
                "/poster4g24.png?v=" +
                num,
            });
            PPTPathService.setFiletype(
              lobbyeventname,
              "Rs4",
              "poster4g24",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: "File Uploaded Successfully!.",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
            //window.location.reload(false);
          } else if (upstatus === "failed") {
            $("#loader4g24").addClass("hidden");
            Swal.fire({
              // position: 'top-end',
              icon: "failure",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
};
  componentDidMount() {
    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname = EventService.getCurrentEvent();
    const filepath = "capgemini/" + lobbyeventname + "/Rs4" + "/poster1";
    console.log(`filepath= ${filepath}`);
    PPTPathService.getFiletype(eventname, "Rs4")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster34g2") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4poster34g2path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs4" +
              "/poster34g2" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster34g2: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4poster34g2path:filedetails.path,
                poster34g2: true,

              });
            this.setState({ R4poster34g2type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname64g21 = EventService.getCurrentEvent();
    const filepath64g21 = "capgemini/" + lobbyeventname + "/Rs4" + "/poster64g21";
    console.log(`filepath= ${filepath64g21}`);
    PPTPathService.getFiletype(eventname64g21, "Rs4")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster64g21") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4poster64g21path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs4" +
              "/poster64g21" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster64g21: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4poster64g21path:filedetails.path,
                poster64g21: true,

              });
            this.setState({ R4poster64g21type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname64g22 = EventService.getCurrentEvent();
    const filepath64g22 = "capgemini/" + lobbyeventname + "/Rs4" + "/poster64g22";
    console.log(`filepath= ${filepath64g22}`);
    PPTPathService.getFiletype(eventname64g22, "Rs4")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster64g22") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4poster64g22path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs4" +
              "/poster64g22" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster64g22: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4poster64g22path:filedetails.path,
                poster64g22: true,

              });
            this.setState({ R4poster64g22type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname64g23 = EventService.getCurrentEvent();
    const filepath64g23 = "capgemini/" + lobbyeventname + "/Rs4" + "/poster64g23";
    console.log(`filepath= ${filepath64g23}`);
    PPTPathService.getFiletype(eventname64g23, "Rs4")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster64g23") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4poster64g23path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs4" +
              "/poster64g23" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster64g23: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4poster64g23path:filedetails.path,
                poster64g23: true,

              });
            this.setState({ R4poster64g23type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    lobbyeventname = EventService.getCurrentEvent();
    console.log(lobbyeventname);
    var num = Math.random();
    const eventname64g24 = EventService.getCurrentEvent();
    const filepath64g24 = "capgemini/" + lobbyeventname + "/Rs4" + "/poster64g24";
    console.log(`filepath= ${filepath64g24}`);
    PPTPathService.getFiletype(eventname64g24, "Rs4")
    .then((response) => {
      response.data.forEach((filedetails) => {
        if (filedetails.filename === "poster64g24") {
          console.log("filepath got from db is sssssssssssssssssssssssss");
          console.log(filedetails);
          console.log(filedetails.path);
          this.setState({
            R4poster64g24path:
              AWS_S3_URL +
              "capgemini/" +
              eventname +
              "/Rs4" +
              "/poster64g24" +
              "." +
              filedetails.filetype +
              "?v=" +
              num,
            poster64g24: true,
          });
          if (
            filedetails.filetype === "txt"

          )  {
              this.setState({
                R4poster64g24path:filedetails.path,
                poster64g24: true,

              });
            this.setState({ R4poster64g24type: "txt" });
          }
        }



      })
    })

    .catch((err) => {
      UnAuthorizedService.unauthorized(err);
    });

    EventService.getSpecificEvent()
    .then((response) => {
      console.log("Eventname from getSpecificEvent");
      console.log(response.data);
      console.log(response.data.eventname);
      const eventpath = "/" + response.data.eventname;
      this.setState({ tenantname: eventpath, showLoginPage: true });
      localStorage.setItem("eventname", response.data.eventname);
      console.log(response.data.eventname);

      const user=AuthService.getCurrentUser();

      AuthService.getCurrentRole().then((roles)=>{
        if (roles) {
          console.log("Got current role from server");
          this.setState({
            currentUser: user,
            showModeratorBoard: roles.data.includes("ROLE_MODERATOR"),
            showAdminBoard: roles.data.includes("ROLE_ADMIN"),
            showAddTenant: roles.data.includes("ROLE_SUPERADMIN"),
            showAddUser: roles.data.includes("ROLE_SUPERADMIN"),
            showRegModerators:
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showRegAdmins:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN"),
            showRegSuperAdmins: roles.data.includes("ROLE_SUPERADMIN"),
            showRegUsers:
              roles.data.includes("ROLE_MODERATOR") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_SUPERADMIN"),
            showCreateEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEvents:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showDetailLoginReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showFeedbackReport:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showEdit:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            showNormalUser: roles.data.includes("ROLE_USER"),
            showTenants:
              roles.data.includes("ROLE_SUPERADMIN") ||
              roles.data.includes("ROLE_ADMIN") ||
              roles.data.includes("ROLE_MODERATOR"),
            //tenantname: tenant,
          });
        }



    this.setState({
      R4poster1path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster1.png?v=" +
        num,
      R4poster2path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4j" +
        "/poster2j.png?v=" +
        num,
        R4poster6path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster6.png?v=" +
        num,
      // Rposter3path:
      //   AWS_S3_URL +
      //   "capgemini/" +
      //   lobbyeventname +
      //   "/Rs4" +
      //   "/poster3.txt?v=" +
      //   num,
        R4poster4path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster4.pdf?v=" +
        num,
        R4poster44g2path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster44g2.png?v=" +
        num,
        R4poster4g21path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster4g21.png?v=" +
        num,
        R4poster4g22path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster4g22.png?v=" +
        num,
        R4poster4g23path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster4g23.png?v=" +
        num,
        R4poster4g24path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster4g24.png?v=" +
        num,
        Rposter64g21path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster64g21.txt?v=" +
        num,
        Rposter64g22path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster64g22.txt?v=" +
        num,
        Rposter64g23path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster64g23.txt?v=" +
        num,
        Rposter64g24path:
        AWS_S3_URL +
        "capgemini/" +
        lobbyeventname +
        "/Rs4" +
        "/poster64g24.txt?v=" +
        num,

      poster1: true,
      poster2: true,
      poster3: true,
      poster6: true,
      poster64g21: true,
      poster64g22: true,

      poster64g23: true,
      poster64g24: true,
      poster44g2: true,
      poster34g2: true,




      poster4g21: true,
      poster4g22: true,
      poster4g23: true,
      poster4g24: true,




    });
  })
}
    )
  }

  render() {
    function hideImg() {
      document.getElementById("myImg").style.display = "none";
    }
    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }
    function hideImg4g21() {
      document.getElementById("myImg4g21").style.display = "none";
    }
    function hideImg4g22() {
      document.getElementById("myImg4g22").style.display = "none";
    }
    function hideImg4g23() {
      document.getElementById("myImg4g23").style.display = "none";
    }
    function hideImg4g24() {
      document.getElementById("myImg4g24").style.display = "none";
    }
    function hideImg44g2() {
      document.getElementById("myImg44g2").style.display = "none";
    }


    const {
      poster1,
      poster2,
      poster3,
      poster6,
      poster4g21,
      poster4g22,
      poster4g23,
      poster4g24,
      poster64g21,
      poster64g22,
      poster64g23,
      poster64g24,
      poster44g2,
      poster34g2,







      R4poster1path,
      R4poster2path,
      R4poster3path,
      R4poster6path,
      R4poster4g21path,
      R4poster4g22path,
      R4poster4g23path,
      R4poster4g24path,
      R4poster64g21path,
      R4poster64g22path,
      R4poster64g23path,
      R4poster64g24path,
      R4poster44g2path,
      R4poster34g2path,






      tenantname,
      progressbar,
    } = this.state;

    return (
      <div className="container-fluide">


        <div className="page-content">
          <div id="content-agenda">
            <div id="bg">
              <img src="https://d2e8va4puks5pl.cloudfront.net/VR-01/VR-1%205%20GRID.jpg" id="bg-image" className="mb-3" />
              <div className="sidenavogation">
                  {/* <Link to={tenantname + "/ResourceEdit"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Resource Center1</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit1"} className="Rs2  text-white buttonRes">
                              <span className="hide-menu">Resource Center2</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit2"} className="Rs3  text-white buttonRes">
                              <span className="hide-menu">Resource Center3</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit3"} className="Rs4  text-white buttonRes">
                              <span className="hide-menu">Resource Center4</span>
                  </Link>
                  <Link to={tenantname + "/ResourceEdit4"} className="Rs5  text-white buttonRes">
                              <span className="hide-menu">Resource Center5</span>
                  </Link> */}
                   <Link to={tenantname + "/agendaEdit"} className="Rs1  text-white buttonRes">
                              <span className="hide-menu">Back To Agenda</span>
                  </Link>

                  </div>
              <div id="Resource44g2">
                {poster44g2 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader1"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInput.click()}
                    >
                      <img
                        src={R4poster44g2path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg44g2()}
                        id="myImg44g2"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange44g2}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource44g2-1">
                {poster4g21 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader4g21"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInput4g21.click()}
                    >
                      <img
                        src={R4poster4g21path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg4g21()}
                        id="myImg4g21"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput4g21 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange4g21}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource44g2-2">
                {poster4g22 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader4g22"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInput4g22.click()}
                    >
                      <img
                        src={R4poster4g22path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg4g22()}
                        id="myImg4g22"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput4g22 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange4g22}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource44g2-3">
                {poster4g23 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader4g23"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInput4g23.click()}
                    >
                      <img
                        src={R4poster4g23path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg4g23()}
                        id="myImg4g23"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput4g23 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange4g23}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource44g2-4">
                {poster4g24 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader4g24"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap4"
                      onClick={(e) => this.myInput4g24.click()}
                    >
                      <img
                        src={R4poster4g24path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg4g24()}
                        id="myImg4g24"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput4g24 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange4g24}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource5edcom4">
                {poster2 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader2"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap5"
                      onClick={(e) => this.myInput1.click()}
                    >
                      <img
                        src={R4poster2path}
                        // src={image1}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg1()}
                        id="myImg1"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput1 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange02}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div>

              <div id="Resource64g2">
                {poster34g2 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange034g2}
                        id="ResText"
                        placeholder={R4poster34g2path}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource64g2-1">
                {poster64g21 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange64g21}
                        id="ResText"
                        placeholder={R4poster64g21path}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource64g2-2">
                {poster64g22 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange64g22}
                        id="ResText"
                        placeholder={R4poster64g22path}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource64g2-3">
                {poster64g23 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange64g23}
                        id="ResText"
                        placeholder={R4poster64g23path}
                      />
                    </div>
                  </form>
                )}
              </div>

              <div id="Resource64g2-4">
                {poster64g24 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader3"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div className="Resourcemap6">

                      <input
                        //  ref={(ref) => (this.myInput2 = ref)}
                        type="text"
                        name="text"

                        // accept="image/png, image/gif, image/jpeg"
                        onChange={this.onFileChange64g24}
                        id="ResText"
                        placeholder={R4poster64g24path}
                      />
                    </div>
                  </form>
                )}
              </div>

          <div id="ResourceCenter4g2">
          <div className="hotspot1">
            {/* <div className="out"></div> */}
            <div className="in1" onClick={this.P1openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen}
                  style={customStyles}
                  onRequestClose={this.P1closeModal}
                >
                  <span className="close" onClick={this.P1closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput23.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput24.click()}>Upload Video  </button>
                  <div id="loader4" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput23 = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange04}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput24 = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange04}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        <div id="ResourceCenter4g2-1">
          <div className="hotspot1">
            {/* <div className="out"></div> */}
            <div className="in1" onClick={this.P1openModal4g2}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen4g2}
                  style={customStyles}
                  onRequestClose={this.P1closeModal4g2}
                >
                  <span className="close" onClick={this.P1closeModal4g2}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput4g2.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput4g2v.click()}>Upload Video  </button>
                  <div id="loader4g2" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput4g2= ref)}
            type="file"
            name="file"
            id="vid4g2"
            onChange={this.onFileChange4g2}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput4g2v = ref)}
            type="file"
            name="file"
            id="vid4g2"
            onChange={this.onFileChange4g2}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        <div id="ResourceCenter4g2-2">
          <div className="hotspot1">
            {/* <div className="out"></div> */}
            <div className="in1" onClick={this.P1openModal4g2a}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen4g2a}
                  style={customStyles}
                  onRequestClose={this.P1closeModal4g2a}
                >
                  <span className="close" onClick={this.P1closeModal4g2a}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput4g2a.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput4g2av.click()}>Upload Video  </button>
                  <div id="loader4g2a" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput4g2a = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange4g2a}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput4g2av = ref)}
            type="file"
            name="file"
            id="vid1"
            onChange={this.onFileChange4g2a}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        <div id="ResourceCenter4g2-3">
          <div className="hotspot1">
            {/* <div className="out"></div> */}
            <div className="in1" onClick={this.P1openModal4g2b}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen4g2b}
                  style={customStyles}
                  onRequestClose={this.P1closeModal4g2b}
                >
                  <span className="close" onClick={this.P1closeModal4g2b}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput4g2b.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput4g2bv.click()}>Upload Video  </button>
                  <div id="loader4g2b" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput4g2b = ref)}
            type="file"
            name="file"
            id="vid4g2b"
            onChange={this.onFileChange4g2b}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput4g2bv = ref)}
            type="file"
            name="file"
            id="vid4g2b"
            onChange={this.onFileChange4g2b}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        <div id="ResourceCenter4g2-4">
          <div className="hotspot1">
            {/* <div className="out"></div> */}
            <div className="in1" onClick={this.P1openModal4g2c}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen4g2c}
                  style={customStyles}
                  onRequestClose={this.P1closeModal4g2c}
                >
                  <span className="close" onClick={this.P1closeModal4g2c}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div class="modal-container btn-group-vertical">
                  <button type="button" class="btn btn-primary"   id="pptupload1">Upload PPT </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput4g2c.click()}>Upload PDF  </button>
                  <button type="button" class="btn btn-primary"   onClick={(e) => this.myInput4g2cv.click()}>Upload Video  </button>
                  <div id="loader4g2c" className="lds-dual-ring hidden overlay loader-modal "></div>
                  </div>
                </Modal>


          <input
            ref={(ref) => (this.myInput4g2c = ref)}
            type="file"
            name="file"
            id="vid4g2c"
            onChange={this.onFileChange4g2c}
            accept=".pdf"
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput4g2cv = ref)}
            type="file"
            name="file"
            id="vid4g2c"
            onChange={this.onFileChange4g2c}
            accept=" video/mp4,video/x-m4v,video/*"
            style={{ display: "none" }}
          />
          <div id="ppt">
            <input type="url" style={{ display: "none" }}/>
          </div>
        </div>

        {/* <div id="Resource8">
                {poster6 && (
                  <form encType="multipart/form-data">
                    <div id="richList"></div>
                    <div
                      id="loader6"
                      className="lds-dual-ring hidden overlay"
                    ></div>
                    <div
                      className="Resourcemap8"
                      onClick={(e) => this.myInput6.click()}
                    >
                      <img
                        src={R4poster6path}
                        // src={image}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onError={() => hideImg2()}
                        id="myImg2"
                      />
                    </div>
                    <input
                      ref={(ref) => (this.myInput6 = ref)}
                      type="file"
                      name="file"
                      id="posterimg1"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={this.onFileChange06}
                      style={{ display: "none" }}
                    />
                  </form>
                )}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ResorceEdit4;
