import React from "react";
import PropTypes from "prop-types";
import videojs from "video.js";
import "video.js/dist/video-js.css";

class VideoPlayer extends React.Component {
  static propTypes = {
    src: PropTypes.string,
    poster: PropTypes.string,
    width: PropTypes.string,
  };

  componentDidMount() {
    this.player = videojs(
      this.videoPlayer,
      {
        autoplay: true,
        controls: true,
        plugins: {},
        playsinline: false,
        width: 640,
        sources: [
          {
            src: this.props.src
              ? this.props.src
              : "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          },
        ],
      },
      () => {
        // on ready function for anything else you need to do
        // after the player is set up…
      }
    );
    this.player.on("click", function () {
      // alert("video was clicked");
      // do something
    });
    // this.player.handleKeyPress(function () {
    //   alert("bigbutton key pressed");
    // });
    // // adding a button to the player
    // //var player = videojs("some-video-id");
    // var Button = videojs.getComponent("Button");
    // var button = new Button(this.player, {
    //   clickHandler: function (event) {
    //     alert("Clicked");
    //   },
    // });

    // this.player.addChild("button");

    // // console.log(button.el());

    // console.log(button.el());

    //var player = videojs("some-vid-id");
    var Component = videojs.getComponent("Component");
    var myComponent = new Component(this.player);
    myComponent.addChild("MyButton", {
      text: "Press Me",
      buttonChildExample: {
        buttonChildOption: true,
      },
    });

    // MyComponent is from the above example
    var myComp = new myComponent(this.player, {
      children: [
        "button",
        {
          name: "button",
          someOtherOption: true,
        },
        {
          name: "button",
          someOtherOption: false,
        },
      ],
    });
  }

  componentWillUnmount() {
    this.player.dispose();
    // whatever other things you need to clean up—maybe remove the DOM reference
    this.videoPlayer = undefined;
  }

  render() {
    return (
      <video
        id="videoPlayer"
        className="video-js"
        style={{ fontSize: "15px" }}
        ref={(c) => {
          this.videoPlayer = c;
        }}
      />
    );
  }
}

export default VideoPlayer;
