// config.js
const dotenv = require("dotenv");
dotenv.config();
module.exports = {
  server_port: process.env.REACT_APP_SERVER_PORT,
  domain: process.env.REACT_APP_DOMAIN,
  client_port: process.env.REACT_APP_CLIENT_PORT,
  react_env: process.env.REACT_APP_ENV,
  aws_upload_link: process.env.REACT_APP_AWS_LINK,
  aws_s3_url: process.env.REACT_APP_AWS_S3_URL,
  subdomain: process.env.REACT_APP_SUBDOMAIN,
  aws_s3_bucket_name: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
  // aws_s3_local_bucket_name: process.env.REACT_APP_AWS_S3_LOCAL_BUCKET_NAME,
};
