import axios from "axios";
import authHeader from "./auth-header";
const { server_port, domain, react_env } = require("../config/config");
let API_URL = "";
if (react_env == "local") API_URL = domain + ":" + server_port + "/";
else API_URL = domain + "/";

class EmailBlockService {
  // getEmailsBlocked() {
  //   return axios.get(API_URL + "getemails", { headers: authHeader() });
  // }
  // setEmailBlock(topic, date, time, crmid, location, eventid, tenantid) {
  //   return axios.post(
  //     API_URL + "setevent",
  //     { topic, date, time, crmid, location, eventid, tenantid },
  //     { headers: authHeader() }
  //   );
  // }
  // delEmailBlock(id) {
  //   return axios.post(
  //     API_URL + "deleteevent",
  //     { id },
  //     { headers: authHeader() }
  //   );
  // }
}

export default new EmailBlockService();
