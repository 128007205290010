import axios from "axios";
import authHeader from "./auth-header";
const {
    server_port,
    domain,
    react_env,
    subdomain,
} = require("../config/config");
let API_URL = "";
let API_EXCEL_URL = "";
if (react_env === "local") {
    API_URL = domain + ":" + server_port + "/api/auth/";
    API_EXCEL_URL = domain + ":" + server_port + "/api/excel/";
} else {
    API_URL = domain + "/api/auth/";
    API_EXCEL_URL = domain + "/api/excel/";
}
const FileDownload = require("js-file-download");
class AuthService {
    async login(email, password, userCaptcha, captcha_id) {
        const response = await axios.post(API_URL + "signin", {
            email,
            password,
            userCaptcha,
            captcha_id,
            subdomain,
        });
        if (response.data.accessToken) {
            let userdata = {
                    "accessToken": response.data.accessToken,
                    "firstname": response.data.firstname,
                    "lastname": response.data.lastname,
                    "email": response.data.email,
                    "tenantid": response.data.tenantid,
                    // "userCaptcha": response.data.userCaptcha,
                    // "captcha_id": response.data.captcha_id

                }
                // localStorage.setItem("user", JSON.stringify(response.data));
            localStorage.setItem("user", JSON.stringify(userdata));
        }
        return response.data;
    }
    async logout() {
        const response = await axios.post(
            API_URL + "signout", {}, { headers: authHeader() }
        );

        localStorage.removeItem("user");
        localStorage.removeItem("mode");
        localStorage.removeItem("intro");
        localStorage.removeItem("eventname");
        return response.data;
    }

    // register(firstname, lastname, roles, eventid, email, password, tenantid) {
    //   console.log(
    //     firstname,
    //     lastname,
    //     roles,
    //     eventid,
    //     email,
    //     password,
    //     tenantid,
    //     subdomain
    //   );
    //   return axios.post(API_URL + "signup", {
    //     firstname,
    //     lastname,
    //     roles,
    //     eventid,
    //     email,
    //     password,
    //     tenantid,
    //     subdomain,
    //   });
    // }

    // registertest(firstname, lastname, roles, email, password, tenantid) {
    //   console.log(firstname, lastname, roles, email, password, tenantid);
    //   return axios.post(API_URL + "testsignup", {
    //     firstname,
    //     lastname,
    //     roles,
    //     email,
    //     password,
    //     tenantid,
    //   });
    // }

    getCurrentUser() {
        console.log("Inside getCurrentUser()");
        return JSON.parse(localStorage.getItem("user"));
    }

    getCurrentIntro() {
        console.log("Inside getCurrentIntro()");
        return JSON.parse(localStorage.getItem("intro"));
    }

    getCurrentMode() {
        console.log("Inside getCurrentMode()");
        return localStorage.getItem("mode");
    }

    getCurrentRole() {
        return axios.get(API_URL + "getcurrentrole", {
            headers: authHeader(),
        });

    }
    getCaptcha() {
        return axios.get(API_URL + "loadcaptchaengine", {
            headers: authHeader(),
        });

    }

    setCurrentMode(mode) {
        console.log("Inside setCurrentMode()");
        //localStorage.setItem("mode", mode);
    }

    //   setCurrentMode(mode) {
    //   const response = await axios.post(API_URL + "setcurrentmode", {
    //     mode,
    //   });
    // }

    // getDetailLoginReport(page, matchstring) {
    //   // return axios.post(API_URL + "detailloginreport", {
    //   //   page,
    //   //   matchstring,
    //   // });
    //   let detlogrep = axios.post(API_URL + "detailloginreport", {
    //     page,
    //     matchstring,
    //   });
    //   console.log(detlogrep);
    //   console.log(detlogrep.response);
    //   console.log(detlogrep.response.data);
    // }

    // getDetailLoginRepDateRange(page, matchstring, startDate, endDate) {
    //   return axios.post(API_URL + "detailloginrepdaterange", {
    //     page,
    //     matchstring,
    //     startDate,
    //     endDate,
    //   });
    // }

    // getDetailloginrepsearch(page, matchstring, startDate, endDate) {
    //   return axios.post(API_URL + "detailloginrepsearch", {
    //     page,
    //     matchstring,
    //   });
    // }

    // getUserLoginRepDateRange(page, matchstring, startDate, endDate) {
    //   return axios.post(API_URL + "userloginrepdaterange", {
    //     page,
    //     matchstring,
    //     startDate,
    //     endDate,
    //   });
    // }

    // getUserLoginReport(page, matchstring) {
    //   return axios.post(API_URL + "userloginreport", {
    //     page,
    //     matchstring,
    //   });
    // }

    // getUserloginrepsearch(page, matchstring, startDate, endDate) {
    //   return axios.post(API_URL + "userloginrepsearch", {
    //     page,
    //     matchstring,
    //   });
    // }

    // downloadUserlogExcel() {
    //   return axios
    //     .get(API_EXCEL_URL + "downloaduserlogrep", {
    //       responseType: "blob",
    //     })
    //     .then((response) => {
    //       console.log(response);
    //       FileDownload(response.data, "UserLoginReport.xlsx");
    //     });
    // }

    // downloadDetailUserlogExcel() {
    //   return axios
    //     .get(API_EXCEL_URL + "downloaddetailuserlogrep", {
    //       responseType: "blob",
    //     })
    //     .then((response) => {
    //       FileDownload(response.data, "DetailedUserLoginReport.xlsx");
    //     });
    // }
}

export default new AuthService();