import axios from "axios";
import authHeader from "./auth-header";
const {
  server_port,
  domain,
  react_env,
  subdomain,
} = require("../config/config");
let API_URL = "";
if (react_env === "local") API_URL = domain + ":" + server_port + "/";
else API_URL = domain + "/";

class UserService {
  // getUserBoard() {
  //   return axios.get(API_URL + "user", { headers: authHeader() });
  // }

  // getModeratorBoard(currentPage) {
  //   console.log("Inside getModeratorBoard");
  //   console.log(currentPage);
  //   return axios.get(API_URL + "mod" + "?page=" + currentPage, {
  //     headers: authHeader(),
  //   });
  // }

  // getAdminBoard(currentPage) {
  //   return axios.get(API_URL + "admin" + "?page=" + currentPage, {
  //     headers: authHeader(),
  //   });
  // }

  // getSuperAdminBoard(currentPage) {
  //   return axios.get(API_URL + "superadmin" + "?page=" + currentPage, {
  //     headers: authHeader(),
  //   });
  // }

  // getSuperSuperAdminBoard(currentPage) {
  //   return axios.get(API_URL + "supersuperadmin" + "?page=" + currentPage, {
  //     headers: authHeader(),
  //   });
  // }

  // delAdmin(id) {
  //   return axios.post(
  //     API_URL + "deleteadmin",
  //     { id },
  //     { headers: authHeader() }
  //   );
  // }

  // delModerator(id) {
  //   return axios.post(
  //     API_URL + "deletemoderator",
  //     { id },
  //     { headers: authHeader() }
  //   );
  // }

  // delUser(id) {
  //   return axios.post(
  //     API_URL + "deleteuser",
  //     { id },
  //     { headers: authHeader() }
  //   );
  // }

  forgotpassword(email) {
    return axios.post(API_URL + "forgotpassword", {
      email,
      subdomain,
    });
  }

  // usersetpassword(email) {
  //   return axios.post(API_URL + "usersetpassword", {
  //     email,
  //     subdomain,
  //   });
  // }

  // resetpassword(email, token, password) {
  //   console.log(email);
  //   console.log(token);
  //   console.log(password);

  //   return axios.post(API_URL + "resetpassword", {
  //     email,
  //     token,
  //     password,
  //   });
  // }
}

export default new UserService();
