import React, { useState ,useEffect} from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import FeedbackService from "../services/feedback.service";
import EventService from "../services/event.service";
import home from "./img/house-solid.png";
import "../App.css";
import "./styles/maping.css";

import {
  BrowserRouter as Router,
  useNavigate,
  useLocation,
} from "react-router-dom";
import async from "marzipano/src/util/async";

const { domain, client_port } = require("../config/config");
const customStyles2 = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(255, 255, 255, 0.10)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const Bottomnav = ({ tenantname, mode }) => {
  const [showNormalUser, setNormalUser] = useState(mode);
  const [open, setOpen] = React.useState(true);
  const [rating, setRating] = useState(undefined);
  const [event, setevent] = useState([]);
  // const [dataupdated, setDataupdated] = useState(true);
  const [comments, setComments] = useState(undefined);
  const [isBoardRoom1, setisBoardRoom1] = useState(true);
  const [isBoardRoom2, setisBoardRoom2] = useState(true);
  const [isEngagement1, setisEngagement1] = useState(true);
  const [isEngagement2, setisEngagement2] = useState(true);
  const [isInnovationLab1, setisInnovationLab1] = useState(true);
  const [isInnovationLab2, setisInnovationLab2] = useState(true);
  let subtitle;
  const [dataupdated, setDataupdated] = useState(true);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }
  function closeModal() {
    setIsOpen(false);
  }
  const onChangeRating = (event) => {
    console.log(event.target.value);
    setRating(event.target.value);
  };
  const onChangeComments = (event) => {
    console.log(event.target.value);
    setComments(event.target.value);
  };
  const onSubmit = () => {
    FeedbackService.setFeedback(rating, comments).then(() => {
      console.log("Feedback Submitted Successfully");
      setIsOpen(false);
    });
  };
  useEffect(() => {
    if (dataupdated) {
      EventService.getEvents()
        .then((response) => {
          setevent(response.data);
          console.log(response.data);
                    
            console.log(response.data.boardRoomNum);
            if(response.data.isBoardRoom ==true){
              if(response.data.boardRoomNum == '1'){
                // console.log( response.data[0].id);
                setisBoardRoom1(!isBoardRoom2);
              }
              else if(response.data.boardRoomNum == '2'){
                setisBoardRoom1(!isBoardRoom1);
                setisBoardRoom2(!isBoardRoom2);
              }
            }else{
              
            }
          
            if(response.data.engagementRoomNum == '1'){
              // console.log( response.data[0].id);
              setisEngagement1(!isEngagement2);
            }
            else if(response.data.engagementRoomNum == '2'){
              setisEngagement1(!isEngagement1);
              setisEngagement2(!isEngagement2);
            }
            if(response.data.innovationLabNum == '1'){
              // console.log( response.data[0].id);
              setisInnovationLab1(!isInnovationLab2);
            }
            else if(response.data.innovationLabNum == '2'){
              setisInnovationLab1(!isInnovationLab1);
              setisInnovationLab2(!isInnovationLab2);
            }
        })
        // .catch((err) => {
        //   UnAuthorizedService.unauthorized(err);
        // });
                  
      setDataupdated(false);

     
    }
  }, [dataupdated]);


  
  return (
    <div>
      <link
        rel="stylesheet"
        href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css"
        integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p"
        crossOrigin="anonymous"
      />

      <nav
        className="navbar fixed-bottom navbar-expand-sm"
        style={{ backgroundColor: "#0070AD" }}
        
      >
        {!showNormalUser && (
          
          <ul className="nav mr-auto ml-auto d-inline-flex">
            <li className="nav-item">
              <a
                href={tenantname + "/Agendaedit"}
                className="nav-link text-white"
                title="Go To Agenda"
              >
                <i className="fa fa-clipboard-list pl-3 "></i>
                <br />
                <span className="hide-menu">Agenda</span>
              </a>
            </li>
            <li className="nav-item">
              <Link
                to={tenantname + "/lobbyedit"}
                className="nav-link text-white"
                title="Go To Lobby"
              >
                <i className="fa fa-home pl-2"></i>
                {/* <img src={home} id="ab"/> */}
                <br />
                <span className="hide-menu">Lobby</span>
              </Link>
            </li>
            {!isBoardRoom1  ?   (
            <li className="nav-item">
              <a
                href={tenantname + "/BoardRoomedit1"}
                className="nav-link text-white"
                title="Go To Lobby"
              >
                <i className="fas fa-location-arrow pl-4"></i>
                <br />
                <span className="hide-menu">Boardroom 1</span>
              </a>
            </li>
            ) :null}
            {!isBoardRoom2  ?   (
              <li className="nav-item">
              <a
                href={tenantname + "/BoardRoomedit2"}
                className="nav-link text-white"
                title="Go To Lobby"
              >
                <i className="fas fa-location-arrow pl-4"></i>
                <br />
                <span className="hide-menu">Boardroom 2</span>
              </a>
            </li>
                              ):  null}
                              {!isEngagement1  ?   (
            <li className="nav-item">
              <a
                href={tenantname + "/EngagementareaEdit1"}
                className="nav-link text-white"
                title="Go To Engagement Area"
              >
                <i className="fa fa-chalkboard-teacher pl-5"></i>
                <br />
                <span className="hide-menu">EngageArea1</span>
              </a>
            </li>  )  :null}
            {!isEngagement2  ?   (
              <li className="nav-item">
              <a
                href={tenantname + "/EngagementareaEdit2"}
                className="nav-link text-white"
                title="Go To Engagement Area2"
              >
                <i className="fa fa-chalkboard-teacher pl-5"></i>
                <br />
                <span className="hide-menu">EngageArea2</span>
              </a>
            </li>            ):null }
            {!isInnovationLab1  ?  (
            <li className="nav-item">
              <a
                href={tenantname + "/InnovationEdit1"}
                className="nav-link text-white"
                title="Go To Innovation Area"
              >
                <i className="fas fa-network-wired pl-5"></i>
                <br />
                <span className="hide-menu">InnovativeLab 1</span>
              </a>
            </li>):null }
            {!isInnovationLab2  ?  (  <li className="nav-item">
              <a
                href={tenantname + "/InnovationEdit2"}
                className="nav-link text-white"
                title="Go To Innovation2 Area"
              >
                <i className="fas fa-network-wired pl-5"></i>
                <br />
                <span className="hide-menu">InnovativeLab 2</span>
              </a>
            </li> ):null }
            {/* </ul> */}
            <li className="nav-item">
              <a
                href={tenantname + "/ResourceEdit"}
                className="nav-link text-white"
                title="Go To Resource Area"
              >
                <i className="fa fa-box-open pl-5"></i>
                <br />
                <span className="hide-menu">Resource Center</span>
              </a>
            </li>
            {/* <li class="nav-item">
              <Link
                to={tenantname + "/feedback"}
                className="nav-link text-white"
                title="Go To Feedback Area"
              >
                <i class="fa fa-box-open pl-3"></i>
                <br />
                <span class="hide-menu">Feedback</span>
              </Link>
            </li> */}
          </ul>
        )}
        {showNormalUser && (
          <ul className="nav mr-auto ml-auto">
            {/* <li className="nav-item">
              <Link
                to={tenantname + "/Resource"}
                className="nav-link text-white"
                title="Go To Resource Area"
              >
                <i className="fa fa-box-open pl-5"></i>
                <br />
                <span className="hide-menu">Resource Center</span>
              </Link>
            </li> */}
            <li className="nav-item">
              <a
                //to={tenantname + "/feedback"}
                href={"#"}
                className="nav-link text-white"
                title="Go To Feedback Area"
                onClick={openModal}
              >
                <i className="fa fa-box-open pl-3"></i>
                <br />
                <span className="hide-menu">Feedback</span>
              </a>
            </li>
          </ul>
        )}
        <div>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles2}
            contentLabel="Example Modal"
          >
            {/* <div class="modal-dialog" role="document" style={customStyles1}> */}
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title text-white"
                  id="sentimeterLabel"
                  ref={(_subtitle) => (subtitle = _subtitle)}
                >
                  Feedback
                </h5>
                <button
                  type="button"
                  class="close"
                  onClick={closeModal}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" class="white-text">
                    ×
                  </span>
                </button>
              </div>
              <form
                className="my-2"
                action="#"
                id="feedback-form"
                method="post"
              >
                <div className="form-group ml-3">
                  <label>
                    We greatly appreciate your feedback on this platform
                  </label>
                  <textarea
                    className="input pb-5 ml-1"
                    name="comment"
                    id="comment"
                    onChange={onChangeComments}
                  ></textarea>
                </div>
                <div className="form-group ml-3">
                  <label>Rate the Conference: (1 - lowest, 5-highest) </label>
                  <br />
                  <input
                    onChange={onChangeRating}
                    type="radio"
                    name="rating"
                    value="1"
                  />{" "}
                  1 &nbsp;
                  <input
                    onChange={onChangeRating}
                    type="radio"
                    name="rating"
                    value="2"
                  />{" "}
                  2 &nbsp;
                  <input
                    onChange={onChangeRating}
                    type="radio"
                    name="rating"
                    value="3"
                  />{" "}
                  3 &nbsp;
                  <input
                    onChange={onChangeRating}
                    type="radio"
                    name="rating"
                    value="4"
                  />{" "}
                  4 &nbsp;
                  <input
                    onChange={onChangeRating}
                    type="radio"
                    name="rating"
                    value="5"
                  />{" "}
                  5
                </div>
                <div className="form-group mt-3">
                  <input type="hidden" name="user_id" id="user_id" required />
                  <button
                    type="button"
                    className="btn btn-sm btn-primary  ml-3"
                    id="subFeedback"
                    onClick={onSubmit}
                  >
                    Submit Feedback
                  </button>
                </div>
              </form>
            </div>
            {/* </div> */}
          </Modal>
        </div>
      </nav>
    </div>
  );
};
export default Bottomnav;
