import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/styles.css";
import EventBus from "../../common/EventBus";
import AuthService from "../../services/auth.service";
import EventService from "../../services/event.service";
import Preloader from "../preloader.component";
const { aws_s3_url } = require("../../config/config");
  const AWS_S3_URL = aws_s3_url;
  // const videourl=AWS_S3_URL+"walkthrough.mp4";
  const videourl="https://d2e8va4puks5pl.cloudfront.net/Transition Video.mp4";
const EnterEng = () => {
  localStorage.setItem("intro", 1);
  const navigate = useNavigate();
  var eng2entryVideo = document.getElementById("gotoEngroomvideo");
  if (eng2entryVideo) {
    eng2entryVideo.addEventListener("ended", eng2entryEnd, false);
    // window.entryVideo.currentTime = 0;
    eng2entryVideo.play();
  }

//   setTimeout(function () {
//     console.log("before sending data in eventbus");
//     EventBus.dispatch("login");
//     localStorage.setItem("intro", 0);
//     var currentuser = AuthService.getCurrentRole().then((roles)=>{
//       if (roles.data.includes("ROLE_USER")) {
//         localStorage.setItem("intro", 0);
//         const eventname = "/" + EventService.getCurrentEvent();
//         navigate(eventname + "/resource");
//         //window.location.href = "/resource";
//       } else {

//         const eventname = "/" + EventService.getCurrentEvent();
//         navigate(eventname + "/Engagementarea2");// the redirect goes here
//         //window.location.href = "/lobby";
//       }

//     })
//  },20000);
  function eng2entryEnd(e) {
    console.log("before sending data in eventbus");
    EventBus.dispatch("login");
    localStorage.setItem("intro", 0);
    // var currentuser = AuthService.getCurrentUser();
    // if (currentuser.roles.includes("ROLE_USER")) {
    //   navigate("/resource");
    //   //window.location.href = "/resource";
    // } else {
      const eventname = "/" + EventService.getCurrentEvent();
      navigate(eventname + "/Engagementarea2");
      //window.location.href = "/lobby";
  //  }
    window.location.reload();
  }
  function skipEntry() {
    console.log("before sending data in eventbus skipEntry");
    EventBus.dispatch("login");
    localStorage.setItem("intro", 0);
    var currentuser = AuthService.getCurrentUser();
    // if (currentuser.roles.includes("ROLE_USER")) {
    //   navigate("/resource");
    //   //window.location.href = "/resource";
    // } else {
      const eventname = "/" + EventService.getCurrentEvent();
      navigate(eventname + "/Engagementarea2");
      //window.location.href = "/lobby";
   // }
    // window.location.reload();
  }
  function load(){
    window.location.reload();
  }

  return (
    <>
      <section className="videotoplay" id="gotoentry">
        <Preloader />
        <video
          className="videoplayer"
          id="gotoEngroomvideo"
          preload="auto"
          autoPlay
        >
          <source src={videourl} type="video/mp4" />
        </video>
        <div className="skip">
          <a href="#" onClick={() => skipEntry()} >
            SKIP
          </a>
        </div>
      </section>
    </>
  );
};
//}
export default EnterEng;
