import React, { PureComponent } from "react";
import Marzipano from "marzipano";
import "./styles/styles.css";
import Modal from "react-modal";
import AWSService from "../services/aws.service";
import EventService from "../services/event.service";
import PPTPathService from "../services/pptpath.service";
import UnauthorizedService from "../common/unauthorized";
import { Uploader } from "../utils/upload";
import $ from "jquery";
import Swal from "sweetalert2";
import image from "./img/image.png";
import Emg from "./img/e13.png";
import Progressbar from "../common/progressbar";
const { aws_s3_url, aws_s3_bucket_name } = require("../config/config");

const AWS_S3_URL = aws_s3_url;
var lobbyeventname = "";
const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: 1020,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "25vh",
    marginRight: "-50%",
    width: "calc(18% - 0px)",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "rgba(0,0,0,.9)",
    background: "unset !important",
    border: "unset !important",

    zIndex: "99999",
    backgroundcolor: "unset !important",
    padding: "0px 0px 0px 0px",
  },
};
const customStylesV1 = {
  overlay: {
    position: "fixed",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "calc(20% - 0px)",
    height: "100%",
    transform: "translate(-50%, -50%)",
    overflow: "hide",
    background: "unset !important",
    margintop: "calc(85vh - 20vh - 20px)",
    zIndex: "99999",
    border: "unset !important",
    padding: "0 0 0 0",
  },
};
class EngagementEdit1 extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      poster1: false,
      poster2: false,
      poster3: false,
      poster4: false,
      poster5: false,
      poster6: false,
      poster7: false,
      poster8: false,
      poster9: false,
      poster10: false,
      poster11: false,
      poster12: false,
      poster13: false,

      Eposter1path : "",
      Eposter2path : "",
      Eposter3path : "",
      Eposter4path : "",
      Eposter5path : "",
      Eposter6path : "",
      Eposter7path : "",
      Eposter8path : "",
      Eposter9path : "",
      Eposter10path : "",
      Eposter11path : "",
      Eposter12path : "",
      Eposter13path : "",
      modalIsOpen: false,
      modal2IsOpen: false,
      modal3IsOpen: false,
      modal4IsOpen: false,
      modal5IsOpen: false,
      modal6IsOpen: false,
      localuploader: undefined,
      progressbar: 0,


    }
  }
  P1openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  P1closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  P2openModal = () => {
    this.setState({ modal2IsOpen: true });
  };

  P2closeModal = () => {
    this.setState({ modal2IsOpen: false });
  };
  P3openModal = () => {
    this.setState({ modal3IsOpen: true });
  };

  P3closeModal = () => {
    this.setState({ modal3IsOpen: false });
  };
  P4openModal = () => {
    this.setState({ modal4IsOpen: true });
  };

  P4closeModal = () => {
    this.setState({ modal4IsOpen: false });
  };
  P5openModal = () => {
    this.setState({ modal5IsOpen: true });
  };

  P5closeModal = () => {
    this.setState({ modal5IsOpen: false });
  };
  P6openModal = () => {
    this.setState({ modal6IsOpen: true });
  };

  P6closeModal = () => {
    this.setState({ modal6IsOpen: false });
  };
  onCancel = async (event) => {
    console.log("OnCancel called");
    console.log(this.state.localuploader);
    if (this.state.localuploader) {
      this.state.localuploader.abort();
      //setFile(undefined);
    }
  };

 // On file select (from the pop up)
 onFileChange01 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function () {
    if (this.width != 320 || this.height != 184) {
      alert(
        // "Uploaded File Dimension: Width= " +
        //   this.width +
        //   "  Height= " +
        //   this.height +
        "Required FIle Dimension: Width = " + 320 + " Height= " + 184
      );
    } else {
      console.log("Inside file dimension are proper loop");
      uploadfile();
      //alert("File Dimensions are Proper");
    }
    _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
    var fileName = "capgemini/" + lobbyeventname + "/Eng" + "/poster1.png";

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(file);

    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);

    this.setState({ localuploader: uploader }, () => {
      console.log(
        `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
      );
    });
    $("#loader1").addClass("hidden");
    this.setState({ poster: false });

    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {

            percentage = newPercentage;
            console.log(`${percentage}%`);
            $("#poster2bar").show();
           $(".poster2barbtn").hide();

          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");
            $("#loader1").addClass("hidden");
            this.setState({ poster: true });
            var num = Math.random();

            this.setState({
              Eposter1path:
                AWS_S3_URL +
                "capgemini/" +
                lobbyeventname +
                "/Eng" +
                "/poster1.png?v=" +
                num,
            });
            PPTPathService.setFiletype(
              lobbyeventname,
              "Eng",
              "poster1",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: "File Uploaded Successfully!.",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
            window.location.reload(false);
          } else if (upstatus === "failed") {
            $("#loader1").addClass("hidden");
            Swal.fire({
              // position: 'top-end',
              icon: "failure",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
};
 // On file select (from the pop up)
 onFileChange02 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function () {
    if (this.width != 264 || this.height != 252) {
      alert(
        // "Uploaded File Dimension: Width= " +
        //   this.width +
        //   "  Height= " +
        //   this.height +
        "Required FIle Dimension: Width = " + 264 + " Height= " + 252
      );
    } else {
      console.log("Inside file dimension are proper loop");
      uploadfile();
      //alert("File Dimensions are Proper");
    }
    _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
    var fileName = "capgemini/" + lobbyeventname + "/Eng" + "/poster2.png";

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(file);

    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);

    this.setState({ localuploader: uploader }, () => {
      console.log(
        `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
      );
    });
    $("#loader1").addClass("hidden");
    this.setState({ poster: false });

    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {

            percentage = newPercentage;
            console.log(`${percentage}%`);
            $("#poster4bar").show();
           $(".poster4barbtn").hide();

          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");
            $("#loader2").addClass("hidden");
            this.setState({ poster: true });
            var num = Math.random();

            this.setState({
              Eposter2path:
                AWS_S3_URL +
                "capgemini/" +
                lobbyeventname +
                "/Eng" +
                "/poster2.png?v=" +
                num,
            });
            PPTPathService.setFiletype(
              lobbyeventname,
              "Eng",
              "poster2",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: "File Uploaded Successfully!.",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
            window.location.reload(false);
          } else if (upstatus === "failed") {
            $("#loader2").addClass("hidden");
            Swal.fire({
              // position: 'top-end',
              icon: "failure",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
};
   // On file select (from the pop up)
 onFileChange03 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function () {
    if (this.width != 512 || this.height != 170) {
      alert(
        // "Uploaded File Dimension: Width= " +
        //   this.width +
        //   "  Height= " +
        //   this.height +
        "Required FIle Dimension: Width = " + 512 + " Height= " + 170
      );
    } else {
      console.log("Inside file dimension are proper loop");
      uploadfile();
      //alert("File Dimensions are Proper");
    }
    _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
    var fileName = "capgemini/" + lobbyeventname + "/Eng" + "/poster3.png";

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(file);

    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);

    this.setState({ localuploader: uploader }, () => {
      console.log(
        `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
      );
    });
    $("#loader3").addClass("hidden");
    this.setState({ poster: false });

    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {

            percentage = newPercentage;
            console.log(`${percentage}%`);
            $("#poster2bar").show();
           $(".poster2barbtn").hide();

          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");
            $("#loader3").addClass("hidden");
            this.setState({ poster: true });
            var num = Math.random();

            this.setState({
              Eposter3path:
                AWS_S3_URL +
                "capgemini/" +
                lobbyeventname +
                "/Eng" +
                "/poster3.png?v=" +
                num,
            });
            PPTPathService.setFiletype(
              lobbyeventname,
              "Eng",
              "poster3",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: "File Uploaded Successfully!.",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
            window.location.reload(false);
          } else if (upstatus === "failed") {
            $("#loader3").addClass("hidden");
            Swal.fire({
              // position: 'top-end',
              icon: "failure",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
};
  // On file select (from the pop up)
  onFileChange04 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 240 || this.height != 706) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 320 + " Height= " + 184
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Eng" + "/poster4.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader4").addClass("hidden");
      this.setState({ poster: false });

      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {

              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster5bar").show();
             $(".poster5barbtn").hide();

            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader4").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();

              this.setState({
                Eposter4path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Eng" +
                  "/poster4.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Eng",
                "poster4",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader4").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
   // On file select (from the pop up)
 onFileChange05 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0].name);

  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  img.src = objectUrl;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  img.onload = function () {
    if (this.width != 240 || this.height != 612) {
      alert(
        // "Uploaded File Dimension: Width= " +
        //   this.width +
        //   "  Height= " +
        //   this.height +
        "Required FIle Dimension: Width = " + 240 + " Height= " + 612
      );
    } else {
      console.log("Inside file dimension are proper loop");
      uploadfile();
      //alert("File Dimensions are Proper");
    }
    _URL.revokeObjectURL(objectUrl);
  };
  // const eventname = EventService.getCurrentEvent();
  let uploadfile = () => {
    var fileName = "capgemini/" + lobbyeventname + "/Eng" + "/poster5.png";

    console.log(`filepath is ${fileName}`);
    console.log(fileName);
    console.log(file);

    let data = JSON.stringify({
      fileName: fileName,
      contentType: fileType,
      bucketname: aws_s3_bucket_name,
    });

    const videoUploaderOptions = {
      fileName: fileName,
      file: file,
    };

    let percentage = undefined;
    let upstatus = undefined;

    const uploader = new Uploader(videoUploaderOptions);

    this.setState({ localuploader: uploader }, () => {
      console.log(
        `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
      );
    });
    $("#loader5").addClass("hidden");
    this.setState({ poster: false });

    uploader
      .onProgress(
        ({
          percentage: newPercentage,
          sent: sent,
          total: total,
          upstatus: upstatus,
        }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {

            percentage = newPercentage;
            console.log(`${percentage}%`);
            $("#poster6bar").show();
           $(".poster6barbtn").hide();

          }
          if (upstatus === "complete") {
            console.log("File uploaded successfully");
            $("#loader5").addClass("hidden");
            this.setState({ poster: true });
            var num = Math.random();

            this.setState({
              Eposter5path:
                AWS_S3_URL +
                "capgemini/" +
                lobbyeventname +
                "/Eng" +
                "/poster5.png?v=" +
                num,
            });
            PPTPathService.setFiletype(
              lobbyeventname,
              "Eng",
              "poster5",
              fileType,
              filepath
            )
              .then((result) => {
                console.log(result);
                console.log(
                  "Uploaded successfully........................................"
                );
                Swal.fire({
                  // position: 'top-end',
                  icon: "success",
                  title: "File Uploaded Successfully!.",
                  showConfirmButton: false,
                  timer: 2000,
                });
              })
              .catch((err) => {
                UnauthorizedService.unauthorized(err);
              });
            window.location.reload(false);
          } else if (upstatus === "failed") {
            $("#loader5").addClass("hidden");
            Swal.fire({
              // position: 'top-end',
              icon: "failure",
              title: "File Upload Failed!.",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        }
      )
      .onError((error) => {
        //setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };
};
onFileChange06 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Eng" + "/poster6" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Eng",
            "poster6",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};

onFileChange07= async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Eng" + "/poster7" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Eng",
            "poster7",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange08 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Eng" + "/poster8" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Eng",
            "poster8",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange09 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Eng" + "/poster9" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Eng",
            "poster9",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange010 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Eng" + "/poster10" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Eng",
            "poster10",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
onFileChange011 = async (event) => {
  // Update the state
  this.setState({ selectedFile: event.target.files[0] });
  console.log("Inside this.onFileChange()");
  console.log(event.target.files[0]);
  var _URL = window.URL || window.webkitURL;
  var file, img;
  file = event.target.files[0];
  // img = new Image();
  var objectUrl = _URL.createObjectURL(event.target.files[0]);
  // img.src = objectUrl;
  //var fileType = event.target.files[0].type;
  var fileType = event.target.files[0].name.split(".").pop();
  var filepath = null;
  // const eventname = EventService.getCurrentEvent();
  // let uploadfile = () => {
  var fileName =
    "capgemini/" + lobbyeventname + "/Eng" + "/poster11" + "." + fileType;

  console.log(`filepath is ${fileName}`);
  console.log(fileName);
  console.log(fileType);
  console.log(event.target.files);
  console.log(fileType);
  var ctype = fileType;
  if (fileType == "pdf") {
    ctype = "application/pdf";
    console.log("filetype=pdf");
  }
  let data = JSON.stringify({
    fileName: fileName,
    contentType: fileType,
    //contentType: ctype,
    bucketname: aws_s3_bucket_name,
  });

  const videoUploaderOptions = {
    fileName: fileName,
    file: file,
  };

  let percentage = undefined;
  let upstatus = undefined;

  const uploader = new Uploader(videoUploaderOptions);
  this.setState({ localuploader: uploader });

  this.setState({ poster: false });
  uploader
    .onProgress(
      ({
        percentage: newPercentage,
        sent: sent,
        total: total,
        upstatus: upstatus,
      }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          this.setState({ progressbar: newPercentage });
          console.log(`${percentage}%`);
          $("#poster1bar").show();
          $(".modal-container").hide();
        }
        if (upstatus === "complete") {
          console.log("File uploaded successfully");

          this.setState({ poster: true });
          var num = Math.random();

          PPTPathService.setFiletype(
            lobbyeventname,
            "Eng",
            "poster11",
            fileType,
            filepath
          )
            .then((result) => {
              console.log(result);
              console.log(
                "Uploaded successfully........................................"
              );
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: "success",
              //   title: "File Uploaded Successfully!.",
              //   showConfirmButton: false,
              //   timer: 2000,
              // });
             //$(".poster1barbtn").hide();
              $("#poster1bar").hide();
             // $(".modal-container").hide();
              // $(".progress").hide();
              $("#complete").show();

              // window.location.reload();
            })
            .catch((err) => {
              UnauthorizedService.unauthorized(err);
            });
        } else if (upstatus === "failed") {

          Swal.fire({
            // position: 'top-end',
            icon: "danger",
            title: "File Upload Failed!.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    )
    .onError((error) => {
      //setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
  onFileChange012 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 1010 || this.height != 302) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 1010 + " Height= " + 302
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Eng" + "/poster12.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader12").addClass("hidden");
      this.setState({ poster: false });
      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster7bar").show();
              $(".poster7barbtn").hide();
            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader12").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();
              this.setState({
                Eposter12path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Eng" +
                  "/poster12.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Eng",
                "poster12",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader12").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  onFileChange013 = async (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    console.log("Inside this.onFileChange()");
    console.log(event.target.files[0].name);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    file = event.target.files[0];
    img = new Image();
    var objectUrl = _URL.createObjectURL(event.target.files[0]);
    img.src = objectUrl;
    var fileType = event.target.files[0].name.split(".").pop();
    var filepath = null;
    img.onload = function () {
      if (this.width != 508 || this.height != 476) {
        alert(
          // "Uploaded File Dimension: Width= " +
          //   this.width +
          //   "  Height= " +
          //   this.height +
          "Required FIle Dimension: Width = " + 508 + " Height= " + 476
        );
      } else {
        console.log("Inside file dimension are proper loop");
        uploadfile();
        //alert("File Dimensions are Proper");
      }
      _URL.revokeObjectURL(objectUrl);
    };
    // const eventname = EventService.getCurrentEvent();
    let uploadfile = () => {
      var fileName = "capgemini/" + lobbyeventname + "/Eng" + "/poster13.png";

      console.log(`filepath is ${fileName}`);
      console.log(fileName);
      console.log(file);

      let data = JSON.stringify({
        fileName: fileName,
        contentType: fileType,
        bucketname: aws_s3_bucket_name,
      });

      const videoUploaderOptions = {
        fileName: fileName,
        file: file,
      };

      let percentage = undefined;
      let upstatus = undefined;

      const uploader = new Uploader(videoUploaderOptions);

      this.setState({ localuploader: uploader }, () => {
        console.log(
          `uploaderrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr set ${this.localuploader}`
        );
      });
      $("#loader13").addClass("hidden");
      this.setState({ poster: false });
      uploader
        .onProgress(
          ({
            percentage: newPercentage,
            sent: sent,
            total: total,
            upstatus: upstatus,
          }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage;
              console.log(`${percentage}%`);
              $("#poster8bar").show();
              $(".poster8barbtn").hide();
            }
            if (upstatus === "complete") {
              console.log("File uploaded successfully");
              $("#loader13").addClass("hidden");
              this.setState({ poster: true });
              var num = Math.random();
              this.setState({
                Eposter13path:
                  AWS_S3_URL +
                  "capgemini/" +
                  lobbyeventname +
                  "/Eng" +
                  "/poster13.png?v=" +
                  num,
              });
              PPTPathService.setFiletype(
                lobbyeventname,
                "Eng",
                "poster13",
                fileType,
                filepath
              )
                .then((result) => {
                  console.log(result);
                  console.log(
                    "Uploaded successfully........................................"
                  );
                  Swal.fire({
                    // position: 'top-end',
                    icon: "success",
                    title: "File Uploaded Successfully!.",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                })
                .catch((err) => {
                  UnauthorizedService.unauthorized(err);
                });
              window.location.reload(false);
            } else if (upstatus === "failed") {
              $("#loader13").addClass("hidden");
              Swal.fire({
                // position: 'top-end',
                icon: "failure",
                title: "File Upload Failed!.",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        )
        .onError((error) => {
          //setFile(undefined);
          console.error(error);
        });

      uploader.start();
    };
  };
  static displayName = "Pano";
  componentDidMount() {
    lobbyeventname = EventService.getCurrentEvent();
    var num = Math.random();
    console.log(lobbyeventname);
    const filepath = "capgemini/" + lobbyeventname + "/Eng" + "/poster.png";
    console.log(`filepath= ${filepath}`);
    this.setState({
    Eposter1path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/Eng" + "/poster1.png?v=" +
      num,
    Eposter2path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/Eng" + "/poster2.png?v=" +
      num,
    Eposter3path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/Eng" + "/poster3.png?v=" +
      num,
    Eposter4path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/Eng" + "/poster4.png?v=" +
      num,
    Eposter5path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/Eng" + "/poster5.png?v=" +
      num,
      Eposter12path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/Eng" + "/poster12.png?v=" +
      num,
    Eposter6path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/Eng" + "/poster6.pptx?v=" +
      num,
    Eposter7path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/Eng" + "/poster7.pptx?v=" +
      num,
    Eposter8path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/Eng" + "/poster8.pptx?v=" +
      num,
    Eposter9path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/Eng" + "/poster9.pptx?v=" +
      num,
    Eposter10path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/Eng" + "/poster10.pptx?v=" +
      num,
      Eposter11path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/Eng" + "/poster11.pptx?v=" +
      num,

      Eposter13path :
      AWS_S3_URL + "capgemini/" + lobbyeventname + "/Eng" + "/poster13.png?v=" +
      num,
      poster1: true,
      poster2: true,
      poster3: true,
      poster4: true,
      poster5: true,
      poster6: true,
      poster7: true,
      poster8: true,
      poster9: true,
      poster10: true,
      poster11: true,
      poster12: true,
      poster13: true,

    })
    $(document).on("click", "#pptupload", function(){

      const { value: text }= Swal.fire({
        input: 'text',
        inputLabel: ' LINK',
        inputPlaceholder: 'copy the link',
      }).then((text) => {
        if (text.isConfirmed) {
          let data=Object.entries(text)[3][1];
          var parser = new DOMParser();
          var parsedIframe = parser.parseFromString(data, "text/html");
           let iFrame = parsedIframe.getElementsByTagName("iframe");
           let src = iFrame[0].src;
          console.log(src);
          Swal.fire(src);
        PPTPathService.setFiletype(
        lobbyeventname,
        "Eng",
        "poster6",
        // fileType,
        "ppt",
       src
        //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
      ) .then((result) => {
        console.log(result);
        console.log(
          "Uploaded successfully........................................"
        );

      })
      .catch(text => {
        if (text.isDenied) {
          Swal.fire('required input');
        }
      })

        }
      // .catch((err) => {
      //   UnauthorizedService.unauthorized(err);
      // })
    }
      )


   });
   $(document).on("click", "#pptupload1", function(){

    const { value: text }= Swal.fire({
      input: 'text',
      inputLabel: ' LINK',
      inputPlaceholder: 'copy the link',
    }).then((text) => {
      if (text.isConfirmed) {
        let data=Object.entries(text)[3][1];
        var parser = new DOMParser();
        var parsedIframe = parser.parseFromString(data, "text/html");
         let iFrame = parsedIframe.getElementsByTagName("iframe");
         let src = iFrame[0].src;
        console.log(src);
        Swal.fire(src);
      PPTPathService.setFiletype(
      lobbyeventname,
      "Eng",
      "poster7",
      // fileType,
      "ppt",
      src
      //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
    ) .then((result) => {
      console.log(result);
      console.log(
        "Uploaded successfully........................................"
      );

    })
    .catch(text => {
      if (text.isDenied) {
        Swal.fire('required input');
      }
    })

      }
    // .catch((err) => {
    //   UnauthorizedService.unauthorized(err);
    // })
  }
    )


 });
 $(document).on("click", "#pptupload2", function(){

  const { value: text }= Swal.fire({
    input: 'text',
    inputLabel: ' LINK',
    inputPlaceholder: 'copy the link',
  }).then((text) => {
    if (text.isConfirmed) {
      let data=Object.entries(text)[3][1];
      var parser = new DOMParser();
      var parsedIframe = parser.parseFromString(data, "text/html");
       let iFrame = parsedIframe.getElementsByTagName("iframe");
       let src = iFrame[0].src;
      console.log(src);
      Swal.fire(src);
    PPTPathService.setFiletype(
    lobbyeventname,
    "Eng",
    "poster8",
    // fileType,
    "ppt",
    src
    //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
  ) .then((result) => {
    console.log(result);
    console.log(
      "Uploaded successfully........................................"
    );

  })
  .catch(text => {
    if (text.isDenied) {
      Swal.fire('required input');
    }
  })

    }
  // .catch((err) => {
  //   UnauthorizedService.unauthorized(err);
  // })
}
  )


});
$(document).on("click", "#pptupload3", function(){

  const { value: text }= Swal.fire({
    input: 'text',
    inputLabel: ' LINK',
    inputPlaceholder: 'copy the link',
  }).then((text) => {
    if (text.isConfirmed) {
      let data=Object.entries(text)[3][1];
      var parser = new DOMParser();
      var parsedIframe = parser.parseFromString(data, "text/html");
       let iFrame = parsedIframe.getElementsByTagName("iframe");
       let src = iFrame[0].src;
      console.log(src);
      Swal.fire(src);
    PPTPathService.setFiletype(
    lobbyeventname,
    "Eng",
    "poster9",
    // fileType,
    "ppt",
   src
    //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
  ) .then((result) => {
    console.log(result);
    console.log(
      "Uploaded successfully........................................"
    );

  })
  .catch(text => {
    if (text.isDenied) {
      Swal.fire('required input');
    }
  })

    }
  // .catch((err) => {
  //   UnauthorizedService.unauthorized(err);
  // })
}
  )


});
$(document).on("click", "#pptupload4", function(){

  const { value: text }= Swal.fire({
    input: 'text',
    inputLabel: ' LINK',
    inputPlaceholder: 'copy the link',
  }).then((text) => {
    if (text.isConfirmed) {
      let data=Object.entries(text)[3][1];
      var parser = new DOMParser();
      var parsedIframe = parser.parseFromString(data, "text/html");
       let iFrame = parsedIframe.getElementsByTagName("iframe");
       let src = iFrame[0].src;
      console.log(src);
      Swal.fire(src);
    PPTPathService.setFiletype(
    lobbyeventname,
    "Eng",
    "poster10",
    // fileType,
    "ppt",
    src
    //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
  ) .then((result) => {
    console.log(result);
    console.log(
      "Uploaded successfully........................................"
    );

  })
  .catch(text => {
    if (text.isDenied) {
      Swal.fire('required input');
    }
  })

    }
  // .catch((err) => {
  //   UnauthorizedService.unauthorized(err);
  // })
}
  )


});
$(document).on("click", "#pptupload5", function(){

  const { value: text }= Swal.fire({
    input: 'text',
    inputLabel: ' LINK',
    inputPlaceholder: 'copy the link',
  }).then((text) => {
    if (text.isConfirmed) {
      let data=Object.entries(text)[3][1];
      var parser = new DOMParser();
      var parsedIframe = parser.parseFromString(data, "text/html");
       let iFrame = parsedIframe.getElementsByTagName("iframe");
       let src = iFrame[0].src;
      console.log(src);
      Swal.fire(src);
    PPTPathService.setFiletype(
    lobbyeventname,
    "Eng",
    "poster11",
    // fileType,
    "ppt",
    src
    //  "https://onedrive.live.com/embed?cid=CA582F2BC3AD1590&resid=CA582F2BC3AD1590%2133463&authkey=AFh1O3tkDLAQzzw&em=2"
  ) .then((result) => {
    console.log(result);
    console.log(
      "Uploaded successfully........................................"
    );

  })
  .catch(text => {
    if (text.isDenied) {
      Swal.fire('required input');
    }
  })

    }
  // .catch((err) => {
  //   UnauthorizedService.unauthorized(err);
  // })
}
  )


});

    this.viewer = new Marzipano.Viewer(this.pano); //, viewerOpts);

    // Create source.
    const source = Marzipano.ImageUrlSource.fromString("https://d2e8va4puks5pl.cloudfront.net/engagement.png");

    // Create geometry.
    const geometry = new Marzipano.EquirectGeometry([{ width: 5000 }]);

    const limiter = Marzipano.RectilinearView.limit.traditional(
      4*1024,
      (100 * Math.PI) / 180
    );
    const view = new Marzipano.RectilinearView({ yaw: Math.PI }, limiter);

    // Get the view values

    var yaw = view.yaw();
    var pitch = view.pitch();
    var fov = view.fov(); // fov is horizontal
    /*var vfov = view.vfov();
var hfov = view.hfov();    // same as view.fov()*/

    // Change the values

    view.setYaw((0 * Math.PI) / 180);
    view.setPitch((0 * Math.PI) / 180);
    view.setFov((120 * Math.PI) / 180);

    const scene = this.viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      pinFirstLevel: true,
    });

    scene.switchTo();

    // Get the hotspot container for scene.
    var container = scene.hotspotContainer();


    container.createHotspot(
      this.poster1,
      { yaw: 2.464, pitch: -0.01 },
      {
        perspective: {
          radius: 1400,
          extraTransforms:"rotateX(2deg) rotateY(-40deg) rotateZ(0deg)",
        },
      }
    );

    container.createHotspot(
      this.poster2,
      { yaw: 4.147, pitch: -0.0257 },
      {
        perspective: {
          radius: 1380,
          extraTransforms: "rotateX(0deg) rotateY(30deg) rotateZ(-2deg)",
        },
      }
    );
    container.createHotspot(
      this.poster3,
      { yaw: 4.72, pitch: -0.019 },
      {
        perspective: {
          radius: 2550,
          extraTransforms: "rotateX(0deg) rotateY(0deg) rotate(0deg)",
        },
      }
    );

    container.createHotspot(
      this.poster4,
      { yaw: 6.852, pitch: -0.36 },
      {
        perspective: {
          radius: 1270,
          extraTransforms: "rotateX(20deg) rotateY(35deg) rotateZ(0deg)",
        },
      }
    );

    container.createHotspot(
      this.poster5,
      { yaw: 5.242, pitch: -0.23 },
      {
        perspective: {
          radius: 2390,
          extraTransforms: "rotateX(14deg) rotateY(-60deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster12,
      {   yaw: -4.97,
        pitch: -0.11, },
      {
        perspective: {
          radius: 2590,
          extraTransforms: "rotateX(6deg) rotateY(-10deg) rotateZ(0deg)",
        },
      }
    );
    container.createHotspot(
      this.poster13,
      {   yaw: -7.539,
        pitch: -0.11, },
      {
        perspective: {
          radius: 2590,
          extraTransforms: "rotateX(8deg) rotateY(4deg) rotateZ(0deg)",
        },
      }
    );
    scene.hotspotContainer().createHotspot(document.querySelector("#pptview"), {
      yaw: -4.199,
      pitch: -0.01,
    });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview1"), {
        yaw: -3.86,
        pitch: 0.1,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview2"), {
        yaw: -2.18,
        pitch: 0.06,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview3"), {
        yaw: -0.15,
        pitch: -0.01,
      });
    scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview4"), {
        yaw: -4.78,
        pitch: -0.01,
      });
      scene
      .hotspotContainer()
      .createHotspot(document.querySelector("#pptview5"), {
        yaw: -7.45,
        pitch: -0.01,
      });

  }

  render() {
    function hideImg1() {
      document.getElementById("myImg1").style.display = "none";
    }
    function hideImg2() {
      document.getElementById("myImg2").style.display = "none";
    }
    function hideImg3() {
      document.getElementById("myImg3").style.display = "none";
    }
    function hideImg4() {
      document.getElementById("myImg4").style.display = "none";
    }
    function hideImg5() {
      document.getElementById("myImg5").style.display = "none";
    }
    function hideImg12() {
      document.getElementById("myImg12").style.display = "none";
    }
    function hideImg13() {
      document.getElementById("myImg13").style.display = "none";
    }
    const{
      poster1,
      poster2,
      poster3,
      poster4,
      poster5,
      poster6,
      poster7,
      poster8,
      poster9,
      poster10,
      poster11,
      poster12,
      poster13,
      Eposter1path,
      Eposter2path,
      Eposter3path,
      Eposter4path,
      Eposter5path,
      Eposter6path,
      Eposter7path,
      Eposter8path,
      Eposter9path,
      Eposter10path,
      Eposter11path,
      Eposter12path,
      Eposter13path,
      progressbar,

    }=this.state;
    return (
      <div className="pano-container" ref={(pano) => (this.pano = pano)}>

        <div id="Engposter2" ref={(poster1) => (this.poster1 = poster1)}>
        <div id="poster2bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster2barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete2">
            <button
                className="btn btn-xl complete2"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster1 && (
            <form encType="multipart/form-data">
              <div id="richList"></div>

              <div id="loader1" className="lds-dual-ring hidden overlay"></div>
              <div id="Emap2">
                <img
                 src={Eposter1path}
                 onError={() => hideImg1()}
                 id="myImg1"
                 style={{ cursor: "pointer" }} alt="" />
              </div>
              <input
                ref={this.imgRef}
                type="file"
                name="file"
                id="posterimg1"
                accept="image/png, image/gif, image/jpeg"
                onChange={this.onFileChange01}
                style={{ display: "none" }}
              />
            </form>
          )}
          </label>
        </div>

        <div id="Engposter4" ref={(poster2) => (this.poster2 = poster2)}>
        <div id="poster3bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster3barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete3">
            <button
                className="btn btn-xl complete3"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster2 && (
            <form encType="multipart/form-data">
              <div id="richList"></div>

              <div id="loader2" className="lds-dual-ring hidden overlay"></div>
              <div id="Emap4">
                <img
                src={Eposter2path}
                onError={() => hideImg2()}
                id="myImg2"
                style={{ cursor: "pointer" }} alt="" />
              </div>
              <input
                ref={this.imgRef}
                type="file"
                name="file"
                id="posterimg2"
                accept="image/png, image/gif, image/jpeg"
                onChange={this.onFileChange02}
                style={{ display: "none" }}
              />
            </form>
          )}
          </label>
        </div>
        <div id="Engposter5" ref={(poster3) => (this.poster3 = poster3)}>
        <div id="poster4bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster4barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete4">
            <button
                className="btn btn-xl complete4"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster3 && (
            <form encType="multipart/form-data">
              <div id="richList"></div>
              <div id="loader3" className="lds-dual-ring hidden overlay"></div>
              <div id="Emap5">
                <img src={Eposter3path} style={{ cursor: "pointer" }} onError={() => hideImg3()}
                    id="myImg3" />
              </div>
              <input
                ref={this.imgRef}
                type="file"
                name="file"
                id="posterimg3"
                accept="image/png, image/gif, image/jpeg"
                onChange={this.onFileChange03}
                style={{ display: "none" }}
              />
            </form>
          )}
          </label>
        </div>

        <div id="Engposter8" ref={(poster4) => (this.poster4 = poster4)}>
        <div id="poster5bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster5barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete5">
            <button
                className="btn btn-xl complete5"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster4 && (
            <form encType="multipart/form-data">
              <div id="richList"></div>
              <div id="loader4" className="lds-dual-ring hidden overlay"></div>
              <div id="Emap8">
                <img
                src={Eposter4path} style={{ cursor: "pointer" }} onError={() => hideImg4()}
                id="myImg4" />
              </div>
              <input
                ref={this.imgRef}
                type="file"
                name="file"
                id="posterimg1"
                accept="image/png, image/gif, image/jpeg"
                onChange={this.onFileChange04}
                style={{ display: "none" }}
              />
            </form>
          )}
          </label>
        </div>

        <div id="Engposter6a" ref={(poster5) => (this.poster5 = poster5)}>
        <div id="poster6bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster6barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete6">
            <button
                className="btn btn-xl complete6"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster5 && (
            <form encType="multipart/form-data">
              <div id="richList"></div>
              <div id="loader5" className="lds-dual-ring hidden overlay"></div>
              <div id="Emap6a">
                <img
                src={Eposter5path}
                onError={() => hideImg5()}
                    id="myImg5"
                 style={{ cursor: "pointer" }} alt="" />
              </div>
              <input
                ref={this.imgRef}
                type="file"
                name="file"
                id="posterimg1"
                onChange={this.onFileChange05}
                accept="image/png, image/gif, image/jpeg"
                style={{ display: "none" }}
              />
            </form>
          )}
          </label>
        </div>

        <div id="Engposter7a" ref={(poster12) => (this.poster12 = poster12)}>
        <div id="poster7bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster7barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete7">
            <button
                className="btn btn-xl complete7"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster12 && (
            <form encType="multipart/form-data">
              <div id="richList"></div>
              <div id="loader12" className="lds-dual-ring hidden overlay"></div>
              <div id="Emap12a">
                <img
                src={Eposter12path}
                // src={image}
                onError={() => hideImg12()}
                    id="myImg12"
                 style={{ cursor: "pointer" }} alt="" />
              </div>
              <input
                ref={this.imgRef}
                type="file"
                name="file"
                id="posterimg1"
                onChange={this.onFileChange012}
                accept="image/png, image/gif, image/jpeg"
                style={{ display: "none" }}
              />
            </form>
          )}
          </label>
        </div>
        <div id="pptview">
          <div className="hotspot">
            <div className="in"   onClick={this.P1openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modalIsOpen}
                  style={customStyles}
                  onRequestClose={this.P1closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P1closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput1.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput2.click()}>Upload Video  </button>
                  <div id="loader6" className="lds-dual-ring hidden overlay loader-modal"></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput1 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept=".pdf"
            onChange={this.onFileChange06}
            style={{ display: "none" }}
          />
           <input
            ref={(ref) => (this.myInput2 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange06}
            style={{ display: "none" }}
          />
        </div>
        <div id="pptview1">
          <div className="hotspot1">
            <div className="in1" onClick={this.P2openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modal2IsOpen}
                  style={customStyles}
                  onRequestClose={this.P2closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P2closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P2closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P2closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload1" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput3.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput4.click()}>Upload Video  </button>
                  <div id="loader7" className="lds-dual-ring hidden overlay loader-modal"></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput3 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept=".pdf"
            onChange={this.onFileChange07}
            style={{ display: "none" }}
          />
          <input
            ref={(ref) => (this.myInput4 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange07}
            style={{ display: "none" }}
          />
        </div>
        <div id="pptview2">
          <div className="hotspot2">
            {/* <div className="out"></div> */}
            <div className="in2" onClick={this.P3openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modal3IsOpen}
                  style={customStyles}
                  onRequestClose={this.P3closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P3closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P3closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P3closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload2" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput5.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput6.click()}>Upload Video  </button>
                  <div id="loader8" className="lds-dual-ring hidden overlay loader-modal"></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput5 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept=".pdf"
            onChange={this.onFileChange08}
            style={{ display: "none" }}
          />
           <input
            ref={(ref) => (this.myInput6 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept=" video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange08}
            style={{ display: "none" }}
          />
        </div>
        <div id="pptview3">
          <div className="hotspot3">
            {/* <div className="out"></div> */}
            <div className="in3"  onClick={this.P4openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modal4IsOpen}
                  style={customStyles}
                  onRequestClose={this.P4closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P4closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P4closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P4closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload3" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput7.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput8.click()}>Upload Video  </button>
                  <div id="loader9" className="lds-dual-ring hidden overlay loader-modal"></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput7 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept=".pdf"
            onChange={this.onFileChange09}
            style={{ display: "none" }}
          />
           <input
            ref={(ref) => (this.myInput8 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange09}
            style={{ display: "none" }}
          />
        </div>
        <div id="pptview4">
          <div className="hotspot4">
            {/* <div className="out"></div> */}
            <div className="in4" onClick={this.P5openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modal5IsOpen}
                  style={customStyles}
                  onRequestClose={this.P5closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P5closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P5closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P5closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload4" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput9.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput10.click()}>Upload Video  </button>
                  <div id="loader10" className="lds-dual-ring hidden overlay loader-modal"></div>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput9 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept=".pdf"
            onChange={this.onFileChange010}
            style={{ display: "none" }}
          />
           <input
            ref={(ref) => (this.myInput10 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange010}
            style={{ display: "none" }}
          />
        </div>
        <div id="pptview5">
          <div className="hotspot5">
            {/* <div className="out"></div> */}
            <div className="in5" onClick={this.P6openModal}></div>
          </div>
          <Modal
                  isOpen={this.state.modal6IsOpen}
                  style={customStyles}
                  onRequestClose={this.P6closeModal}
                  ariaHideApp={false}
                >
                  <span className="close1" onClick={this.P6closeModal}>
                    x
                  </span>
                  <div id="poster1bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster1barbtn"
                onClick={this.P6closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete">
            <button
                className="btn btn-xl complete"
                onClick={this.P6closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>

                  <div className="modal-container  btn-group-vertical">
                  <button type="button" className="btn btn-primary "   id="pptupload5" >Upload PPT </button>
                  <button type="button" className="btn btn-primary"   onClick={(e) => this.myInput11.click()}>Upload PDF  </button>
                  <button type="button"className="btn btn-primary"   onClick={(e) => this.myInput12.click()}>Upload Video  </button>
                  </div>
                </Modal>
          <input
            ref={(ref) => (this.myInput11 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept=".pdf"
            onChange={this.onFileChange011}
            style={{ display: "none" }}
          />
           <input
            ref={(ref) => (this.myInput12 = ref)}
            type="file"
            name="file"
            id="vid1"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={this.onFileChange011}
            style={{ display: "none" }}
          />
        </div>
        <div id="Engposter13" ref={(poster13) => (this.poster13 = poster13)}>
        <div id="poster8bar">
              <Progressbar
                bgcolor="orange"
                progress={progressbar}
                height={30}
                width={20}
                id="progress"
                className="progressbtn"
                autoComplete="off"
              />
              <button
                className="btn btn-primary poster8barbtn"
                // onClick={this.P1closeModal}
              >
               <span   onClick={this.onCancel}>Abort</span>
              </button>

            </div>
            <div id="complete8">
            <button
                className="btn btn-xl complete8"
                // onClick={this.P1closeModal}
              >
               <span>Uploaded Successfully!..</span>
              </button>
            </div>
          <label>
          {poster13 && (
            <form encType="multipart/form-data">
              <div id="richList"></div>
              <div id="loader13" className="lds-dual-ring hidden overlay"></div>
              <div id="Emap13">
                <img
                src={Eposter13path}
                // src={Emg}
                onError={() => hideImg13()}
                    id="myImg13"
                 style={{ cursor: "pointer" }} alt="" />
              </div>
              <input
                ref={this.imgRef}
                type="file"
                name="file"
                id="posterimg1"
                onChange={this.onFileChange013}
                accept="image/png, image/gif, image/jpeg"
                style={{ display: "none" }}
              />
            </form>
          )}
          </label>
        </div>
      </div>
    );
  }
}

export default EngagementEdit1;
